import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import convertDateTime from '../../../functions/ConvertDateTime';
import GetApelidoEquip from '../../../functions/GetApelidoEquip';
import GetNameEdificio from '../../../functions/GetNameEdificio';
import GetNameFunc from '../../../functions/GetNameFunc';

interface IProps {
  preventiva: {
    id_preventiva: number;
    id_cliente: number;
    id_equipamento: number;
    id_funcionario: number;
    nome_cliente_responsavel?: string;
    assign_cliente: boolean;
    start?: string;
    finish?: string;
    mes_ref: string;
    ano_ref: string;
    parecer_tecnico?: string;
    observacao?: string;
    status: string;
    trash: boolean;
    created_at: Date;
    updated_at: Date;
  };
}

const RowTable = ({ preventiva }: IProps) => {
  const [start, setStart] = useState('');
  const [finish, setFinish] = useState('');
  const [tecnico_responsavel, setTecnico_responsavel] = useState('');
  const [apelido_equipamento, setApelido_equipamento] = useState('');
  const [edificio, setEdificio] = useState('');

  useEffect(() => {
    convertData({ preventiva });
  }, [preventiva]);

  async function convertData({ preventiva }: IProps): Promise<void> {
    const start = await convertDateTime(preventiva.start);
    setStart(start);

    const finish = await convertDateTime(preventiva.finish);
    setFinish(finish);

    const { edificio } = await GetNameEdificio(preventiva.id_cliente);
    setEdificio(edificio);

    const apelido_equip = await GetApelidoEquip(preventiva.id_equipamento);
    setApelido_equipamento(apelido_equip);

    const { nome_apelido } = await GetNameFunc(preventiva.id_funcionario);
    setTecnico_responsavel(nome_apelido);
  }

  return (
    <tr key={preventiva.id_preventiva}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {edificio}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {apelido_equipamento}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {start}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {finish}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {tecnico_responsavel}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">
        {preventiva.status === 'concluida' ? (
          <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
            {preventiva.status}
          </span>
        ) : (
          <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
            {preventiva.status}
          </span>
        )}
      </td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <Link
          to={{
            pathname: '/preventiva',
            state: { id_preventiva: preventiva.id_preventiva, mode: 'view' },
          }}
        >
          <a className="text-blue-600 hover:text-gray-900">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </a>
        </Link>
      </td>
    </tr>
  );
};

export default RowTable;
