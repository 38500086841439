/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Image } from 'antd';

import { Container, Box } from './styles';
import api from '../../../../../services/api';

interface IProps {
  id_req_peca: number;
}

export default function ImagesReqList(props: IProps) {
  const [listImages, setListImages] = useState([]);

  useEffect(() => {
    const { id_req_peca } = props;
    getImages(id_req_peca);
  }, []);

  async function getImages(id_req_peca: number): Promise<void> {
    try {
      const response = await api.get(
        `foto?origin=req_peca&id_table_origin=${id_req_peca}`,
      );
      setListImages(response.data.data);
    } catch (err) {
      return;
    }
  }

  return (
    <>
      {listImages.map(image => (
        <div key={image.id_foto} className="inline-block mt-0.25 mr-1 h-8 w-8">
          <Image
            className="inline-block mt-0.25 mr-1 h-8 w-8"
            src={image.url}
            alt=""
          />
        </div>
      ))}
    </>
  );
}
