import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../../../services/api';
import io from '../../../services/socket';
import SpinnerLoad from '../../../components/SpinnerLoad';
import RowTable from './RowTable';
import { feedbackError } from '../../../functions/MessageFeedback';
import NavBarList from '../../../components/NavBarList';
import TopRowTable from './TopRowTable';
import { useOSView } from '../../../contexts/OSView';

export default function List() {
  const [osAbertas, setOsAbertas] = useState([]);
  const [page, setpage] = useState(1);
  const [lines_per_page, setLines_per_page] = useState(100);
  const [qtdPages, setQtdPages] = useState<number>();
  const [totalRegisters, setTotalRegisters] = useState<number>();
  const [onlyFirstAttend, setOnlyFirstAttend] = useState(false);
  const [loading, setLoading] = useState(true);

  const { openModal } = useOSView();

  const urlFilter = `&lines_per_page=${lines_per_page}&chamado=true`;

  useEffect(() => {
    getPreferencesFilter();

    getList();
  }, [page]);

  useEffect(() => {
    if (!openModal) getList();
  }, [openModal]);

  useEffect(() => {
    eventsRealTime();
  }, []);

  useEffect(() => {
    if (onlyFirstAttend) {
      localStorage.setItem('@app-biz: ShowOnlyFirstAttend', 'true');
    } else {
      localStorage.removeItem('@app-biz: ShowOnlyFirstAttend');
    }
  }, [onlyFirstAttend]);

  function getPreferencesFilter() {
    const offOnlyFirst = localStorage.getItem('@app-biz: ShowOnlyFirstAttend');

    if (offOnlyFirst == 'true') setOnlyFirstAttend(true);
  }

  function changePage(action: string) {
    if (action == 'avancar') {
      setpage(page + 1);
    }
    if (action == 'voltar' && page > 1) {
      setpage(page - 1);
    }
  }

  async function getList() {
    try {
      const response = await api.get(
        `os/pending?page=${page}${urlFilter}&situacao=liberada`,
      );

      setOsAbertas(response.data.data);

      let numPages = response.data.total / lines_per_page;
      numPages = Math.ceil(numPages);
      setQtdPages(numPages);

      setTotalRegisters(response.data.total);
      setLoading(false);
    } catch (err) {
      setLoading(false);

      feedbackError(
        'Não foi possível carregar a lista. Tentando novamente em alguns segundos...',
      );

      setTimeout(() => {
        getList();
      }, 10000);
    }
  }

  function eventsRealTime() {
    io.on('list_os', refresh => {
      getList();

      setpage(1);
    });
  }

  return (
    <div className="px-2 sm:px-4 lg:px-6">
      <div className="mt-2 sm:flex sm:items-center">
        <div className="mt-4 sm:mt-0 sm:ml-0 sm:flex-none flex flex-row">
          <Link to="/novaos">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
            >
              Criar Novo
            </button>
          </Link>
          <div className="px-4 py-2 mx-4 flex flex-row items-center">
            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="onlyfirstattend"
                  aria-describedby="onlyfirstattend"
                  name="onlyfirstattend"
                  type="checkbox"
                  checked={onlyFirstAttend}
                  onChange={() => setOnlyFirstAttend(!onlyFirstAttend)}
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="onlyfirstattend"
                  className="font-medium text-gray-700"
                >
                  Mostrar apenas não atendidos
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading ? (
        <SpinnerLoad />
      ) : (
        <div className="mt-2 flex flex-col">
          <div className="-my-2 mx-1 overflow-x-auto sm:mx-1 lg:mx-0">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
                <table className="min-w-full divide-y divide-gray-300">
                  <TopRowTable />
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {osAbertas.map(os =>
                      onlyFirstAttend &&
                      os.id_atendimento_last !== 0 &&
                      (os.status === 'aberto' ||
                        os.status === 'agendado') ? null : (
                        <RowTable key={os.id_os} os={os} />
                      ),
                    )}
                  </tbody>
                </table>
                <NavBarList
                  page={page}
                  qtdPages={qtdPages}
                  totalRegisters={totalRegisters}
                  changePage={changePage}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
