import api from '../../../../../../services/api';
import { IPreferenceNotification } from '../../../../../../types/Notification/IPreferenceNotification';

async function GetPreferencesNotifications(
  id_user: number,
): Promise<IPreferenceNotification | void> {
  try {
    const response = await api.get(`notification/preference/${id_user}`);

    const { data } = response;

    return data;
  } catch (err) {
    return;
  }
}

export default GetPreferencesNotifications;
