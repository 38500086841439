import React from 'react';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

export default function InputSelectDate(props) {
  const { setFilter_created_date_start, setFilter_created_date_end } = props;

  async function setRange(value, dateString) {
    setFilter_created_date_start(dateString[0]);
    setFilter_created_date_end(dateString[1]);
  }

  return (
    <div className="mt-0 mr-2 border border-gray-300 focus-within:border-blue-600 focus-within:rounded-lg">
      <RangePicker id="range" name="range" onChange={setRange} />
    </div>
  );
}
