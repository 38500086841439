/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MdExitToApp } from 'react-icons/md';
import api from '../../../../services/api';
import io from '../../../../services/socket';

export default function StatusFuncionarios() {
  const [funcionarios, setFuncionarios] = useState([]);

  useEffect(() => {
    getPositions();

    eventsRealTime();
  }, []);

  async function eventsRealTime() {
    io.on('reg_ponto', async () => {
      getPositions();
    });
  }

  async function getPositions() {
    try {
      const response = await api.get(`collaborator/geolocation/mapa_aovivo`);

      setFuncionarios(response.data);
    } catch (error) {
      return;
    }
  }

  const funcionariosDisponiveis = funcionarios?.map(
    (funcionario, id_funcionario) => {
      if (funcionario.status_availability === 'Disponivel') {
        return (
          <section
            className="text-green-900 font-medium"
            key={funcionario?.id_collaborator}
          >
            {funcionario.surname}
          </section>
        );
      }
    },
  );

  return (
    <div className="bg-gray-100 rounded-md px-2 py-1">
      <section className="text-green-900 font-semibold text-base mb-3">
        Disponíveis
      </section>
      {funcionariosDisponiveis.length > 0 ? funcionariosDisponiveis : null}
      <br />
      <br />
      <div className="mt-12 text-gray-dark font-bold">
        <Link className="linkSair" to="/" title="Voltar">
          <MdExitToApp size="30" />
        </Link>
      </div>
    </div>
  );
}
