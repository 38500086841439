import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
`;

export const Head = styled.div`
  flex: 1;
  margin-top: 5px;
  background-color: #3c6ed8;
  color: #fff;
  flex-direction: row;
  min-height: 70px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 5px;
  padding-top: 5px;
`;

export const LineName = styled.div`
  width: 100%;
`;

export const Edificio = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

export const ApelidoEdificio = styled.div`
  font-size: 12px;
`;

export const BoxAdress = styled.div`
  width: 95%;
  padding-top: 5px;
  margin-right: 8px;
  font-size: 10px;
`;

export const BoxAdressLeft = styled.div`
  width: 95%;
  padding-top: 5px;
  margin-right: 8px;
  font-size: 10px;
`;

export const BoxAdressRight = styled.div`
  width: 5%;
`;

export const TextAdress = styled.div`
  font-size: 12px;
`;

export const BoxObs = styled.div`
  margin-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #dadada;
`;

export const TextObs = styled.div`
  font-size: 12px;
`;
