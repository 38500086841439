import styled from 'styled-components';

interface IProps {
  active: string;
}

export const ContainerBody = styled.div`
  width: 700px;
  background-color: #fff;
  border-right: 1px solid #e8e8e8;
  padding-top: 0px;
`;

export const ContainerHeader = styled.div`
  width: 100%;
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export const BoxAvatar = styled.div`
  width: 110px;
  height: 110px;
  border-radius: 360px;
`;

export const BoxButtonsTop = styled.div`
  width: 300px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const LineName = styled.text`
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
`;

export const ContainerNavigation = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
  border-bottom: 2px solid #e8e8e8;
`;

export const BoxButtonNavigation = styled.div<IProps>`
  height: 30px;
  margin-right: 18px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: ${props => (props.active ? '2px solid #3B72FF' : null)};
`;

export const TextButtonNavigation = styled.button<IProps>`
  border: 0px;
  background-color: #fff;
  font-size: 14px;
  color: ${props => (props.active ? '#666' : '#aaa')};
  font-weight: ${props => (props.active ? 'bold' : null)};
`;
