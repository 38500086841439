import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Checkbox } from 'antd';
import { Select, MenuItem, TextField } from '@material-ui/core';
import { Form } from '@unform/web';
import { useOSBuilder } from '../../../../../contexts/OSBuilder';
import {
  BoxListReq,
  ButtonSave,
  Text,
  CardInput,
  TextTitleBox,
  CardInputText,
  BtnSave,
} from './styles';
import api from '../../../../../services/api';
import Input from '../../../../../components/Input';
import InputStatusEquipamento from '../../../../../components/InputStatusEquipamento';
import { feedbackError } from '../../../../../functions/MessageFeedback';
import AutocompleteInput from '../../../../../components/AutocompleteInput';
import SolicitanteInput from './components/SolicitanteInput';

export default function FormOS() {
  const {
    id_os_edit,
    id_cliente,
    defineLoading,
    handleOs,
    id_req_edit,
    id_equipamento_edit,
    defineIdEquipamento,
  } = useOSBuilder();

  const [listaReq, setListaReq] = useState([]);
  const [checkChamado, setCheckChamado] = useState(true);
  const [checkPassageiroPreso, setCheckPassageiroPreso] = useState(false);
  const [prioridade, setPrioridade] = useState('media');
  const [listaEquipamentos, setListaEquipamentos] = useState([]);
  const [funcionario_agendamento, setFuncionarioAgendamento] = useState();
  const [listaTecnicos, setListaTecnicos] = useState([]);
  const [visibleSnackError, setVisibleSnackError] = useState(false);
  const [descricao, setDescricao] = useState('');
  const [pre_observacao, setPre_observacao] = useState('');
  const [solicitante, setSolicitante] = useState('');
  const [id_equipamento, setid_Equipamento] = useState(0);
  const [situacao, setSituacao] = useState('liberada');
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    defineIdEquipamento(null); // Limpa o state do context

    if (id_os_edit) {
      buscaOS(id_os_edit);
      getReq(id_os_edit);
    }

    buscaEquipamentos(id_cliente);
    buscaTecnicos();
  }, []);

  async function buscaOS(id_os) {
    try {
      const response = await api.get('os/' + id_os);
      const { data } = response;

      setPrioridade(data.prioridade);
      setSolicitante(data.solicitante);
      setDescricao(data.descricao);
      setPre_observacao(data.pre_observacao);
      defineEquip(data.id_equipamento);
      setFuncionarioAgendamento(data.funcionario_agendamento);
      setSituacao(data.situacao);
      setStatus(data.status);
      setCheckChamado(data.chamado);
    } catch (err) {
      feedbackError('Falha ao carregar a OS. Tente novamente');
    }
  }

  async function getReq(id_os) {
    try {
      const response = await api.get(`req_peca?id_os=${id_os}`);
      setListaReq(response.data);
    } catch (err) {
      feedbackError('Falha ao carregar a OS. Tente novamente');
    }
  }

  async function buscaEquipamentos(id_cliente) {
    try {
      const response = await api.get(`equipamento?id_cliente=${id_cliente}`);
      const { data } = response.data;

      const num_equip = response.data.total;

      if (num_equip === 1) {
        setid_Equipamento(data[0].id_equipamento);
        defineIdEquipamento(data[0].id_equipamento);
      }

      setListaEquipamentos(response.data.data);
    } catch (err) {
      feedbackError('Falha ao carregar os equipamentos. Tente novamente');
    }
  }

  async function buscaTecnicos() {
    try {
      const response = await api.get(
        'collaborator/min?tecnico=true&status=ativo',
      );
      setListaTecnicos(response.data.data);
    } catch (err) {
      feedbackError('Falha ao carregar os técnicos. Tente novamente');
    }
  }

  async function handleSave() {
    if (!descricao) {
      feedbackError('Descrição em branco');
      return;
    }

    if (!id_equipamento) {
      feedbackError('Selecione o equipamento');
      return;
    }

    setLoading(true);

    const os = {
      chamado: checkChamado,
      solicitante,
      prioridade,
      id_equipamento,
      descricao,
      pre_observacao,
      funcionario_agendamento,
      id_cliente,
      situacao,
    };

    try {
      await handleOs(os, history);
    } catch {
      feedbackError('Erro ao salvar a OS.');
    }

    setLoading(false);
  }

  const listaEquip = listaEquipamentos.map((equipamento, id_equipamento) => {
    return (
      <MenuItem value={equipamento.id_equipamento} key={id_equipamento}>
        {equipamento.apelido}
      </MenuItem>
    );
  });

  const listaTec = listaTecnicos.map((tecnico, id_funcionario) => {
    return (
      <MenuItem value={tecnico.id_funcionario} key={id_funcionario}>
        {tecnico.nome_apelido}
      </MenuItem>
    );
  });

  function defineEquip(id_equipamento) {
    setid_Equipamento(id_equipamento);
    defineIdEquipamento(id_equipamento);
  }

  function setPassageiroPreso() {
    setCheckPassageiroPreso(!checkPassageiroPreso);

    if (!checkPassageiroPreso) {
      setPrioridade('urgente');
      setDescricao('Passageiro preso.');
    } else {
      setPrioridade('media');
      setDescricao('');
    }
  }

  return (
    <div>
      <Form>
        <CardInput>
          <Checkbox
            checked={checkChamado ? 'checked' : ''}
            onChange={() => {
              setCheckChamado(!checkChamado);
            }}
          />

          <Text>Chamado Técnico</Text>
          {checkChamado ? (
            <>
              <SolicitanteInput
                id_cliente={id_cliente}
                setSolicitante={setSolicitante}
                solicitante={solicitante}
              />
            </>
          ) : (
            <></>
          )}
        </CardInput>

        <CardInput>
          {checkChamado ? (
            <>
              <Checkbox
                checked={checkPassageiroPreso ? 'checked' : ''}
                onChange={() => {
                  setPassageiroPreso();
                }}
              />
              <Text>Tem passageiro preso? </Text>
            </>
          ) : (
            <></>
          )}
        </CardInput>

        <CardInput>
          <Select
            style={{ width: '30%', height: 40 }}
            required
            labelId="Prioridade"
            id="prioridade"
            variant="outlined"
            value={prioridade}
            onChange={e => setPrioridade(e.target.value)}
          >
            <MenuItem value="media">Média</MenuItem>
            <MenuItem value="alta">Alta</MenuItem>
            <MenuItem value="baixa">Baixa</MenuItem>
            <MenuItem value="urgente">Urgente</MenuItem>
          </Select>

          <Text>Prioridade</Text>
        </CardInput>

        <CardInput>
          <Select
            style={{ width: '50%', height: 40 }}
            required
            labelId="Equipamento"
            id="equipamento"
            variant="outlined"
            value={id_equipamento}
            onChange={e => defineEquip(e.target.value)}
          >
            {listaEquip}
          </Select>
          <Text>Elevador</Text>
        </CardInput>

        <CardInput>
          {id_equipamento_edit ? <InputStatusEquipamento /> : <></>}
        </CardInput>

        <CardInputText>
          <TextField
            style={{ marginRight: 20, marginTop: 10, width: '100%' }}
            selectionColor="#000"
            multiline
            variant="outlined"
            label="Descrição (obrigatório)"
            value={descricao}
            onChange={e => setDescricao(e.target.value)}
          />
        </CardInputText>

        <CardInputText>
          <TextField
            style={{ marginRight: 20, marginTop: 10, width: '100%' }}
            multiline
            variant="outlined"
            label="Pré-Obs."
            value={pre_observacao}
            onChange={e => setPre_observacao(e.target.value)}
          />
        </CardInputText>

        {id_os_edit ? null : (
          <CardInput>
            <Text>Indicar técnico (opcional)</Text>
            <Text>Técnico: </Text>
            <Select
              style={{ width: '35%', height: 40 }}
              labelId="Funcionário"
              id="funcionario_agendamento"
              variant="outlined"
              value={funcionario_agendamento}
              onChange={e => setFuncionarioAgendamento(e.target.value)}
            >
              <MenuItem value={null}>-</MenuItem>
              {listaTec}
            </Select>
          </CardInput>
        )}

        {/* <Text>Itens:</Text>
                <BoxListReq>
                    {listaReq.data.map(req => (
                        <Req_pecaInline req_peca={item} />
                    ))}
                    </BoxListReq> */}

        <CardInput>
          <BtnSave
            type="primary"
            disabled={loading}
            loading={loading}
            onClick={() => handleSave()}
          >
            Salvar
          </BtnSave>
        </CardInput>
      </Form>
    </div>
  );
}
