import React, { useEffect } from 'react';
import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';

interface IProps {
  status: string;
  setStatus(status: string): unknown | void;
}

const statusOptions = [
  {
    title: 'Todos',
    description: 'Mostra todos os registros. Clientes ativos e não ativos.',
    current: true,
  },
  {
    title: 'Ativos',
    description: 'Mostra somente os clientes ativos. (Atuais clientes)',
    current: false,
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function InputSelectStatus(props: IProps) {
  const [selected, setSelected] = useState(statusOptions[0]);

  const { setStatus } = props;

  useEffect(() => {
    if (selected.title === 'Ativos') {
      setStatus('ativo');
    } else {
      setStatus('%');
    }
  }, [selected]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">Filtrar status</Listbox.Label>
          <div className="relative">
            <div className="inline-flex h-full shadow-sm rounded-md divide-x divide-blue-600">
              <div className="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-blue-600">
                <div className="relative inline-flex items-start bg-blue-500 py-1 pl-3 pr-4 border border-transparent rounded-l-md shadow-sm text-white">
                  <CheckIcon className="h-5 w-5 mt-2" aria-hidden="true" />
                  <p className="ml-3 mt-2 font-medium">{selected.title}</p>
                </div>
                <Listbox.Button className="relative inline-flex items-center bg-blue-500 p-2 rounded-l-none rounded-r-md text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-500">
                  <span className="sr-only">Filtrar status</span>
                  <ChevronDownIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                </Listbox.Button>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                {statusOptions.map(option => (
                  <Listbox.Option
                    key={option.title}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-blue-500' : 'text-gray-900',
                        'cursor-default select-none relative p-4 text-sm',
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p
                            className={
                              selected ? 'font-semibold' : 'font-normal'
                            }
                          >
                            {option.title}
                          </p>
                          {selected ? (
                            <span
                              className={
                                active ? 'text-white' : 'text-blue-500'
                              }
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </div>
                        <p
                          className={classNames(
                            active ? 'text-blue-200' : 'text-gray-500',
                            'mt-2',
                          )}
                        >
                          {option.description}
                        </p>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
