import React from 'react';

interface IProps {
  title: string;
  valueLine1?: string;
  valueLine2?: string;
  valueLine3?: string;
  valueLine4?: string;
}

export default function Card(props: IProps) {
  const { title, valueLine1, valueLine2, valueLine3, valueLine4 } = props;

  return (
    <div className="sm:col-span-1 bg-gray-100 rounded-lg px-2 py-1">
      <dt className="text-sm font-medium text-gray-500">{title}</dt>
      <dd className="mt-1 text-sm text-gray-900">
        <p>{valueLine1}</p>
        {valueLine2 ? <p>{valueLine2}</p> : null}
        {valueLine3 ? <p>{valueLine3}</p> : null}
        {valueLine4 ? <p>{valueLine4}</p> : null}
      </dd>
    </div>
  );
}
