const OptionsCategories = [
  {
    value: 'A1',
    label: 'Casa de máquinas',
    children: [
      {
        value: 'A1B1',
        label: 'Quadro comando',
      },
      {
        value: 'A1B2',
        label: 'Motor',
      },
      {
        value: 'A1B3',
        label: 'Redutor',
      },
      {
        value: 'A1B4',
        label: 'Freio',
      },
      {
        value: 'A1B5',
        label: 'Quadro energia',
      },
      {
        value: 'A1B6',
        label: 'Reg. Velocidade',
      },
      {
        value: 'A1B7',
        label: 'Ambiente',
      },
      {
        value: 'A1B999',
        label: 'Outros',
      },
    ],
  },
  {
    value: 'A2',
    label: 'Topo de cabine',
    children: [
      {
        value: 'A2B1',
        label: 'Operador porta',
      },
      {
        value: 'A2B2',
        label: 'Sustentação',
      },
      {
        value: 'A2B3',
        label: 'Energia',
      },
      {
        value: 'A2B4',
        label: 'Ambiente',
      },
      {
        value: 'A2B999',
        label: 'Outros',
      },
    ],
  },
  {
    value: 'A3',
    label: 'Pavimentos',
    children: [
      {
        value: 'A3B1',
        label: 'Portas pav.',
      },
      {
        value: 'A3B2',
        label: 'Botoeiras / painel',
      },
      {
        value: 'A3B3',
        label: 'Ambiente',
      },
      {
        value: 'A3B999',
        label: 'Outros',
      },
    ],
  },
  {
    value: 'A4',
    label: 'Cabine',
    children: [
      {
        value: 'A4B1',
        label: 'Porta cabine',
      },
      {
        value: 'A4B2',
        label: 'Subteto',
      },
      {
        value: 'A4B3',
        label: 'Botoeira / painel',
      },
      {
        value: 'A4B4',
        label: 'Acabamentos',
      },
      {
        value: 'A4B5',
        label: 'Ambiente',
      },
      {
        value: 'A4B999',
        label: 'Outros',
      },
    ],
  },
  {
    value: 'A5',
    label: 'Caixa corrida',
    children: [
      {
        value: 'A5B1',
        label: 'Guias',
      },
      {
        value: 'A5B2',
        label: 'Suporte',
      },
      {
        value: 'A5B3',
        label: 'Ambiente',
      },
      {
        value: 'A5B999',
        label: 'Outros',
      },
    ],
  },
  {
    value: 'A6',
    label: 'Poço',
    children: [
      {
        value: 'A6B1',
        label: 'Energia',
      },
      {
        value: 'A6B2',
        label: 'Amortecimento',
      },
      {
        value: 'A6B3',
        label: 'Ambiente',
      },
      {
        value: 'A6B999',
        label: 'Outros',
      },
    ],
  },
  {
    value: 'A999',
    label: 'Outros',
    children: [
      {
        value: 'A999B999',
        label: 'Outros',
      },
    ],
  },
];

export { OptionsCategories };
