import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  background-color: #fff;
`;

export const FormSection = styled.div`
  width: 540px;
  min-height: 500px;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BoxForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const BoxButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 18px;
`;

export const BottomBar = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 5px;
  justify-content: flex-start;
  align-items: flex-start;
`;
