import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import Dashboard from '../pages/Dashboard';
import MapaAoVivo from '../pages/MapaAoVivo';
import OsAbertas from '../pages/OsAbertas';
import Chamados from '../pages/Chamados';
import ConsultarOs from '../pages/ConsultarOs';
import ValidarOs from '../pages/ValidarOs';
import NegociarOs from '../pages/NegociarOs';
import Separar from '../pages/Separar';
import GerirOs from '../pages/GerirOs';
import FaturarOs from '../pages/FaturarOs';
import NovaOs from '../pages/NovaOs';
import BuildOs from '../pages/BuildOs';
import OsConcluidas from '../pages/OsConcluidas';
import Notificacoes from '../pages/Notificacoes';
import PontoEletronico from '../pages/PontoEletronico';
import MpConcluidas from '../pages/MpConcluidas';
import Clientes from '../pages/Clientes';
import NovoCliente from '../pages/NovoCliente';
import ClienteRegister from '../pages/ClienteRegister';
import Logs from '../pages/Logs';
import Parts from '../pages/Parts';
import RegisterParts from '../pages/RegisterParts';
import Collaborators from '../pages/Collaborators';
import CollaboratorRegister from '../pages/CollaboratorRegister';
import Outfitters from '../pages/Outfitters';
import Definitions from '../pages/Definitions';
import Preventivas from '../pages/Preventivas';
import Preventiva from '../pages/Preventiva';
import LiberarOs from '../pages/LiberarOs';
import UsersClientManager from '../pages/UsersClientManager';
import PreventivesCardsPrint from '../pages/PreventivesCardsPrint';
import Relatorios from '../pages/Relatorios';
import Agenda from '../pages/Agenda';
import PurchaseOrder from '../pages/PurchaseOrder';
import OSCanceladas from '../pages/OSCanceladas';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Route path="/signin" component={SignIn} />
        <Route path="/dashboard" component={Dashboard} isPrivate />
        <Route path="/agenda" component={Agenda} isPrivate />
        <Route path="/mapaaovivo" component={MapaAoVivo} isPrivate />
        <Route path="/osabertas" component={OsAbertas} isPrivate />
        <Route path="/chamados" component={Chamados} isPrivate />
        <Route path="/consultaros" component={ConsultarOs} isPrivate />
        <Route path="/validaros" component={ValidarOs} isPrivate />
        <Route path="/negociaros" component={NegociarOs} isPrivate />
        <Route path="/separar" component={Separar} isPrivate />
        <Route path="/liberaros" component={LiberarOs} isPrivate />
        <Route path="/geriros" component={GerirOs} isPrivate />
        <Route path="/faturaros" component={FaturarOs} isPrivate />
        <Route path="/novaos" component={NovaOs} isPrivate />
        <Route path="/buildos" component={BuildOs} isPrivate />
        <Route path="/osconcluidas" component={OsConcluidas} isPrivate />
        <Route path="/oscanceladas" component={OSCanceladas} isPrivate />
        <Route path="/notificacoes" component={Notificacoes} isPrivate />
        <Route path="/pontoeletronico" component={PontoEletronico} isPrivate />
        <Route path="/mpconcluidas" component={MpConcluidas} isPrivate />
        <Route path="/clientes" component={Clientes} isPrivate />
        <Route path="/novocliente" component={NovoCliente} isPrivate />
        <Route path="/clienteregister" component={ClienteRegister} isPrivate />
        <Route path="/reports" component={Relatorios} isPrivate />
        <Route
          path="/usersclientmanager"
          component={UsersClientManager}
          isPrivate
        />
        <Route path="/logs" component={Logs} isPrivate />
        <Route path="/parts" component={Parts} isPrivate />
        <Route path="/registerparts" component={RegisterParts} isPrivate />
        <Route path="/collaborators" component={Collaborators} isPrivate />
        <Route
          path="/collaboratorregister"
          component={CollaboratorRegister}
          isPrivate
        />
        <Route path="/outfitters" component={Outfitters} isPrivate />
        <Route path="/definitions" component={Definitions} isPrivate />
        <Route path="/listarpreventivas" component={Preventivas} isPrivate />
        <Route path="/preventiva" component={Preventiva} isPrivate />
        <Route
          path="/preventivescardsprint"
          component={PreventivesCardsPrint}
          isPrivate
        />
        <Route path="/ordensdecompra" component={PurchaseOrder} isPrivate />
      </Switch>
    </BrowserRouter>
  );
}
