import { Button } from 'antd';
import styled from 'styled-components';

export const CardInput = styled.div`
  width: 100%;
  margin-bottom: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const CardInputText = styled.div`
  width: 100%;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonSave = styled.div`
  width: 55%;
  border-radius: 6px;
  margin-bottom: 20px;
  margin-top: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 8px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: green;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
`;

export const BoxListReq = styled.div`
  width: 100%;
`;

export const TextTitleBox = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  font-weight: bold;
`;

export const Text = styled.div`
  font-size: 16px;
  margin-left: 10px;
  margin-right: 5px;
`;

export const BtnSave = styled(Button)`
  height: 40px;
  width: 130px;
  border-radius: 4px;
`;
