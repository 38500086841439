/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useState, useContext } from 'react';

const GlobalAttributesContext = createContext<any>({});

export const GlobalAttributesProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [collapseMenu, setCollapseMenu] = useState(false);

  return (
    <GlobalAttributesContext.Provider
      value={{
        loading,
        setLoading,
        refresh,
        setRefresh,
        collapseMenu,
        setCollapseMenu,
      }}
    >
      {children}
    </GlobalAttributesContext.Provider>
  );
};

export function useGlobalAttributes() {
  const context = useContext(GlobalAttributesContext);
  return context;
}
