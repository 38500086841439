import React from 'react';
import Drawer from '../../components/Drawer';
import { Body } from './Body';

const GerirOs: React.FC = () => {
  return (
    <Drawer page="Gerindo OS">
      <Body />
    </Drawer>
  );
};

export default GerirOs;
