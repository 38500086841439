import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SaveButton } from './styles';

import FormPart from './FormPart';
import FormPartComplementary from './FormPartComplementary';
import { Container } from './styles';

interface IProps {
  id_part_edit?: number;
  part_mode: 'new' | 'edit';
}

export default function Body(props: IProps) {
  const [id_part, setId_part] = useState(null);
  const [loading, setLoading] = useState(false);

  const { id_part_edit, part_mode } = props;

  useEffect(() => {
    if (id_part_edit) {
      setId_part(id_part_edit);
    } else {
      setId_part(null);
    }
  }, [id_part_edit]);

  return (
    <Container>
      <FormPart id_part={id_part} setId_part={setId_part}>
        <SaveButton type="primary" loading={loading}>
          Salvar
        </SaveButton>
      </FormPart>

      {id_part ? (
        <FormPartComplementary id_part={id_part}>
          <SaveButton type="primary" loading={loading}>
            Salvar
          </SaveButton>
        </FormPartComplementary>
      ) : null}
    </Container>
  );
}
