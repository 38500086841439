import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 300px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  overflow-x: auto;
`;

export const Box = styled.div`
  background-color: #fff;
  min-height: 80px;
  min-width: 80px;
  width: 50px;
  height: 50px;
  border: 2px solid #fff;
`;
