import api from '../services/api';
import { intervalToDuration } from 'date-fns';

async function GetNameOutfitter(id: number) {
  const storagedEquip = localStorage.getItem(`@elevsis-biz:outfitter/${id}`);

  if (storagedEquip) {
    const outfitter = await JSON.parse(storagedEquip);

    const oldCache = intervalToDuration({
      start: new Date(outfitter.oldCache),
      end: new Date(),
    });

    if (oldCache.hours < 24) {
      const { nome_apelido } = outfitter;
      return nome_apelido;
    } else {
      const response = await api.get(`outfitter/${id}`);
      const { nome_apelido } = response.data;

      response.data.oldCache = new Date();
      localStorage.setItem(
        `@elevsis-biz:outfitter/${id}`,
        JSON.stringify(response.data),
      );

      return nome_apelido;
    }
  } else {
    const response = await api.get(`outfitter/${id}`);
    const { nome_apelido } = response.data;

    response.data.oldCache = new Date();

    localStorage.setItem(
      `@elevsis-biz:outfitter/${id}`,
      JSON.stringify(response.data),
    );

    return nome_apelido;
  }
}

export default GetNameOutfitter;
