import styled from 'styled-components';

export const IconRed = styled.div`
  background-color: #d50000;
  width: 20px;
  height: 20px;
  border-radius: 15px;
`;

export const IconOrange = styled.div`
  background-color: #f57f17;
  width: 20px;
  height: 20px;
  border-radius: 15px;
`;

export const IconYellow = styled.div`
  background-color: #ffd600;
  width: 20px;
  height: 20px;
  border-radius: 15px;
`;

export const IconGreen = styled.div`
  background-color: #aeea00;
  width: 20px;
  height: 20px;
  border-radius: 15px;
`;
