import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  height: 140px;
  display: flex;
  flex-direction: column;
`;

export const LineEdificio = styled.div`
  width: 100%;
  height: 31px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  padding-left: 6px;
`;

export const LineAdress = styled.div`
  width: 100%;
  height: 31px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  padding-left: 6px;
`;

export const LineSup = styled.div`
  width: 100%;
  height: 39px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 16px;
  border-top: 1px solid #000;
`;

export const LineInf = styled.div`
  width: 100%;
  height: 39px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 16px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
`;

export const Box = styled.div`
  flex: 1;
  height: 39px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  border-right: 1px solid #000;
`;

export const BoxLast = styled.div`
  flex: 1;
  height: 39px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
`;

export const BoxTitle = styled.text`
  font-size: 11px;
  padding-left: 2px;
  padding-top: 2px;
`;

export const BoxText = styled.text`
  font-size: 12px;
  font-weight: 600;
  padding-left: 3px;
  padding-top: 2px;
  line-height: 10px;
`;

export const ClienteText = styled.text`
  font-size: 14px;
  font-weight: 600;
  padding-left: 6px;
`;
