import React, { useEffect } from 'react';
import { Badge } from 'antd';
import { useNotifications } from '../../contexts/Notifications';
import NotificationsWindow from '../NotificationsWindow';
import socket from '../../services/socket';
import { Icon } from './styles';

export default function BadgeNotification() {
  const {
    viewNotifyWindow,
    setViewNotifyWindow,
    numNotif,
    countNotifications,
  } = useNotifications();

  useEffect(() => {
    countNotifications();
  }, [countNotifications]);

  useEffect(() => {
    getSocket();
  }, []);

  function getSocket() {
    socket.on(`count_notify`, async notify => {
      await countNotifications();
    });
  }

  function changeWindow() {
    setViewNotifyWindow(!viewNotifyWindow);
  }

  return (
    <>
      <Badge count={numNotif}>
        <Icon onClick={() => changeWindow()} twoToneColor="#3B72FF" />
      </Badge>

      {viewNotifyWindow ? <NotificationsWindow /> : <></>}
    </>
  );
}
