/* eslint-disable no-case-declarations */

import React, { useState, useEffect } from 'react';
import ConvertDateTime from '../../../functions/ConvertDateTime';
import { useOSView } from '../../../contexts/OSView';
import { Button } from '@material-ui/core';
import { AlertOutlined, CheckOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { parseISO, isValid } from 'date-fns';
import ScheduleOs from '../../../components/ScheduleOs';
import { IconRed, IconOrange, IconYellow, IconGreen } from './styles';
import io from '../../../services/socket';
import api from '../../../services/api';
import OSViewSlideOver from '../../../components/OSViewSlideOver';
import GetApelidoEquip from '../../../functions/GetApelidoEquip';

const RowTable = ({ os }) => {
  const [data_criacao, setDataCriacao] = useState('');
  const [status_exibir, setStatus_exibir] = useState('');
  const [duration_text, setDuration_text] = useState('');
  const [qtdReq_peca, setQtdReq_peca] = useState(0);
  const [apelidoEquipamento, setApelidoEquipamento] = useState('');
  const [open, setOpen] = useState(false);

  const { defineIdOs } = useOSView();

  useEffect(() => {
    convertDate(os.created_at);

    defineStatus(os);

    eventsRealTime();

    contReq_peca();

    getEquip();
  }, [os]);

  async function getEquip() {
    if (!os.id_equipamento) return;

    const apelido = await GetApelidoEquip(os.id_equipamento);

    setApelidoEquipamento(apelido);
  }

  async function contReq_peca() {
    try {
      const response = await api.get(
        `req_peca?lines_per_page=0&id_os=${os.id_os}`,
      );

      setQtdReq_peca(response.data.total);
    } catch (err) {
      return;
    }
  }

  function eventsRealTime() {
    io.on(`updateposition?id=${os.id_funcionario_resp}`, refresh => {
      defineStatus(os);
    });
  }

  async function convertDate(created_at) {
    const data_convertida = await ConvertDateTime(created_at);
    setDataCriacao(data_convertida);
  }

  async function defineStatus(os) {
    switch (os.status) {
      case 'aberto':
        if (os.id_atendimento_last) {
          setStatus_exibir('Retornar');
        } else {
          setStatus_exibir('Aberto');
        }
        break;

      case 'agendado':
        const data_agendamento_valid = isValid(new Date(os.data_agendamento));
        const agenda_data_br = '';

        if (data_agendamento_valid) {
          const agenda_data = parseISO(os.data_agendamento);
          //agenda_data_br = format(agenda_data, "dd'/'MM'/'yy - HH:mm");
          //Alterar aqui quando tiver agendamento de data
        }

        if (os.nome_apelido_agendamento || agenda_data_br) {
          setStatus_exibir(
            'Agendado - ' + os.nome_apelido_agendamento + '  ' + agenda_data_br,
          );
        } else {
          setStatus_exibir('Agendado');
        }

        break;

      case 'deslocamento':
        setStatus_exibir(os.nome_apelido_atendimento + ' Deslocando');

        //Sem uso enquanto sem key api maps
        // const response = await EstimateDistanceDuration(
        //   os.id_funcionario_resp,
        //   os.id_cliente,
        // );

        // setDuration_text(response?.duration_text);

        break;

      case 'atendimento':
        setStatus_exibir(os.nome_apelido_atendimento + ' em Atendimento');

        break;
    }
  }

  return (
    <>
      <OSViewSlideOver
        id_os={os.id_os}
        open={open}
        setOpen={setOpen}
        action="consultar"
      />
      <tr key={os.id_os}>
        <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-2 lg:pl-2">
          <Button onClick={() => setOpen(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
          </Button>
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          <Button onClick={() => defineIdOs(os.id_os, 'consultar')}>
            {os.id_os}
          </Button>
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          <Tooltip title={os.edificio} placement="right">
            {os.apelido}
          </Tooltip>
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          {apelidoEquipamento}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          <Tooltip title={os.descricao} placement="right">
            {os.descricao.substring(25, 0)} ...
          </Tooltip>
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          {data_criacao}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          {status_exibir}
          {os.status === 'aberto' || os.status === 'agendado' ? (
            <>
              <ScheduleOs os={os} />
            </>
          ) : null}
          {os.status === 'deslocamento' && duration_text ? (
            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
              {duration_text} para chegar
            </span>
          ) : null}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 flex flex-row">
          {os.prioridade == 'urgente' ? (
            <Tooltip title="Urgente" placement="right">
              <IconRed />
            </Tooltip>
          ) : (
            <></>
          )}
          {os.prioridade == 'alta' ? (
            <Tooltip title="Prioridade Alta" placement="right">
              <IconOrange />
            </Tooltip>
          ) : (
            <></>
          )}
          {os.prioridade == 'media' ? (
            <Tooltip title="Prioridade Média" placement="right">
              <IconYellow />
            </Tooltip>
          ) : (
            <></>
          )}
          {os.prioridade == 'baixa' ? (
            <Tooltip title="Prioridade Baixa" placement="right">
              <IconGreen />
            </Tooltip>
          ) : (
            <></>
          )}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-base text-gray-500">
          {os.situacao == 'liberada' ? (
            <Tooltip title="Liberada para Execução" placement="right">
              <CheckOutlined style={{ fontSize: 22, color: '#64dd17' }} />
            </Tooltip>
          ) : (
            <></>
          )}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-base text-gray-500">
          {os.funcionamento_equipamento == 'inoperante' ? (
            <Tooltip title="Equipamento Inoperante" placement="right">
              <AlertOutlined style={{ fontSize: 22, color: '#d50000' }} />
            </Tooltip>
          ) : (
            <></>
          )}
          {os.funcionamento_equipamento == 'parcial' ? (
            <Tooltip title="Funcionamento Parcial" placement="right">
              <AlertOutlined style={{ fontSize: 22, color: '#ffa900' }} />
            </Tooltip>
          ) : (
            <></>
          )}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-base text-gray-500">
          {qtdReq_peca ? (
            <Tooltip
              title={'Quantidade de peças: ' + qtdReq_peca}
              placement="right"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                />
              </svg>
            </Tooltip>
          ) : null}
        </td>
      </tr>
    </>
  );
};

export default RowTable;
