import React from 'react';

import Drawer from '../../components/Drawer';
import Body from './Body';

export default function RegisterParts() {
  return (
    <Drawer page="Registro de Peça">
      <Body part_mode="new" />
    </Drawer>
  );
}
