import React, { useState, useEffect } from 'react';
import GetNameEdificio from '../../../../functions/GetNameEdificio';
import { Container, Line, Title, Description } from './styles';

function Alter_status_funcionamento({ log }) {
  const [edificio, setEdificio] = useState('');
  const [apelidoEdificio, setApelidoEdificio] = useState('');

  useEffect(() => {
    const { table_origin, id_table_origin } = log;
    if (table_origin === 'cliente') getNameCliente(id_table_origin);
  }, [log]);

  async function getNameCliente(id_cliente) {
    const { edificio, apelido } = await GetNameEdificio(id_cliente);

    setEdificio(edificio);
    setApelidoEdificio(apelido);
  }

  return (
    <Container>
      <Line>
        <Title>Edifício:</Title>
        <Description>
          {edificio} - {apelidoEdificio}
        </Description>
      </Line>

      <Line>
        <Title>Descrição:</Title>
        <Description>{log.description}</Description>
      </Line>
    </Container>
  );
}

export default Alter_status_funcionamento;
