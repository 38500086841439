/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { MailIcon } from '@heroicons/react/solid';
import TabMenu from '../TabMenu';
import { IUserList } from '../../../../types/Cliente/Users/IUserList';
import api from '../../../../services/api';
import convertDateTime from '../../../../functions/ConvertDateTime';
import { Edit } from '@material-ui/icons';

interface IProps {
  id_user_select: string;
  editUser(id: string): void;
}

export default function Description(props: IProps) {
  const [user, setUser] = useState<IUserList | null>();
  const [created, setCreated] = useState('');

  const { id_user_select, editUser } = props;

  useEffect(() => {
    getUser();
  }, [id_user_select]);

  async function getUser() {
    if (!id_user_select) return;

    try {
      const response = await api.get(`client_api/user/${id_user_select}`);

      setUser(response.data);

      await convertDate(response.data.created_at);
    } catch (err) {
      return;
    }
  }

  async function convertDate(created_at: string): Promise<void> {
    const date = await convertDateTime(created_at);

    setCreated(date);
  }

  return (
    <article>
      {id_user_select ? (
        <>
          <div>
            <div>
              <img
                className="h-20 w-full object-cover lg:h-20"
                src="https://www.pnst.com.br/wp-content/uploads/2016/11/fundo-neutro.png"
                alt=""
              />
            </div>
            <div className="max-w-5xl bg-gray-100 mx-auto px-4 sm:px-6 lg:px-8">
              <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                <div className="flex">
                  <img
                    className="h-24 w-24 rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                    src={
                      user?.avatar_url
                        ? user.avatar_url
                        : 'https://storage-app-do-elevador.s3.sa-east-1.amazonaws.com/default-avatar.svg'
                    }
                    alt=""
                  />
                </div>
                <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                  <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                    <h1 className="text-2xl font-bold text-gray-900 truncate">
                      {user?.first_name} {user?.last_name}
                    </h1>
                  </div>
                  <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                    <a href={`mailto:${user?.email}`} title="Abrir E-mail">
                      <button
                        type="button"
                        className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        <MailIcon
                          className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span>Enviar E-mail</span>
                      </button>
                    </a>
                    <button
                      type="button"
                      onClick={() => editUser(user?.id)}
                      className="inline-flex justify-center px-2 py-2 ml-3 border border-gray-300 bg-blue-600 hover:bg-blue-700 shadow-sm text-sm text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <Edit className="h-4 w-4" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
                <h1 className="text-2xl font-bold text-gray-900 truncate">
                  {user?.first_name} {user?.last_name}
                </h1>
              </div>
            </div>
          </div>

          <TabMenu />

          <div className="py-6 bg-gray-100 rounded max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Username</dt>
                <dd className="mt-1 text-sm text-gray-900">{user?.username}</dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Status</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {user?.disabled ? (
                    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-300 text-gray-900">
                      Desativado
                    </span>
                  ) : user?.blocked ? (
                    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-200 text-red-900">
                      Bloqueado
                    </span>
                  ) : (
                    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-200 text-green-900">
                      Ativo
                    </span>
                  )}
                </dd>
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {user?.cnpj ? 'CNPJ' : 'CPF'}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {user?.cnpj ? user.cnpj : user?.cpf}
                </dd>
              </div>
            </dl>
          </div>
        </>
      ) : null}
    </article>
  );
}
