/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-case-declarations */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import ConvertDateTime from '../../../functions/ConvertDateTime';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { parseISO, format, isValid } from 'date-fns';

import { Tag } from './styles';

const RowTable = ({ os }) => {
  const [data_criacao, setDataCriacao] = useState('');
  const [status_exibir, setStatus_exibir] = useState('');

  useEffect(() => {
    convertDate(os.created_at);
    defineStatus(os);
  }, []);

  async function convertDate(created_at) {
    const data_convertida = await ConvertDateTime(created_at);
    setDataCriacao(data_convertida);
  }

  async function defineStatus(os) {
    switch (os.status) {
      case 'aberto':
        if (os.id_atendimento_last) {
          setStatus_exibir('Retornar');
        } else {
          setStatus_exibir('Aberto');
        }

        break;

      case 'agendado':
        const data_agendamento_valid = isValid(new Date(os.data_agendamento));
        let agenda_data_br;

        if (data_agendamento_valid) {
          const agenda_data = parseISO(os.data_agendamento);
          agenda_data_br = format(agenda_data, "dd'/'MM'/'yy - HH:mm");
          //Alterar aqui quando tiver agendamento de data
        }

        if (os.nome_apelido_agendamento) {
          setStatus_exibir(
            'Agendado - ' + os.nome_apelido_agendamento + '  ' + agenda_data_br,
          );
        }

        break;

      case 'deslocamento':
        setStatus_exibir(os.nome_apelido_atendimento + ' Deslocando');

        break;

      case 'atendimento':
        setStatus_exibir(os.nome_apelido_atendimento + ' em Atendimento');

        break;
    }
  }

  return (
    <TableRow key={os.id_os} style={{ height: 50 }}>
      <TableCell style={{ fontSize: 16, fontWeight: 600 }} align="left">
        <Link to={{ pathname: '/consultaros', id: os.id_os }}>{os.id_os}</Link>
      </TableCell>
      <TableCell
        style={{ fontSize: 14 }}
        align="left"
        component="th"
        scope="row"
      >
        {os.apelido}
      </TableCell>
      <TableCell style={{ fontSize: 12 }} align="left">
        {os.apelido_equipamento}
      </TableCell>
      <TableCell style={{ fontSize: 12 }} align="left">
        {data_criacao}
      </TableCell>
      <TableCell style={{ fontSize: 12 }} align="left">
        {os.prioridade}
      </TableCell>
      <TableCell style={{ fontSize: 12 }} align="left">
        {status_exibir}
      </TableCell>
      <TableCell style={{ fontSize: 12 }} align="left">
        {os.chamado == 'true' ? (
          <Tag
            style={{
              backgroundColor: '#f0f3ff',
              borderColor: '#aebaff',
              color: '#5600eb',
            }}
          >
            Chamado
          </Tag>
        ) : (
          <></>
        )}
      </TableCell>
      <TableCell style={{ fontSize: 12 }} align="left">
        {os.situacao == 'liberada' ? (
          <Tag
            style={{
              backgroundColor: '#f1ffed',
              borderColor: '#96fe91',
              color: '#00da28',
            }}
          >
            Liberada
          </Tag>
        ) : (
          <></>
        )}
      </TableCell>
      <TableCell style={{ fontSize: 12 }} align="left">
        {os.funcionamento_equipamento == 'inoperante' ? (
          <Tag
            style={{
              backgroundColor: '#fff3e8',
              borderColor: '#ffb894',
              color: '#ff1700',
            }}
          >
            Chamado
          </Tag>
        ) : (
          <></>
        )}
      </TableCell>
    </TableRow>
  );
};

export default RowTable;
