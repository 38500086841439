/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Select, Switch } from 'antd';
import InputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-input';
import api from '../../../../services/api';
import { IShowCliente } from '../../../../types/IShowCliente';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../../functions/MessageFeedback';
import { useClientRegister } from '../../../../contexts/ClientRegister';
import GetCoordsByAdress from '../../../../functions/GetCoordsByAdress';
import { ISetor } from '../../../../types/SetorMP/ISetor';
import AlertFixedErrorList from '../../../../components/AlertFixedErrorList';
import { Redirect } from 'react-router-dom';

interface ICoords {
  lat: string;
  lng: string;
}

interface IResponseList {
  total: number;
  data: any;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const { Option } = Select;

export default function FormGeral() {
  const [cliente, setCliente] = useState<IShowCliente>();
  const [loading, setLoading] = useState(false);
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [cep, setCep] = useState('');
  const [coords, setCoords] = useState<ICoords>();
  const [listSetores, setListSetores] = useState<ISetor[]>([]);
  const [listClienteExists, setListClienteExists] = useState<IResponseList>();

  const { id_cliente } = useClientRegister();

  useEffect(() => {
    getSetores();
    if (id_cliente) getCliente();
  }, [id_cliente]);

  useEffect(() => {
    const cepLenght = cep.replace(/[^\d]+/g, '').length;

    if (cepLenght >= 8) {
      getCep(cep);
    }
  }, [cep]);

  async function getCep(cep: string) {
    fetch(`https://viacep.com.br/ws/${cep}/json`)
      .then(res => res.json())
      .then(res => {
        if (res.erro) {
          feedbackError('CEP não encontrado. Favor Revisar.');
          return;
        }

        setLogradouro(res.logradouro);
        setBairro(res.bairro);
        setCidade(res.localidade);
        setEstado(res.uf);
      });
  }

  async function verifyClientExistByCNPJ() {
    if (!cliente?.cnpj) return;

    try {
      const response = await api.get(
        `cliente/validate/bycnpj?cnpj=${cliente.cnpj}`,
      );

      const { data } = response;

      if (data && data.id_cliente != id_cliente)
        feedbackError(`Este cliente já possui cadastro! ${data.edificio}`);

      setListClienteExists(data);
    } catch (err) {
      return;
    }
  }

  async function verifyClientExistByAdress() {
    if (!cep || !logradouro || !numero || !cidade || !estado) return;

    try {
      const response = await api.get(
        `cliente/validate/byadress?cep=${cep}&logradouro=${logradouro}&numero=${numero}&cidade=${cidade}&estado=${estado}&pais=BR`,
      );

      const { data } = response;

      if (data && data.id_cliente != id_cliente)
        feedbackError(`Este cliente já possui cadastro! ${data.edificio}`);

      setListClienteExists(data);
    } catch (err) {
      return;
    }
  }

  async function getCliente() {
    const response = await api.get(`cliente/${id_cliente}`);

    setCliente(response.data);
    setCep(response.data.cep);
    setLogradouro(response.data.logradouro);
    setNumero(response.data.numero);
    setBairro(response.data.bairro);
    setCidade(response.data.cidade);
    setEstado(response.data.estado);
  }

  async function handleChange(event) {
    setCliente({
      ...cliente,
      [event.target.name]: event.target.value,
    });
  }

  async function handleSubmit() {
    if (!cliente?.edificio || !cliente?.apelido) {
      feedbackError('Edifício e apelido são obrigatórios');
      return;
    }

    if (!cliente?.cnpj) {
      feedbackError('O campo CNPJ é obrigatório');
      return;
    }

    if (!logradouro || !numero || !cidade) {
      feedbackError('O endereço completo é obrigatório');
      return;
    }

    if (!cliente?.periodicidade_mp) {
      feedbackError('O campo periodicidade é obrigatório');
      return;
    }

    if (!cliente?.status) {
      feedbackError('O campo Status é obrigatório');
      return;
    }

    setLoading(true);

    cliente.cep = cep;
    cliente.logradouro = logradouro;
    cliente.numero = numero;
    cliente.bairro = bairro;
    cliente.cidade = cidade;
    cliente.estado = estado;

    const coords = await GetCoordsByAdress(
      `${cliente.logradouro},${cliente.numero},${cliente.bairro},${cliente.cidade},${cliente.estado},${cliente.cep}`,
    );

    cliente.geo_lat = coords.lat;
    cliente.geo_lon = coords.lng;

    if (id_cliente) {
      try {
        await api.put(`cliente/${id_cliente}`, cliente);
        feedbackSuccess('Salvo com sucesso');

        setLoading(false);
      } catch (err) {
        feedbackError('Não foi possível editar');
      }
    } else {
      try {
        const response = await api.post(`cliente`, cliente);

        if (response.data[0]) {
          const id_cliente = response.data[0];

          feedbackSuccess('Salvo com sucesso');

          <Redirect
            to={{
              pathname: '/clienteregister',
              state: { id_cliente, mode: 'edit' },
            }}
          />;
        }

        setLoading(false);
      } catch (err) {
        feedbackError('Não foi possível criar');
      }
    }
    setLoading(false);
  }

  async function getSetores() {
    const response = await api.get(`setor`);

    setListSetores(response.data);
  }

  const setores = listSetores.map((setor, index) => {
    return (
      <Option value={setor.id_setor} key={setor.id_setor}>
        {setor.nome_setor}
      </Option>
    );
  });

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-10">
      {listClienteExists && listClienteExists.total > 0 ? (
        <AlertFixedErrorList
          title="Talvez este cliente já esteja cadastrado. Confira abaixo:"
          messageLine={listClienteExists.data}
        />
      ) : null}
      <form action="#" method="POST">
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Geral
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Dados gerais sobre o cliente / condomínio
              </p>
            </div>

            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="edificio"
                  className="block text-sm font-medium text-gray-700"
                >
                  Edifício
                </label>
                <input
                  type="text"
                  name="edificio"
                  id="edificio"
                  value={cliente?.edificio}
                  defaultValue={cliente?.edificio}
                  onChange={e => handleChange(e)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="apelido"
                  className="block text-sm font-medium text-gray-700"
                >
                  Apelido
                </label>
                <input
                  type="text"
                  name="apelido"
                  id="apelido"
                  value={cliente?.apelido}
                  defaultValue={cliente?.apelido}
                  onChange={e => handleChange(e)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="cnpj"
                  className="block text-sm font-medium text-gray-700"
                >
                  CNPJ
                </label>
                <input
                  type="text"
                  name="cnpj"
                  id="cnpj"
                  value={cliente?.cnpj}
                  defaultValue={cliente?.cnpj}
                  onChange={e => handleChange(e)}
                  onBlur={() => verifyClientExistByCNPJ()}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="razao_social"
                  className="block text-sm font-medium text-gray-700"
                >
                  Razão social
                </label>
                <input
                  type="text"
                  name="razao_social"
                  id="razao_social"
                  value={cliente?.razao_social}
                  defaultValue={cliente?.razao_social}
                  onChange={e => handleChange(e)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>

              <div className="col-span-4 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="cep"
                  className="block text-sm font-medium text-gray-700"
                >
                  CEP
                </label>
                <InputMask
                  mask="99999-999"
                  name="cep"
                  id="cep"
                  autoComplete="nope"
                  value={cep}
                  defaultValue={cep}
                  onChange={e => setCep(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>

              <div className="col-span-3 sm:col-span-4 lg:col-span-2">
                <label
                  htmlFor="logradouro"
                  className="block text-sm font-medium text-gray-700"
                >
                  Logradouro
                </label>
                <input
                  type="text"
                  name="logradouro"
                  id="logradouro"
                  value={logradouro}
                  defaultValue={logradouro}
                  onChange={e => setLogradouro(e.target.value)}
                  autoComplete="street-address"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                <label
                  htmlFor="numero"
                  className="block text-sm font-medium text-gray-700"
                >
                  Número
                </label>
                <input
                  type="text"
                  name="numero"
                  id="numero"
                  value={numero}
                  defaultValue={numero}
                  onChange={e => setNumero(e.target.value)}
                  onBlur={() => verifyClientExistByAdress()}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="bairro"
                  className="block text-sm font-medium text-gray-700"
                >
                  Bairro
                </label>
                <input
                  type="text"
                  name="bairro"
                  id="bairro"
                  value={bairro}
                  defaultValue={bairro}
                  onChange={e => setBairro(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="cidade"
                  className="block text-sm font-medium text-gray-700"
                >
                  Cidade
                </label>
                <input
                  type="text"
                  name="cidade"
                  id="cidade"
                  value={cidade}
                  defaultValue={cidade}
                  onChange={e => setCidade(e.target.value)}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>

              <div className="col-span-3">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-gray-700"
                >
                  Observação
                </label>
                <div className="mt-1">
                  <textarea
                    id="observacao"
                    name="observacao"
                    rows={3}
                    value={cliente?.observacao}
                    defaultValue={cliente?.observacao}
                    onChange={e => handleChange(e)}
                    className="shadow-sm px-1 focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                    placeholder=""
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Informações extras sobre o cliente
                </p>
              </div>

              <div className="col-span-3">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-gray-700"
                >
                  Observação Técnica
                </label>
                <div className="mt-1">
                  <textarea
                    id="obs_tecnica"
                    name="obs_tecnica"
                    rows={3}
                    value={cliente?.obs_tecnica}
                    defaultValue={cliente?.obs_tecnica}
                    onChange={e => handleChange(e)}
                    className="shadow-sm px-1 focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                    placeholder=""
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  Estes dados serão exibidos para todos os técnicos
                </p>
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="setor"
                  className="pb-1 block text-sm font-medium text-gray-700"
                >
                  Setor
                </label>

                <Select
                  style={{ width: '100%' }}
                  id="setor"
                  defaultValue={cliente?.id_setor}
                  value={cliente?.id_setor}
                  onChange={e =>
                    setCliente(prevState => {
                      return { ...prevState, id_setor: e };
                    })
                  }
                >
                  {setores}
                </Select>
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="periodicidade_mp"
                  className="pb-1 block text-sm font-medium text-gray-700"
                >
                  Periodicidade MP
                </label>
                <Select
                  style={{ width: '100%' }}
                  id="periodicidade_mp"
                  value={cliente?.periodicidade_mp}
                  onChange={e =>
                    setCliente(prevState => {
                      return { ...prevState, periodicidade_mp: e };
                    })
                  }
                >
                  <Option value="mensal">Mensal</Option>
                  <Option value="quinzenal">Quinzenal</Option>
                  <Option value="semanal">Semanal</Option>
                  <Option value="bimestral">Bimestral</Option>
                  {/* <Option value="trimestral">Trimestral</Option>
                  <Option value="semestral">Semestral</Option>
                  <Option value="anual">Anual</Option> */}
                </Select>
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="atend_tec_parceiros"
                  className="pb-1 block text-sm font-medium text-gray-700"
                >
                  Atendimento Terceiros
                </label>

                <div className="flex items-center">
                  <Switch
                    defaultChecked={cliente?.atend_tec_parceiros}
                    onChange={e =>
                      setCliente(prevState => {
                        return { ...prevState, atend_tec_parceiros: e };
                      })
                    }
                    className={classNames(
                      cliente?.atend_tec_parceiros
                        ? 'bg-blue-600'
                        : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500',
                    )}
                  />
                </div>
              </div>
              {/*
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="vigencia_de"
                  className="pb-1 block text-sm font-medium text-gray-700"
                >
                  Vigência Início
                </label>

                <Input
                  style={{ width: '100%' }}
                  name="vigencia_de"
                  id="vigencia_de"
                  value={cliente?.vigencia_de}
                  defaultValue={cliente?.vigencia_de}
                  type="date"
                  onChange={e => handleChange(e)}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="vigência_ate"
                  className="pb-1 block text-sm font-medium text-gray-700"
                >
                  Vigência término
                </label>
                <Input
                  style={{ width: '100%' }}
                  name="vigencia_ate"
                  id="vigencia_ate"
                  value={cliente?.vigencia_ate}
                  defaultValue={cliente?.vigencia_ate}
                  type="date"
                  onChange={e => handleChange(e)}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="cliente_desde"
                  className="pb-1 block text-sm font-medium text-gray-700"
                >
                  Cliente desde
                </label>
                <Input
                  style={{ width: '100%' }}
                  name="cliente_desde"
                  id="cliente_desde"
                  value={cliente?.cliente_desde}
                  defaultValue={cliente?.cliente_desde}
                  type="date"
                  onChange={e => handleChange(e)}
                />
              </div> */}

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="valor_mensal"
                  className="block text-sm font-medium text-gray-700"
                >
                  Valor mensal
                </label>

                <CurrencyInput
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  //value={price}
                  precision="2"
                  decimalSeparator="."
                  thousandSeparator=""
                  placeholder="R$ "
                  //onChangeEvent={e => setPrice(e.target.value)}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="mi"
                  className="pb-1 block text-sm font-medium text-gray-700"
                >
                  Manutenção integral
                </label>

                <Switch
                  defaultChecked={cliente?.mi}
                  onChange={e =>
                    setCliente(prevState => {
                      return { ...prevState, mi: e };
                    })
                  }
                  className={classNames(
                    cliente?.mi ? 'bg-blue-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500',
                  )}
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="status"
                  className="pb-1 block text-sm font-medium text-gray-700"
                >
                  Status
                </label>
                <Select
                  style={{ width: '100%' }}
                  id="status"
                  value={cliente?.status}
                  onChange={e =>
                    setCliente(prevState => {
                      return { ...prevState, status: e };
                    })
                  }
                >
                  <Option value="ativo">Ativo</Option>
                  <Option value="inativo">Inativo</Option>
                </Select>
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            {loading ? (
              'Salvando...'
            ) : (
              <button
                type="button"
                onClick={() => handleSubmit()}
                className="bg-blue-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Salvar
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
