import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../../../services/api';
import SpinnerLoad from '../../../components/SpinnerLoad';
import { feedbackError } from '../../../functions/MessageFeedback';
import ContentResumo from './Contents/ContentResumo';
import ContentPessoal from './Contents/ContentPessoal';
import ContentProfissional from './Contents/ContentProfissional';
import ContentContato from './Contents/ContentContato';
import { Button, Avatar } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import capa from '../../../assets/images/capa.png';
import {
  ContainerBody,
  ContainerHeader,
  BoxButtonsTop,
  BoxAvatar,
  LineName,
} from './styles';
import NavigationTopBar from './NavigationTopBar';
import { ICollaborator } from '../../../types/Collaborator/ICollaborator';

interface IProps {
  id_funcionario: number;
}

export default function Body(props: IProps) {
  const [collaborator, setCollaborator] = useState<ICollaborator | null>();
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState('resumo');

  const { id_funcionario } = props;

  useEffect(() => {
    getCollaborator();
  }, [id_funcionario]);

  async function getCollaborator() {
    if (!id_funcionario) return;

    setLoading(true);

    try {
      const response = await api.get(`collaborator/${id_funcionario}`);

      setCollaborator(response.data);
    } catch (err) {
      feedbackError('Erro ao buscar o colaborador');
    }
    setLoading(false);
  }

  return (
    <ContainerBody>
      {loading ? (
        <SpinnerLoad />
      ) : (
        <>
          <ContainerHeader style={{ backgroundImage: `url(${capa})` }}>
            <BoxAvatar>
              <Avatar size={80} src={collaborator?.foto} />
            </BoxAvatar>
            <BoxButtonsTop>
              <Link
                to={{
                  pathname: '/collaboratorregister',
                  state: {
                    id_funcionario: collaborator?.id_funcionario,
                    mode_collaborator: 'edit',
                  },
                }}
              >
                <Button icon={<EditOutlined />}>Editar</Button>
              </Link>
            </BoxButtonsTop>
          </ContainerHeader>

          <LineName>{collaborator?.nome}</LineName>

          <NavigationTopBar section={section} setSection={setSection} />

          {section === 'resumo' ? (
            <ContentResumo collaborator={collaborator} />
          ) : null}

          {section === 'pessoal' ? (
            <ContentPessoal collaborator={collaborator} />
          ) : null}

          {section === 'profissional' ? (
            <ContentProfissional collaborator={collaborator} />
          ) : null}

          {section === 'contato' ? (
            <ContentContato collaborator={collaborator} />
          ) : null}
        </>
      )}
    </ContainerBody>
  );
}
