import React, { useEffect, useState } from 'react';
import { List, Mentions, Button } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { Container, Head, InputContainer, TextFeedback } from './styles';

import api from '../../services/api';
import socket from '../../services/socket';

import Message from './components/Message';

export default function Chat(props) {
  const [data, setData] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [id_table_origin, setId_table_origin] = useState('');
  const [table_origin, setTable_origin] = useState('');
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [des, setDes] = useState([]);

  useEffect(() => {
    const { id_table_origin, table_origin } = props;
    setId_table_origin(id_table_origin);
    setTable_origin(table_origin);

    getMessages(id_table_origin, table_origin);
    getUsers();
    getSocket(id_table_origin, table_origin);
  }, []);

  const { Option } = Mentions;

  async function onSelect(option) {
    const result = await users.find(user => user.usuario === option.value);
    setDes([...des, result.id_usuario]);
  }

  async function getMessages(id_table_origin, table_origin) {
    const response = await api.get(
      `messages?id_table_origin=${id_table_origin}&table_origin=${table_origin}`,
    );
    setData(response.data);
    setLoading(false);
  }

  async function getUsers() {
    const response = await api.get(`user?desativado=false`);
    setUsers(response.data.data);
  }

  function getSocket(id_table_origin, table_origin) {
    socket.on(
      `list_messages?id_table_origin=${id_table_origin}&table_origin=${table_origin}`,
      message => {
        getMessages(id_table_origin, table_origin);
      },
    );
  }

  async function sendMessage() {
    if (!newMessage) return;

    setLoading(true);

    const body = {
      id_table_origin,
      table_origin,
      message: newMessage,
      des,
    };

    try {
      const response = await api.post(`messages`, body);
      setData([response.data, ...data]);
    } catch (err) {
      return;
    }

    setNewMessage('');
    setDes([]);
    setLoading(false);
  }

  const listUsers = users.map((user, id_usuario) => {
    return (
      <Option value={user.usuario} key={id_usuario}>
        {user.usuario} - {user.nome_apelido}
      </Option>
    );
  });

  return (
    <Container>
      <Head>
        <InputContainer>
          <Mentions
            style={{ width: 300, marginRight: 5, borderColor: '#3B72FF' }}
            autoFocus={true}
            autoSize={{ minRows: 2, maxRows: 2 }}
            value={newMessage}
            onChange={e => setNewMessage(e)}
            onSelect={onSelect}
            defaultValue=""
            placeholder="Escreva sua mensagem"
          >
            {listUsers}
          </Mentions>

          <Button
            style={{ backgroundColor: '#3B72FF', color: '#FFF' }}
            icon={<SendOutlined />}
            loading={loading}
            onClick={() => sendMessage()}
          />
        </InputContainer>
      </Head>

      {data.length === 0 && !loading ? (
        <TextFeedback>Não há mensagens</TextFeedback>
      ) : (
        <></>
      )}

      <List
        inverted
        initialNumToRender={10000}
        dataSource={data}
        renderItem={item => <Message message={item} />}
      />
    </Container>
  );
}
