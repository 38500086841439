/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import convertDateTime from '../../../../functions/ConvertDateTime';
import GetNameFuncByUser from '../../../../functions/GetNameFuncByUser';
import GetNameOutfitter from '../../../../functions/GetNameOutfitter';
import { IPurchaseOrder } from '../../../../types/PurchaseOrder/IPurchaseOrder';
import SlideOverShow from '../SlideOverShow';

interface IProps {
  purchase: IPurchaseOrder;
  children?: any;
  getList?(): void;
}

const RowTable = ({ purchase, children, getList }: IProps) => {
  const [outfitter, setOutfitter] = useState('');
  const [purchaseBy, setPurchaseBy] = useState('');
  const [purchaseDate, setPurchaseDate] = useState('');
  const [approvedBy, setApprovedBy] = useState('');
  const [approvedDate, setApprovedDate] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    convertData({ purchase });
  }, [purchase]);

  async function convertData({ purchase }: IProps): Promise<void> {
    if (purchase?.purchaseDate) {
      const date_purchase = await convertDateTime(purchase.purchaseDate);
      setPurchaseDate(date_purchase);
    }

    if (purchase?.purchaseBy) {
      const by_purchase = await GetNameFuncByUser(purchase.purchaseBy);
      setPurchaseBy(by_purchase.nome_apelido);
    }

    if (purchase?.approvedDate) {
      const date_approved = await convertDateTime(purchase.approvedDate);
      setApprovedDate(date_approved);
    }

    if (purchase?.approvedBy) {
      const by_approved = await GetNameFuncByUser(purchase.approvedBy);
      setApprovedBy(by_approved.nome_apelido);
    }

    if (purchase?.id_outfitter) {
      const name_outfitter = await GetNameOutfitter(purchase.id_outfitter);
      setOutfitter(name_outfitter);
    } else {
      setOutfitter('');
    }
  }

  async function viewPurchaseOrder() {
    setOpen(true);
  }

  return (
    <>
      <tr>
        <a onClick={() => viewPurchaseOrder()}>
          <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
            {purchase.sequencialCode}/{purchase.yearCode}
          </td>
        </a>
        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
          {outfitter}
        </td>
        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
          {purchaseBy} - {purchaseDate.substring(8, 0)}
        </td>
        <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
          {purchase?.approved ? (
            <>
              <span className="text-green-700 font-semibold">Aprovado</span>
              <br />
              <span className="text-green-700 text-xs">{approvedBy}</span>
              <span className="text-green-700 text-xs ml-2">
                {approvedDate}
              </span>
            </>
          ) : (
            <span className="text-orange-700 font-semibold">Não Aprovado</span>
          )}
        </td>
        <td className="whitespace-nowrap text-right px-3 py-2 text-sm text-gray-500">
          {children}
        </td>
      </tr>

      <SlideOverShow
        open={open}
        setOpen={setOpen}
        codesPurchase={`${purchase.sequencialCode}/${purchase.yearCode}`}
        getList={getList}
      />
    </>
  );
};

export default RowTable;
