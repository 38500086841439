const OptionsCompatibility = [
  {
    value: 'A1',
    label: 'Atlas',
    children: [
      {
        value: 'A1B999',
        label: 'Outros',
      },
    ],
  },
  {
    value: 'A2',
    label: 'Thyssen',
    children: [
      {
        value: 'A2B999',
        label: 'Outros',
      },
    ],
  },
  {
    value: 'A3',
    label: 'Otis',
    children: [
      {
        value: 'A3B999',
        label: 'Outros',
      },
    ],
  },
  {
    value: 'A4',
    label: 'Sur',
    children: [
      {
        value: 'A4B999',
        label: 'Outros',
      },
    ],
  },
  {
    value: 'A5',
    label: 'Schindler',
    children: [
      {
        value: 'A5B999',
        label: 'Outros',
      },
    ],
  },
  {
    value: 'A6',
    label: 'GMV',
    children: [
      {
        value: 'A6B999',
        label: 'Outros',
      },
    ],
  },
  {
    value: 'A7',
    label: 'Kone',
    children: [
      {
        value: 'A7B999',
        label: 'Outros',
      },
    ],
  },
  {
    value: 'A8',
    label: 'Alfa',
    children: [
      {
        value: 'A8B999',
        label: 'Outros',
      },
    ],
  },
  {
    value: 'A9',
    label: 'Neutron',
    children: [
      {
        value: 'A9B999',
        label: 'Outros',
      },
    ],
  },
  {
    value: 'A10',
    label: 'Ortobras',
    children: [
      {
        value: 'A10B999',
        label: 'Outros',
      },
    ],
  },
  {
    value: 'A11',
    label: 'Scanchip',
    children: [
      {
        value: 'A11B999',
        label: 'Outros',
      },
    ],
  },
  {
    value: 'A12',
    label: 'AddTech',
    children: [
      {
        value: 'A12B999',
        label: 'Outros',
      },
    ],
  },
  {
    value: 'A13',
    label: 'Infolev',
    children: [
      {
        value: 'A13B999',
        label: 'Outros',
      },
    ],
  },
  {
    value: 'A14',
    label: 'Sectron',
    children: [
      {
        value: 'A14B999',
        label: 'Outros',
      },
    ],
  },
  {
    value: 'A999',
    label: 'Outros',
    children: [
      {
        value: 'A999B999',
        label: 'Outros',
      },
    ],
  },
];

export { OptionsCompatibility };
