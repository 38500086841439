function CheckForcePassword(password: string): boolean {
  const upperCase = /[A-Z]/;
  const lowerCase = /[a-z]/;
  const numbers = /[0-9]/;
  const symbols = /[!|@|#|$|%|^|&|*|(|)|-|_]/;

  if (
    password.length >= 8 &&
    password.length <= 16 &&
    upperCase.test(password) &&
    lowerCase.test(password) &&
    numbers.test(password) &&
    symbols.test(password)
  ) {
    return true;
  } else {
    return false;
  }
}

export default CheckForcePassword;
