import { intervalToDuration } from 'date-fns';
import api from '../services/api';
import { IClienteMin } from '../types/Cliente/IClienteMin';

async function GetNameEdificio(id: number): Promise<IClienteMin | undefined> {
  const storagedCustomer = localStorage.getItem(`@elevsis:customer/min/${id}`);

  if (storagedCustomer) {
    const customer = await JSON.parse(storagedCustomer);

    const oldCache = intervalToDuration({
      start: new Date(customer.oldCache),
      end: new Date(),
    });

    if (oldCache.hours < 1) {
      return customer;
    } else {
      const customer = await getApi(id);

      return customer;
    }
  } else {
    const customer = await getApi(id);

    return customer;
  }

  async function getApi(id: number) {
    try {
      const response = await api.get(`cliente/min/${id}`);

      const { data } = response;

      response.data.oldCache = new Date();

      localStorage.setItem(
        `@elevsis:customer/min/${id}`,
        JSON.stringify(response.data),
      );

      return data;
    } catch (error) {
      return;
    }
  }
}

export default GetNameEdificio;
