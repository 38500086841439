/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Image } from 'antd';

import { Container, Box } from './styles';
import api from '../../../../../services/api';
import { useOSView } from '../../../../../contexts/OSView';

const ImagesOSList: React.FC = () => {
  const [listImages, setListImages] = useState<any[]>([]);

  const { id_os_view } = useOSView();

  useEffect(() => {
    getImages(id_os_view);
  }, [id_os_view]);

  async function getImages(id_os: number) {
    try {
      const response = await api.get(`foto?origin=os&id_table_origin=${id_os}`);
      setListImages(response.data.data);
    } catch (err) {
      return;
    }
  }

  return (
    <Container>
      {listImages.map(image => (
        <Box key={image}>
          <Image src={image.url} width="100%" height="100%" />
        </Box>
      ))}
    </Container>
  );
};

export default ImagesOSList;
