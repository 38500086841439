import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 300px;
  margin-top: 5px;
  padding: 2px;
`;

export const Box = styled.div`
  background-color: #fff;
  width: 80px;
  height: 110px;
  border: 2px solid #fff;
`;
