import React, { useState } from 'react';
import { Modal, Button, message } from 'antd';

import api from '../../services/api';
import { useOSView } from '../../contexts/OSView';

const ChangeStatusLiberacao = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { os, getOs } = useOSView();

  const showModal = () => {
    setIsModalVisible(true);
  };

  async function handleOk() {
    setIsModalVisible(false);

    let situacao;

    if (os.situacao === 'liberada') situacao = 'pendencia';
    if (os.situacao === 'pendencia') situacao = 'liberada';

    try {
      await api.put(`os/manager/${os.id_os}/liberacao`, { situacao });

      successSave();

      getOs(os.id_os);
    } catch (err) {
      errorSave();
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const successSave = () => {
    message.success('OS alterada com sucesso');
  };

  const errorSave = () => {
    message.error('Erro ao alterar a OS');
  };

  return (
    <div style={{ marginLeft: 10, marginRight: 10 }}>
      <Button type="primary" onClick={showModal}>
        {os.situacao === 'liberada'
          ? 'Bloquear Execução'
          : 'Liberar para Executar'}
      </Button>
      <Modal
        title="Atenção!"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Confirma a Alteração de Status desta OS?</p>
      </Modal>
    </div>
  );
};

export default ChangeStatusLiberacao;
