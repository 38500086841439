import React, { useEffect, useState } from 'react';
import ValidateForcePassword from '../../../../../components/ValidateForcePassword';
import { BoxInfo, Columm, TitleField } from '../../styles';
import { Input } from 'antd';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import CheckForcePassword from '../../../../../functions/CheckForcePassword';

export default function PasswordSection({ setPassword }) {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  useEffect(() => {
    if (!password1 || !password2) return;

    if (password1 !== password2) return;

    const isHard = CheckForcePassword(password1);

    if (isHard) setPassword(password1);
  }, [password1, password2]);

  return (
    <>
      <Columm>
        <BoxInfo>
          <TitleField>Nova senha</TitleField>
          <Input
            type="password"
            name="password1"
            id="password1"
            onChange={e => setPassword1(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {password1 ? <ValidateForcePassword password={password1} /> : null}
        </BoxInfo>
      </Columm>

      <Columm>
        <BoxInfo>
          <TitleField>Confirme a nova senha</TitleField>
          <Input
            type="password"
            name="password2"
            id="password2"
            onChange={e => setPassword2(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
          {password1 !== password2 && (
            <div className="text-xs text-red-500 flex flex-row">
              <ExclamationCircleIcon className="text-red-500 w-4 mr-1" />
              As senhas precisam ser iguais
            </div>
          )}
        </BoxInfo>
      </Columm>
    </>
  );
}
