import React, { useState } from 'react';
import { useAuth } from '../../../../../contexts/Auth';
import { useOSView } from '../../../../../contexts/OSView';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../../../functions/MessageFeedback';
import api from '../../../../../services/api';
import { IOS } from '../../../../../types/OS/IOS';
import ModalConfirmCancel from './components/ModalConfirmCancel';

interface IProps {
  os: IOS;
}

export default function ManagerButtons(props: IProps) {
  const [openCancel, setOpenCancel] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const { user } = useAuth();

  const { getOs } = useOSView();

  const { os } = props;

  async function cancelOrder(motive: string): Promise<void> {
    setLoading(true);

    const data = {
      motive,
    };

    try {
      await api.put(`os/manager/${os.id_os}/cancelar`, data);

      feedbackSuccess('OS cancelada!');

      await getOs(os.id_os);
    } catch (err) {
      feedbackError('Ocorreu um erro. Caso persista, contate o suporte.');
    }

    setLoading(false);
  }

  return (
    <>
      {user.id_grupo_usuario <= 58 &&
      os.status !== 'cancelado' &&
      os.status !== 'concluido' ? (
        <div className="bg-gray-200 mt-2 mx-1 shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-4 font-medium text-gray-900">
              Cancelar OS
            </h3>
            <div className="mt-2 sm:flex sm:items-start sm:justify-between">
              <div className="max-w-xl text-sm text-gray-500">
                <p>
                  A OS. ficará com status de Cancelada. Seu histórico será
                  mantido.
                </p>
              </div>
              <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                <button
                  type="button"
                  disabled={loading}
                  onClick={() => setOpenCancel(true)}
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <ModalConfirmCancel
        cancelOrder={cancelOrder}
        open={openCancel}
        setOpen={setOpenCancel}
      />
      {/*
      <div className="bg-gray-200 mt-2 mx-1 shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-4 font-medium text-gray-900">
            Deletar definitivamente
          </h3>
          <div className="mt-2 sm:flex sm:items-start sm:justify-between">
            <div className="max-w-xl text-sm text-gray-500">
              <p>A OS. será totalmente removida.</p>
            </div>
            <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
              <button
                type="button"
                disabled={loading}
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-black hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black sm:text-sm"
              >
                Deletar
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
