import React from 'react';
import { useOSView } from '../../../contexts/OSView';
import { IOS } from '../../../types/OS/IOS';
import { Button } from 'antd';

interface IProps {
  os: IOS;
}

const RowTable = ({ os }: IProps) => {
  const { defineIdOs } = useOSView();

  return (
    <tr key={os.id_os}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <Button
          type="link"
          style={{ color: '#000' }}
          onClick={() => defineIdOs(os.id_os, 'consultar')}
        >
          {os.id_os}
        </Button>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {os.apelido}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {os.apelido_equipamento}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {os.descricao.substring(50, 0)}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">
        <span className="inline-flex rounded-full bg-red-800 px-2 text-xs font-medium leading-5 text-white">
          Cancelada
        </span>
      </td>
    </tr>
  );
};

export default RowTable;
