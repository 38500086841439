import {
  feedbackError,
  feedbackSuccess,
} from '../../../../../functions/MessageFeedback';
import api from '../../../../../services/api';
import { ICollaborator } from '../../../../../types/Collaborator/ICollaborator';

async function HandleSubmit(
  collaborator: ICollaborator,
  id_funcionario: number,
): Promise<number | Error> {
  if (!collaborator?.nome) {
    feedbackError('Campo nome é obrigatório');
    return;
  }

  if (!collaborator?.nome_apelido) {
    feedbackError('Campo apelido é obrigatório');
    return;
  }

  if (!collaborator?.departamento) {
    feedbackError('Campo departamento é obrigatório');
    return;
  }

  if (collaborator?.tecnico !== true && collaborator?.tecnico !== false) {
    feedbackError('Campo técnico é obrigatório');
    return;
  }

  if (!collaborator?.status) {
    feedbackError('Campo status é obrigatório');
    return;
  }

  const body = {
    nome: collaborator.nome,
    nome_apelido: collaborator.nome_apelido,
    cpf: collaborator.cpf,
    rg: collaborator.rg,
    tecnico: collaborator.tecnico,
    departamento: collaborator.departamento,
    status: collaborator.status,
    telefone1: collaborator.telefone1,
    telefone2: collaborator.telefone2,
    telefone3: collaborator.telefone3,
    email1: collaborator.email1,
    email2: collaborator.email2,
    email3: collaborator.email3,
    whatsapp: collaborator.whatsapp,
  };

  if (id_funcionario) {
    try {
      await api.put(`collaborator/${id_funcionario}`, body);
      feedbackSuccess('Salvo com sucesso');

      return id_funcionario;
    } catch (err) {
      feedbackError(
        'Erro ao salvar. Caso o problema persista, contate o suporte.',
      );

      return err;
    }
  } else {
    try {
      const id = await api.post(`collaborator`, body);

      feedbackSuccess('Salvo com sucesso');

      return id.data;
    } catch (err) {
      feedbackError(
        'Erro ao salvar. Caso o problema persista, contate o suporte.',
      );

      return err;
    }
  }
}

export default HandleSubmit;
