/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { SearchIcon } from '@heroicons/react/solid';
import Row from './Row';
import { IOutfitter } from '../../../../types/Outfitter/IOutfitter';
import { PlusIcon } from '@heroicons/react/outline';

interface IProps {
  outfitters: [IOutfitter] | [];
  getList(filter?: string | null): void;
  setId_outfitter(id_outfitter: number): void;
  id_outfitter: number | null;
  newOutfitter(): void;
}

export default function List(props: IProps) {
  const [searchValue, setSearchValue] = useState('');

  const { setId_outfitter, id_outfitter, newOutfitter, outfitters, getList } =
    props;

  useEffect(() => {
    let filter = null;

    if (searchValue) filter = `&name=${searchValue}`;

    getList(filter);
  }, [searchValue]);

  return (
    <aside className="hidden h-screen xl:order-first xl:flex xl:flex-col flex-shrink-0 w-96 border-r border-gray-200">
      <div className="px-6 pt-6 pb-4 bg-white">
        <h2 className="text-lg font-medium text-gray-900">
          Lista de Fornecedores
        </h2>
        <p className="mt-1 text-sm text-gray-600 flex justify-between">
          <span>Exibindo {outfitters.length} fornecedores.</span>
          <button
            type="button"
            onClick={() => newOutfitter()}
            className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            Novo
          </button>
        </p>
        <form className="mt-6 flex space-x-4" action="#">
          <div className="flex-1 min-w-0">
            <label htmlFor="search" className="sr-only">
              Buscar
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="search"
                name="search"
                id="search"
                className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                placeholder="Buscar"
                onChange={e => setSearchValue(e.target.value)}
              />
            </div>
          </div>
        </form>
      </div>
      <nav
        className="flex-1 min-h-0 overflow-y-auto bg-white border-gray-200 border"
        aria-label="Outfitters"
      >
        {outfitters?.map((outfitter: IOutfitter, idx: number) => (
          <Row
            key={idx}
            setId_outfitter={setId_outfitter}
            id_outfitter={id_outfitter}
            outfitter={outfitter}
          />
        ))}
      </nav>
    </aside>
  );
}
