import {
  feedbackError,
  feedbackSuccess,
} from '../../../../../functions/MessageFeedback';
import api from '../../../../../services/api';
import { IUser } from '../../../../../types/Collaborator/IUser';

interface IBody {
  id_funcionario: number;
  usuario: string;
  id_grupo_usuario: number;
  bloqueado: boolean;
  desativado: boolean;
  senha?: string;
}

async function HandleSubmit(
  id_funcionario: number,
  user: IUser,
): Promise<void | Error> {
  if (!user?.usuario) {
    feedbackError('Campo usuário não pode ficar em branco');
    return;
  }

  if (!user?.id_grupo_usuario) {
    feedbackError('Campo grupo é obrigatório');
    return;
  }

  const body: IBody = {
    id_funcionario,
    usuario: user.usuario,
    id_grupo_usuario: user.id_grupo_usuario,
    bloqueado: user.bloqueado,
    desativado: user.desativado,
  };

  if (user.senha) body.senha = user.senha;

  if (user.id_usuario) {
    try {
      await api.put(`user/${user.id_usuario}`, body);
      feedbackSuccess('Salvo com sucesso');

      return;
    } catch (err) {
      if (err.response.data?.message === 'This username is already') {
        feedbackError('Este usuário já existe. Tente um diferente');
      } else {
        feedbackError(
          'Erro ao salvar. Caso o problema persista, contate o suporte.',
        );
      }

      return err;
    }
  } else {
    try {
      await api.post(`user`, body);

      feedbackSuccess('Salvo com sucesso');

      return;
    } catch (err) {
      feedbackError(
        'Erro ao salvar. Caso o problema persista, contate o suporte.',
      );

      return err;
    }
  }
}

export default HandleSubmit;
