import intervalToDuration from 'date-fns/intervalToDuration';
import api from '../services/api';
import { IUser } from '../types/Collaborator/IUser';

async function GetUserByFunc(id_funcionario: number): Promise<IUser> {
  const now = new Date();

  const stgUser = localStorage.getItem(
    `@elevsis-biz:userByFunc/${id_funcionario}`,
  );

  if (stgUser) {
    const user = await JSON.parse(stgUser);

    const oldCache = intervalToDuration({
      start: new Date(user.oldCache),
      end: new Date(now),
    });

    if (oldCache.hours < 23) {
      delete user.oldCache;

      return user;
    } else {
      try {
        const response = await api.get(`user/byFunc/${id_funcionario}`);

        response.data.oldCache = now;

        localStorage.setItem(
          `@elevsis-biz:userByFunc/${id_funcionario}`,
          JSON.stringify(response.data),
        );

        return response.data;
      } catch (err) {
        return;
      }
    }
  } else {
    try {
      const response = await api.get(`user/byFunc/${id_funcionario}`);

      response.data.oldCache = now;

      localStorage.setItem(
        `@elevsis-biz:userByFunc/${id_funcionario}`,
        JSON.stringify(response.data),
      );

      return response.data;
    } catch (err) {
      return;
    }
  }
}

export default GetUserByFunc;
