/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container, Line, Title, Description } from './styles';
import GetApelidoEquip from '../../../../functions/GetApelidoEquip';

function Alter_os({ log }) {
  const [apelidoEquip1, setApelidoEquip1] = useState('');
  const [apelidoEquip2, setApelidoEquip2] = useState('');

  useEffect(() => {
    if (log?.old.id_equipamento !== log?.body.id_equipamento) {
      convertEquip(log);
    }
  }, [log]);

  async function convertEquip() {
    const apelido_equipamento1 = await GetApelidoEquip(log?.old.id_equipamento);
    const apelido_equipamento2 = await GetApelidoEquip(
      log?.body.id_equipamento,
    );

    setApelidoEquip1(apelido_equipamento1);
    setApelidoEquip2(apelido_equipamento2);
  }

  return (
    <>
      <Container>
        {log?.old.solicitante == log?.body.solicitante ? null : (
          <Line>
            <Title>Solicitante:</Title>
            <Description>
              Foi alterado de: <b>{log?.old.solicitante}</b> para{' '}
              <b>{log?.body.solicitante}</b>
            </Description>
          </Line>
        )}

        {log?.old.prioridade == log?.body.prioridade ? null : (
          <Line>
            <Title>Prioridade:</Title>
            <Description>
              Foi alterado de: <b>{log?.old.prioridade}</b> para{' '}
              <b>{log?.body.prioridade}</b>
            </Description>
          </Line>
        )}

        {log?.old.id_equipamento == log?.body.id_equipamento ? null : (
          <Line>
            <Title>Equipamento:</Title>
            <Description>
              Foi alterado de: <b>{apelidoEquip1}</b> para{' '}
              <b>{apelidoEquip2}</b>
            </Description>
          </Line>
        )}

        {log?.old.descricao == log?.body.descricao ? null : (
          <Line>
            <Title>Descrição da OS:</Title>
            <Description>
              Foi alterado de: <b>{log?.old.descricao}</b> para{' '}
              <b>{log?.body.descricao}</b>
            </Description>
          </Line>
        )}

        {log?.old.pre_observacao == log?.body.pre_observacao ? null : (
          <Line>
            <Title>Pré-observação:</Title>
            <Description>
              Foi alterado de: <b>{log?.old.pre_observacao}</b> para{' '}
              <b>{log?.body.pre_observacao}</b>
            </Description>
          </Line>
        )}

        {log?.old.chamado == log?.body.chamado ? null : (
          <Line>
            <Title>Chamado:</Title>
            <Description>
              Foi alterado de: <b>{log?.old.chamado}</b> para{' '}
              <b>{log?.body.chamado}</b>
            </Description>
          </Line>
        )}

        <Line />

        <Line>
          <Title>Descrição:</Title>
          <Description>{log.description}</Description>
        </Line>
      </Container>
    </>
  );
}

export default Alter_os;
