/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import ptLocale from 'date-fns/locale/pt';
import { URLs } from '../../../../configs/URLs';
import api from '../../../../services/api';
import io from '../../../../services/socket';
import { key } from '../../../../services/apiKeyGoogleMaps';
import { formatDistanceToNow } from 'date-fns/esm';

export default function Map() {
  const [markers, setMarkers] = useState([]);
  const [osPending, setOsPending] = useState([]);
  const [IdsTec, setIdsTec] = useState([]);

  useEffect(() => {
    getPositions();
    getChamadosPendentes();

    setInterval(() => {
      getChamadosPendentes();
    }, 30000);

    eventsRealTime();
  }, []);

  async function getPositions() {
    const response = await api.get('collaborator/geolocation/mapa_aovivo');

    const { data } = response;

    setMarkers(data);
  }

  async function getChamadosPendentes() {
    const response = await api.get(
      `os/pending?lines_per_page=999&chamado=true&situacao=liberada`,
    );

    setOsPending(response.data.data);
  }

  function eventsRealTime() {
    io.on('atualiza_mapa', () => {
      getPositions();
    });
  }

  const AnyReactComponent = ({ text, lat, lng }) => <div>{text}</div>;

  const markersfuncionarios = markers?.map((marker, item) => {
    if (marker.status_availability === 'Indisponivel') {
      const date_last_status = new Date(marker?.date_last_status);
      const date_time = date_last_status.getTime();

      const date = formatDistanceToNow(new Date(date_time), {
        addSuffix: false,
        locale: ptLocale,
      });

      return (
        <AnyReactComponent
          key={marker?.coords?.id_collaborator}
          lat={marker?.coords?.lat}
          lng={marker?.coords?.lng}
          text={
            <>
              <span
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  display: 'block',
                  height: 6,
                  width: 6,
                  borderRadius: 365,
                  backgroundColor: 'red',
                }}
              />
              <a title={marker?.surname + ' - Off há ' + date}>
                <img
                  style={{
                    height: 32,
                    width: 32,
                    borderRadius: 365,
                  }}
                  src={marker?.avatar ? marker.avatar : URLs['avatar-icon']}
                />
              </a>
            </>
          }
        />
      );
    }

    if (marker?.status_availability === 'Disponivel') {
      const date_last_position = new Date(marker?.date_last_position);
      const date_time = date_last_position.getTime();

      const date = formatDistanceToNow(new Date(date_time), {
        addSuffix: false,
        locale: ptLocale,
      });

      return (
        <AnyReactComponent
          key={marker?.coords?.id_collaborator}
          lat={marker?.coords?.lat}
          lng={marker?.coords?.lng}
          text={
            <>
              <span
                style={{
                  position: 'absolute',
                  display: 'block',
                  width: 6,
                  top: 0,
                  right: 0,
                  height: 6,
                  borderRadius: 365,
                  backgroundColor: 'green',
                }}
              />
              <a title={marker?.surname}>
                <img
                  style={{
                    height: 36,
                    width: 36,
                    borderRadius: 365,
                  }}
                  src={marker?.avatar ? marker.avatar : URLs['avatar-icon']}
                />
              </a>
              <span
                style={{
                  display: 'inline-flex',
                  width: 96,
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingLeft: 6,
                  paddingRight: 6,
                  paddingTop: 2,
                  paddingBottom: 2,
                  borderRadius: 6,
                  color: '#000',
                  fontSize: 9,
                  backgroundColor: 'white',
                  opacity: 0.9,
                }}
              >
                Há {date}
              </span>
            </>
          }
        />
      );
    }
  });

  const markersOsPending = osPending.map((marker, item) => {
    if (marker.id_atendimento_last !== 0) return;

    return (
      <AnyReactComponent
        key={marker?.id_os}
        lat={marker?.geo_lat}
        lng={marker?.geo_lon}
        text={
          <>
            <a title={marker?.apelido + ' #' + marker?.id_os}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ height: 25, width: 25 }}
                viewBox="0 0 20 20"
                fill={marker?.status === 'aberto' ? 'red' : 'gray'}
              >
                <path
                  fillRule="evenodd"
                  d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </>
        }
      />
    );
  });

  const defaultProps = {
    center: {
      lat: -30.013791056741006,
      lng: -51.19836277896573,
    },
    zoom: 15,
  };

  return (
    <div>
      <GoogleMapReact
        style={{ height: '100%', width: '100%' }}
        bootstrapURLKeys={{ key }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {markersfuncionarios?.length > 0 ? markersfuncionarios : null}
        {markersOsPending?.length > 0 ? markersOsPending : null}
      </GoogleMapReact>
    </div>
  );
}
