/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Badge } from 'antd';
import { WechatOutlined } from '@ant-design/icons';

import { ContainerChat } from './styles';
import { useOSGerir } from '../../../../contexts/OSGerir';
import Chat from '../../../../components/Chat';

import api from '../../../../services/api';
import socket from '../../../../services/socket';

export default function SectionChat() {
  const [viewChat, setViewChat] = useState(false);
  const [numMsg, setNumMsg] = useState(0);

  const { id_os_view } = useOSGerir();

  useEffect(() => {
    countMessages();
    getSocket();
  }, [id_os_view]);

  async function countMessages() {
    if (!id_os_view) return;

    try {
      const response = await api.post(`messages/countNotRead`, {
        id_table_origin: id_os_view,
        table_origin: 'os',
      });
      setNumMsg(response.data);
    } catch (err) {
      setNumMsg(0);
    }
  }

  function getSocket() {
    socket.on(
      `list_messages?id_table_origin=${id_os_view}&table_origin=os`,
      async message => {
        await countMessages();
      },
    );
  }

  function socketOff() {
    socket.off(
      `list_messages?id_table_origin=${id_os_view}&table_origin=os`,
      message => {
        return;
      },
    );
  }

  function changeWindow() {
    setViewChat(!viewChat);
    setNumMsg(0);
  }

  return (
    <ContainerChat>
      <Badge count={numMsg} offset={[-65, 10]}>
        <WechatOutlined
          onClick={() => changeWindow()}
          style={{
            fontSize: 40,
            height: 70,
            color: '#FFF',
            backgroundColor: '#3b72ff',
            padding: 15,
            borderRadius: 360,
          }}
        />
      </Badge>

      {viewChat ? (
        <Chat id_table_origin={id_os_view} table_origin="os" />
      ) : (
        socketOff()
      )}
    </ContainerChat>
  );
}
