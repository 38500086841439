import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  background-color: #fff;
  flex-direction: row;
  min-height: 600px;
  width: 500px;
  min-width: 300px;
`;
