import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';

import { IOS } from '../../types/OS/IOS';
import { IReqPeca } from '../../types/ReqPeca/IReqPeca';
import { IAtendimento } from '../../types/Atendimento/IAtendimento';
import api from '../../services/api';
import { useOSView } from '../../contexts/OSView';
import convertDateTime from '../../functions/ConvertDateTime';
import GetApelidoEquip from '../../functions/GetApelidoEquip';
import GetNameFunc from '../../functions/GetNameFunc';
import { useAuth } from '../../contexts/Auth';

interface IProps {
  id_os: number;
  open: boolean;
  setOpen(Boolean): void;
  action: string;
}

export default function OSViewSlideOver(props: IProps) {
  const [os, setOs] = useState<IOS>();
  const [req_pecas, setReq_pecas] = useState<IReqPeca[]>([]);
  const [atendimentos, setAtendimentos] = useState<IAtendimento[]>([]);
  const [data_criacao, setData_criacao] = useState('');
  const [data_conclusao, setData_conclusao] = useState('');
  const [func_gerou, setFunc_gerou] = useState('');
  const [func_concluiu, setFunc_concluiu] = useState('');
  const [apelido_equip, setApelido_equip] = useState('');

  const { id_os, open, setOpen, action } = props;

  const { user } = useAuth();

  const { defineIdOs } = useOSView();

  useEffect(() => {
    getOs();
    getReqPecas();
    // getAttendances();
  }, [props]);

  async function getOs() {
    if (!id_os) return;
    if (!open) return;

    try {
      const response = await api.get(`os/${id_os}`);

      setOs(response.data);

      convertDatas(response.data);
    } catch (err) {
      return;
    }
  }

  async function getReqPecas() {
    if (!id_os) return;
    if (!open) return;

    try {
      const response = await api.get(`req_peca?id_os=${id_os}`);

      setReq_pecas(response.data.data);
    } catch (err) {
      return;
    }
  }

  function openOs(action: 'consultar' | 'gerir') {
    defineIdOs(os?.id_os, action);

    setOpen(false);
  }

  async function getAttendances() {
    if (!id_os) return;
    if (!open) return;

    try {
      const response = await api.get(`atendimento?id_os=${id_os}`);

      setAtendimentos(response.data);
    } catch (err) {
      return;
    }
  }

  async function convertDatas(os: IOS) {
    const func_gerou = await GetNameFunc(os.id_funcionario_gerou);
    setFunc_gerou(func_gerou.nome_apelido);

    const apelido_equip = await GetApelidoEquip(os.id_equipamento);
    setApelido_equip(apelido_equip);

    const data_criacao = await convertDateTime(os.created_at);
    setData_criacao(data_criacao);

    if (os.status === 'concluido') {
      const data_conclusao = await convertDateTime(os.data_saida);
      setData_conclusao(data_conclusao);

      const func_concluiu = await GetNameFunc(os.id_funcionario_resp);
      setFunc_concluiu(func_concluiu.nome_apelido);
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto relative w-96">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                    <button
                      type="button"
                      className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Fechar painel</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full overflow-y-auto bg-white p-6">
                  <div className="space-y-4 pb-16">
                    <div>
                      <div className="mt-1 flex items-start justify-between border-b-2">
                        <div>
                          <h2 className="text-lg font-medium text-gray-900">
                            <span className="sr-only">Detalhes de </span>
                            OS: {os?.id_os}
                          </h2>
                          <p className="leading-4 font-medium text-gray-500">
                            {os?.apelido}
                          </p>
                        </div>
                        {os?.chamado ? 'Chamado' : 'OS Simples'}
                      </div>
                    </div>

                    <div>
                      <h3 className="font-medium text-gray-900">
                        Descrição do serviço
                      </h3>
                      <div className="mt-2 flex items-center justify-between">
                        <p className="text-sm italic text-gray-500">
                          {os?.descricao}
                        </p>
                      </div>
                    </div>

                    {os?.status === 'concluido' ? (
                      <div>
                        <h3 className="font-medium text-gray-900">
                          Parecer técnico
                        </h3>
                        <div className="mt-2 flex items-center justify-between">
                          <p className="text-sm italic text-gray-500">
                            {os?.parecer}
                          </p>
                        </div>
                      </div>
                    ) : null}
                    <div>
                      <dl className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
                        <div className="flex justify-between py-2 text-sm font-medium">
                          <dt className="text-gray-500">Equipamento</dt>
                          <dd className="text-gray-900">{apelido_equip}</dd>
                        </div>
                        <div className="flex justify-between py-2 text-sm font-medium">
                          <dt className="text-gray-500">Gerada</dt>
                          <dd className="text-gray-900">
                            {data_criacao} ({func_gerou})
                          </dd>
                        </div>
                        {os?.status === 'concluido' ? (
                          <div className="flex justify-between py-2 text-sm font-medium">
                            <dt className="text-gray-500">Concluída</dt>
                            <dd className="text-gray-900">
                              {data_conclusao} - {func_concluiu}
                            </dd>
                          </div>
                        ) : null}
                        {os?.solicitante ? (
                          <div className="flex justify-between py-2 text-sm font-medium">
                            <dt className="text-gray-500">Solicitante</dt>
                            <dd className="text-gray-900">{os?.solicitante}</dd>
                          </div>
                        ) : null}
                        <div className="flex justify-between py-2 text-sm font-medium">
                          <dt className="text-gray-500">Prioridade</dt>
                          <dd className="text-gray-900 capitalize">
                            {os?.prioridade}
                          </dd>
                        </div>
                        <div className="flex justify-between py-2 text-sm font-medium">
                          <dt className="text-gray-500">
                            Status de Atendimento
                          </dt>
                          <dd className="text-gray-900 capitalize">
                            {os?.status}
                          </dd>
                        </div>
                        {os?.status !== 'concluido' ? (
                          <div className="flex justify-between py-2 text-sm font-medium">
                            <dt className="text-gray-500">
                              Status de Liberação
                            </dt>
                            <dd className="text-gray-900 capitalize">
                              {os?.situacao === 'liberada'
                                ? 'Liberada para Executar'
                                : null}
                              {os?.situacao === 'pendencia'
                                ? 'Bloqueada para Executar'
                                : null}
                            </dd>
                          </div>
                        ) : null}
                      </dl>
                    </div>

                    <div>
                      <h3 className="font-medium text-gray-900">
                        Requisições de Peça
                      </h3>
                      {req_pecas.length > 0 ? (
                        <ul
                          role="list"
                          className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200"
                        >
                          {req_pecas.map(req_peca => (
                            <li
                              className="flex items-center justify-between py-2"
                              key={req_peca.id_req_peca}
                            >
                              <div className="flex items-start">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                                  />
                                </svg>
                                <p className="ml-4 text-xs font-medium text-gray-600">
                                  {req_peca.quantidade} {req_peca.nome_peca}
                                </p>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p className="ml-4 text-xs font-medium text-gray-600">
                          Não há peças
                        </p>
                      )}
                    </div>
                    <div className="flex">
                      <button
                        onClick={() => openOs('consultar')}
                        type="button"
                        className="flex-1 rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      >
                        Consultar
                      </button>
                      {user.id_grupo_usuario <= 58 && (
                        <button
                          onClick={() => openOs('gerir')}
                          type="button"
                          className="flex-1 rounded-md border border-transparent bg-gray-500 ml-2 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                        >
                          Validar / Cancelar
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
