import React, { useEffect, useState } from 'react';
import {
  BoxInfo,
  Columm,
  ContainerContent,
  LineAjuste,
  TitleAjuste,
  TitleField,
} from '../styles';
import { Input, Select, Switch, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { IUser } from '../../../../types/Collaborator/IUser';
import GetUser from './functions/GetUser';
import { ContainerLineContent } from '../../../Collaborators/Body/Contents/styles';
import { IGrupoUsuario } from '../../../../types/User/IGrupoUsuario';
import GetPermissionsGroups from './functions/GetPermissionsGroups';
import HandleSubmit from './functions/HandleSubmit';
import PasswordSection from './components/PasswordSection';
import { useAuth } from '../../../../contexts/Auth';
import NoUserFound from './components/NoUserFound';
import SpinnerLoad from '../../../../components/SpinnerLoad';

const { Option } = Select;

interface IProps {
  id_funcionario?: number;
  setId_funcionario?(id_funcionario: number): void;
}

export default function FormUser(props: IProps) {
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState(false);
  const [groupsUser, setGroupsUser] = useState<IGrupoUsuario[] | []>([]);

  const { id_funcionario } = props;

  const { user: userAccess } = useAuth();

  useEffect(() => {
    if (id_funcionario) {
      getUser();
    } else {
      cleanStates();
    }

    getGroups();
  }, [id_funcionario]);

  async function getGroups(): Promise<void> {
    try {
      const groups = await GetPermissionsGroups();

      if (groups) setGroupsUser(groups);
    } catch (error) {
      return;
    }
  }

  async function handleChange(event) {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  }

  function setPassword(password: string): void {
    setUser({
      ...user,
      senha: password,
    });
  }

  async function getUser() {
    if (!id_funcionario) return;

    setLoading(true);

    try {
      const data = await GetUser(id_funcionario);

      if (data) setUser(data);
    } catch (error) {
      setLoading(false);
    }

    setLoading(false);
  }

  async function cleanStates() {
    setUser(null);
  }

  async function saveData() {
    setLoading(true);

    try {
      await HandleSubmit(id_funcionario, user);

      getUser();
    } catch (error) {
      setLoading(false);

      return;
    }

    setLoading(false);
  }

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-4 sm:p-6">
          <div>
            <h3 className="text-lg leading-4 font-medium text-gray-900">
              Dados de usuário
            </h3>
          </div>

          {!user?.id_usuario && !loading && <NoUserFound />}

          {loading ? (
            <SpinnerLoad />
          ) : (
            <>
              <ContainerContent>
                <Columm>
                  <BoxInfo>
                    <TitleField>Usuário</TitleField>
                    <Input
                      name="usuario"
                      id="usuario"
                      value={user?.usuario}
                      defaultValue={user?.usuario}
                      maxLength={30}
                      onChange={e => handleChange(e)}
                    />
                  </BoxInfo>
                </Columm>

                <Columm>
                  <BoxInfo>
                    <TitleField>
                      Grupo de permissões
                      <Tooltip title="Você só pode definir um grupo com privilégios iguais ou inferiores que os seus">
                        <QuestionCircleOutlined style={{ paddingLeft: 3 }} />
                      </Tooltip>
                    </TitleField>
                    <Select
                      style={{ width: '100%' }}
                      id="id_grupo_usuario"
                      defaultValue={user?.id_grupo_usuario}
                      value={user?.id_grupo_usuario}
                      onChange={e =>
                        setUser(prevState => {
                          return { ...prevState, id_grupo_usuario: e };
                        })
                      }
                    >
                      {groupsUser?.map(
                        (item: IGrupoUsuario) =>
                          userAccess.id_grupo_usuario <=
                            item.id_grupo_usuario && (
                            <Option
                              key={item.id_grupo_usuario}
                              value={item.id_grupo_usuario}
                            >
                              {item.nome_grupo}
                            </Option>
                          ),
                      )}
                    </Select>
                  </BoxInfo>
                </Columm>
              </ContainerContent>

              <ContainerLineContent>
                <LineAjuste>
                  <TitleAjuste>Bloquear Usuário</TitleAjuste>
                  <Switch
                    id="bloqueado"
                    defaultChecked={user?.bloqueado}
                    checked={user?.bloqueado}
                    onChange={e =>
                      setUser(prevState => {
                        return { ...prevState, bloqueado: e };
                      })
                    }
                  />
                </LineAjuste>
                <LineAjuste>
                  <TitleAjuste>Desativar Usuário</TitleAjuste>
                  <Switch
                    id="desativado"
                    defaultChecked={user?.desativado}
                    checked={user?.desativado}
                    onChange={e =>
                      setUser(prevState => {
                        return { ...prevState, desativado: e };
                      })
                    }
                  />
                </LineAjuste>
              </ContainerLineContent>

              <div>
                <h3 className="text-base mt-10 leading-4 font-medium text-gray-900">
                  {!user?.id_usuario
                    ? 'Você precisa criar uma senha para este novo usuário. (obrigatório)'
                    : 'Para alterar a senha, preencha uma nova abaixo. (opcional)'}
                </h3>
              </div>

              <ContainerContent>
                <PasswordSection setPassword={setPassword} />
              </ContainerContent>
            </>
          )}
        </div>

        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            disabled={loading}
            onClick={() => saveData()}
            type="submit"
            className="bg-blue-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  );
}
