/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { AutoComplete } from 'antd';

export default function AutocompleteInput(props, { ...rest }) {
  const { options, value, setValue, style, placeholder } = props;

  const onSelect = data => {
    setValue(data);
  };

  const onChange = data => {
    setValue(data);
  };

  return (
    <>
      <AutoComplete
        value={value}
        options={options}
        onSelect={onSelect}
        onChange={onChange}
        style={style}
        placeholder={placeholder}
      />
    </>
  );
}
