import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  max-width: 1368px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  margin-left: 0px;
`;

export const List = styled.div`
  width: 250px;
  border: 2px solid #000;
  border-radius: 2px;
  background-color: #ccc;
  flex-direction: column;
`;

export const Ficha = styled.div`
  width: 660px;
  height: 730px;
  border: 2px solid #000;
  border-radius: 2px;
  background-color: #fff;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 30px;
  margin-right: 10px;
`;
