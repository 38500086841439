import React from 'react';
import styled from 'styled-components';

interface IProps {
  active: boolean;
}

export const ContainerNavigation = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
  border-bottom: 2px solid #e8e8e8;
`;

export const BoxButtonNavigation = styled.div<IProps>`
  height: 30px;
  margin-right: 18px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: ${props => (props.active ? '2px solid #3B72FF' : null)};
`;

export const TextButtonNavigation = styled.button<IProps>`
  border: 0px;
  background-color: #fff;
  font-size: 14px;
  color: ${props => (props.active ? '#666' : '#aaa')};
  font-weight: ${props => (props.active ? 'bold' : null)};
`;
