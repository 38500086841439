/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import api from '../../../../../../../services/api';
import ImagesListPreview from '../../../../../../../components/ImagesListPreview';

export default function SlideOverPart(props) {
  const [open, setOpen] = useState(false);
  const [part, setPart] = useState<any>({});
  const [partDetails, setPartDetails] = useState<any>({});

  const { openSlider, id_part, setOpenSlider, setId_part_view } = props;

  useEffect(() => {
    if (openSlider && id_part) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    if (id_part) getPart();
  }, [props]);

  useEffect(() => {
    if (!open) {
      setOpenSlider();
      setId_part_view();
    }
  }, [open]);

  async function getPart() {
    try {
      const response = await api.get(`parts/${id_part}`);
      setPart(response.data);

      const response2 = await api.get(`parts/details/${id_part}`);
      setPartDetails(response2.data);
    } catch (err) {
      setOpen(false);
      return;
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative inset-0 overflow-hidden z-50"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto relative w-96">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                    <button
                      type="button"
                      className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full overflow-y-auto bg-white p-8">
                  <div className="space-y-6 pb-16">
                    <div>
                      <div className="aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg"></div>
                      <div className="mt-4 flex items-start justify-between">
                        <div>
                          <h2 className="text-lg font-medium text-gray-900">
                            <span className="sr-only">Detalhes</span>
                            {part.description}
                          </h2>
                          <p className="text-sm font-medium text-gray-800">
                            Cód: <b>{part.id_part}</b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h3 className="font-medium text-gray-900">Informações</h3>
                      <dl className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
                        <div className="flex justify-between py-3 text-sm font-medium">
                          <dt className="text-gray-500">Endereço no Estoque</dt>
                          <dd className="text-gray-900">
                            {partDetails?.stockAdress
                              ? partDetails.stockAdress[0] +
                                ' - ' +
                                partDetails.stockAdress[1] +
                                ' - ' +
                                partDetails.stockAdress[2] +
                                ' - ' +
                                partDetails.stockAdress[3]
                              : null}
                          </dd>
                        </div>
                        <div className="flex justify-between py-3 text-sm font-medium">
                          <dt className="text-gray-500">Quantidade atual</dt>
                          <dd className="text-gray-900">
                            {part.current_quantity}
                          </dd>
                        </div>
                        <div className="flex justify-between py-3 text-sm font-medium">
                          <dt className="text-gray-500">Quantidade mínima</dt>
                          <dd className="text-gray-900">
                            {part.minimum_quantity}
                          </dd>
                        </div>
                        <div className="flex justify-between py-3 text-sm font-medium">
                          <dt className="text-gray-500">Quantidade ideal</dt>
                          <dd className="text-gray-900">
                            {part.ideal_quantity}
                          </dd>
                        </div>
                        <div className="flex justify-between py-3 text-sm font-medium">
                          <dt className="text-gray-500">Preço de venda</dt>
                          <dd className="text-gray-900">
                            {part.price ? `R$ ${part.price}` : '-'}
                          </dd>
                        </div>
                      </dl>
                    </div>
                    {part.details ? (
                      <div>
                        <h3 className="font-medium text-gray-900">Detalhes</h3>
                        <div className="mt-2 flex items-center justify-between">
                          <p className="text-sm italic text-gray-500">
                            {part.details}
                          </p>
                        </div>
                      </div>
                    ) : null}
                    <div>
                      <h3 className="font-medium text-gray-900">
                        Fotos cadastradas
                      </h3>
                      <div className="mt-2 flex items-center justify-between">
                        <p className="text-sm italic text-gray-500">
                          <ImagesListPreview
                            table_origin="part"
                            id_table_origin={id_part}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
