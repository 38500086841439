/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Cascader, Select } from 'antd';
import api from '../../../../services/api';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../../functions/MessageFeedback';
import UploadPhoto from '../../../../components/UploadPhoto';
import {
  Container,
  FormSection,
  BoxForm,
  BoxButton,
  BottomBar,
} from './styles';
import ImagesPartList from '../ImagesPartList';
import { OptionsCategories } from './utils/OptionsCategories';
import { OptionsCompatibility } from './utils/OptionsCompatibility';
import {
  OptionsStep1,
  OptionsStep2,
  OptionsStep3,
  OptionsStep4,
} from './utils/OptionsStockAdress';

const { Option } = Select;

const FormPartComplementary = ({ id_part, children }) => {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<any>([[]]);
  const [compatibility, setCompatibility] = useState<any>([[]]);
  const [step1, setStep1] = useState('S1');
  const [step2, setStep2] = useState(null);
  const [step3, setStep3] = useState(null);
  const [step4, setStep4] = useState(null);

  const history = useHistory();

  useEffect(() => {
    getPartDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_part]);

  async function getPartDetails() {
    try {
      const response = await api.get(`parts/details/${id_part}`);
      const { category, compatibility, stockAdress } = response.data;

      setCategory(category);
      setCompatibility(compatibility);

      if (stockAdress[0]) setStep1(stockAdress[0]);
      setStep2(stockAdress[1]);
      setStep3(stockAdress[2]);
      setStep4(stockAdress[3]);
    } catch (err) {
      return;
    }
  }

  //Sala > Fileira > Linha > Coluna
  async function changeStep1(value: string) {
    //Sala
    setStep1(value);
    setStep2('');
    setStep3('');
    setStep4('');
  }

  async function changeStep2(value: string) {
    //Fileira
    setStep2(value);
    setStep3('');
    setStep4('');
  }

  async function changeStep3(value: string) {
    //Linha
    setStep3(value);
    setStep4('');
  }

  async function changeStep4(value: string) {
    //Coluna
    setStep4(value);
  }

  async function handlePart() {
    setLoading(true);

    const stockAdress = [step1, step2, step3, step4];

    const body = {
      category,
      compatibility,
      stockAdress,
    };

    try {
      await api.put(`/parts/details/${id_part}`, body);

      feedbackSuccess('Sucesso');
    } catch (err) {
      feedbackError('Ocorreu um erro ao salvar a peça');
    }

    setLoading(false);
  }

  const optionsStep1 = OptionsStep1.map(option => {
    return (
      <Option key={option.value} value={option.value}>
        {option.label}
      </Option>
    );
  });

  const optionsStep2 = OptionsStep2.map(option => {
    return (
      <Option key={option.value} value={option.value}>
        {option.label}
      </Option>
    );
  });

  const optionsStep3 = OptionsStep3.map(option => {
    return (
      <Option key={option.value} value={option.value}>
        {option.label}
      </Option>
    );
  });

  const optionsStep4 = OptionsStep4.map(option => {
    return (
      <Option key={option.value} value={option.value}>
        {option.label}
      </Option>
    );
  });

  return (
    <Container>
      <FormSection>
        {id_part ? (
          <>
            <BoxForm>
              Categoria
              <Cascader
                style={{ width: 420 }}
                size="middle"
                options={OptionsCategories}
                multiple
                placeholder="Local da peça"
                defaultValue={category}
                value={category}
                onChange={e => setCategory(e)}
              />
            </BoxForm>

            <BoxForm>
              Compatibilidade
              <Cascader
                style={{ width: 420 }}
                size="middle"
                options={OptionsCompatibility}
                multiple
                placeholder="Marca / Modelo"
                defaultValue={compatibility}
                value={compatibility}
                onChange={e => setCompatibility(e)}
              />
            </BoxForm>

            <BoxForm>
              Endereço no estoque
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Select onChange={e => changeStep1(e)} value={step1}>
                  {optionsStep1}
                </Select>

                {step1 ? (
                  <Select
                    style={{ minWidth: 120 }}
                    value={step2}
                    onChange={e => changeStep2(e)}
                    placeholder="Fileira"
                  >
                    {optionsStep2}
                  </Select>
                ) : null}

                {step2 ? (
                  <Select
                    style={{ minWidth: 110 }}
                    value={step3}
                    onChange={e => changeStep3(e)}
                    placeholder="Linha"
                  >
                    {optionsStep3}
                  </Select>
                ) : null}

                {step3 ? (
                  <Select
                    style={{ minWidth: 120 }}
                    value={step4}
                    onChange={e => changeStep4(e)}
                    placeholder="Coluna"
                  >
                    {optionsStep4}
                  </Select>
                ) : null}
              </div>
            </BoxForm>

            <BoxForm>
              <BoxButton onClick={handlePart}>{children}</BoxButton>
            </BoxForm>

            <ImagesPartList origem="part" id_table_origem={id_part} />

            <UploadPhoto origem="part" id_table_origem={id_part} />
          </>
        ) : (
          'Preencha o formulário ao lado e clique em Salvar, para liberar aos demais campos.'
        )}
      </FormSection>

      <BottomBar></BottomBar>
    </Container>
  );
};
export default FormPartComplementary;
