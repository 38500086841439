import React, { useState, useEffect } from 'react';
import { Skeleton, Card, Avatar } from 'antd';
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from '@ant-design/icons';

import api from '../../services/api';
import ConvertDateTime from '../../functions/ConvertDateTime';
import GetUserPerCode from '../../functions/GetUserPerCode';
import GetNameFunc from '../../functions/GetNameFunc';

export default function LineLog(props) {
  const [description, setDescription] = useState('');
  const [dataConvertida, setDataConvertida] = useState('');
  const [nameFuncAuthor, setNameFuncAuthor] = useState('');

  useEffect(() => {
    let log = props.log;
    setDescription(props.log.description);

    convertData(log);
  }, [props]);

  async function convertData(log) {
    const data_convertida = await ConvertDateTime(log.dateTime);
    setDataConvertida(data_convertida);

    const { id_funcionario } = await GetUserPerCode(log.id_user_author);
    const { nome_apelido } = await GetNameFunc(id_funcionario);
    setNameFuncAuthor(nome_apelido);
  }

  return (
    <>
      <h4>
        {description} - {nameFuncAuthor} em {dataConvertida}
      </h4>
    </>
  );
}
