import React, { useEffect } from 'react';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Select } from 'antd';
import { IShowEquipamento } from '../../../../../types/IShowEquipamento';
import api from '../../../../../services/api';
import { useClientRegister } from '../../../../../contexts/ClientRegister';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../../../functions/MessageFeedback';
import { Link } from 'react-router-dom';

const { Option } = Select;

export default function SlideOverEquipamento(props) {
  const [equipamento, setEquipamento] = useState<IShowEquipamento>();

  const { id_equipamento, setId_equipamento_view } = props;

  const { openSliderEquip, setOpenSliderEquip, id_cliente } =
    useClientRegister();

  const { setLoading } = useClientRegister();

  useEffect(() => {
    if (id_equipamento) {
      getEquipamento();
    } else {
      setEquipamento(null);
    }
  }, [props]);

  async function getEquipamento() {
    try {
      const response = await api.get(`equipamento/${id_equipamento}`);

      setEquipamento(response.data);
    } catch (err) {
      feedbackError(
        'Erro ao carregar o equipamento. Tente novamente mais tarde.',
      );
    }
  }

  async function handleChange(event) {
    setEquipamento({
      ...equipamento,
      [event.target.name]: event.target.value,
    });
  }

  async function handleSubmit() {
    if (!equipamento.apelido) return;

    setLoading(true);

    if (id_equipamento) {
      try {
        await api.put(`equipamento/${id_equipamento}`, equipamento);
        feedbackSuccess('Salvo com sucesso');

        setLoading(false);
      } catch (err) {
        feedbackError('Não foi possível editar');
      }
    } else {
      const newEquip = equipamento;

      newEquip.id_cliente = id_cliente;
      newEquip.tipo = 'elevador';
      newEquip.funcionamento = 'normal';
      newEquip.status = 'ativo';

      try {
        await api.post(`equipamento`, newEquip);
        feedbackSuccess('Salvo com sucesso');

        setLoading(false);
      } catch (err) {
        feedbackError('Não foi possível criar');
      }
    }
    setLoading(false);

    setOpenSliderEquip(false);
  }

  return (
    <Transition.Root show={openSliderEquip} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={setOpenSliderEquip}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-md">
                <span className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div className="h-0 flex-1 overflow-y-auto">
                    <div className="bg-blue-700 py-6 px-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">
                          {!id_equipamento
                            ? 'Novo Equipamento'
                            : 'Dados do Equipamento'}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-blue-700 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpenSliderEquip(false)}
                          >
                            <span className="sr-only">Fechar painel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-blue-300">
                          Dados gerais sobre o equipamento.
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                      <div className="divide-y divide-gray-200 px-4 sm:px-6">
                        <div className="space-y-6 pt-6 pb-5">
                          <div>
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium text-gray-900"
                            >
                              Apelido do Equipamento
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="apelido"
                                id="apelido"
                                value={equipamento?.apelido}
                                defaultValue={equipamento?.apelido}
                                onChange={e => handleChange(e)}
                                className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="observacao"
                              className="block text-sm font-medium text-gray-900"
                            >
                              Observação
                            </label>
                            <div className="mt-1">
                              <textarea
                                id="observacao"
                                name="observacao"
                                rows={3}
                                value={equipamento?.observacao}
                                defaultValue={equipamento?.observacao}
                                onChange={e => handleChange(e)}
                                className="block w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          <div className="flex grid-cols-2 gap-4">
                            <div>
                              <label
                                htmlFor="fabricante"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Fabricante
                              </label>
                              <div className="mt-1">
                                <select
                                  id="fabricante"
                                  name="fabricante"
                                  style={{ backgroundColor: '#fff' }}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  value={equipamento?.fabricante}
                                  onChange={e => handleChange(e)}
                                >
                                  <option value=""></option>
                                  <option value="A1">Atlas</option>
                                  <option value="A2">Thyssen</option>
                                  <option value="A3">Otis</option>
                                  <option value="A4">Sur</option>
                                  <option value="A5">Schindler</option>
                                  <option value="A6">GMV</option>
                                  <option value="A7">Kone</option>
                                  <option value="A9">Neutron</option>
                                  <option value="A999">Outro</option>
                                </select>
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Modelo
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="modelo"
                                  id="modelo"
                                  value={equipamento?.modelo}
                                  defaultValue={equipamento?.modelo}
                                  onChange={e => handleChange(e)}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex grid-cols-2 gap-4">
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Capacidade (kg)
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="capacidade"
                                  id="capacidade"
                                  value={equipamento?.capacidade}
                                  defaultValue={equipamento?.capacidade}
                                  onChange={e => handleChange(e)}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Comando
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="comando"
                                  id="comando"
                                  value={equipamento?.comando}
                                  defaultValue={equipamento?.comando}
                                  onChange={e => handleChange(e)}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex grid-cols-2 gap-4">
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Máquina
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="maquina"
                                  id="maquina"
                                  value={equipamento?.maquina}
                                  defaultValue={equipamento?.maquina}
                                  onChange={e => handleChange(e)}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Obra
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="obra"
                                  id="obra"
                                  value={equipamento?.obra}
                                  defaultValue={equipamento?.obra}
                                  onChange={e => handleChange(e)}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex grid-cols-2 gap-4">
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                N° Paradas
                              </label>
                              <div className="mt-1">
                                <input
                                  type="number"
                                  min={1}
                                  name="paradas"
                                  id="paradas"
                                  value={equipamento?.paradas}
                                  defaultValue={equipamento?.paradas}
                                  onChange={e => handleChange(e)}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="tipoporta"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Tipo de porta
                              </label>
                              <div className="mt-1">
                                <select
                                  id="tipoporta"
                                  name="tipoporta"
                                  style={{ backgroundColor: '#fff' }}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  value={equipamento?.tipoporta}
                                  onChange={e => handleChange(e)}
                                >
                                  <option value=""></option>
                                  <option value="Eixo vertical - Manual">
                                    Eixo vertical - Manual
                                  </option>
                                  <option value="Abert. lateral - Auto">
                                    Abert. lateral - Auto
                                  </option>
                                  <option value="Abert. central - Auto">
                                    Abert. central - Auto
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="flex grid-cols-2 gap-4">
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Velocidade (mpm)
                              </label>
                              <div className="mt-1">
                                <input
                                  name="velocidade"
                                  id="velocidade"
                                  value={equipamento?.velocidade}
                                  defaultValue={equipamento?.velocidade}
                                  onChange={e => handleChange(e)}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-shrink-0 justify-end px-4 py-4">
                    <Link
                      to={{
                        pathname: '/preventivescardsprint',
                        state: { equip1: id_equipamento },
                      }}
                    >
                      <button className="bg-white flex mr-32 mt-2 text-xs font-light text-gray-600">
                        <b>Imprimir ficha MP.</b>
                      </button>
                    </Link>
                    <button
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      onClick={() => setOpenSliderEquip(false)}
                    >
                      Cancelar
                    </button>
                    <button
                      onClick={() => handleSubmit()}
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Salvar
                    </button>
                  </div>
                </span>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
