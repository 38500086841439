import React, { useEffect, useState } from 'react';
import { Image } from 'antd';

import { Container, Box } from './styles';
import api from '../../../services/api';

export default function ImagesAttendanceList(props) {
  const { id_atendimento } = props;

  const [listImages, setListImages] = useState([]);

  useEffect(() => {
    getImages(id_atendimento);
  }, [id_atendimento]);

  async function getImages(id_atendimento) {
    try {
      const response = await api.get(
        `foto?origin=atendimento&id_table_origin=${id_atendimento}`,
      );
      setListImages(response.data.data);
    } catch (err) {
      return;
    }
  }

  return (
    <Container>
      {listImages.map(image => (
        <Box key={image}>
          <Image src={image.url} width="100%" height="100%" />
        </Box>
      ))}
    </Container>
  );
}
