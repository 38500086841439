import React from 'react';
import Drawer from '../../components/Drawer';
import Body from './Body';

export default function BuildOs() {
  return (
    <Drawer page="Preenchimento de OS ">
      <Body />
    </Drawer>
  );
}
