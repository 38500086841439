import { feedbackSuccess } from '../../../../../../functions/MessageFeedback';
import api from '../../../../../../services/api';
import { ICollaboratorConfig } from '../../../../../../types/Collaborator/ICollaboratorConfig';

async function HandleSubmit(
  configs: ICollaboratorConfig,
): Promise<void | Error> {
  try {
    await api.post(`collaborator/config`, configs);
    feedbackSuccess('Salvo com sucesso');

    return;
  } catch (err) {
    return err;
  }
}

export default HandleSubmit;
