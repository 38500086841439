import React, { useEffect, useState } from 'react';
import { Input, Checkbox } from 'antd';
import { useHistory } from 'react-router-dom';
import CurrencyInput from 'react-currency-input';
import api from '../../../../services/api';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../../functions/MessageFeedback';
import {
  Container,
  FormSection,
  ContainerInputs,
  BoxForm,
  BoxButton,
  BottomBar,
  BoxInputCurrency,
} from './styles';
import BoxPriceComposition from './components/BoxPriceComposition';
import GetPart from '../../../../functions/GetPart';

const { TextArea } = Input;

const FormPart = ({ id_part, setId_part, children }) => {
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [details, setDetails] = useState('');
  const [current_quantity, setCurrent_quantity] = useState<number | string>();
  const [minimum_quantity, setMinimum_quantity] = useState<number | string>();
  const [ideal_quantity, setIdeal_quantity] = useState<number | string>();
  const [allow_out_stock, setAllow_out_stock] = useState(true);
  const [price, setPrice] = useState('');
  const [stock_adress, setStock_adress] = useState('');
  const [cost_value, setCost_value] = useState('');

  const history = useHistory();

  useEffect(() => {
    if (id_part) {
      getPart();
    } else {
      cleanState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_part]);

  async function getPart() {
    try {
      const data = await GetPart(id_part);

      if (!data) {
        feedbackError('Não foi possível buscar esta peça');

        history.push('/parts');

        return;
      }

      setDescription(data?.description);
      setDetails(data.details);
      setCurrent_quantity(data.current_quantity);
      setMinimum_quantity(data.minimum_quantity);
      setIdeal_quantity(data.ideal_quantity);
      setCost_value(data.cost_value);
      setAllow_out_stock(data.allow_out_stock);
      setPrice(data.price);
    } catch (err) {
      feedbackError('Não foi possível buscar esta peça');

      history.push('/parts');
    }
  }

  async function cleanState() {
    setDescription('');
    setDetails('');
    setCurrent_quantity(0);
    setMinimum_quantity(0);
    setIdeal_quantity(0);
    setAllow_out_stock(true);
    setStock_adress('');
    setPrice('');
    setCost_value('');
  }

  async function handlePart() {
    if (!description) {
      feedbackError('O campo "descrição" precisa ser preenchido');
      return;
    }

    setLoading(true);

    const body = {
      description,
      details,
      current_quantity,
      minimum_quantity,
      ideal_quantity,
      allow_out_stock,
      stock_adress,
      price,
      cost_value,
    };

    try {
      if (id_part) {
        await api.put(`/parts/${id_part}`, body);
      } else {
        const response = await api.post(`/parts`, body);
        setId_part(response.data[0]);
      }

      feedbackSuccess('Sucesso!');
    } catch (err) {
      feedbackError('Ocorreu um erro ao cadastrar a peça');
    }

    setLoading(false);
  }

  return (
    <Container>
      <FormSection>
        <ContainerInputs>
          <BoxForm>
            Descrição
            <Input
              style={{ width: 350, height: 40 }}
              value={description}
              placeholder="Nome da peça"
              onChange={e => setDescription(e.target.value)}
              maxLength={50}
            />
          </BoxForm>

          <BoxForm>
            Código
            <Input
              disabled
              style={{ width: 150, height: 40 }}
              value={id_part}
              maxLength={10}
            />
          </BoxForm>
        </ContainerInputs>

        <BoxForm>
          Observação
          <TextArea
            style={{ width: 520 }}
            value={details}
            placeholder="Observação"
            onChange={e => setDetails(e.target.value)}
            maxLength={300}
            rows={3}
          />
        </BoxForm>

        <ContainerInputs>
          <BoxForm>
            Qtd. atual
            <Input
              style={{ width: 220, height: 40 }}
              type="number"
              placeholder="Estoque"
              value={current_quantity}
              onChange={e => setCurrent_quantity(e.target.value)}
              maxLength={12}
            />
          </BoxForm>

          <BoxForm>
            Qtd. mínima
            <Input
              style={{ width: 130, height: 40 }}
              min="0"
              type="number"
              value={minimum_quantity}
              onChange={e => setMinimum_quantity(e.target.value)}
              maxLength={12}
            />
          </BoxForm>

          <BoxForm>
            Qtd. ideal
            <Input
              style={{ width: 130, height: 40 }}
              min={0}
              type="number"
              value={ideal_quantity}
              onChange={e => setIdeal_quantity(e.target.value)}
              maxLength={12}
            />
          </BoxForm>
        </ContainerInputs>

        <ContainerInputs>
          <BoxForm>
            Valor de custo
            <BoxInputCurrency>
              <CurrencyInput
                style={{
                  padding: 5,
                  height: 40,
                  width: '100%',
                  borderWidth: 0,
                  backgroundColor: '#fff',
                }}
                precision="2"
                decimalSeparator="."
                thousandSeparator=""
                placeholder="R$ "
                value={cost_value}
                onChangeEvent={e => setCost_value(e.target.value)}
              />
            </BoxInputCurrency>
          </BoxForm>
        </ContainerInputs>

        {/* <BoxPriceComposition costValue={costValue} setPrice={setPrice} /> */}

        <BoxForm>
          Valor de venda
          <BoxInputCurrency>
            <CurrencyInput
              style={{
                padding: 5,
                height: 40,
                width: '100%',
                borderWidth: 0,
                backgroundColor: '#fff',
              }}
              value={price}
              precision="2"
              decimalSeparator="."
              thousandSeparator=""
              placeholder="R$ "
              onChangeEvent={e => setPrice(e.target.value)}
            />
          </BoxInputCurrency>
        </BoxForm>

        <BoxForm>
          <Checkbox
            defaultChecked={allow_out_stock}
            checked={allow_out_stock}
            onChange={e => setAllow_out_stock(e.target.checked)}
          >
            Permitir requisitar se fora de estoque
          </Checkbox>
        </BoxForm>

        <BoxForm>
          <BoxButton onClick={handlePart}>{children}</BoxButton>
        </BoxForm>
      </FormSection>

      <BottomBar></BottomBar>
    </Container>
  );
};

export default FormPart;
