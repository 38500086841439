import React from 'react';

export default function TopRowTable() {
  return (
    <thead className="bg-gray-50">
      <tr>
        <th
          scope="col"
          className="px-2 py-2 text-left text-xs font-medium text-gray-900"
        ></th>
        <th
          scope="col"
          className="py-2 pl-2 pr-2 text-left text-xs font-medium text-gray-900 sm:pl-2 lg:pl-2"
        >
          Cód
        </th>
        <th
          scope="col"
          className="px-2 py-2 text-left text-xs font-medium text-gray-900"
        >
          Edifício
        </th>
        <th
          scope="col"
          className="px-2 py-2 text-left text-xs font-medium text-gray-900"
        >
          Elevador
        </th>
        <th
          scope="col"
          className="px-2 py-2 text-left text-xs font-medium text-gray-900"
        >
          Prévia
        </th>
        <th
          scope="col"
          className="px-2 py-2 text-left text-xs font-medium text-gray-900"
        >
          Criação
        </th>
        <th
          scope="col"
          className="px-2 py-2 text-left text-xs font-medium text-gray-900"
        >
          Status
        </th>
        <th
          scope="col"
          className="px-2 py-2 text-left text-xs font-medium text-gray-900"
        />
        <th
          scope="col"
          className="px-2 py-2 text-left text-xs font-medium text-gray-900"
        />
        <th
          scope="col"
          className="px-2 py-2 text-left text-xs font-medium text-gray-900"
        />
        <th
          scope="col"
          className="px-2 py-2 text-left text-xs font-medium text-gray-900"
        />
      </tr>
    </thead>
  );
}
