import React, { useState } from 'react';
import {
  KeyIcon,
  UserCircleIcon,
  ViewGridAddIcon,
} from '@heroicons/react/outline';
import { MoreHoriz } from '@material-ui/icons';
import FormBasic from '../FormBasic';
import FormUser from '../FormUser';
import FormPreferences from '../FormPreferences';

const navigation = [
  { name: 'Básico', icon: UserCircleIcon },
  // { name: 'Mais informações', icon: MoreHoriz },
  { name: 'Usuário', icon: KeyIcon },
  { name: 'Preferências', icon: ViewGridAddIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

interface IProps {
  id_funcionario?: number;
  setId_funcionario?(id_funcionario: number): void;
}

export default function Sidebar(props: IProps) {
  const [screenView, setScreenView] = useState('Básico');

  const { id_funcionario, setId_funcionario } = props;

  return (
    <div className="lg:grid w-full lg:grid-cols-12 lg:gap-x-5">
      <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
        <nav className="space-y-1">
          {navigation.map(item =>
            item.name === 'Básico' || id_funcionario ? (
              <a
                key={item.name}
                onClick={() => setScreenView(item.name)}
                className={classNames(
                  item.name === screenView
                    ? 'bg-gray-50 text-blue-700 hover:text-blue-700 hover:bg-white'
                    : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                  'group rounded-md px-3 py-2 flex items-center text-sm font-medium',
                )}
                aria-current={item.name === screenView ? 'page' : undefined}
              >
                <item.icon
                  className={classNames(
                    item.name === screenView
                      ? 'text-blue-500 group-hover:text-blue-500'
                      : 'text-gray-400 group-hover:text-gray-500',
                    'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
                  )}
                  aria-hidden="true"
                />
                <span className="truncate">{item.name}</span>
              </a>
            ) : (
              <></>
            ),
          )}
        </nav>
      </aside>

      {screenView === 'Básico' && (
        <FormBasic
          id_funcionario={id_funcionario}
          setId_funcionario={setId_funcionario}
        />
      )}

      {screenView === 'Usuário' && <FormUser id_funcionario={id_funcionario} />}

      {screenView === 'Preferências' && (
        <FormPreferences id_funcionario={id_funcionario} />
      )}
    </div>
  );
}
