import React, { useState } from 'react';
import Drawer from '../../components/Drawer';
import List from './components/List';

export default function PurchaseOrder() {
  return (
    <Drawer page="Ordens de Compra">
      <List />
    </Drawer>
  );
}
