/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { IPart } from '../../../../../../../types/Part/IPart';
import api from '../../../../../../../services/api';
import PartInline from './PartInline';
import InputSearchName from './InputSearchName';

interface IProps {
  openSlider: boolean;
  setOpenSlider(open: boolean): void;
  changePart(index: number | null, part: IPart | null): unknown;
}

export default function SlideOverNewEdit(props: IProps) {
  const [listParts, setListParts] = useState<IPart[] | []>([]);
  const [searchPart, setSearchPart] = useState('');
  const [id_part, setId_part] = useState<number | null>();
  const [loading, setLoading] = useState(false);

  const { openSlider, setOpenSlider, changePart } = props;

  useEffect(() => {
    if (searchPart) {
      if (searchPart.length > 2) {
        getParts();
      } else {
        setListParts([]);
      }
    }
  }, [searchPart]);

  async function getParts() {
    setLoading(true);

    try {
      const response = await api.get(
        `parts?lines_per_page=999&description=${searchPart}`,
      );

      setListParts(response.data.data);
    } catch (err) {
      return;
    }

    setLoading(false);
  }

  function definePart(part: IPart) {
    changePart(null, part);

    setOpenSlider(false);
  }

  return (
    <Transition.Root show={openSlider} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-50"
        onClose={setOpenSlider}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-md">
                <span className="flex px-4 py-3 h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <InputSearchName setSearchPart={setSearchPart} />
                  <div className="overflow-auto">
                    <div className="flow-root mt-6">
                      <ul
                        role="list"
                        className="-my-5 divide-y divide-gray-200"
                      >
                        {listParts.map(part => (
                          <PartInline
                            part={part}
                            definePart={definePart}
                            key={part.id_part}
                          />
                        ))}
                      </ul>
                    </div>
                  </div>
                </span>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
