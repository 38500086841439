import React from 'react';

import { Avatar } from 'antd';
import { ContentCard, NameCard, DescriptionCard } from './styles';

const Card = ({ collaborator, children }) => {
  return (
    <>
      <Avatar
        size="large"
        style={{ backgroundColor: '#fff' }}
        src={collaborator.foto}
      />
      <ContentCard>
        <NameCard>{collaborator.nome}</NameCard>
        <DescriptionCard>{collaborator.status}</DescriptionCard>
      </ContentCard>

      {children}
    </>
  );
};

export default Card;
