import React, { useState } from 'react';
import { Container, Line, Title, Description } from './styles';
import HeadOS from '../../../../components/HeadOS';
import InstrucoesOs from '../../../../components/InstrucoesOs';
import ImagesOSList from './ImagesOSList';
import { useOSView } from '../../../../contexts/OSView';
import GetApelidoEquip from '../../../../functions/GetApelidoEquip';
import ConvertDateTime from '../../../../functions/ConvertDateTime';
import GetNameFunc from '../../../../functions/GetNameFunc';
import ManagerButtons from './ManagerButtons';
import ListAttendances from '../../../../components/AttendancesOSList/ListAttendances';
import LineLastMp from '../../../../components/OS/LineLastMp';

function SectionOS() {
  const [apelidoEquip, setApelidoEquip] = useState('');
  const [dataCriacao, setDataCriacao] = useState('');
  const [nameFunc, setNameFunc] = useState('');
  const [nameFuncAgenda, setNameFuncAgenda] = useState('');

  const { id_os_view, id_cliente_view, os } = useOSView();

  (async () => {
    const apelido = await GetApelidoEquip(os.id_equipamento);
    setApelidoEquip(apelido);

    convertData();
  })(os);

  async function convertData() {
    const data_convertida = await ConvertDateTime(os.created_at);
    setDataCriacao(data_convertida);

    const { nome_apelido } = await GetNameFunc(os.id_funcionario_gerou);
    setNameFunc(nome_apelido);

    if (os.funcionario_agendamento != 0) {
      const { nome_apelido: nome_agenda } = await GetNameFunc(
        os.funcionario_agendamento,
      );
      setNameFuncAgenda(nome_agenda);
    }
  }

  return (
    <Container>
      <HeadOS id_cliente={id_cliente_view} />
      {os.chamado ? (
        <Line>
          <Title>Chamado - </Title>
          <Title>Solicitante:</Title>
          <Description>{os.solicitante}</Description>
        </Line>
      ) : (
        <></>
      )}

      <Line>
        <Title>ID: {os.id_os}</Title>
      </Line>

      <Line>
        <Title>Prioridade:</Title>
        <Description>{os.prioridade}</Description>
      </Line>

      <Line>
        <Title>Equipamento:</Title>
        <Description>{apelidoEquip}</Description>
      </Line>

      <LineLastMp id_equipamento={os.id_equipamento} />

      <Line>
        <Title>Descrição:</Title>
        <Description>{os.descricao}</Description>
      </Line>

      {os.pre_observacao ? (
        <Line>
          <Title>Pré-observação:</Title>
          <Description>{os.pre_observacao}</Description>
        </Line>
      ) : (
        <></>
      )}

      <Line>
        <Title>Criação:</Title>
        <Description>
          {dataCriacao} - {nameFunc}
        </Description>
      </Line>

      {os.funcionario_agendamento ? (
        <Line>
          <Title>Agendamento:</Title>
          <Description>{nameFuncAgenda}</Description>
        </Line>
      ) : (
        <></>
      )}

      <Line>
        <Title>Status de Atendimento:</Title>
        <Description>
          {os.status === 'cancelado' ? (
            <span className="bg-red-600 text-white px-3 py-1 rounded">
              Cancelada
            </span>
          ) : (
            os.status
          )}
        </Description>
      </Line>

      <Line>
        <Title>Status de Liberação:</Title>
        <Description>
          {os.situacao === 'pendencia'
            ? 'Bloqueada'
            : os.status == 'concluido'
            ? 'Concluída'
            : 'Liberada'}
        </Description>
      </Line>

      <ImagesOSList />

      <InstrucoesOs id_os={os.id_os} />

      <ListAttendances id_os={os.id_os} />

      <ManagerButtons os={os} />
    </Container>
  );
}

export default SectionOS;
