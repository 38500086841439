import styled from 'styled-components';

export const ContainerChat = styled.div`
  flex: 1;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
`;
