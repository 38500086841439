/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Image } from 'antd';

import { Container, Box } from './styles';
import api from '../../../../../services/api';

export default function ImagesReqList(props) {
  const [listImages, setListImages] = useState([]);

  useEffect(() => {
    const { id_req_peca } = props;
    getImages(id_req_peca);
  }, []);

  async function getImages(id_req_peca) {
    try {
      const response = await api.get(
        `foto?origin=req_peca&id_table_origin=${id_req_peca}`,
      );
      setListImages(response.data.data);
    } catch (err) {
      return;
    }
  }

  return (
    <Container>
      {listImages.map(image => (
        <Box key={image}>
          <Image src={image.url} width="100%" height="100%" />
        </Box>
      ))}
    </Container>
  );
}
