/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
} from 'recharts';
import { BoxChart, TitleBox, ControlsBox, HeadBox } from '../../styles';
import { feedbackError } from '../../../../functions/MessageFeedback';
import api from '../../../../services/api';

const { Option } = Select;

type ITypes = 'sold' | 'billed' | 'solicit';
type IPeriod = '2024' | '2023' | '2022' | '2021' | '2020' | '2019' | '2018';

export default function ChartSales() {
  const [typeMeasure, setTypeMeasure] = useState<ITypes>('sold');
  const [periodMeasure, setPeriodMeasure] = useState<IPeriod>('2024');
  const [data, setData] = useState([]);

  useEffect(() => {
    loadData();
  }, [periodMeasure, typeMeasure]);

  async function loadData() {
    try {
      if (typeMeasure === 'sold') {
        const month01 = await getApproved(1);
        const jan = {
          name: 'jan',
          Atual: month01 ? parseFloat(month01.toFixed(2)) : null,
          Meta: 40000,
          Min: 40000,
        };

        const month02 = await getApproved(2);
        const fev = {
          name: 'fev',
          Atual: month02 ? parseFloat(month02.toFixed(2)) : null,
          Meta: 40000,
          Min: 40000,
        };

        const month03 = await getApproved(3);
        const mar = {
          name: 'mar',
          Atual: month03 ? parseFloat(month03.toFixed(2)) : null,
          Meta: 40000,
          Min: 40000,
        };

        const month04 = await getApproved(4);
        const abr = {
          name: 'abr',
          Atual: month04 ? parseFloat(month04.toFixed(2)) : null,
          Meta: 40000,
          Min: 40000,
        };

        const month05 = await getApproved(5);
        const mai = {
          name: 'mai',
          Atual: month05 ? parseFloat(month05.toFixed(2)) : null,
          Meta: 40000,
          Min: 40000,
        };

        const month06 = await getApproved(6);
        const jun = {
          name: 'jun',
          Atual: month06 ? parseFloat(month06.toFixed(2)) : null,
          Meta: 40000,
          Min: 40000,
        };

        const month07 = await getApproved(7);
        const jul = {
          name: 'jul',
          Atual: month07 ? parseFloat(month07.toFixed(2)) : null,
          Meta: 40000,
          Min: 40000,
        };

        const month08 = await getApproved(8);
        const ago = {
          name: 'ago',
          Atual: month08 ? parseFloat(month08.toFixed(2)) : null,
          Meta: 40000,
          Min: 40000,
        };

        const month09 = await getApproved(9);
        const set = {
          name: 'set',
          Atual: month09 ? parseFloat(month09.toFixed(2)) : null,
          Meta: 40000,
          Min: 40000,
        };

        const month10 = await getApproved(10);
        const out = {
          name: 'out',
          Atual: month10 ? parseFloat(month10.toFixed(2)) : null,
          Meta: 40000,
          Min: 40000,
        };

        const month11 = await getApproved(11);
        const nov = {
          name: 'nov',
          Atual: month11 ? parseFloat(month11.toFixed(2)) : null,
          Meta: 40000,
          Min: 40000,
        };

        const month12 = await getApproved(12);
        const dez = {
          name: 'dez',
          Atual: month12 ? parseFloat(month12?.toFixed(2)) : null,
          Meta: 40000,
          Min: 40000,
        };

        setData([jan, fev, mar, abr, mai, jun, jul, ago, set, out, nov, dez]);
      }

      if (typeMeasure === 'solicit') {
        const month01 = await getGenerated(1);

        const jan = {
          name: 'jan',
          Atual: month01 ? parseFloat(month01.toFixed(2)) : null,
          Meta: 80000,
          Min: 80000,
        };

        const month02 = await getGenerated(2);
        const fev = {
          name: 'fev',
          Atual: month02 ? parseFloat(month02.toFixed(2)) : null,
          Meta: 80000,
          Min: 80000,
        };

        const month03 = await getGenerated(3);
        const mar = {
          name: 'mar',
          Atual: month03 ? parseFloat(month03.toFixed(2)) : null,
          Meta: 80000,
          Min: 80000,
        };

        const month04 = await getGenerated(4);
        const abr = {
          name: 'abr',
          Atual: month04 ? parseFloat(month04.toFixed(2)) : null,
          Meta: 80000,
          Min: 80000,
        };

        const month05 = await getGenerated(5);
        const mai = {
          name: 'mai',
          Atual: month05 ? parseFloat(month05.toFixed(2)) : null,
          Meta: 80000,
          Min: 80000,
        };

        const month06 = await getGenerated(6);
        const jun = {
          name: 'jun',
          Atual: month06 ? parseFloat(month06.toFixed(2)) : null,
          Meta: 80000,
          Min: 80000,
        };

        const month07 = await getGenerated(7);
        const jul = {
          name: 'jul',
          Atual: month07 ? parseFloat(month07.toFixed(2)) : null,
          Meta: 80000,
          Min: 80000,
        };

        const month08 = await getGenerated(8);
        const ago = {
          name: 'ago',
          Atual: month08 ? parseFloat(month08.toFixed(2)) : null,
          Meta: 80000,
          Min: 80000,
        };

        const month09 = await getGenerated(9);
        const set = {
          name: 'set',
          Atual: month09 ? parseFloat(month09.toFixed(2)) : null,
          Meta: 80000,
          Min: 80000,
        };

        const month10 = await getGenerated(10);
        const out = {
          name: 'out',
          Atual: month10 ? parseFloat(month10.toFixed(2)) : null,
          Meta: 80000,
          Min: 80000,
        };

        const month11 = await getGenerated(11);
        const nov = {
          name: 'nov',
          Atual: month11 ? parseFloat(month11?.toFixed(2)) : null,
          Meta: 80000,
          Min: 80000,
        };

        const month12 = await getGenerated(12);
        const dez = {
          name: 'dez',
          Atual: month12 ? parseFloat(month12.toFixed(2)) : null,
          Meta: 80000,
          Min: 80000,
        };

        setData([jan, fev, mar, abr, mai, jun, jul, ago, set, out, nov, dez]);
      }
    } catch (error) {
      feedbackError('Erro ao carregar os dados');
    }
  }

  async function getApproved(month: number) {
    try {
      const response = await api.get(
        `stats/total_approved?modo_edicao=false&month=${month}&year=${periodMeasure}`,
      );

      return response.data;
    } catch (error) {
      return;
    }
  }

  async function getGenerated(month: number) {
    try {
      const response = await api.get(
        `stats/total_generate?modo_edicao=false&month=${month}&year=${periodMeasure}`,
      );

      return response.data;
    } catch (error) {
      return;
    }
  }

  return (
    <BoxChart>
      <HeadBox>
        <TitleBox>Análise de vendas R$</TitleBox>
        <ControlsBox>
          <Select
            value={typeMeasure}
            style={{ width: 150, margin: '0px' }}
            onChange={setTypeMeasure}
          >
            <Option value="sold">Aprovadas</Option>
            {/* <Option value="billed">Faturados</Option>*/}
            <Option value="solicit">Requisitadas</Option>
          </Select>

          <Select
            value={periodMeasure}
            style={{ width: 150, margin: '0px' }}
            onChange={setPeriodMeasure}
          >
            <Option value="2023">2023</Option>
            <Option value="2022">2022</Option>
            <Option value="2021">2021</Option>
            <Option value="2020">2020</Option>
            <Option value="2019">2019</Option>
            <Option value="2018">2018</Option>
          </Select>
        </ControlsBox>
      </HeadBox>
      <AreaChart
        width={780}
        height={250}
        data={data}
        margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#3B72FF" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#3B72FF" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.1} />
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.1} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#E79E90" stopOpacity={0.1} />
            <stop offset="95%" stopColor="#E79E90" stopOpacity={0.1} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="Atual"
          stroke="#3B72FF"
          fillOpacity={1}
          fill="url(#colorUv)"
        />
        <Area
          type="monotone"
          dataKey="Meta"
          stroke="#82ca9d"
          fillOpacity={1}
          fill="url(#colorPv)"
        />
        <Area
          type="monotone"
          dataKey="Min"
          stroke="#E79E90"
          fillOpacity={1}
          fill="url(#colorPv)"
        />
      </AreaChart>
    </BoxChart>
  );
}
