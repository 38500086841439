import React from 'react';
import {
  ContainerContent,
  Columm,
  BoxInfo,
  TitleInfo,
  DescriptionInfo,
} from './styles';

const ContentProfissional = ({ collaborator }) => {
  return (
    <ContainerContent>
      <Columm>
        <BoxInfo>
          <TitleInfo>Cart. Profissional</TitleInfo>
          <DescriptionInfo>{collaborator.cart_profissional}</DescriptionInfo>
        </BoxInfo>

        <BoxInfo>
          <TitleInfo>Cart. Reservista</TitleInfo>
          <DescriptionInfo>{collaborator.cart_reservista}</DescriptionInfo>
        </BoxInfo>

        <BoxInfo>
          <TitleInfo>Título de eleitor</TitleInfo>
          <DescriptionInfo>{collaborator.grau_instrucao}</DescriptionInfo>
        </BoxInfo>

        <BoxInfo>
          <TitleInfo>PIS</TitleInfo>
          <DescriptionInfo>{collaborator.pis}</DescriptionInfo>
        </BoxInfo>

        <BoxInfo>
          <TitleInfo>Período admissão</TitleInfo>
          <DescriptionInfo>
            {Array.isArray(collaborator.admissao)
              ? collaborator.admissao[0]?.admissao
              : null}{' '}
            -
            {Array.isArray(collaborator.admissao)
              ? collaborator.admissao[0]?.demissao
              : null}
          </DescriptionInfo>
        </BoxInfo>

        <BoxInfo>
          <TitleInfo>Local de trabalho</TitleInfo>
          <DescriptionInfo>{collaborator.local_trabalho}</DescriptionInfo>
        </BoxInfo>

        <BoxInfo>
          <TitleInfo>Função</TitleInfo>
          <DescriptionInfo>{collaborator.funcao}</DescriptionInfo>
        </BoxInfo>
      </Columm>

      <Columm>
        <BoxInfo>
          <TitleInfo>Data ctps</TitleInfo>
          <DescriptionInfo>{collaborator.data_ctps}</DescriptionInfo>
        </BoxInfo>

        <BoxInfo>
          <TitleInfo>Categoria</TitleInfo>
          <DescriptionInfo>{collaborator.categoria}</DescriptionInfo>
        </BoxInfo>

        <BoxInfo>
          <TitleInfo>Zona eleitoral</TitleInfo>
          <DescriptionInfo>{collaborator.secao_eleitoral}</DescriptionInfo>
        </BoxInfo>

        <BoxInfo>
          <TitleInfo>Data de Cadastro PIS</TitleInfo>
          <DescriptionInfo>{collaborator.data_cad_pis}</DescriptionInfo>
        </BoxInfo>

        <BoxInfo>
          <TitleInfo>Salário atual</TitleInfo>
          <DescriptionInfo>{collaborator.salario_atual}</DescriptionInfo>
        </BoxInfo>

        <BoxInfo>
          <TitleInfo>Parceiro</TitleInfo>
          <DescriptionInfo>{collaborator.parceiro}</DescriptionInfo>
        </BoxInfo>
      </Columm>

      <Columm>
        <BoxInfo>
          <TitleInfo>CBO</TitleInfo>
          <DescriptionInfo>{collaborator.cbo}</DescriptionInfo>
        </BoxInfo>

        <BoxInfo>
          <TitleInfo>Membro CIPA</TitleInfo>
          <DescriptionInfo>{collaborator.membro_cipa}</DescriptionInfo>
        </BoxInfo>

        <BoxInfo>
          <TitleInfo>Seção eleitoral</TitleInfo>
          <DescriptionInfo>{collaborator.secao_eleitoral}</DescriptionInfo>
        </BoxInfo>

        <BoxInfo>
          <TitleInfo>Data opção FGTS</TitleInfo>
          <DescriptionInfo>{collaborator.data_opcao_fgts}</DescriptionInfo>
        </BoxInfo>

        <BoxInfo>
          <TitleInfo>Tipo de salário</TitleInfo>
          <DescriptionInfo>{collaborator.tipo_salario}</DescriptionInfo>
        </BoxInfo>

        <BoxInfo>
          <TitleInfo>Forma de pagamento</TitleInfo>
          <DescriptionInfo>{collaborator.forma_pagamento}</DescriptionInfo>
        </BoxInfo>
      </Columm>
    </ContainerContent>
  );
};

export default ContentProfissional;
