/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import RowTable from './RowTable';
import api from '../../../../services/api';
import { Link } from 'react-router-dom';
import { useClientRegister } from '../../../../contexts/ClientRegister';
import NavBarList from '../../../../components/NavBarList';
import InputSelectStatus from './InputSelectStatus';
import InputSearchName from './InputSearchName';

export default function List() {
  const [clientes, setClientes] = useState([]);
  const [status, setStatus] = useState('%');
  const [nameSearch, setNameSearch] = useState('');
  const [page, setpage] = useState<number>(1);
  const [lines_per_page, setLines_per_page] = useState<number>(12);
  const [qtdPages, setQtdPages] = useState<number>();
  const [totalRegisters, setTotalRegisters] = useState<number>();

  const { setId_cliente } = useClientRegister();

  const urlFilter = `&lines_per_page=${lines_per_page}`;

  useEffect(() => {
    setpage(1);
    setId_cliente(null);
  }, []);

  useEffect(() => {
    getList();
  }, [page]);

  useEffect(() => {
    setpage(1);
    getList();
  }, [nameSearch, status]);

  function changePage(action: string) {
    if (action == 'avancar') {
      setpage(page + 1);
    }
    if (action == 'voltar' && page > 1) {
      setpage(page - 1);
    }
  }

  async function getList() {
    const response = await api.get(
      `cliente/min?page=${page}${urlFilter}&edificio=${nameSearch}&status=${status}`,
    );
    setClientes(response.data.data);
    setTotalRegisters(response.data.total);

    let numPages = response.data.total / lines_per_page;
    numPages = Math.ceil(numPages);
    setQtdPages(numPages);
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900"></h1>
          <p className="mt-4 text-sm text-gray-700 inline-flex">
            <InputSearchName
              setNameSearch={setNameSearch}
              nameSearch={nameSearch}
            />

            <InputSelectStatus status={status} setStatus={setStatus} />
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Link
            to={{
              pathname: '/novocliente',
              state: { mode: 'new' },
            }}
          >
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
            >
              Novo Cliente
            </button>
          </Link>
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Edificío
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Apelido
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Ver</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {clientes.map(cliente => (
                    <RowTable key={cliente.id_cliente} cliente={cliente} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <NavBarList
        page={page}
        qtdPages={qtdPages}
        totalRegisters={totalRegisters}
        changePage={changePage}
      />
    </div>
  );
}
