/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import api from '../../../../services/api';
import { IOutfitter } from '../../../../types/Outfitter/IOutfitter';
import { PlusIcon } from '@heroicons/react/outline';
import SlideOver from '../../../Outfitters/components/SlideOver';

interface IProps {
  idOutfitter: number | null;
  setIdOutfitter(idOutfitter: number | null): void;
}

export default function SelectOutfitter(props: IProps) {
  const [outfitters, setOutfitters] = useState([]);
  const [searchOutfitter, setSearchOutfitter] = useState<IOutfitter>();
  const [openNewOutfitter, setOpenNewOutfitter] = useState(false);

  const { idOutfitter, setIdOutfitter } = props;

  useEffect(() => {
    getList();

    if (idOutfitter) getOutfitter(idOutfitter);
  }, [idOutfitter]);

  useEffect(() => {
    setIdOutfitter(searchOutfitter?.id_outfitter);
  }, [searchOutfitter]);

  async function getOutfitter(id_outfitter: number) {
    try {
      const response = await api.get(`outfitter/${id_outfitter}`);

      setSearchOutfitter(response.data);
    } catch (err) {
      return;
    }
  }

  async function getList() {
    try {
      const response = await api.get(`outfitter?lines_per_page=500`);

      setOutfitters(response.data.data);
    } catch (error) {
      return;
    }
  }

  function newOutfitter() {
    setOpenNewOutfitter(true);
  }

  return (
    <>
      <div className="flex flex-row mb-2">
        <Autocomplete
          options={outfitters}
          getOptionLabel={outfitter => outfitter.nome_apelido}
          className="w-3/4"
          value={searchOutfitter}
          onChange={(event, outfitterselected) => {
            setSearchOutfitter(outfitterselected);
          }}
          renderInput={params => (
            <TextField
              {...params}
              label="Pesquisar Fornecedor"
              variant="outlined"
            />
          )}
        />

        <button
          type="button"
          onClick={() => newOutfitter()}
          className="inline-flex w-1/4 items-center ml-2 px-2 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <PlusIcon className="ml-2 mr-2 h-4 w-4" aria-hidden="true" />
          Novo
        </button>
      </div>

      <SlideOver
        open={openNewOutfitter}
        setOpen={setOpenNewOutfitter}
        id_outfitter_edit={null}
        getList={getList}
      />

      {searchOutfitter ? (
        <div>
          <dt className="w-full text-sm mb-2 font-semibold text-gray-700 sm:flex-shrink-0">
            {searchOutfitter?.nome_apelido}
          </dt>
          <dt className="w-full text-xs font-semibold text-gray-500 sm:flex-shrink-0">
            Endereço:
          </dt>
          <dd className="mt-0 text-xs text-gray-500 sm:col-span-2">
            {searchOutfitter?.logradouro}
            {searchOutfitter?.numero ? `, ${searchOutfitter.numero}` : null}
            {searchOutfitter?.bairro ? ` ${searchOutfitter.bairro}` : null}
            {searchOutfitter?.cidade ? ` - ${searchOutfitter.cidade}` : null}
            {searchOutfitter?.estado ? ` - ${searchOutfitter.estado}` : null}
          </dd>
          <dt className="w-full text-xs font-semibold text-gray-500 sm:flex-shrink-0">
            Contato:
          </dt>
          <dd className="mt-0 text-xs text-gray-500 sm:col-span-2">
            {searchOutfitter?.fone_contato}
            {searchOutfitter?.email_contato
              ? ` - ${searchOutfitter.email_contato}`
              : null}
          </dd>
        </div>
      ) : null}
    </>
  );
}
