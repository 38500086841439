/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../../../services/api';
import io from '../../../services/socket';
import RowTable from './RowTable';
import { useOSView } from '../../../contexts/OSView';
import { useGlobalAttributes } from '../../../contexts/GlobalAttributes';
import ButtonDefault from '../../../components/ButtonDefault';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { Button, Select, MenuItem } from '@material-ui/core';
import SpinnerLoad from '../../../components/SpinnerLoad';
import { Input } from 'antd';
import {
  List_container,
  BodyList,
  FormFilterTop,
  FormFilterRight,
  TopBar,
  LineFilter,
} from './styles';
import { feedbackError } from '../../../functions/MessageFeedback';
import NavBarList from '../../../components/NavBarList';

const { Search } = Input;

const useStyles = makeStyles({
  table: {
    minWidth: 750,
    width: 1150,
    marginBottom: 1,
  },
  inputMed: {
    paddingLeft: 5,
    marginHorizontal: 5,
    height: 30,
    width: 100,
    color: '#232129',
    borderWidth: 1,
    borderColor: '#2c2c2c',
  },
});

export default function List() {
  const [osAbertas, setOsAbertas] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [searchCliente, setSearchCliente] = useState();
  const [qtdRegistros, setQtdRegistros] = useState(0);
  const [page, setpage] = useState(1);
  const [lines_per_page, setLines_per_page] = useState(20);
  const [qtdPages, setQtdPages] = useState();
  const [filterPrioridade, setFilterPrioridade] = useState('%');
  const [filterSituacao, setFilterSituacao] = useState('%');
  const [loading, setLoading] = useState(true);

  const { defineIdOs, openModal } = useOSView();
  const { refresh } = useGlobalAttributes();

  useEffect(() => {
    getClientes();
    //eventsRealTime();
  }, []);

  useEffect(() => {
    getList();
  }, [page, searchCliente, refresh]);

  useEffect(() => {
    if (!openModal) getList();
  }, [openModal]);

  function changePage(action) {
    if (action == 'avancar') {
      setpage(page + 1);
    }
    if (action == 'voltar' && page > 1) {
      setpage(page - 1);
    }
  }

  function getList() {
    let id_cliente = '%'; //Default todos os clientes
    if (searchCliente) id_cliente = searchCliente; //Se foi pesquisado cliente, define aqui

    try {
      api
        .get(
          `os/pending?page=${page}&lines_per_page=${lines_per_page}&prioridade=${filterPrioridade}&situacao=${filterSituacao}&id_cliente=${id_cliente}`,
        )
        .then(response => {
          setOsAbertas(response.data.data);
          setQtdRegistros(response.data.total);

          let numPages = response.data.total / lines_per_page;
          numPages = Math.ceil(numPages);
          setQtdPages(numPages);

          setLoading(false);
        });
    } catch (err) {
      setLoading(false);

      feedbackError(
        'Não foi possível carregar a lista. Tentando novamente em alguns segundos...',
      );

      setTimeout(() => {
        getList();
      }, 10000);
    }
  }

  function getClientes() {
    api.get(`cliente/min?lines_per_page=99999999`).then(response => {
      setClientes(response.data.data);
    });
  }

  function getOsPerCod(value) {
    if (!value) return;

    api.get(`os/${value}`).then(response => {
      if (response.data) defineIdOs(value);
    });
  }

  async function eventsRealTime() {
    io.on('list_os', refresh => {
      getList();
      setpage(1);
    });
  }

  const classes = useStyles();

  return (
    <List_container>
      <TopBar>
        <Link to="/novaos">
          <ButtonDefault href="/novaos">Criar Nova</ButtonDefault>
        </Link>

        <FormFilterTop>
          <Search
            placeholder="Código"
            autoComplete="none"
            type="number"
            min="1"
            onSearch={getOsPerCod}
            style={{ width: 150 }}
          />

          <Autocomplete
            options={clientes}
            getOptionLabel={clientes => clientes.apelido}
            style={{ width: 350, marginLeft: 20 }}
            value={searchCliente}
            onFocus={() => setpage(1)}
            onChange={(event, clienteselected) => {
              setSearchCliente(clienteselected?.id_cliente);
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Pesquisar Cliente"
                variant="outlined"
              />
            )}
          />
          <div className="px-2 py-2 mx-2 border-2 flex flex-row items-center">
            <div className="ml-3">
              <label
                htmlFor="aprovacao"
                className="block text-xs font-light text-gray-700"
              >
                Situação
              </label>
              <Select
                required
                labelId="Situacao"
                id="situacao"
                defaultValue=""
                value={filterSituacao}
                onChange={e => setFilterSituacao(e.target.value)}
              >
                <MenuItem value="%">Todas</MenuItem>
                <MenuItem value="liberada">Liberadas para Executar</MenuItem>
                <MenuItem value="pendencia">Bloqueadas para Executar</MenuItem>
              </Select>
            </div>

            <div className="ml-3">
              <label
                htmlFor="validacao"
                className="block text-xs font-light text-gray-700"
              >
                Prioridade
              </label>
              <Select
                required
                labelId="prioridade"
                id="prioridade"
                value={filterPrioridade}
                onChange={e => setFilterPrioridade(e.target.value)}
              >
                <MenuItem value="%">Todos</MenuItem>
                <MenuItem value="urgente">Urgente</MenuItem>
                <MenuItem value="alta">Alta</MenuItem>
                <MenuItem value="media">Média</MenuItem>
                <MenuItem value="baixa">Baixa</MenuItem>
              </Select>
            </div>

            <div className="mx-2 mt-4">
              <button
                type="button"
                onClick={() => getList()}
                className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Filtrar
              </button>
            </div>
          </div>
        </FormFilterTop>
      </TopBar>

      {loading ? (
        <SpinnerLoad />
      ) : (
        <BodyList>
          <div style={{ width: 'auto', overflowX: 'auto' }}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="tablecontacts"
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 18 }} align="left"></TableCell>
                    <TableCell style={{ width: 75 }} align="left">
                      Cód.
                    </TableCell>
                    <TableCell style={{ width: 150 }} align="left">
                      Edifício
                    </TableCell>
                    <TableCell style={{ width: 75 }} align="left">
                      Elevador
                    </TableCell>
                    <TableCell style={{ width: 150 }} align="left">
                      Prévia
                    </TableCell>
                    <TableCell style={{ width: 70 }} align="left">
                      Criação
                    </TableCell>
                    <TableCell style={{ width: 170 }} align="left">
                      Status
                    </TableCell>
                    <TableCell style={{ width: 20 }} align="left"></TableCell>
                    <TableCell style={{ width: 20 }} align="left"></TableCell>
                    <TableCell style={{ width: 20 }} align="left"></TableCell>
                    <TableCell style={{ width: 20 }} align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {osAbertas.map(os => (
                    <RowTable key={os.id_os} os={os} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <NavBarList
              page={page}
              qtdPages={qtdPages}
              totalRegisters={qtdRegistros}
              changePage={changePage}
            />
          </div>
        </BodyList>
      )}
    </List_container>
  );
}
