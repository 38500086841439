import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Layout, Menu } from 'antd';
import {
  FolderViewOutlined,
  PieChartOutlined,
  GlobalOutlined,
  CopyOutlined,
  AppstoreAddOutlined,
  GroupOutlined,
  SwitcherFilled,
  ScheduleOutlined,
  ShopOutlined,
} from '@ant-design/icons';
import CardUser from '../CardUser';
import BadgeNotification from '../BadgeNotification';
import { useAuth } from '../../contexts/Auth';
import { useGlobalAttributes } from '../../contexts/GlobalAttributes';
import {
  LayoutContainer,
  HeaderMenu,
  CardContainer,
  PageName,
  PageContent,
  PageFooter,
  BoxAvatar,
  ImgAvatar,
} from './styles';

const { Sider } = Layout;
const { SubMenu } = Menu;

interface IProps {
  page: string;
  children: React.ReactNode;
}

export default function Drawer(props: IProps) {
  const { user, signOut } = useAuth();

  const { collapseMenu, setCollapseMenu } = useGlobalAttributes();

  return (
    <LayoutContainer>
      <Sider
        collapsible
        collapsed={collapseMenu}
        onCollapse={() => setCollapseMenu(!collapseMenu)}
      >
        <div className="logo"></div>
        <Menu theme="dark" mode="inline">
          <Menu.Item key="1" icon={<PieChartOutlined />}>
            Início
            <Link to="/dashboard" />
          </Menu.Item>

          <Menu.Item key="112" icon={<ScheduleOutlined />}>
            Agenda
            <Link to="/agenda" />
          </Menu.Item>

          <Menu.Item key="2" icon={<GlobalOutlined />}>
            Mapa
            <Link to="/mapaaovivo" />
          </Menu.Item>
          <SubMenu key="sub1" icon={<CopyOutlined />} title="OS">
            <Menu.Item key="11">
              Nova OS
              <Link to="/novaos" />
            </Menu.Item>
            <Menu.Item key="10">
              Chamados
              <Link to="/chamados" />
            </Menu.Item>
            <Menu.Item key="3">
              Todas OS. Abertas
              <Link to="/osabertas" />
            </Menu.Item>
            <Menu.Item key="4">
              OS Concluídas
              <Link to="/osconcluidas" />
            </Menu.Item>

            <Menu.Item key="41">
              Canceladas
              <Link to="/oscanceladas" />
            </Menu.Item>

            {user.id_grupo_usuario <= 58 ? ( //apenas se for do grupo comercial ou root
              <Menu.Item key="5">
                Validar OS
                <Link to="/validaros" />
              </Menu.Item>
            ) : null}

            {user.id_grupo_usuario <= 58 ? ( //apenas se for do grupo comercial ou root
              <Menu.Item key="6">
                Negociar OS
                <Link to="/negociaros" />
              </Menu.Item>
            ) : null}

            {user.id_grupo_usuario <= 58 ? ( //apenas se for do grupo tecnico_super ou root
              <Menu.Item key="7">
                Separar Peças
                <Link to="/separar" />
              </Menu.Item>
            ) : null}

            {user.id_grupo_usuario <= 58 ? ( //apenas se for do grupo tecnico_super ou root
              <Menu.Item key="19">
                Liberar OS
                <Link to="/liberaros" />
              </Menu.Item>
            ) : null}

            {user.id_grupo_usuario <= 58 ? ( //apenas se for do grupo financeiro ou root
              <Menu.Item key="8">
                Faturar OS
                <Link to="/faturaros" />
              </Menu.Item>
            ) : null}
          </SubMenu>

          {user.id_grupo_usuario <= 20 || user.id_grupo_usuario == 50 ? (
            <SubMenu key="sub2" icon={<FolderViewOutlined />} title="Logs">
              <Menu.Item key="9">
                Consultar Logs
                <Link to="/logs" />
              </Menu.Item>
            </SubMenu>
          ) : null}

          {user.id_grupo_usuario <= 58 ? (
            <SubMenu key="sub3" icon={<FolderViewOutlined />} title="Clientes">
              <Menu.Item key="18">
                Novo Cliente
                <Link
                  to={{
                    pathname: '/novocliente',
                    state: { mode: 'new' },
                  }}
                />
              </Menu.Item>
              <Menu.Item key="14">
                Clientes
                <Link to="/clientes" />
              </Menu.Item>
              <Menu.Item key="30">
                Usuários
                <Link to="/usersclientmanager" />
              </Menu.Item>
            </SubMenu>
          ) : null}

          {user.id_grupo_usuario <= 40 ? (
            <Menu.Item key="15" icon={<FolderViewOutlined />}>
              Colaboradores
              <Link to="/collaborators" />
            </Menu.Item>
          ) : null}

          <SubMenu key="sub4" icon={<SwitcherFilled />} title="Estoque">
            {user.id_grupo_usuario <= 58 ? (
              <Menu.Item key="12" icon={<AppstoreAddOutlined />}>
                Cadastrar Peça
                <Link to="/registerparts" />
              </Menu.Item>
            ) : null}

            {user.id_grupo_usuario <= 58 ? (
              <Menu.Item key="13" icon={<GroupOutlined />}>
                Peças
                <Link to="/parts" />
              </Menu.Item>
            ) : null}
          </SubMenu>
          {user.id_grupo_usuario <= 58 ? (
            <Menu.Item key="2323" icon={<ShopOutlined />}>
              Fornecedores
              <Link to="/outfitters" />
            </Menu.Item>
          ) : null}

          {user.id_grupo_usuario <= 58 ? (
            <Menu.Item key="22323" icon={<ShopOutlined />}>
              Ordens de Compra
              <Link to="/ordensdecompra" />
            </Menu.Item>
          ) : null}

          {/*{user.id_grupo_usuario == 10 ? (
            <Menu.Item key="16" icon={<FolderViewOutlined />}>
              Fornecedores
              <Link to="/outfitters" />
            </Menu.Item>
          ) : null}*/}

          {/*{user.id_grupo_usuario == 10 ? (
            <Menu.Item key="17" icon={<SettingOutlined />}>
              Definições
              <Link to="/definitions" />
            </Menu.Item>
          ) : null}*/}

          <SubMenu key="sub5" icon={<FolderViewOutlined />} title="Preventiva">
            <Menu.Item key="16" icon={<FolderViewOutlined />}>
              Concluídas
              <Link to="/listarpreventivas" />
            </Menu.Item>
          </SubMenu>

          {/* {user.id_grupo_usuario <= 10 ? (
            <Menu.Item key="18" icon={<FileCopyOutlined />}>
              Relatórios
              <Link to="/reports" />
            </Menu.Item>
          ) : null} */}

          {/*<SubMenu key="sub2" icon={<ToolOutlined />} title="Preventiva">
              <Menu.Item key="8">
                  Concluídas
                  <Link to="/mpconcluidas" />
                  </Menu.Item>
            </SubMenu>
            <SubMenu key="sub3" icon={<AuditOutlined />} title="Ponto eletrônico">
              <Menu.Item key="9">
                  Histórico
                  <Link to="/pontoeletronico" />
                  </Menu.Item>
    </SubMenu>*/}
        </Menu>
        <CardContainer>
          {collapseMenu ? (
            <>
              <BoxAvatar>
                <ImgAvatar
                  title={user.nome}
                  src={
                    user.foto
                      ? user.foto
                      : 'https://storage-app-do-elevador.s3.sa-east-1.amazonaws.com/default-avatar.svg'
                  }
                  alt="Avatar"
                />
              </BoxAvatar>
              <Button
                type="text"
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 5,
                  marginTop: 15,
                  color: '#FFF',
                }}
                loading={false}
                onClick={() => signOut()}
              >
                Sair
              </Button>
            </>
          ) : (
            <CardUser />
          )}
        </CardContainer>
      </Sider>
      <Layout className="site-layout">
        <HeaderMenu className="site-layout-background">
          <>
            <BadgeNotification />
            <PageName>{props.page}</PageName>
          </>
        </HeaderMenu>
        <PageContent>
          <div className="site-layout-background">{props.children}</div>
        </PageContent>
        <PageFooter></PageFooter>
      </Layout>
    </LayoutContainer>
  );
}
