/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import RowTable from './RowTable';
import { useOSView } from '../../../contexts/OSView';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Select, MenuItem } from '@material-ui/core';
import { Input } from 'antd';
import { FormFilterTop } from './styles';
import NavBarList from '../../../components/NavBarList';
import { feedbackError } from '../../../functions/MessageFeedback';
import { FilterIcon } from '@heroicons/react/solid';
import TableHead from '../TableHead';

const { Search } = Input;

export default function List() {
  const [req_pecas, setReq_pecas] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [searchCliente, setSearchCliente] = useState<string | null>();
  const [page, setpage] = useState(1);
  const [lines_per_page, setLines_per_page] = useState(10);
  const [qtdPages, setQtdPages] = useState<number>();
  const [totalRegisters, setTotalRegisters] = useState<number>();
  const [filterValidado, setFilterValidado] = useState<string | unknown>(
    'true',
  );
  const [filterAprovado, setFilterAprovado] = useState<string | unknown>(
    'false',
  );
  const [filterLegenda_comercial, setFilterLegenda_comercial] = useState<
    string | unknown
  >('%');
  const [loading, setLoading] = useState(true);

  const { defineIdOs, openModal } = useOSView();

  useEffect(() => {
    getList();
  }, [page]);

  useEffect(() => {
    if (!openModal) getList();
  }, [openModal]);

  useEffect(() => {
    getClientes();
  }, []);

  useEffect(() => {
    getList();
  }, [searchCliente]);

  function changePage(action: string) {
    if (action == 'avancar') {
      setpage(page + 1);
    }
    if (action == 'voltar' && page > 1) {
      setpage(page - 1);
    }
  }

  function changeFilter() {
    setpage(1);

    getList();
  }

  async function getList() {
    let id_cliente = '%'; //Default todos os clientes
    if (searchCliente) id_cliente = searchCliente; //Se foi pesquisado cliente, define aqui

    let validado = '%';
    if (filterValidado === 'false') validado = '';
    if (filterValidado === 'true') validado = 'true';

    let aprovado = '%';
    if (filterAprovado === 'false') aprovado = '';
    if (filterAprovado === 'true') aprovado = 'true';

    try {
      const response = await api.get(
        `req_peca?modo_edicao=false&page=${page}&lines_per_page=${lines_per_page}&status_not=cancelado&validado=${validado}&aprovado=${aprovado}&legenda_comercial=${filterLegenda_comercial}&id_cliente=${id_cliente}`,
      );

      setReq_pecas(response.data.data);

      let numPages = response.data.total / lines_per_page;

      numPages = Math.ceil(numPages);

      setQtdPages(numPages);

      setTotalRegisters(response.data.total);
    } catch (err) {
      feedbackError('Erro ao carregar a listagem');
    }

    setLoading(false);
  }

  async function getClientes() {
    try {
      const response = await api.get(`cliente/min?lines_per_page=99999999`);

      setClientes(response.data.data);
    } catch (err) {
      return;
    }
  }

  async function getOsPerCod(value: number | unknown) {
    if (!value) return;

    try {
      const response = await api.get(`os/${value}`);

      if (response.data) defineIdOs(value, 'gerir');
    } catch (err) {
      feedbackError('Não foi possível buscar a OS informada');
    }
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <FormFilterTop>
            <div className="px-2 py-2 mx-2 border-2 flex flex-row items-center">
              <Search
                placeholder="Código"
                autoComplete="none"
                type="number"
                min="1"
                onSearch={getOsPerCod}
                style={{ width: 150 }}
              />

              <Autocomplete
                options={clientes}
                getOptionLabel={clientes => clientes.apelido}
                style={{ width: 300, marginLeft: 20 }}
                value={searchCliente}
                onChange={(event, clienteselected) => {
                  setSearchCliente(clienteselected?.id_cliente);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Pesquisar Cliente"
                    variant="outlined"
                  />
                )}
              />
            </div>

            <div className="px-2 py-2 mx-2 border-2 flex flex-row items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="black"
                strokeWidth="1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                />
              </svg>

              <div className="ml-3">
                <label
                  htmlFor="aprovacao"
                  className="block text-xs font-light text-gray-700"
                >
                  Aprovação
                </label>
                <Select
                  required
                  labelId="Aprovadas"
                  id="aprovado"
                  defaultValue=""
                  value={filterAprovado}
                  onChange={e => setFilterAprovado(e.target.value)}
                >
                  <MenuItem value="%">Todas</MenuItem>
                  <MenuItem value="true">Sim</MenuItem>
                  <MenuItem value="false">Não</MenuItem>
                </Select>
              </div>

              <div className="ml-3">
                <label
                  htmlFor="validacao"
                  className="block text-xs font-light text-gray-700"
                >
                  Validação
                </label>
                <Select
                  required
                  labelId="Validadas"
                  id="validado"
                  value={filterValidado}
                  onChange={e => setFilterValidado(e.target.value)}
                >
                  <MenuItem value="%">Todas</MenuItem>
                  <MenuItem value="true">Sim</MenuItem>
                  <MenuItem value="false">Não</MenuItem>
                </Select>
              </div>

              <div className="ml-3">
                <label
                  htmlFor="legenda"
                  className="block text-xs font-light text-gray-700"
                >
                  Legenda
                </label>
                <Select
                  required
                  labelId="Legenda"
                  id="legenda_comercial"
                  defaultValue=""
                  value={filterLegenda_comercial}
                  onChange={e => setFilterLegenda_comercial(e.target.value)}
                >
                  <MenuItem value="%">Todas</MenuItem>
                  <MenuItem value="r">R - Reenviar</MenuItem>
                  <MenuItem value="e">E - Enviado</MenuItem>
                  <MenuItem value="ne">NE - Não Enviado</MenuItem>
                  <MenuItem value="o">O - Orçamento</MenuItem>
                  <MenuItem value="mi">MI - Manutenção Integral</MenuItem>
                  <MenuItem value="c">C - Cliente</MenuItem>
                  <MenuItem value="a">A - Aplicado</MenuItem>
                  <MenuItem value="g">G - Garantia</MenuItem>
                </Select>
              </div>

              <div className="mx-2 mt-4">
                <button
                  type="button"
                  onClick={() => changeFilter()}
                  className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <FilterIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  Filtrar
                </button>
              </div>
            </div>
          </FormFilterTop>
        </div>
        <div className="mt-2 sm:mt-0 sm:ml-16 sm:flex-none"></div>
      </div>
      <div className="mt-2 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
              <table className="min-w-full divide-y divide-gray-300">
                <TableHead />
                <tbody className="divide-y divide-gray-200 bg-white">
                  {req_pecas.map(req => (
                    <RowTable key={req.id_req_peca} req_peca={req} />
                  ))}
                </tbody>
              </table>

              <NavBarList
                page={page}
                qtdPages={qtdPages}
                totalRegisters={totalRegisters}
                changePage={changePage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
