import React, { useEffect, useState } from 'react';

import { Container } from './styles';
import api from '../../../../../services/api';
import { useOSView } from '../../../../../contexts/OSView';
import ReqPeca from '../ReqPeca';
import ImagesReqList from '../ImagesReqList';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 450,
  },
});

export default function ReqPecaList() {
  const [listReqPecas, setListReqPecas] = useState([]);

  const { id_os_view } = useOSView();

  useEffect(() => {
    getReqPecas(id_os_view);
  }, [id_os_view]);

  async function getReqPecas(id_os) {
    try {
      const response = await api.get(`req_peca?id_os=${id_os}`);
      setListReqPecas(response.data.data);
    } catch (err) {
      return;
    }
  }

  const classes = useStyles();

  return (
    <Container>
      {listReqPecas.length ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 60 }} align="center">
                  Quant.
                </TableCell>
                <TableCell style={{ width: 320 }} align="left">
                  Descrição
                </TableCell>
                <TableCell style={{ width: 60 }} align="center">
                  Separado
                </TableCell>
                <TableCell style={{ width: 30 }} align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listReqPecas.map(req_peca => (
                <>
                  <TableRow key={req_peca.id_req_peca}>
                    <ReqPeca req_peca={req_peca} />
                  </TableRow>
                  <ImagesReqList id_req_peca={req_peca.id_req_peca} />
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <h1>Esta OS não possui Requisições</h1>
      )}
    </Container>
  );
}
