/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import {
  ContainerContent,
  ContainerLineContent,
  Columm,
  BoxInfo,
  TitleInfo,
  DescriptionInfo,
} from './styles';
import api from '../../../../services/api';
import { IUser } from '../../../../types/Collaborator/IUser';

const ContentResumo = ({ collaborator }) => {
  const [user, setUser] = useState<IUser | null>();

  useEffect(() => {
    getUser();
  }, [collaborator]);

  async function getUser() {
    if (!collaborator?.id_funcionario) return;

    try {
      const response = await api.get(
        `user/byFunc/${collaborator.id_funcionario}`,
      );

      setUser(response.data);
    } catch (err) {
      return;
    }
  }

  return (
    <>
      <ContainerContent>
        <Columm>
          <BoxInfo>
            <TitleInfo>Apelido</TitleInfo>
            <DescriptionInfo>{collaborator?.nome_apelido}</DescriptionInfo>
          </BoxInfo>

          <BoxInfo>
            <TitleInfo>Departamento</TitleInfo>
            <DescriptionInfo>{collaborator?.departamento}</DescriptionInfo>
          </BoxInfo>

          <BoxInfo>
            <TitleInfo>Sexo</TitleInfo>
            <DescriptionInfo>{collaborator?.sexo}</DescriptionInfo>
          </BoxInfo>
        </Columm>

        <Columm>
          <BoxInfo>
            <TitleInfo>Técnico</TitleInfo>
            <DescriptionInfo>
              {collaborator?.tecnico ? 'Sim' : 'Não'}
            </DescriptionInfo>
          </BoxInfo>

          <BoxInfo>
            <TitleInfo>Status</TitleInfo>
            <DescriptionInfo>{collaborator?.status}</DescriptionInfo>
          </BoxInfo>
        </Columm>

        <Columm>
          <BoxInfo>
            <TitleInfo>Usuário</TitleInfo>
            <DescriptionInfo>
              {user?.usuario}
              {!user ? (
                '-'
              ) : user?.desativado ? (
                <Tooltip title="Desativado">
                  <CloseCircleOutlined
                    style={{ color: 'red', paddingLeft: 5 }}
                  />
                </Tooltip>
              ) : user?.bloqueado ? (
                <Tooltip title="Bloqueado">
                  <ExclamationCircleOutlined
                    style={{ color: '#FFBF00', paddingLeft: 5 }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Ativo">
                  <CheckCircleOutlined
                    style={{ color: 'green', paddingLeft: 5 }}
                  />
                </Tooltip>
              )}
            </DescriptionInfo>
          </BoxInfo>

          <BoxInfo>
            <TitleInfo>CPF</TitleInfo>
            <DescriptionInfo>{collaborator?.cpf}</DescriptionInfo>
          </BoxInfo>

          <BoxInfo>
            <TitleInfo>RG</TitleInfo>
            <DescriptionInfo>{collaborator?.rg}</DescriptionInfo>
          </BoxInfo>
        </Columm>
      </ContainerContent>

      <ContainerLineContent>
        <BoxInfo>
          <TitleInfo>Observação</TitleInfo>
          <DescriptionInfo>{collaborator?.observacao}</DescriptionInfo>
        </BoxInfo>
      </ContainerLineContent>
    </>
  );
};

export default ContentResumo;
