/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import api from '../../../services/api';
import { useOSBuilder } from '../../../contexts/OSBuilder';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, Select, MenuItem } from '@material-ui/core';

import { List_container, BodyList, FormFilter } from '../styles';

const useStyles = makeStyles({
  table: {
    minWidth: 600,
    width: 900,
    backgroundColor: '#f5f5f5',
  },
  inputMed: {
    width: 180,
    height: 30,
    fontSize: 12,
    color: '#333',
    borderWidth: 1,
    borderColor: '#dcdce6',
    borderRadius: 4,
    paddingLeft: 3,
    marginBottom: 6,
    marginLeft: 5,
    marginRight: 15,
  },
});

export default function List() {
  const [clientes, setClientes] = useState([]);
  const [lines_per_page, setLines_per_page] = useState(30);

  //Filtros
  const [filterEdificio, setFilterEdificio] = useState();
  const [filterEndereco, setFilterEndereco] = useState();
  const [filterStatus, setFilterStatus] = useState('ativo');

  let urlFilter = `lines_per_page=${lines_per_page}`;

  const history = useHistory();

  const { defineCliente, defineId_os_edit } = useOSBuilder();

  useEffect(() => {
    api.get(`cliente/min?${urlFilter}`).then(response => {
      setClientes(response.data.data);
    });
  }, [filterEdificio, filterEndereco, filterStatus]);

  function changeFilter() {
    if (filterEdificio) urlFilter = urlFilter + '&edificio=' + filterEdificio;
    if (filterEndereco) urlFilter = urlFilter + '&endereco=' + filterEndereco;
    if (filterStatus) urlFilter = urlFilter + '&status=' + filterStatus;
  }

  async function definirCliente(id_cliente) {
    await defineCliente(id_cliente);
    await defineId_os_edit(null);
    history.push('/buildos');
  }

  const classes = useStyles();

  return (
    <List_container>
      <BodyList>
        <TableContainer component={Paper} style={{ width: 900, minWidth: 200 }}>
          <Table
            className={classes.table}
            size="small"
            aria-label="tableclients"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ width: 270 }}>
                  Edifício
                </TableCell>
                <TableCell align="left" style={{ width: 250 }}>
                  Endereço
                </TableCell>
                <TableCell align="left" style={{ width: 50 }}>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clientes.map(cliente => (
                <TableRow key={cliente.id_cliente}>
                  <TableCell
                    align="left"
                    style={{ fontSize: 13, fontWeight: 'bold' }}
                    component="th"
                    scope="row"
                  >
                    <Link
                      to="#"
                      onClick={() => definirCliente(cliente.id_cliente)}
                    >
                      {cliente.apelido}
                    </Link>
                  </TableCell>
                  <TableCell align="left" style={{ fontSize: 12 }}>
                    {cliente.logradouro}, {cliente.numero} - {cliente.cidade}
                  </TableCell>
                  {cliente.status == 'inativo' ? (
                    <TableCell
                      align="left"
                      style={{ color: 'red', textTransform: 'capitalize' }}
                    >
                      <b>{cliente.status}</b>
                    </TableCell>
                  ) : (
                    <TableCell
                      align="left"
                      style={{ color: 'green', textTransform: 'capitalize' }}
                    >
                      <b>{cliente.status}</b>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <FormFilter>
          <h2>Filtrar:</h2>
          <br />
          &nbsp; Edifício
          <input
            className={classes.inputMed}
            autoComplete="none"
            placeholder="Por nome ou parte dele"
            value={filterEdificio}
            onChange={e => setFilterEdificio(e.target.value)}
          />
          <br />
          <br />
          &nbsp; Endereço
          <input
            className={classes.inputMed}
            autoComplete="none"
            placeholder="Por rua, bairro, cidade..."
            value={filterEndereco}
            onChange={e => setFilterEndereco(e.target.value)}
          />
          <br />
          <br />
          &nbsp; Status <br />
          <Select
            required
            labelId="Status"
            id="status"
            defaultValue=""
            className={classes.inputMed}
            value={filterStatus}
            onChange={e => setFilterStatus(e.target.value)}
          >
            <MenuItem value="">Todos</MenuItem>
            <MenuItem value="ativo">Cliente</MenuItem>
            <MenuItem value="inativo">Não Cliente</MenuItem>
          </Select>
          <Button onClick={changeFilter()} />
        </FormFilter>
      </BodyList>
    </List_container>
  );
}
