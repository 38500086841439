import React, { useEffect, useContext, useState } from 'react';
import { Time, Remetente, Mensagem, Item, Box_date, Balloon } from './styles';

import { useAuth } from '../../../contexts/Auth';
import convertDateTime from '../../../functions/ConvertDateTime';
import GetUserPerCode from '../../../functions/GetUserPerCode';

export default function Message(props) {
  const [data_br, setData_br] = useState('');
  const [remetente, setRemetente] = useState({});

  const { message } = props;

  const { user } = useAuth();

  useEffect(() => {
    converterDados(message);
  }, [message]);

  async function converterDados(message) {
    const data_convertida = await convertDateTime(message.dateTime);
    setData_br(data_convertida);

    const rem = await GetUserPerCode(message.id_user_rem);
    setRemetente(rem);
  }

  function renderDate(date) {
    return (
      <Box_date>
        <Time>{date}</Time>
        {message.id_user_rem !== user.id_usuario ? (
          <Remetente>
            {' '}
            @{remetente.usuario} - {remetente.nome_apelido}
          </Remetente>
        ) : (
          <Remetente> Você</Remetente>
        )}
      </Box_date>
    );
  }

  return (
    <Item>
      <Balloon>
        <Mensagem>{message.message}</Mensagem>
      </Balloon>
      {renderDate(data_br)}
    </Item>
  );
}
