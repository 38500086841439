/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { LockClosedIcon, PlusIcon } from '@heroicons/react/solid';
import React, { useState, useEffect } from 'react';
import { useOSView } from '../../../../../../../contexts/OSView';
import { feedbackError } from '../../../../../../../functions/MessageFeedback';
import api from '../../../../../../../services/api';
import { IPart } from '../../../../../../../types/Part/IPart';
import { IReqPeca } from '../../../../../../../types/ReqPeca/IReqPeca';
import RowList from '../RowList';
import SlideOverNewEdit from '../SlideOverNewEdit';

interface IProps {
  listReqPecas: any;
  setListReqPecas(req: IReqPeca[]): void;
  handleSave(): void;
  submeting: boolean;
  setShowSliderDetailsPart(show: boolean): void;
  setId_part_view(id_part: number): void;
}

export default function ListItems(props: IProps) {
  const [valorTotal, setValorTotal] = useState<string>();
  const [openSliderNewEdit, setOpenSliderNewEdit] = useState(false);
  const [indexPosition, setIndexPosition] = useState<number | null>();
  const [countChanges, setCountChanges] = useState<number>(0);

  const { id_os_view } = useOSView();

  const {
    listReqPecas,
    setListReqPecas,
    handleSave,
    submeting,
    setShowSliderDetailsPart,
    setId_part_view,
  } = props;

  useEffect(() => {
    somaTotal();
  }, [props]);

  async function somaTotal() {
    let vtotal = '0';

    setValorTotal(vtotal);

    listReqPecas.map(function (req_peca: IReqPeca) {
      //Se o valor da req estiver em branco, não entra na soma
      if (req_peca.valor_total) {
        const numero1 = parseFloat(vtotal);
        const numero2 = parseFloat(req_peca.valor_total);

        vtotal = (numero1 + numero2).toFixed(2);

        setValorTotal(vtotal);
      }
    });
  }

  async function changePart(index: number | null, part: IPart | null) {
    if (!part) {
      setIndexPosition(index);

      setOpenSliderNewEdit(true);
    }

    if (!index && part) {
      const newArr = [...listReqPecas];
      newArr[indexPosition].nome_peca = part.description;
      newArr[indexPosition].id_peca = part.id_part;
      newArr[indexPosition].valor_unit = part.price;

      newArr[indexPosition].valor_total = part.price;

      setListReqPecas(newArr);

      somaSubItem();

      await somaTotal();
    }
  }

  async function removePart(index: number) {
    //Precisa corrigir este método, removendo do banco de dados i item que for removido daqui, pois apeas removendo do vetor, não causará efeito nos ites salvos no banco de dados

    const id_req_pecaExists = listReqPecas[index]?.id_req_peca;

    if (id_req_pecaExists) {
      try {
        await api.delete(`req_peca/${id_req_pecaExists}`);
      } catch (err) {
        feedbackError(
          'Não foi possível deletar este item. Se o problema persistir, contate o suporte.',
        );

        return;
      }
    }

    const list = listReqPecas;

    list.splice(index, 1);

    setListReqPecas(list);

    setCountChanges(countChanges + 1);

    await somaTotal();
  }

  async function addPart() {
    const list = listReqPecas;

    const defaultData = {
      quantidade: 0,
      valor_unit: '0.00',
      valor_total: '0.00',
      id_os: id_os_view,
      id_peca: 0,
    };

    list.push(defaultData);

    setListReqPecas(list);

    setCountChanges(countChanges + 1);
  }

  function somaSubItem() {
    const newArr = [...listReqPecas];

    const total_item =
      listReqPecas[indexPosition].valor_unit *
      listReqPecas[indexPosition].quantidade;

    newArr[indexPosition].valor_total = total_item;

    setListReqPecas(newArr);
  }

  async function showSliderDetailsPart(show: boolean, id_part: number) {
    setShowSliderDetailsPart(show);
    setId_part_view(id_part);
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold text-gray-900">Requisição</h1>
          <button
            type="button"
            onClick={() => addPart()}
            className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            Incluir novo Item
          </button>
        </div>
      </div>
      <div className="-mx-4 mt-1 flex flex-col sm:-mx-6 md:mx-0">
        {listReqPecas.length > 0 ? (
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3 px-0 text-left text-sm font-semibold text-gray-900 sm:pl-2 md:pl-0"
                />
                <th
                  scope="col"
                  className="py-3 px-2 text-left text-sm font-semibold text-gray-900 sm:pl-2 md:pl-0"
                >
                  Quant.
                </th>
                <th
                  scope="col"
                  className="py-3 pl-2 pr-2 text-left text-sm font-semibold text-gray-900 sm:pl-2 md:pl-0"
                >
                  Descrição
                </th>
                <th
                  scope="col"
                  className="hidden py-3 px-2 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Código
                </th>
                <th
                  scope="col"
                  className="hidden py-3 px-2 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  R$ Unit.
                </th>
                <th
                  scope="col"
                  className="py-3 pl-3 pr-2 text-right text-sm font-semibold text-gray-900 sm:pr-2 md:pr-0"
                >
                  Subtotal
                </th>
              </tr>
            </thead>

            <tbody>
              {listReqPecas && listReqPecas.length >= 1
                ? listReqPecas.map((item, index) => (
                    <RowList
                      req_peca={item}
                      pos={index}
                      listReqPecas={listReqPecas}
                      setListReqPecas={setListReqPecas}
                      setOpenSliderNewEdit={setOpenSliderNewEdit}
                      changePart={changePart}
                      removePart={removePart}
                      showSliderDetailsPart={showSliderDetailsPart}
                      key={item.id_req_peca}
                    />
                  ))
                : null}
            </tbody>
            <tfoot>
              <tr>
                <td
                  scope="row"
                  colSpan={5}
                  className="hidden pl-3 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
                >
                  Total
                </td>
                <td
                  scope="row"
                  className="pl-3 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
                >
                  Total
                </td>
                <td className="pl-3 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                  R$ {valorTotal}
                </td>
              </tr>
            </tfoot>
          </table>
        ) : null}
      </div>

      <div className="mt-4 sm:mt-0 sm:ml-0 sm:flex-none">
        {listReqPecas.length >= 1 ? (
          listReqPecas[0].aprovado ? (
            <button
              disabled={true}
              className="inline-flex items-center justify-center border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
            >
              O.S aprovada
              <LockClosedIcon
                className="ml-2 -mr-0.5 h-5 w-5"
                aria-hidden="true"
              />
            </button>
          ) : (
            listReqPecas[0].status !== 'cancelado' && (
              <button
                type="button"
                disabled={submeting}
                onClick={() => handleSave()}
                className="inline-flex items-center justify-center rounded border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
              >
                Salvar e Validar
              </button>
            )
          )
        ) : null}
      </div>

      <SlideOverNewEdit
        openSlider={openSliderNewEdit}
        setOpenSlider={setOpenSliderNewEdit}
        changePart={changePart}
      />
    </div>
  );
}
