import React from 'react';

interface IProps {
  setReportType(type: string): void;
}

export default function InputSelectTypeReport(props: IProps) {
  const { setReportType } = props;

  return (
    <div className="w-60 m-4 py-3 px-3 bg-gray-200 rounded-lg">
      <label htmlFor="type" className="block text-sm font-medium text-gray-700">
        Tipo de relatório
      </label>
      <select
        id="type"
        name="type"
        className="mt-1 block w-full pl-3 pr-10 py-2 bg-white text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
        defaultValue=""
        onChange={e => setReportType(e.target.value)}
      >
        <option value="">Selecione</option>
        <option value="chamados">Chamados</option>
      </select>
    </div>
  );
}
