import React, { useEffect, useState } from 'react';
import {
  BoxInfo,
  Columm,
  ContainerContent,
  InputPhone,
  TitleField,
} from '../styles';
import { Input, Select, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ICollaborator } from '../../../../types/Collaborator/ICollaborator';
import AvatarArea from './components/AvatarArea';
import HandleSubmit from './functions/HandleSubmit';
import GetCollaborator from './functions/GetCollaborator';
import SpinnerLoad from '../../../../components/SpinnerLoad';

const { Option } = Select;

interface IProps {
  id_funcionario?: number;
  setId_funcionario?(id_funcionario: number): void;
}

export default function FormBasic(props: IProps) {
  const [collaborator, setCollaborator] = useState<ICollaborator | null>(null);
  const [loading, setLoading] = useState(false);

  const { id_funcionario, setId_funcionario } = props;

  useEffect(() => {
    if (id_funcionario) {
      getCollaborator();
    } else {
      cleanStates();
    }
  }, [id_funcionario]);

  async function handleChange(event) {
    setCollaborator({
      ...collaborator,
      [event.target.name]: event.target.value,
    });
  }

  async function getCollaborator() {
    if (!id_funcionario) return;

    setLoading(true);

    try {
      const data = await GetCollaborator(id_funcionario);

      if (data) setCollaborator(data);
    } catch (error) {
      setLoading(false);

      return;
    }

    setLoading(false);
  }

  async function cleanStates() {
    setCollaborator(null);
  }

  async function saveData() {
    setLoading(true);

    try {
      const id = await HandleSubmit(collaborator, id_funcionario);

      if (typeof id === 'number') setId_funcionario(id);
    } catch (error) {
      setLoading(false);

      return;
    }

    setLoading(false);
  }

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-4 sm:p-6">
          <div>
            <h3 className="text-lg leading-4 font-medium text-gray-900">
              Dados pessoais
            </h3>
          </div>

          {loading && <SpinnerLoad />}

          <ContainerContent>
            <Columm>
              <BoxInfo>
                <TitleField>Nome completo</TitleField>
                <Input
                  name="nome"
                  id="nome"
                  value={collaborator?.nome}
                  defaultValue={collaborator?.nome}
                  maxLength={30}
                  onChange={e => handleChange(e)}
                />
              </BoxInfo>

              <BoxInfo>
                <TitleField>CPF</TitleField>
                <Input
                  name="cpf"
                  id="cpf"
                  value={collaborator?.cpf}
                  defaultValue={collaborator?.cpf}
                  maxLength={11}
                  onChange={e => handleChange(e)}
                />
              </BoxInfo>

              <BoxInfo>
                <TitleField>Departamento</TitleField>
                <Input
                  name="departamento"
                  id="departamento"
                  value={collaborator?.departamento}
                  defaultValue={collaborator?.departamento}
                  maxLength={30}
                  onChange={e => handleChange(e)}
                />
              </BoxInfo>

              <BoxInfo>
                <TitleField>
                  Status
                  <Tooltip title="Se é funcionário atualmente">
                    <QuestionCircleOutlined style={{ paddingLeft: 3 }} />
                  </Tooltip>
                </TitleField>
                <Select
                  style={{ width: '100%' }}
                  id="status"
                  defaultValue={collaborator?.status}
                  value={collaborator?.status}
                  onChange={e =>
                    setCollaborator(prevState => {
                      return { ...prevState, status: e };
                    })
                  }
                >
                  <Option value="ativo">Ativo</Option>
                  <Option value="inativo">Inativo</Option>
                </Select>
              </BoxInfo>
            </Columm>

            <Columm>
              <BoxInfo>
                <TitleField>
                  Apelido
                  <Tooltip title="O nome que ficará visível no sistema">
                    <QuestionCircleOutlined style={{ paddingLeft: 3 }} />
                  </Tooltip>
                </TitleField>
                <Input
                  name="nome_apelido"
                  id="nome_apelido"
                  value={collaborator?.nome_apelido}
                  defaultValue={collaborator?.nome_apelido}
                  maxLength={30}
                  onChange={e => handleChange(e)}
                />
              </BoxInfo>

              <BoxInfo>
                <TitleField>RG</TitleField>
                <Input
                  name="rg"
                  id="rg"
                  value={collaborator?.rg}
                  defaultValue={collaborator?.rg}
                  maxLength={10}
                  onChange={e => handleChange(e)}
                />
              </BoxInfo>

              <BoxInfo>
                <TitleField>
                  Técnico
                  <Tooltip title="Se ele ficará disponível para atendimentos técnicos">
                    <QuestionCircleOutlined style={{ paddingLeft: 3 }} />
                  </Tooltip>
                </TitleField>
                <Select
                  style={{ width: '100%' }}
                  id="tecnico"
                  defaultValue={collaborator?.tecnico}
                  value={collaborator?.tecnico}
                  onChange={e =>
                    setCollaborator(prevState => {
                      return { ...prevState, tecnico: e };
                    })
                  }
                >
                  <Option value={true}>Sim</Option>
                  <Option value={false}>Não</Option>
                </Select>
              </BoxInfo>
            </Columm>
          </ContainerContent>
        </div>

        <div className="bg-white py-2 px-4 space-y-4 sm:p-6">
          <div>
            <h3 className="text-lg leading-4 font-medium text-gray-900">
              Contato
            </h3>
          </div>

          <ContainerContent>
            <Columm>
              <BoxInfo>
                <TitleField>Telefone principal</TitleField>
                <InputPhone
                  type="text"
                  mask="(99) 999999999"
                  name="telefone1"
                  id="telefone1"
                  value={collaborator?.telefone1}
                  defaultValue={collaborator?.telefone1}
                  onChange={e => handleChange(e)}
                />
              </BoxInfo>

              <BoxInfo>
                <TitleField>E-mail principal</TitleField>

                <Input
                  name="email1"
                  id="email1"
                  value={collaborator?.email1}
                  defaultValue={collaborator?.email1}
                  maxLength={40}
                  onChange={e => handleChange(e)}
                />
              </BoxInfo>

              <BoxInfo>
                <TitleField>WhatsApp</TitleField>
                <InputPhone
                  type="text"
                  mask="(99) 999999999"
                  name="whatsapp"
                  id="whatsapp"
                  value={collaborator?.whatsapp}
                  defaultValue={collaborator?.whatsapp}
                  onChange={e => handleChange(e)}
                />
              </BoxInfo>
            </Columm>

            <Columm>
              <BoxInfo>
                <TitleField>Telefone alternativo</TitleField>
                <InputPhone
                  type="text"
                  mask="(99) 999999999"
                  name="telefone2"
                  id="telefone2"
                  value={collaborator?.telefone2}
                  defaultValue={collaborator?.telefone2}
                  onChange={e => handleChange(e)}
                />
              </BoxInfo>

              <BoxInfo>
                <TitleField>E-mail alternativo</TitleField>

                <Input
                  name="email2"
                  id="email2"
                  value={collaborator?.email2}
                  defaultValue={collaborator?.email2}
                  maxLength={30}
                  onChange={e => handleChange(e)}
                />
              </BoxInfo>
            </Columm>
          </ContainerContent>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            disabled={loading}
            onClick={() => saveData()}
            type="submit"
            className="bg-blue-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Salvar
          </button>
        </div>
      </div>

      {id_funcionario && (
        <AvatarArea
          id_funcionario={collaborator?.id_funcionario}
          foto={collaborator?.foto}
          setCollaborator={setCollaborator}
        />
      )}
    </div>
  );
}
