import React from 'react';
import { feedbackError } from '../../../functions/MessageFeedback';
import VerifyOnlyNumber from '../../../functions/VerifyOnlyNumber';

async function TransformToCodes(
  value: string,
): Promise<{ sequencialCode: string; yearCode: string }> {
  if (!value) return;

  const codes = value.split('/');

  if (codes.length !== 2 || codes[1].length !== 4) {
    feedbackError('Código inválido. Formato exigido: xx/xxxx');
    return;
  }

  const onlyNumber1 = await VerifyOnlyNumber(codes[0]);
  const onlyNumber2 = await VerifyOnlyNumber(codes[1]);

  if (!onlyNumber1 || !onlyNumber2) {
    feedbackError('Código inválido. Não são aceitas letras.');
    return;
  }

  const sequencialCode = codes[0];
  const yearCode = codes[1];

  return { sequencialCode, yearCode };
}

export default TransformToCodes;
