/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  ContainerNavigation,
  BoxButtonNavigation,
  TextButtonNavigation,
} from './styles';

interface IProps {
  section: string;
  setSection: any;
}

export default function NavigationTopBar(props: IProps) {
  const { section, setSection } = props;

  return (
    <ContainerNavigation>
      <BoxButtonNavigation
        active={section === 'parts' ? true : false}
        onClick={() => setSection('parts')}
      >
        <TextButtonNavigation active={section === 'parts' ? true : false}>
          Peças e Estoque
        </TextButtonNavigation>
      </BoxButtonNavigation>
    </ContainerNavigation>
  );
}
