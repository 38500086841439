import React from 'react';

import Routes from './routes';
import GlobalStyle from './styles/global';
import './App.css';

import { AuthProvider } from './contexts/Auth';
import { OSBuilderProvider } from './contexts/OSBuilder';
import { OSViewProvider } from './contexts/OSView';
import { GlobalAttributesProvider } from './contexts/GlobalAttributes';
import { OSGerirProvider } from './contexts/OSGerir';
import { NotificationsProvider } from './contexts/Notifications';
import { ClientRegisterProvider } from './contexts/ClientRegister';

const App: React.FC = () => (
  <>
    <AuthProvider>
      <GlobalAttributesProvider>
        <NotificationsProvider>
          <OSBuilderProvider>
            <OSViewProvider>
              <OSGerirProvider>
                <ClientRegisterProvider>
                  <Routes />
                </ClientRegisterProvider>
              </OSGerirProvider>
            </OSViewProvider>
          </OSBuilderProvider>
        </NotificationsProvider>
      </GlobalAttributesProvider>
    </AuthProvider>

    <GlobalStyle />
  </>
);

export default App;
