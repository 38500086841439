import { message } from 'antd';

function feedbackSuccess(msg = 'Sucesso') {
  message.success(msg);
}

function feedbackError(msg = 'Erro') {
  message.error(msg);
}

function feedbackWarning(msg = 'Atenção') {
  message.warning(msg);
}

export { feedbackSuccess, feedbackError, feedbackWarning };
