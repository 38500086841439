/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../../functions/MessageFeedback';
import { IOutfitter } from '../../../../types/Outfitter/IOutfitter';
import api from '../../../../services/api';
import SelectOutfitter from '../../../PurchaseOrder/components/SelectOutfitter';

interface IProps {
  open: boolean;
  setOpen(open: boolean): void;
  id_outfitter_edit?: number;
  setId_outfitter?(id_outfitter: number | null): void;
  getList?(filter?: string | null): void;
}

export default function SlideOver(props: IProps) {
  const [tipo, setTipo] = useState('juridica');
  const [cnpj, setCnpj] = useState<string | null>();
  const [outfitter, setOutfiter] = useState<IOutfitter | null>();
  const [loading, setLoading] = useState(false);

  const { open, setOpen, id_outfitter_edit, setId_outfitter, getList } = props;

  useEffect(() => {
    if (!open) {
      setOutfiter(null);
      setCnpj(null);
    }

    if (open && !id_outfitter_edit) {
      setOutfiter(null);
      setCnpj(null);
    }

    if (open && id_outfitter_edit) getOutfitter();
  }, [open]);

  useEffect(() => {
    const cnpjLenght = cnpj?.replace(/[^\d]+/g, '').length;

    if (cnpjLenght >= 14) {
      getCNPJ(cnpj);
    }
  }, [cnpj]);

  async function getOutfitter() {
    if (!id_outfitter_edit) return;

    try {
      const response = await api.get(`outfitter/${id_outfitter_edit}`);

      const { data } = response;

      setOutfiter(data);

      if (data.cnpj) {
        setCnpj(response.data.cnpj);
        setTipo('juridica');
      } else {
        setTipo('fisica');
        setCnpj('');
      }
    } catch (error) {
      feedbackError('Erro ao carregar os dados');
      setOpen(false);
    }
  }

  async function getCNPJ(cnpj: string) {
    fetch(`https://publica.cnpj.ws/cnpj/${cnpj}`)
      .then(res => res.json())
      .then(res => {
        if (res.status === 429 || res.status === 400) {
          feedbackError(res.titulo + ' - ' + res.detalhes);

          return;
        }

        setOutfiter({
          ...outfitter,
          razao_social: res.razao_social,
          logradouro:
            res?.estabelecimento?.tipo_logradouro +
            ' ' +
            res?.estabelecimento?.logradouro,
          numero: res?.estabelecimento?.numero,
          complemento: res?.estabelecimento?.complemento,
          bairro: res?.estabelecimento?.bairro,
          cep: res?.estabelecimento?.cep,
          cidade: res?.estabelecimento?.cidade?.nome,
          estado: res?.estabelecimento?.estado?.sigla,
          pais: res?.estabelecimento?.pais?.iso2,
          fone_contato:
            res?.estabelecimento?.ddd1 + res?.estabelecimento?.telefone1,
          email_contato: res?.estabelecimento?.email,
        });
      });
  }

  function handleChange(event) {
    setOutfiter({
      ...outfitter,
      [event.target.name]: event.target.value,
    });
  }

  async function handleSubmit() {
    if (outfitter?.nome_apelido?.length < 3) {
      feedbackError('O nome é muito curto');

      return;
    }

    if (!outfitter.cpf && !cnpj) {
      feedbackError('Você precisa inserir o CNPJ ou CPF');

      return;
    }

    setLoading(true);

    const data = outfitter;

    if (tipo === 'juridica') {
      data.cnpj = cnpj;
      data.cpf = '';
      data.nome_completo = '';
    } else {
      data.cnpj = '';
      data.razao_social = '';
    }

    data.status = 'enabled';

    try {
      if (id_outfitter_edit) {
        await api.put(`outfitter/${id_outfitter_edit}`, data);

        feedbackSuccess('Dados atualizados');
      } else {
        await api.post(`outfitter`, data);

        feedbackSuccess('Criado com sucesso');
      }
    } catch (error) {
      feedbackError('Erro ao salvar. Caso o erro persista, contate o suporte.');
    }

    setLoading(false);

    if (getList) getList();

    setOutfiter(null);

    setCnpj(null);

    setOpen(false);

    if (setId_outfitter) setId_outfitter(null);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-md">
                <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div className="h-0 flex-1 overflow-y-auto">
                    <div className="bg-blue-700 py-4 px-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">
                          {id_outfitter_edit
                            ? 'Editar Fornecedor'
                            : 'Novo Fornecedor'}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-blue-700 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Fechar</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                      <div className="divide-y divide-gray-200 px-4 sm:px-6">
                        <div className="space-y-6 pt-6 pb-5">
                          <div>
                            <label
                              htmlFor="nome_apelido"
                              className="block text-xs font-medium text-gray-900"
                            >
                              Nome
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="nome_apelido"
                                id="nome_apelido"
                                value={outfitter?.nome_apelido}
                                defaultValue={outfitter?.nome_apelido}
                                onChange={e => handleChange(e)}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-xs"
                              />
                            </div>
                          </div>

                          <div className="flex flex-row w-full">
                            <div className="flex w-1/3 flex-col mr-2">
                              <label
                                htmlFor="tipo"
                                className="block text-xs font-medium text-gray-900"
                              >
                                Pessoa
                              </label>
                              <div className="mt-1">
                                <select
                                  id="tipo"
                                  name="tipo"
                                  style={{ backgroundColor: '#fff' }}
                                  className="block h-9 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  value={tipo}
                                  onChange={e => setTipo(e.target.value)}
                                >
                                  <option value="juridica">Jurídica</option>
                                  <option value="fisica">Física</option>
                                </select>
                              </div>
                            </div>

                            {tipo === 'juridica' ? (
                              <div className="flex w-2/3 flex-col">
                                <label
                                  htmlFor="cnpj"
                                  className="block text-xs font-medium text-gray-900"
                                >
                                  CNPJ
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="cnpj"
                                    id="cnpj"
                                    value={cnpj}
                                    defaultValue={cnpj}
                                    placeholder="Somente números"
                                    maxLength={14}
                                    autoComplete="cnpj"
                                    onChange={e =>
                                      setCnpj(
                                        e.target.value.replace(
                                          /[^\d\s-/]/g,
                                          '',
                                        ),
                                      )
                                    }
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="flex w-2/3 flex-col">
                                <label
                                  htmlFor="cpf"
                                  className="block text-xs font-medium text-gray-900"
                                >
                                  CPF
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="cpf"
                                    id="cpf"
                                    value={outfitter?.cpf}
                                    defaultValue={outfitter?.cpf}
                                    placeholder="Somente números"
                                    maxLength={11}
                                    autoComplete="cpf"
                                    onChange={e => handleChange(e)}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                            )}
                          </div>

                          {tipo === 'juridica' ? (
                            <div>
                              <label
                                htmlFor="razao_social"
                                className="block text-xs font-medium text-gray-900"
                              >
                                Razão Social
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="razao_social"
                                  id="razao_social"
                                  value={outfitter?.razao_social}
                                  defaultValue={outfitter?.razao_social}
                                  onChange={e => handleChange(e)}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-xs"
                                />
                              </div>
                            </div>
                          ) : (
                            <div>
                              <label
                                htmlFor="nome_completo"
                                className="block text-xs font-medium text-gray-900"
                              >
                                Nome Completo
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="nome_completo"
                                  id="nome_completo"
                                  value={outfitter?.nome_completo}
                                  defaultValue={outfitter?.nome_completo}
                                  onChange={e => handleChange(e)}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-xs"
                                />
                              </div>
                            </div>
                          )}

                          <div className="flex flex-row w-full">
                            <div className="flex w-2/3 flex-col mr-2">
                              <label
                                htmlFor="logradouro"
                                className="block text-xs font-medium text-gray-900"
                              >
                                Logradouro
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="logradouro"
                                  id="logradouro"
                                  value={outfitter?.logradouro}
                                  defaultValue={outfitter?.logradouro}
                                  onChange={e => handleChange(e)}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-xs"
                                />
                              </div>
                            </div>

                            <div className="flex w-1/3 flex-col">
                              <label
                                htmlFor="numero"
                                className="block text-xs font-medium text-gray-900"
                              >
                                Número
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="numero"
                                  id="numero"
                                  value={outfitter?.numero}
                                  defaultValue={outfitter?.numero}
                                  autoComplete="numero"
                                  onChange={e => handleChange(e)}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-xs"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-row w-full">
                            <div className="flex w-1/3 flex-col mr-2">
                              <label
                                htmlFor="complemento"
                                className="block text-xs font-medium text-gray-900"
                              >
                                Complemento
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="complemento"
                                  id="complemento"
                                  value={outfitter?.complemento}
                                  defaultValue={outfitter?.complemento}
                                  onChange={e => handleChange(e)}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-xs"
                                />
                              </div>
                            </div>

                            <div className="flex w-1/3 flex-col mr-2">
                              <label
                                htmlFor="bairro"
                                className="block text-xs font-medium text-gray-900"
                              >
                                Bairro
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="bairro"
                                  id="bairro"
                                  value={outfitter?.bairro}
                                  defaultValue={outfitter?.bairro}
                                  autoComplete="bairro"
                                  onChange={e => handleChange(e)}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-xs"
                                />
                              </div>
                            </div>

                            <div className="flex w-1/3 flex-col">
                              <label
                                htmlFor="cep"
                                className="block text-xs font-medium text-gray-900"
                              >
                                CEP
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="cep"
                                  id="cep"
                                  value={outfitter?.cep}
                                  defaultValue={outfitter?.cep}
                                  autoComplete="cep"
                                  onChange={e => handleChange(e)}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-row w-full">
                            <div className="flex w-8/12 flex-col mr-2">
                              <label
                                htmlFor="cidade"
                                className="block text-xs font-medium text-gray-900"
                              >
                                Cidade
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="cidade"
                                  id="cidade"
                                  value={outfitter?.cidade}
                                  defaultValue={outfitter?.cidade}
                                  onChange={e => handleChange(e)}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-xs"
                                />
                              </div>
                            </div>

                            <div className="flex w-2/12 flex-col mr-2">
                              <label
                                htmlFor="estado"
                                className="block text-xs font-medium text-gray-900"
                              >
                                Estado
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="estado"
                                  id="estado"
                                  value={outfitter?.estado}
                                  defaultValue={outfitter?.estado}
                                  autoComplete="estado"
                                  maxLength={2}
                                  onChange={e => handleChange(e)}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-xs"
                                />
                              </div>
                            </div>

                            <div className="flex w-2/12 flex-col">
                              <label
                                htmlFor="pais"
                                className="block text-xs font-medium text-gray-900"
                              >
                                País
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="pais"
                                  id="pais"
                                  value={outfitter?.pais}
                                  defaultValue={
                                    outfitter?.pais ? outfitter.pais : 'BR'
                                  }
                                  autoComplete="pais"
                                  onChange={e => handleChange(e)}
                                  maxLength={2}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-xs"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-row w-full">
                            <div className="flex w-2/5 flex-col mr-2">
                              <label
                                htmlFor="nome_contato"
                                className="block text-xs font-medium text-gray-900"
                              >
                                Contato
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="nome_contato"
                                  id="nome_contato"
                                  value={outfitter?.nome_contato}
                                  defaultValue={outfitter?.nome_contato}
                                  onChange={e => handleChange(e)}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-xs"
                                />
                              </div>
                            </div>

                            <div className="flex w-2/5 flex-col mr-2">
                              <label
                                htmlFor="fone_contato"
                                className="block text-xs font-medium text-gray-900"
                              >
                                Fone
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="fone_contato"
                                  id="fone_contato"
                                  value={outfitter?.fone_contato}
                                  defaultValue={outfitter?.fone_contato}
                                  onChange={e => handleChange(e)}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-xs"
                                />
                              </div>
                            </div>

                            <div className="flex w-1/5 flex-col mr-2">
                              <label
                                htmlFor="complemento_contato"
                                className="block text-xs font-medium text-gray-900"
                              >
                                Ramal
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="complemento_contato"
                                  id="complemento_contato"
                                  value={outfitter?.complemento_contato}
                                  defaultValue={outfitter?.complemento_contato}
                                  autoComplete="complemento_contato"
                                  onChange={e => handleChange(e)}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-xs"
                                />
                              </div>
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="email_contato"
                              className="block text-xs font-medium text-gray-900"
                            >
                              E-mail
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="email_contato"
                                id="email_contato"
                                value={outfitter?.email_contato}
                                defaultValue={outfitter?.email_contato}
                                autoComplete="email_contato"
                                onChange={e => handleChange(e)}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-xs"
                              />
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="observacao"
                              className="block text-sm font-medium text-gray-900"
                            >
                              Observação
                            </label>
                            <div className="mt-1">
                              <textarea
                                id="observacao"
                                name="observacao"
                                rows={3}
                                value={outfitter?.observacao}
                                defaultValue={outfitter?.observacao}
                                onChange={e => handleChange(e)}
                                className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-xs"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-shrink-0 justify-end px-4 py-4">
                    <button
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      onClick={() => setOpen(false)}
                    >
                      Cancelar
                    </button>
                    <button
                      disabled={loading}
                      onClick={() => handleSubmit()}
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Salvar
                    </button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
