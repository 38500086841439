import React from 'react';

export default function Card(props) {
  const { equipamentos, selectEquip } = props;

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
      {equipamentos.map(equipamento => (
        <div
          key={equipamento.id_equipamento}
          onClick={() => selectEquip(equipamento.id_equipamento)}
          className="relative rounded-lg border border-gray-300 bg-white px-2 py-2 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
        >
          <div className="flex-shrink-0"></div>
          <div className="flex-1 min-w-0">
            <a href="#" className="focus:outline-none">
              <span className="absolute inset-0" aria-hidden="true" />
              <p className="text-sm font-medium text-gray-900 leading-4">
                {equipamento.apelido}
              </p>
              <p className="text-xs text-gray-500 leading-4 truncate capitalize ">
                Funcionamento: {equipamento.funcionamento}
              </p>
              <p className="text-xs text-gray-500 leading-4 truncate capitalize">
                Tipo: {equipamento.tipo}
              </p>
              <p className="text-sm text-gray-500 leading-4 truncate">
                {equipamento.observacao}
              </p>
            </a>
          </div>
        </div>
      ))}
    </div>
  );
}
