/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { TextField, Button } from '@material-ui/core';
import { Form } from '@unform/web';
import { useOSView } from '../../../../../contexts/OSView';
import { feedbackSuccess } from '../../../../../functions/MessageFeedback';

import { Container, Box } from './styles';
import api from '../../../../../services/api';

export default function BoxSeparacaoReqPeca() {
  const [message, setMessage] = useState('');
  const [listReqPecas, setListReqPecas] = useState([]);

  const { id_os_view } = useOSView();

  useEffect(() => {
    getReqPecas();
    getQuadro();
  }, [id_os_view]);

  async function getQuadro() {
    if (!id_os_view) return;

    try {
      const response = await api.get(`os/board/${id_os_view}?type=separacao`);
      const { message } = response.data;

      setMessage(message);
    } catch (err) {
      return;
    }
  }

  async function handleSave() {
    const id_os = parseInt(id_os_view);

    const os = {
      type: 'separacao',
      message,
      id_os,
    };

    try {
      await api.post(`os/board`, os);

      feedbackSuccess('Informações salvas com sucesso');
    } catch {
      return;
    }
  }

  async function getReqPecas() {
    try {
      const response = await api.get(`req_peca?id_os=${id_os_view}`);
      setListReqPecas(response.data.data);
    } catch (err) {
      return;
    }
  }

  return (
    <>
      {listReqPecas.length > 0 ? (
        <Form onSubmit={handleSave}>
          <Container>
            <Box>
              <TextField
                style={{
                  minWidth: 200,
                  width: 350,
                  fontSize: 5,
                  backgroundColor: '#fff',
                }}
                label="Quadro"
                variant="outlined"
                multiline
                rows={3}
                value={message}
                onChange={e => setMessage(e.target.value)}
              />
            </Box>
            <Box>
              <Button
                variant="contained"
                type="submit"
                style={{ marginTop: 10, marginLeft: 6, padding: 2 }}
              >
                Salvar
              </Button>
            </Box>
          </Container>
        </Form>
      ) : null}
    </>
  );
}
