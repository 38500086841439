import React, { useCallback } from 'react';
import { Container, Content, Title } from './styles';
import logoImg from '../../assets/logo.svg';
import Input from '../../components/Input';
import ButtonDefault from '../../components/ButtonDefault';
import { Form } from '@unform/web';
import { useAuth } from '../../contexts/Auth';

const SignIn: React.FC = () => {
  const { sign } = useAuth();

  const handleSubmit = useCallback(
    async data => {
      const { username, password } = data;

      sign(username, password);
    },
    [sign],
  );

  return (
    <Container>
      <Content>
        <img src={logoImg} alt="Logo" width="350px" />

        <Form onSubmit={handleSubmit}>
          <Title>Entre com seus dados</Title>

          <Input
            name="username"
            placeholder="Login"
            style={{ width: '100%' }}
          />
          <Input
            name="password"
            type="password"
            placeholder="Senha"
            style={{ width: '100%' }}
          />

          <ButtonDefault
            type="submit"
            style={{ backgroundColor: '#010719', width: '95%' }}
          >
            Acessar
          </ButtonDefault>
        </Form>
      </Content>
    </Container>
  );
};

export default SignIn;
