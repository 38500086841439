import React from 'react';
import { feedbackError } from '../../../functions/MessageFeedback';
import api from '../../../services/api';
import { IPurchaseOrder } from '../../../types/PurchaseOrder/IPurchaseOrder';
import TransformToCodes from './TransformToCodes';

async function ShowByCode(value: string): Promise<IPurchaseOrder> {
  if (!value) return;

  const { sequencialCode, yearCode } = await TransformToCodes(value);

  try {
    const response = await api.get(
      `purchase_order/bycode?sequencialCode=${sequencialCode}&yearCode=${yearCode}`,
    );

    if (response.data) return response.data;
  } catch (err) {
    feedbackError('Não foi possível buscar a ordem informada');
  }
}

export default ShowByCode;
