import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 2px;
  padding: 3px;
  color: #fff;
  overflow-x: auto;
`;

export const Box = styled.div`
  background-color: #fff;
  min-height: 30px;
  min-width: 25px;
  width: 80px;
  height: 70px;
  border: 2px solid #fff;
`;
