import React from 'react';

import { Container } from './styles';
import StepsRelease from './StepsRelease';
import ReqPecaList from './ReqPecaList';
import BoxAprovacaoReqPeca from './BoxAprovacaoReqPeca';
import ReqPecaPrepairMail from './ReqPecaPrepairMail';
import BoxFaturamentoReqPeca from './BoxFaturamentoReqPeca';
import { useAuth } from '../../../../contexts/Auth';
import ProposalSend from './ProposalSend';

const SectionReq: React.FC = () => {
  const { user } = useAuth();

  return (
    <Container>
      <StepsRelease />
      {user.id_grupo_usuario <= 58 && <ReqPecaList />}
      {user.id_grupo_usuario <= 58 && <BoxAprovacaoReqPeca />}
      {user.id_grupo_usuario <= 40 && <BoxFaturamentoReqPeca />}
      {user.id_grupo_usuario <= 58 && <ReqPecaPrepairMail />}
      {/* <ProposalSend /> */}
    </Container>
  );
};

export default SectionReq;
