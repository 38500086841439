import React, { useEffect, useState } from 'react';
import { ICollaboratorMin } from '../../types/Collaborator/ICollaboratorMin';
import GetNameFunc from '../../functions/GetNameFunc';

interface IProps {
  id_collaborator: number;
  name?: string;
  avatar?: string;
}

export default function AvatarCollaborator(props: IProps) {
  const [collaborator, setCollaborator] = useState<ICollaboratorMin | null>(
    null,
  );

  const { id_collaborator, name, avatar } = props;

  useEffect(() => {
    if (!name || !avatar) getCollaborator();
  }, []);

  async function getCollaborator() {
    if (!id_collaborator) null;

    const collaborator = await GetNameFunc(id_collaborator);

    setCollaborator(collaborator);
  }
  return (
    <div className="flex-shrink-0 group block">
      <div className="flex items-baseline">
        <div>
          <p className="text-sm font-medium text-gray-600 flex flex-col">
            <span>{name ? name : collaborator?.nome_apelido}</span>
          </p>
        </div>
        <div className="ml-3">
          <img
            className="inline-block h-6 w-6 rounded-full"
            src={avatar ? avatar : collaborator?.foto}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}
