import styled from 'styled-components';

export const Container = styled.div`
  flex: 2;
  background-color: #fff;
  flex-direction: row;
  min-height: 600px;
  width: 500px;
  min-width: 440px;
`;

export const Line = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 15px;
`;

export const Title = styled.div`
  font-size: 16px;
  color: #000;
  font-weight: bold;
  margin-right: 5px;
`;

export const Description = styled.div`
  font-size: 14px;
  color: #2c2c2c;
  ::first-letter {
    text-transform: uppercase;
  }
`;
