/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import TableCell from '@material-ui/core/TableCell';

import api from '../../../../../services/api';
import { useOSView } from '../../../../../contexts/OSView';
import { useAuth } from '../../../../../contexts/Auth';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../../../functions/MessageFeedback';
import SlideOverPart from '../ReqPecaList/components/SlideOverPart';
import SwitchInputDefault from '../../../../../components/SwitchInputDefault';

export default function ReqPeca(props) {
  const [separado, setSeparado] = useState();
  const [changeBlock, setChangeBlock] = useState(true);
  const [openSlider, setOpenSlider] = useState(false);
  const [id_part_view, setId_part_view] = useState();

  const { user } = useAuth();
  const { id_os_view, setRefresh, refresh } = useOSView();

  const { req_peca } = props;

  useEffect(() => {
    getPermissionUser();

    if (req_peca.separado) {
      setSeparado(true);
    } else {
      setSeparado(false);
    }
  }, [props.req_peca]);

  function getPermissionUser() {
    if (
      user.id_grupo_usuario == 58 ||
      user.id_grupo_usuario == 10 ||
      user.id_grupo_usuario == 50
    )
      setChangeBlock(false);
  }

  function changeStatus() {
    if (changeBlock) return;

    setSeparado(!separado);

    saveStatusSeparacao();
  }

  async function saveStatusSeparacao() {
    const req = {
      id_req_peca: req_peca.id_req_peca,
      id_os: id_os_view, //enviar sempre
      type: 'separacao',
      separado: !separado,
    };

    try {
      await api.put(`req_peca/${req_peca.id_req_peca}/changestatus`, req);
      feedbackSuccess('Alterado status de separação');

      setRefresh(!refresh);
    } catch {
      feedbackError('Erro ao definir o status');
      return;
    }
  }

  async function showSlider(show) {
    setOpenSlider(show);
    setId_part_view(req_peca.id_peca);
  }

  return (
    <>
      <SlideOverPart
        openSlider={openSlider}
        id_part={id_part_view}
        setOpenSlider={setOpenSlider}
        setId_part_view={setId_part_view}
      />

      <TableCell align="center">{req_peca.quantidade}</TableCell>
      <TableCell align="left">{req_peca.nome_peca}</TableCell>
      <TableCell align="center">
        {req_peca.status !== 'cancelado' && (
          <SwitchInputDefault enabled={separado} setEnabled={changeStatus} />
        )}
      </TableCell>
      <TableCell align="center" onClick={() => showSlider(true)}>
        <a>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 ml-3"
            fill="none"
            viewBox="0 0 24 24"
            stroke="gray"
            strokeWidth="1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
            />
          </svg>
        </a>
      </TableCell>
    </>
  );
}
