import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  min-height: 80vh;
  background-color: #fff;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 20px;
  padding-right: 10px;
  padding-left: 10px;
  border-bottom: 2px solid #e8e8e8;
`;
