import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  flex: 0.5;
  padding: 2px;
  border: 1px solid #ccc;
  height: 600px;
  min-width: 400px;
  overflow-y: scroll;
  position: relative;
`;

export const List = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

export const Head = styled.div`
  height: 70px;
  background-color: #ccc;
  padding: 8px;
`;

export const btnSend = styled.div`
  background-color: #3b72ff;
  width: 40px;
  height: 40px;
  border-radius: 30px;
`;

export const InputContainer = styled.div`
  border-radius: 10px;
  border: 1px solid #ccc;
  height: 40px;
  flex: 1;
  margin-right: 10px;
`;

export const Inputs = styled.div`
  height: 40px;
  margin-left: 16px;
  flex: 1px;
`;

export const TextFeedback = styled.div`
  padding: 10px;
  font-size: 14px;
`;
