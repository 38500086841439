import api from '../services/api';
import { ICollaboratorMin } from '../types/Collaborator/ICollaboratorMin';
import { intervalToDuration } from 'date-fns';

async function GetNameFuncByUser(id_user: number) {
  if (!id_user) return;

  const stgcollaborator = localStorage.getItem(
    `@elevsis:collaborator/min/byuser/${id_user}`,
  );

  if (stgcollaborator) {
    const collaborator = await JSON.parse(stgcollaborator);

    const oldCache = intervalToDuration({
      start: new Date(collaborator.oldCache),
      end: new Date(),
    });

    if (oldCache.hours < 48) {
      return collaborator;
    } else {
      const collaborator = await getApi(id_user);

      return collaborator;
    }
  } else {
    const collaborator = await getApi(id_user);

    return collaborator;
  }

  async function getApi(id_user: number): Promise<ICollaboratorMin> {
    try {
      const response_user = await api.get(`user/${id_user}`);
      const { id_funcionario } = response_user.data;

      const response_func = await api.get(`collaborator/min/${id_funcionario}`);

      response_func.data.oldCache = new Date();

      localStorage.setItem(
        `@elevsis:collaborator/min/byuser/${id_user}`,
        JSON.stringify(response_func.data),
      );

      return response_func.data;
    } catch {
      return;
    }
  }
}

export default GetNameFuncByUser;
