import React, { useState, useEffect } from 'react';
import { Container, Line, Title, Description } from './styles';

function Alter_board_os({ log }) {
  const [message, setMessage] = useState('');

  useEffect(() => {
    let { body } = log;
    if (body) setMessage(body.message);
  }, [log]);

  return (
    <Container>
      <Line>
        <Title>Quadro de mensagem atualizado:</Title>
        <Description>{message}</Description>
      </Line>

      <Line>
        <Title>Descrição:</Title>
        <Description>{log.description}</Description>
      </Line>
    </Container>
  );
}

export default Alter_board_os;
