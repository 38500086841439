import React from 'react';
import Drawer from '../../components/Drawer';
import Body from './components/Body';

import { Container } from './styles';

const Definitions: React.FC = () => {
  return (
    <Drawer page="Definições">
      <Container>
        <Body />
      </Container>
    </Drawer>
  );
};

export default Definitions;
