import api from '../services/api';
import { IExtraService } from '../types/ExtraService/IExtraService';

async function GetExtraServiceCurrent(
  id_collaborator: number,
): Promise<IExtraService | undefined> {
  if (!id_collaborator) return;

  try {
    const response = await api.get(
      `extraservice/current/bycollaborator/${id_collaborator}`,
    );

    return response.data;
  } catch {
    return;
  }
}

export default GetExtraServiceCurrent;
