import React from 'react';

import { Container } from '../styles';
import SectionOS from './SectionOS';
import SectionReq from './SectionReq';
import SectionChat from './SectionChat';

export function Body() {
  return (
    <Container>
      <SectionOS />
      <SectionReq />

      <SectionChat />
    </Container>
  );
}
