/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Image } from 'antd';
import api from '../../../../../../../../services/api';
import { IFoto } from '../../../../../../../../types/Foto/IFoto';
import { IPart } from '../../../../../../../../types/Part/IPart';

interface IProps {
  part: IPart;
  definePart(part: IPart): void;
}

export default function PartInline(props: IProps) {
  const [image, setImage] = useState<IFoto | null>();

  const { part, definePart } = props;

  useEffect(() => {
    getImage();
  }, [part]);

  async function getImage() {
    try {
      const response = await api.get(
        `foto?origin=part&id_table_origin=${part.id_part}`,
      );

      setImage(response.data.data[0]);
    } catch (err) {
      return;
    }
  }

  return (
    <li className="py-2 pr-1">
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          {image?.url ? (
            <Image
              className="inline-block mt-0.25 mr-1 h-8 w-8"
              width="50px"
              src={image?.url ? image.url : null}
              alt=""
            />
          ) : (
            <div className="h-14 w-14 text-xs">Sem Foto</div>
          )}
        </div>
        <div className="flex-1 min-w-0">
          <p className="text-xs font-normal text-gray-900">
            {part.description}
          </p>
          <p className="text-xs text-gray-500 truncate">
            {'Cód: ' + part.id_part}
          </p>
        </div>
        <div>
          <a
            onClick={() => definePart(part)}
            className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-xs font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
          >
            Selecionar
          </a>
        </div>
      </div>
    </li>
  );
}
