/* eslint-disable @typescript-eslint/no-explicit-any */
async function ConvertCurrency(value: any) {
  const valor_real = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);

  return valor_real;
}

export default ConvertCurrency;
