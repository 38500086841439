/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import GetApelidoEquip from '../../../functions/GetApelidoEquip';
import ConvertDateTime from '../../../functions/ConvertDateTime';
import ConvertCurrency from '../../../functions/ConvertCurrency';
import OSViewSlideOver from '../../../components/OSViewSlideOver';
import { Resume } from './styles';
import { useOSView } from '../../../contexts/OSView';
import { Tooltip } from 'antd';
import { Button } from '@material-ui/core';
import api from '../../../services/api';
import { IReqPeca } from '../../../types/ReqPeca/IReqPeca';

interface IProps {
  req_peca: IReqPeca;
}

const RowTable = (props: IProps) => {
  const [data_criacao, setDataCriacao] = useState('');
  const [valor_total, setValor_total] = useState('');
  const [apelido_equipamento, setApelido_equipamento] = useState('');
  const [open, setOpen] = useState(false);
  const [logsReport, setLogsReport] = useState([]);

  const { req_peca } = props;

  const { defineIdOs } = useOSView();

  useEffect(() => {
    convertDateCriacao(req_peca.created_at);

    convertValorTotal(req_peca.valor_total);

    convertApelidoEquip();

    getLogsReport();
  }, []);

  async function getLogsReport() {
    try {
      const response = await api.get(
        `logs?table_origin=os&id_table_origin=${req_peca.id_os}&type=send_budget_mail_client`,
      );

      setLogsReport(response.data);
    } catch (err) {
      return;
    }
  }

  async function convertDateCriacao(created_at: string) {
    const data_convertida = await ConvertDateTime(created_at);

    setDataCriacao(data_convertida);
  }

  async function convertValorTotal(valor_total: string) {
    const valor_total_convertido = await ConvertCurrency(valor_total);

    setValor_total(valor_total_convertido);
  }

  async function convertApelidoEquip() {
    const apelido_equip = await GetApelidoEquip(req_peca.id_equipamento);
    setApelido_equipamento(apelido_equip);
  }

  return (
    <>
      <OSViewSlideOver
        id_os={req_peca.id_os}
        open={open}
        setOpen={setOpen}
        action="gerir"
      />
      <tr key={req_peca.id_req_peca}>
        <td className="whitespace-nowrap py-2 pl-2 pr-2 text-sm font-medium text-gray-900 sm:pl-2 lg:pl-2">
          <Button onClick={() => setOpen(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
          </Button>
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          <Button onClick={() => defineIdOs(req_peca.id_os, 'gerir')}>
            {req_peca.id_os}
          </Button>
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          {req_peca.edificio} <br />
          <Resume>{req_peca.nome_peca}</Resume>
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          {apelido_equipamento}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          {data_criacao}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          {valor_total}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
          {req_peca.status}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 flex flex-row">
          {req_peca.validado ? (
            <Tooltip title="Validado">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="green"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                />
              </svg>
            </Tooltip>
          ) : (
            <Tooltip title="Não validado">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="red"
                opacity={0.2}
                strokeWidth="1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                />
              </svg>
            </Tooltip>
          )}
          {req_peca.aprovado ? (
            <Tooltip title="Aprovado">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="green"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </Tooltip>
          ) : (
            <Tooltip title="Não aprovado">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="red"
                opacity={0.2}
                strokeWidth="1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </Tooltip>
          )}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-base text-gray-500">
          {req_peca.legenda_comercial}
        </td>
        <td className="whitespace-nowrap px-2 py-2 text-xs text-gray-500 max-w-xs flex flex-row">
          {logsReport.length > 0 ? (
            logsReport.map(log => (
              <Tooltip title={log.description} key={log.id}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="green"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </Tooltip>
            ))
          ) : (
            <Tooltip title="Não enviado">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="red"
                opacity={0.2}
                strokeWidth="1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
            </Tooltip>
          )}
        </td>
      </tr>
    </>
  );
};

export default RowTable;
