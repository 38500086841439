import { formatDistanceToNow } from 'date-fns';
import ptLocale from 'date-fns/locale/pt';

function DurationTimeCalculate(date: Date): string {
  const duration = formatDistanceToNow(new Date(date), {
    addSuffix: false,
    locale: ptLocale,
  });

  return `Há ${duration}`;
}

export default DurationTimeCalculate;
