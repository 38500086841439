import api from '../../../../../../services/api';
import { ICollaboratorConfig } from '../../../../../../types/Collaborator/ICollaboratorConfig';

async function GetConfigs(
  id_funcionario: number,
): Promise<ICollaboratorConfig | void> {
  try {
    const response = await api.get(`collaborator/config/${id_funcionario}`);

    const { data } = response;

    return data;
  } catch (err) {
    return;
  }
}

export default GetConfigs;
