import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const SpinnerLoad: React.FC = () => (
  <div className="pt-3 pl-3">
    <Spin indicator={antIcon} />
  </div>
);

export default SpinnerLoad;
