/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../contexts/Auth';
import api from '../../../../services/api';
import { IUserList } from '../../../../types/User/IUserList';

interface IProps {
  idUser: number | null;
  setIdUser(idUser: number | null): void;
}

export default function SelectAuthor(props: IProps) {
  const [users, setUsers] = useState<IUserList[] | []>([]);

  const { idUser, setIdUser } = props;

  const { user } = useAuth();

  useEffect(() => {
    getList();
  }, []);

  async function getList() {
    try {
      const response = await api.get(`user?desativado=false`);

      setUsers(response.data.data);
    } catch (error) {
      return;
    }
  }

  return (
    <div>
      <select
        id="idUser"
        name="idUser"
        className="mt-1 block w-full pl-1 pr-2 py-2 bg-white text-sm border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 focus:outline-none sm:text-xs rounded-md"
        value={idUser ? idUser : user.id_usuario}
      >
        {users?.map((user: IUserList, idx: number) => (
          <option key={idx} value={user.id_usuario}>
            {user.nome_apelido}
          </option>
        ))}
      </select>
    </div>
  );
}
