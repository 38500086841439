import React, { useEffect, useState } from 'react';
import { Line, DescriptionComplement } from './styles';
import { IPreventiva } from '../../../types/Preventiva/IPreventiva';
import GetNameFunc from '../../../functions/GetNameFunc';
import api from '../../../services/api';
import DurationTimeCalculate from '../../../functions/DurationTimeCalculate';

interface IProps {
  id_equipamento: number;
}

export default function LineLastMp({ id_equipamento }: IProps) {
  const [preventiva, setPreventiva] = useState<IPreventiva>(null);
  const [nameFunc, setNameFunc] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id_equipamento) getPreventiva();
  }, [id_equipamento]);

  async function getPreventiva() {
    setLoading(true);

    const response = await api.get(
      `preventiva?id_equipamento=${id_equipamento}&status=concluida&lines_per_page=1`,
    );

    if (response.data.data[0]) {
      const { data } = response.data;

      setPreventiva(data[0]);

      await convertData(data[0]);
    }

    setLoading(false);
  }

  async function convertData(data: IPreventiva) {
    if (data.id_funcionario) {
      const { nome_apelido } = await GetNameFunc(data.id_funcionario);

      setNameFunc(nome_apelido);
    }
  }

  return (
    <Line>
      <DescriptionComplement style={{ marginRight: 4 }}>
        Última Preventiva:
      </DescriptionComplement>

      {loading ? (
        <DescriptionComplement>Carregando...</DescriptionComplement>
      ) : (
        <>
          {preventiva?.finish ? (
            <DescriptionComplement>
              {DurationTimeCalculate(new Date(preventiva?.finish))}, por{' '}
              {nameFunc}
            </DescriptionComplement>
          ) : (
            <DescriptionComplement>Nenhuma para exibir</DescriptionComplement>
          )}
        </>
      )}
    </Line>
  );
}
