import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 20px;
`;

export const BodyMail = styled.div`
  background-color: #fff;
  padding: 10px;
  border: 1px solid #2c2c2c;
  border-radius: 3px;
`;

export const BodyAdress = styled.div`
  background-color: #fff;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #2c2c2c;
  border-radius: 3px;
`;

export const BodyReports = styled.div`
  background-color: #f0f2f5;
  min-width: 300px;
  padding: 10px;
  margin-left: 2px;
  margin-top: 5px;
  border: 1px solid #2c2c2c;
  border-radius: 3px;
`;

export const BodySubmit = styled.div`
  padding: 10px;
  margin-top: 5px;
`;

export const Text = styled.div`
  margin-top: 5px;
`;
