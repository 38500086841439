/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Tooltip, Modal } from 'antd';
import { PlusOutlined, MinusOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Container,
  BodyMail,
  BodyAdress,
  BodyReports,
  Text,
  BodySubmit,
} from './styles';
import api from '../../../../../services/api';
import { useOSGerir } from '../../../../../contexts/OSGerir';
import { useAuth } from '../../../../../contexts/Auth';
import { TextField } from '@material-ui/core';
import GetNameEdificio from '../../../../../functions/GetNameEdificio';
import GetApelidoEquip from '../../../../../functions/GetApelidoEquip';
import GetNameFunc from '../../../../../functions/GetNameFunc';
import ConvertDateTime from '../../../../../functions/ConvertDateTime';
import {
  feedbackSuccess,
  feedbackError,
} from '../../../../../functions/MessageFeedback';

export default function ReqPecaPrepairMail() {
  const [submeting, setSubmeting] = useState(false);
  const [listReqPecas, setListReqPecas] = useState([]);
  const [valorTotal, setValorTotal] = useState('0');
  const [mensagem, setMensagem] = useState('');
  const [reportTo, setReportTo] = useState([]);
  const [logsReport, setLogsReport] = useState([]);
  const [adressSend, setAdressSend] = useState([]);
  const [newAdress, setNewAdress] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [viewModalIndex, setViewModalIndex] = useState(null);
  const [datetimeLog, setDatetimeLog] = useState('');

  const { getReqPecas, loading, id_os_view, id_cliente_view, os } =
    useOSGerir();
  const { user } = useAuth();

  useEffect(() => {
    getListReqPecas();
    getListReport();
    getLogsReport();
  }, [loading, id_os_view]);

  async function getListReqPecas() {
    try {
      const response = await api.get(`req_peca?id_os=${id_os_view}`);

      setListReqPecas(response.data.data);

      somaValorTotal(response.data.data);
    } catch (err) {
      return;
    }
  }

  async function somaValorTotal(lista_req_peca) {
    let vtotal = '0';
    setValorTotal(vtotal);

    lista_req_peca.map(function (req_peca) {
      //Se o valor da req estiver em branco, não entra na soma
      if (req_peca.valor_total) {
        var numero1 = parseFloat(vtotal.replace(',', '.'));
        var numero2 = parseFloat(req_peca.valor_total.replace(',', '.'));

        vtotal = (numero1 + numero2).toFixed(2);

        setValorTotal(vtotal);
      }
    });
  }

  async function getListReport() {
    try {
      const response = await api.get(
        `adress_report?id_cliente=${id_cliente_view}&inf_peca_solicitada=sim`,
      );

      const { data } = response;

      //Copiar este e-mail ao enviar requisição para e-mails
      //const origin = { cod_email: 0, email: 'tecnica@eliteelevadores.com.br' };
      //changeAdressList(origin.email, 'insert');

      data.push(origin);

      setReportTo(data);
    } catch (err) {
      return;
    }
  }

  async function getLogsReport() {
    try {
      const response = await api.get(
        `logs?table_origin=os&id_table_origin=${id_os_view}&type=send_budget_mail_client`,
      );
      setLogsReport(response.data);
    } catch (err) {
      return;
    }
  }

  async function registerNewAdress() {
    if (newAdress === '') return; //Se o campo estiver em branco

    if (validateEmail(newAdress) === false) {
      feedbackError('e-mail inválido');
      return;
    }

    const body = {
      id_cliente: id_cliente_view,
      inf_peca_solicitada: 'sim',
      adress: newAdress,
    };

    try {
      await api.post('adress_report', body);
    } catch (err) {
      feedbackError('Falha ao cadastrar e-mail');
    }

    setNewAdress('');
    getListReport();
  }

  function validateEmail(email) {
    //
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  async function changeAdressList(email, action) {
    if (action === 'insert') {
      setAdressSend([...adressSend, email]);
    }

    if (action === 'remove') {
      setAdressSend(adressSend.filter(item => item !== email));
    }
  }

  async function changeReq_pecaList(id_req_peca, action) {
    if (action === 'remove') {
      const lista_req_peca = listReqPecas.filter(
        item => item.id_req_peca !== id_req_peca,
      );
      setListReqPecas(lista_req_peca);

      somaValorTotal(lista_req_peca);
    }
  }

  async function sendMail() {
    if (adressSend.length === 0) {
      feedbackError('Nenhum e-mail selecionado');
      return;
    }

    setSubmeting(true);

    const data_convertida = await ConvertDateTime(os.created_at);
    const { nome_apelido } = await GetNameFunc(os.id_funcionario_gerou);
    const { edificio } = await GetNameEdificio(os.id_cliente);
    const apelido = await GetApelidoEquip(os.id_equipamento);

    const body_mail = `
            <h2>Requisição</h2>

            <b>Cod.</b>: ${id_os_view}<br>
            <b>Edifício</b>: ${edificio}<br>
            <b>Elevador</b>: ${apelido}<br>
            <b>Data</b>: ${data_convertida}<br><br>

                ${mensagem}<br><br>

                ${listReqPecas.map(
                  req_peca =>
                    '<b>Descrição</b>: ' +
                    req_peca.nome_peca +
                    '<br>' +
                    '<b>Quantidade</b>: ' +
                    req_peca.quantidade +
                    '<br>' +
                    '<b>Valor unitário</b>: R$ ' +
                    req_peca.valor_unit +
                    '<br>' +
                    '<b>Total do Item</b>: ' +
                    new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(req_peca.valor_total) +
                    '<br><br>',
                )}

                <br><b>Valor total</b>: R$ ${valorTotal}<br><br>

                Para aprovar os serviços citados, você pode responder este e-mail.

                <br><br><br>
                Elite Elevadores<br>
                Fone/Whats: 51 2313.4740 - 9 9275 0670<br>
                E-mail: consultoria@eliteelevadores.com.br
        `;

    const body = {
      adress_send: adressSend,
      subject: 'Requisição - Elite Elevadores',
      body_message: body_mail,
      from: 'consultoria', //Será o remetente do e-mail
      type: 'send_budget_mail_client', //Obrigatório
      id_user_author: user.id_usuario,
      table_origin: 'os',
      id_table_origin: id_os_view,
    };

    try {
      await api.post('email/send', body);

      feedbackSuccess('Enviado com sucesso');

      setTimeout(() => {
        getLogsReport();
      }, 2000);
    } catch (err) {
      feedbackError('Ocorreu um erro no envio');
    }

    setSubmeting(false);
  }

  async function showModal(index) {
    setViewModalIndex(index);

    const { dateTime } = logsReport[index];
    const datetimeLog = await ConvertDateTime(dateTime);
    setDatetimeLog(datetimeLog);

    setIsModalVisible(true);
  }

  function closeModal() {
    setIsModalVisible(false);
    setDatetimeLog('');
    setViewModalIndex(null);
  }

  return (
    <Container>
      <h2>Envio para Cliente:</h2>

      <TextField
        style={{
          minWidth: 100,
          width: '100%',
          marginBottom: 5,
          fontSize: 6,
          backgroundColor: '#fff',
        }}
        label="Corpo da mensagem"
        variant="outlined"
        multiline
        rows={4}
        value={mensagem}
        onChange={e => setMensagem(e.target.value)}
      />

      <BodyMail>
        <table
          style={{
            borderWidth: 1,
            borderColor: '#2c2c2c',
            borderStyle: 'solid',
          }}
        >
          <tr
            style={{
              borderWidth: 1,
              borderColor: '#2c2c2c',
              borderStyle: 'solid',
            }}
          >
            <td
              style={{
                padding: 5,
                borderWidth: 1,
                borderColor: '#2c2c2c',
                borderStyle: 'solid',
              }}
            >
              Quant.
            </td>
            <td
              style={{
                padding: 5,
                borderWidth: 1,
                borderColor: '#2c2c2c',
                borderStyle: 'solid',
              }}
            >
              Nome
            </td>
            <td
              style={{
                padding: 5,
                borderWidth: 1,
                borderColor: '#2c2c2c',
                borderStyle: 'solid',
              }}
            >
              Valor Unit.
            </td>
            <td style={{ padding: 0 }}></td>
          </tr>

          {listReqPecas.map(req_peca => (
            <tr key={req_peca.id_req_peca}>
              <td
                style={{
                  padding: 5,
                  borderWidth: 1,
                  borderColor: '#2c2c2c',
                  borderStyle: 'solid',
                }}
              >
                {req_peca.quantidade}
              </td>
              <td
                style={{
                  padding: 5,
                  borderWidth: 1,
                  borderColor: '#2c2c2c',
                  borderStyle: 'solid',
                }}
              >
                {req_peca.nome_peca}
              </td>
              <td
                style={{
                  padding: 5,
                  borderWidth: 1,
                  borderColor: '#2c2c2c',
                  borderStyle: 'solid',
                }}
              >
                R$ {req_peca.valor_unit}
              </td>
              <td style={{ padding: 0 }}>
                <Tooltip title="Não Enviar" placement="right">
                  <Button
                    onClick={() =>
                      changeReq_pecaList(req_peca.id_req_peca, 'remove')
                    }
                    type="text"
                    icon={
                      <MinusOutlined style={{ color: 'red', fontSize: 16 }} />
                    }
                  />
                </Tooltip>
              </td>
            </tr>
          ))}
        </table>

        <Text>Valor Total: R$ {valorTotal}</Text>
      </BodyMail>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <BodyAdress>
          <TextField
            style={{
              fontSize: 6,
              minWidth: 250,
              backgroundColor: '#fff',
              marginBottom: 10,
            }}
            label="Cadastrar novo e-mail"
            required
            value={newAdress}
            onChange={e => setNewAdress(e.target.value)}
          />

          <Button
            variant="contained"
            onClick={() => registerNewAdress()}
            style={{ marginTop: 10, marginLeft: 10 }}
          >
            Cadastrar
          </Button>

          <table>
            <tr>
              <td style={{ padding: 5 }}>
                <b>Destinatários:</b>{' '}
              </td>
            </tr>
            {reportTo.map(email => (
              <tr style={{ height: 30 }} key={email}>
                <td style={{ padding: 5 }}>
                  {adressSend.includes(email.email) ? (
                    <text style={{ color: 'green', fontWeight: 'bold' }}>
                      {email.email}
                    </text>
                  ) : (
                    <text style={{ color: 'gray' }}>{email.email}</text>
                  )}
                </td>
                <td style={{ width: 30, paddingLeft: 20 }}>
                  {adressSend.includes(email.email) ? (
                    <Tooltip title="Remover" placement="right">
                      <Button
                        onClick={() => changeAdressList(email.email, 'remove')}
                        type="text"
                        icon={
                          <MinusOutlined
                            style={{ color: 'red', fontSize: 18 }}
                          />
                        }
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Adicionar" placement="right">
                      <Button
                        onClick={() => changeAdressList(email.email, 'insert')}
                        type="text"
                        icon={
                          <PlusOutlined
                            style={{ color: 'green', fontSize: 18 }}
                          />
                        }
                      />
                    </Tooltip>
                  )}
                </td>
              </tr>
            ))}
          </table>

          <BodySubmit>
            {os.status !== 'cancelado' && (
              <Button
                loading={submeting}
                onClick={() => sendMail()}
                type="primary"
              >
                Disparar e-mails aos destinatários
              </Button>
            )}
          </BodySubmit>
        </BodyAdress>

        <BodyReports>
          <table>
            <tr>
              <td>
                <b>Histórico de Envio:</b>{' '}
              </td>
            </tr>
            {logsReport.map((log, index) => (
              <tr key={index}>
                <td style={{ height: 30 }}>
                  <text style={{ color: '#00a15a', fontSize: 14 }}>
                    {log.description.replace(/,/gi, ', ')}
                  </text>
                </td>

                <td style={{ height: 30, width: 30, paddingLeft: 10 }}>
                  <Tooltip title="Ver" placement="right">
                    <Button
                      onClick={() => showModal(index)}
                      type="text"
                      icon={
                        <SearchOutlined
                          style={{ color: '#000', fontSize: 18 }}
                        />
                      }
                    />
                  </Tooltip>
                </td>
              </tr>
            ))}
          </table>

          <Modal
            title="Prévia"
            visible={isModalVisible}
            onOk={closeModal}
            onCancel={closeModal}
          >
            <h3>
              <u>Enviada em {datetimeLog}</u>
            </h3>

            <td
              dangerouslySetInnerHTML={{
                __html: logsReport[viewModalIndex]?.body,
              }}
            />
          </Modal>
        </BodyReports>
      </div>
    </Container>
  );
}
