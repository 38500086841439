/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import api from '../../../services/api';
import RowTable from './RowTable';
import { useOSView } from '../../../contexts/OSView';
import SpinnerLoad from '../../../components/SpinnerLoad';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { Input } from 'antd';

import { List_container, BodyList, FormFilter, TopBar } from './styles';
import NavBarList from '../../../components/NavBarList';

const { Search } = Input;

const useStyles = makeStyles({
  table: {
    minWidth: 750,
    width: 1100,
    marginBottom: 1,
  },
  inputMed: {
    paddingLeft: 5,
    marginHorizontal: 5,
    height: 30,
    width: 100,
    color: '#232129',
    borderWidth: 1,
    borderColor: '#2c2c2c',
  },
  buttonSearch: {
    height: 25,
    marginLeft: 5,
    color: '#3B72FF',
    fontSize: 10,
    backgroundColor: '#2c2c2c',
  },
});

export default function List() {
  const [osConcluidas, setOsConcluidas] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [searchCliente, setSearchCliente] = useState();
  const [page, setpage] = useState(1);
  const [lines_per_page, setLines_per_page] = useState(20);
  const [totalRegisters, setTotalRegisters] = useState();
  const [qtdPages, setQtdPages] = useState();
  const [loading, setLoading] = useState(true);

  let urlFilter = `&lines_per_page=${lines_per_page}&status=concluido`;

  const { defineIdOs } = useOSView();

  useEffect(() => {
    getList();
  }, [page]);

  useEffect(() => {
    getClientes();
  }, []);

  useEffect(() => {
    getList();
  }, [searchCliente]);

  function changePage(action) {
    if (action == 'avancar') {
      setpage(page + 1);
    }
    if (action == 'voltar' && page > 1) {
      setpage(page - 1);
    }
  }

  function getList() {
    let id_cliente = '%'; //Default todos os clientes
    if (searchCliente) id_cliente = searchCliente; //Se foi pesquisado cliente, define aqui

    api
      .get(`os?page=${page}${urlFilter}&id_cliente=${id_cliente}`)
      .then(response => {
        setOsConcluidas(response.data.data);

        let numPages = response.data.total / lines_per_page;
        numPages = Math.ceil(numPages);
        setQtdPages(numPages);
        setTotalRegisters(response.data.total);

        setLoading(false);
      });
  }

  function getClientes() {
    api.get(`cliente/min?lines_per_page=99999999`).then(response => {
      setClientes(response.data.data);
    });
  }

  function getOsPerCod(value) {
    if (!value) return;

    api.get(`os/${value}`).then(response => {
      if (response.data) defineIdOs(value);
    });
  }

  const classes = useStyles();

  return (
    <List_container>
      <TopBar>
        <FormFilter>
          <Search
            placeholder="Código"
            autoComplete="none"
            type="number"
            min="1"
            onSearch={getOsPerCod}
            style={{ width: 150 }}
          />

          <Autocomplete
            options={clientes}
            getOptionLabel={clientes => clientes.apelido}
            style={{ width: 350, marginLeft: 20 }}
            value={searchCliente}
            onChange={(event, clienteselected) => {
              setSearchCliente(clienteselected?.id_cliente);
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Pesquisar Cliente"
                variant="outlined"
              />
            )}
          />
        </FormFilter>
      </TopBar>

      {loading ? (
        <SpinnerLoad />
      ) : (
        <BodyList>
          <div style={{ width: 'auto', overflowX: 'auto' }}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="tablecontacts"
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 18 }} align="left"></TableCell>
                    <TableCell style={{ width: 75 }} align="left">
                      Cód.
                    </TableCell>
                    <TableCell style={{ width: 150 }} align="left">
                      Edifício
                    </TableCell>
                    <TableCell style={{ width: 150 }} align="left">
                      Elevador
                    </TableCell>
                    <TableCell style={{ width: 150 }} align="left">
                      Prévia
                    </TableCell>
                    <TableCell style={{ width: 75 }} align="left">
                      Criação
                    </TableCell>
                    <TableCell style={{ width: 75 }} align="left">
                      Técnico
                    </TableCell>
                    <TableCell style={{ width: 75 }} align="left">
                      Status
                    </TableCell>
                    <TableCell style={{ width: 25 }} align="left"></TableCell>

                    <TableCell style={{ width: 20 }} align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {osConcluidas.map(os => (
                    <RowTable key={os.id_os} os={os} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <NavBarList
              page={page}
              qtdPages={qtdPages}
              totalRegisters={totalRegisters}
              changePage={changePage}
            />
          </div>
        </BodyList>
      )}
    </List_container>
  );
}
