import { feedbackError } from '../../../../../functions/MessageFeedback';
import api from '../../../../../services/api';
import { ICollaborator } from '../../../../../types/Collaborator/ICollaborator';

async function GetCollaborator(
  id_funcionario: number,
): Promise<ICollaborator | void> {
  try {
    const response = await api.get(`collaborator/${id_funcionario}`);

    const { data } = response;

    data.tecnico = !!data.tecnico;

    return data;
  } catch (err) {
    feedbackError('Erro ao buscar o colaborador');

    return;
  }
}

export default GetCollaborator;
