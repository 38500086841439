/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { CurrencyDollarIcon, DocumentTextIcon } from '@heroicons/react/outline';
import Card from './Card';
import api from '../../../../services/api';

interface IProps {
  month: number | string;
  year: number | string;
}

export default function Cards(props: IProps) {
  const [totalGenerated, setTotalGenerated] = useState('');
  const [totalApproved, setTotalApproved] = useState('');

  const { month, year } = props;

  useEffect(() => {
    getTotalGenerated();

    getTotalApproved();
  }, [month, year]);

  async function getTotalGenerated() {
    try {
      const response = await api.get(
        `stats/total_generate?modo_edicao=false&month=${month}&year=${year}`,
      );

      const { data } = response;

      setTotalGenerated(
        data.toLocaleString('pt-br', {
          style: 'currency',
          currency: 'BRL',
        }),
      );
    } catch (error) {
      setTotalGenerated('');
      setTotalApproved('');
      return;
    }
  }

  async function getTotalApproved() {
    try {
      const response = await api.get(
        `stats/total_approved?modo_edicao=false&month=${month}&year=${year}`,
      );

      const { data } = response;

      setTotalApproved(
        data.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      );
    } catch (error) {
      return;
    }
  }

  return (
    <>
      <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 justify-start">
        <Card
          Icon={DocumentTextIcon}
          title="Requisições Geradas"
          value={totalGenerated}
        />
        <Card
          Icon={CurrencyDollarIcon}
          title="Requisições aprovadas"
          value={totalApproved}
        />
      </div>
    </>
  );
}
