import api from '../services/api';
import { IPart } from '../types/Part/IPart';

async function GetPart(id: number): Promise<IPart | undefined> {
  if (!id) return;

  try {
    const response = await api.get(`parts/${id}`);

    return response.data;
  } catch (err) {
    return err;
  }
}

export default GetPart;
