import React, { useEffect } from 'react';

import Drawer from '../../components/Drawer';
import { useHistory } from 'react-router-dom';

export default function NovoCliente() {
  const history = useHistory();

  useEffect(() => {
    history.push({
      pathname: '/clienteregister',
      state: {
        mode: 'new',
      },
    });
  }, [history]);

  return (
    <Drawer page="Novo Cliente">
      <></>
    </Drawer>
  );
}
