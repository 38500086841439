/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useState, useContext } from 'react';
import { message } from 'antd';

import api from '../services/api';

const OSBuilderContext = createContext<any>({ modo_edicao: false });

export const OSBuilderProvider: React.FC = ({ children }) => {
  const [id_os_edit, setId_os_edit] = useState(null);
  const [id_req_edit, setId_req_edit] = useState(null);
  const [id_equipamento_edit, setId_equipamento_edit] = useState(null);
  const [id_cliente, setId_cliente] = useState(null);
  const [loading, setloading] = useState(true);

  const successCreate = () => {
    message.success('OS criada com sucesso');
  };

  const successSave = () => {
    message.success('OS salva com sucesso');
  };

  const errorSave = () => {
    message.error('Erro ao salvar a OS');
  };

  function defineLoading(state: any) {
    setloading(state);
  }

  function defineId_os_edit(state: any) {
    setId_os_edit(state);
  }

  function defineCliente(id_cliente: any) {
    setId_cliente(id_cliente);
  }

  function defineIdEquipamento(id_equipamento: any) {
    setId_equipamento_edit(id_equipamento);
  }

  async function defineIdOs(
    id_os: any,
    id_cliente: any,
    history: any,
    destino: any,
  ) {
    setId_os_edit(id_os);
    setId_cliente(id_cliente);

    if (destino == 'edit') {
      history.push('/buildos');
    } else {
      return;
    }
  }

  async function handleOs(os: any, history: any) {
    setloading(true);

    if (id_os_edit) {
      try {
        await api.put(`os/${id_os_edit}`, os);
        successSave();
      } catch (err) {
        errorSave();
      }

      if (!os.chamado) {
        history.push('/osabertas');
      } else {
        history.push('/chamados');
      }
    } else {
      //Se está sendo criada uma OS, sempre será criada como pendencia
      if (!os.chamado) os.situacao = 'pendencia';

      await api.post('os', os);
      successCreate();

      if (!os.chamado) {
        history.push('/osabertas');
      } else {
        history.push('/chamados');
      }
    }
    setId_req_edit(null);
    setId_os_edit(null);
    setId_equipamento_edit(null);
    setId_cliente(null);

    setloading(false);
  }

  return (
    <OSBuilderContext.Provider
      value={{
        id_cliente,
        id_os_edit,
        defineId_os_edit,
        id_req_edit,
        id_equipamento_edit,
        defineIdEquipamento,
        defineIdOs,
        loading,
        defineLoading,
        defineCliente,
        handleOs,
      }}
    >
      {children}
    </OSBuilderContext.Provider>
  );
};

export function useOSBuilder() {
  const context = useContext(OSBuilderContext);
  return context;
}
