import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';
import { IUserList } from '../../../../types/Cliente/Users/IUserList';

interface IProps {
  setId_user_select(id_user_select: string): void;
  refresh: number;
}

export default function List(props: IProps) {
  const [users, setUsers] = useState<IUserList[] | []>([]);

  const { setId_user_select, refresh } = props;

  useEffect(() => {
    getUsers();
  }, [refresh]);

  async function getUsers() {
    try {
      const response = await api.get(`client_api/user`);

      setUsers(response.data);
    } catch (err) {
      return;
    }
  }

  return (
    <>
      <nav className="flex-1 min-h-0 overflow-y-auto" aria-label="users">
        <ul role="list" className="relative z-0 divide-y divide-gray-200">
          {users.map(user => (
            <li key={user?.id} onClick={() => setId_user_select(user?.id)}>
              <div className="relative px-6 py-3 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500">
                <div className="flex-shrink-0">
                  <img
                    className="h-10 w-10 rounded-full"
                    src={
                      user?.avatar_url
                        ? user.avatar_url
                        : 'https://storage-app-do-elevador.s3.sa-east-1.amazonaws.com/default-avatar.svg'
                    }
                    alt=""
                  />
                </div>
                <div className="flex-1 min-w-0">
                  <a className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-sm font-medium text-gray-900">
                      {user?.first_name} {user?.last_name}
                    </p>
                    <p className="text-xs text-gray-400 truncate">
                      {user?.username}
                    </p>
                  </a>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
}
