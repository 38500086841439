import React, { useEffect, useState } from 'react';
import { IItem } from '../../../../types/PurchaseOrder/IItem';
import Row from './Row';

interface IProps {
  listItems: IItem[] | [];
}

export default function ListItemsShow(props: IProps) {
  const [showValorTotal, setShowValorTotal] = useState('');

  const { listItems } = props;

  useEffect(() => {
    let valorTotal = 0;

    listItems?.map((item: IItem, idx: number) => {
      const total_item = item.unitaryValue * item.quantity;

      valorTotal = valorTotal + total_item;
    });

    if (valorTotal) setShowValorTotal(valorTotal.toFixed(2));
  }, [listItems]);

  return (
    <div className="-mx-4 mt-4 flex flex-col sm:-mx-6 md:mx-0">
      <table className="min-w-full divide-y divide-gray-300">
        {listItems.length > 0 ? (
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 justify-start text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
              ></th>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 justify-start text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
              >
                Quant.
              </th>
              <th
                scope="col"
                className="hidden py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Descrição
              </th>
              <th
                scope="col"
                className="hidden py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                OS.
              </th>
              <th
                scope="col"
                className="py-3.5 pl-3 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
              >
                Valor Unitário
              </th>
              <th
                scope="col"
                className="py-3.5 pl-3 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
              >
                R$ Total
              </th>
            </tr>
          </thead>
        ) : null}
        <tbody>
          {listItems && listItems.length >= 1
            ? listItems.map((item, index) => <Row item={item} key={index} />)
            : null}
        </tbody>
        {listItems.length > 0 ? (
          <tfoot>
            <tr>
              <th
                scope="row"
                colSpan={5}
                className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
              >
                Total
              </th>
              <th
                scope="row"
                colSpan={5}
                className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
              >
                Total
              </th>
              <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                {showValorTotal}
              </td>
            </tr>
          </tfoot>
        ) : null}
      </table>
    </div>
  );
}
