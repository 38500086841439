/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  ContainerNavigation,
  BoxButtonNavigation,
  TextButtonNavigation,
} from './styles';

interface IProps {
  section: string;
  setSection: any;
}

export default function NavigationTopBar(props: IProps) {
  const { section, setSection } = props;

  return (
    <ContainerNavigation>
      <BoxButtonNavigation
        active={section === 'resumo' ? true : false}
        onClick={() => setSection('resumo')}
      >
        <TextButtonNavigation active={section === 'resumo' ? true : false}>
          Resumo
        </TextButtonNavigation>
      </BoxButtonNavigation>

      <BoxButtonNavigation
        active={section === 'pessoal' ? true : false}
        onClick={() => setSection('pessoal')}
      >
        <TextButtonNavigation active={section === 'pessoal' ? true : false}>
          Pessoal
        </TextButtonNavigation>
      </BoxButtonNavigation>

      <BoxButtonNavigation
        active={section === 'profissional' ? true : false}
        onClick={() => setSection('profissional')}
      >
        <TextButtonNavigation
          active={section === 'profissional' ? true : false}
        >
          Profissional
        </TextButtonNavigation>
      </BoxButtonNavigation>

      <BoxButtonNavigation
        active={section === 'contato' ? true : false}
        onClick={() => setSection('contato')}
      >
        <TextButtonNavigation active={section === 'contato' ? true : false}>
          Contato
        </TextButtonNavigation>
      </BoxButtonNavigation>

      {/* <BoxButtonNavigation
                    active={section === 'avaliacoes' ? true : false}
                    onClick={() => setSection('avaliacoes')}>
                        <TextButtonNavigation active={section === 'avaliacoes' ? true : false}>
                            Avaliações
                        </TextButtonNavigation>
                    </BoxButtonNavigation> */}

      {/* <BoxButtonNavigation
                    active={section === 'disponibilidade' ? true : false}
                    onClick={() => setSection('disponibilidade')}>
                        <TextButtonNavigation active={section === 'disponibilidade' ? true : false}>
                            Disponibilidade
                        </TextButtonNavigation>
                    </BoxButtonNavigation> */}
    </ContainerNavigation>
  );
}
