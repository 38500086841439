import React from 'react';

async function TransformToCode(
  sequencialCode: number,
  yearCode: number,
): Promise<string> {
  if (!sequencialCode || !yearCode) return;

  const code = `${sequencialCode}/${yearCode}`;

  return code;
}

export default TransformToCode;
