import styled from 'styled-components';
import { Button } from 'antd';

export const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const SaveButton = styled(Button)`
  display: flex;
  padding-left: 50px;
  padding-right: 50px;
  justify-content: flex-start;
  align-items: flex-start;
`;
