import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';
import { IPivotUserClient } from '../../../../types/Cliente/Users/IPivotUserClient';
import { IUserList } from '../../../../types/Cliente/Users/IUserList';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../../functions/MessageFeedback';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import { useAuth } from '../../../../contexts/Auth';

interface IProps {
  user: IPivotUserClient;
  getUsers(): void;
}

export default function Card(props: IProps) {
  const [userClient, setUserClient] = useState<IUserList>();
  const [loading, setLoading] = useState(false);

  const { user: userSession } = useAuth();

  const { user, getUsers } = props;

  useEffect(() => {
    getUser();
  }, [props]);

  async function getUser() {
    if (!user?.id) return;

    try {
      const response = await api.get(`client_api/user/${user.id_user}`);

      setUserClient(response.data);
    } catch (err) {
      return;
    }
  }

  async function changeType(newType: string) {
    if (userSession.id_grupo_usuario > 50) {
      feedbackError('Você não tem permissão');
      return;
    }

    setLoading(true);

    const data = { type: newType };

    try {
      await api.put(`/client_api/pivotuserclient/${user.id}`, data);

      feedbackSuccess('Permissão de usuário alterada');
    } catch (err) {
      feedbackError('Não foi possível');
    }

    setLoading(false);

    getUsers();
  }

  async function vinculateRemove() {
    if (userSession.id_grupo_usuario > 10) {
      feedbackError('Você não tem permissão');
      return;
    }

    try {
      await api.delete(`/client_api/pivotuserclient/${user.id}`);
    } catch (err) {
      feedbackError('Não foi possível remover a vinculação deste usuário');
    }

    getUsers();
  }

  return (
    <li className="col-span-1 bg-white rounded-lg border-2 shadow divide-y divide-gray-200">
      <div className="w-full flex items-center justify-between p-6 space-x-6">
        <div className="flex-1 truncate">
          <div className="flex items-start space-x-3">
            <h3 className="text-gray-900 text-sm font-medium truncate">
              {userClient?.first_name} {userClient?.last_name}
            </h3>
            {userClient?.disabled ? (
              <span className="flex-shrink-0 inline-block px-2 py-0.5 text-gray-900 text-xs font-medium bg-gray-300 rounded">
                Desativado
              </span>
            ) : userClient?.blocked ? (
              <span className="flex-shrink-0 inline-block px-2 py-0.5 text-red-800 text-xs font-medium bg-red-100 rounded">
                Bloqueado
              </span>
            ) : (
              <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded">
                Ativo
              </span>
            )}
          </div>
          <p className="mt-0 text-gray-500 text-sm truncate capitalize">
            Tipo:{' '}
            {user.type === 'admin' ? (
              <>
                <span>Administrador</span>
                <ConfirmDialog
                  title="Você tem certeza?"
                  onConfirm={() => changeType('default')}
                >
                  <button
                    disabled={loading}
                    className="inline-flex items-center px-3 py-1 m-1 font-normal text-red-400 bg-white"
                  >
                    Tornar Usuário Padrão
                    <ArrowDownIcon
                      className="ml-2 h-4 w-4"
                      aria-hidden="true"
                    />
                  </button>
                </ConfirmDialog>
              </>
            ) : (
              <>
                <span>Padrão</span>
                <ConfirmDialog
                  title="Cuidado! Você tem certeza que deseja dar permissões de administrador para este usuário?"
                  onConfirm={() => changeType('admin')}
                >
                  <button
                    disabled={loading}
                    className="inline-flex items-center px-3 py-2 m-1 font-normal text-green-700 bg-white"
                  >
                    Tornar Administrador
                    <ArrowUpIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                  </button>
                </ConfirmDialog>
              </>
            )}
          </p>
          <p className="mt-0 text-gray-500 text-sm truncate">
            E-mail / login: {userClient?.email}
          </p>
        </div>
        <img
          className="w-12 h-12 bg-white rounded-full flex-shrink-0"
          src={
            userClient?.avatar_url
              ? userClient.avatar_url
              : 'https://storage-app-do-elevador.s3.sa-east-1.amazonaws.com/default-avatar.svg'
          }
          alt=""
        />
      </div>
      <ConfirmDialog
        title="Cuidado! Se você confirmar, este usuário perderá o acesso à este cliente."
        onConfirm={() => vinculateRemove()}
      >
        <button
          type="button"
          className="inline-flex items-center px-3 py-2 m-1 border border-transparent rounded shadow-sm text-sm leading-3 font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <TrashIcon className="-ml-0.5 h-4 w-4" aria-hidden="true" />
        </button>
      </ConfirmDialog>
    </li>
  );
}
