/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import api from '../../../services/api';
import RowTable from './RowTable';
import NavBarList from '../../../components/NavBarList';
import { setInterval } from 'timers';
import { IOS } from '../../../types/OS/IOS';

export default function List() {
  const [list, setList] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [searchCliente, setSearchCliente] = useState();
  const [page, setpage] = useState(1);
  const [lines_per_page, setLines_per_page] = useState(20);
  const [totalRegisters, setTotalRegisters] = useState();
  const [qtdPages, setQtdPages] = useState<number>();
  const [loading, setLoading] = useState(true);

  const urlFilter = `&lines_per_page=${lines_per_page}&status=cancelado`;

  useEffect(() => {
    getList();
    getClientes();
  }, [page]);

  useEffect(() => {
    setpage(1);
  }, []);

  useEffect(() => {
    setpage(1);
    getList();
  }, [searchCliente]);

  async function getClientes() {
    const response = await api.get(`cliente/min?lines_per_page=99999999`);
    setClientes(response.data.data);
  }

  function changePage(action: string) {
    if (action == 'avancar') {
      setpage(page + 1);
    }
    if (action == 'voltar' && page > 1) {
      setpage(page - 1);
    }
  }

  async function getList() {
    let id_cliente = '%';
    if (searchCliente) id_cliente = searchCliente;

    try {
      const response = await api.get(
        `os?page=${page}${urlFilter}&id_cliente=${id_cliente}`,
      );

      setList(response.data.data);

      let numPages = response.data.total / lines_per_page;
      numPages = Math.ceil(numPages);
      setQtdPages(numPages);
      setTotalRegisters(response.data.total);

      setLoading(false);
    } catch (err) {
      setInterval(getList, 3000);
    }
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="pt-4 sm:mt-0 sm:ml-0 sm:flex-none">
          <Autocomplete
            options={clientes}
            getOptionLabel={clientes => clientes.apelido}
            style={{ width: 350, marginLeft: 20 }}
            value={searchCliente}
            onChange={(event, clienteselected) => {
              setSearchCliente(clienteselected?.id_cliente);
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Pesquisar Cliente"
                variant="outlined"
              />
            )}
          />
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Cód.
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Edifício
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Elevador
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Prévia
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {list.map((item: IOS) => (
                    <RowTable key={item.id_os} os={item} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <NavBarList
        page={page}
        qtdPages={qtdPages}
        totalRegisters={totalRegisters}
        changePage={changePage}
      />
    </div>
  );
}
