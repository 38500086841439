import React, { useState, useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import { Container, Line, Col, Title, Text } from './styles';
import api from '../../services/api';

import ConvertDateTime from '../../functions/ConvertDateTime';
import GetNameFunc from '../../functions/GetNameFunc';

export default function InstrucoesOs(props) {
  const [id_os, setId_os] = useState();
  const [data_instrucoes, setData_instrucoes] = useState('-');
  const [nome_func, setNome_func] = useState('');
  const [instrucoes, setInstrucoes] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { id_os } = props;

    setId_os(id_os);

    getInstrucoes(id_os);
  }, []);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  async function getInstrucoes(id_os) {
    try {
      setLoading(true);

      const response = await api.get(`/os/instructions/${id_os}`);
      setInstrucoes(response.data);

      const nome_func = await GetNameFunc(response.data.id_funcionario);
      setNome_func(nome_func.nome_apelido);

      const data_instrucoes = await ConvertDateTime(response.data.datetime);
      setData_instrucoes(data_instrucoes);

      setLoading(false);
    } catch (err) {
      setNome_func('Não há instruções para exibir');

      setLoading(false);
    }
  }

  return (
    <>
      <Container>
        <Col>
          <Spin indicator={antIcon} spinning={loading} />

          {instrucoes.observacao ? (
            <Line>
              <Title>Observação:</Title>
              <Text>{instrucoes.observacao}</Text>
            </Line>
          ) : (
            <></>
          )}

          {instrucoes.motivo ? (
            <Line>
              <Title>Motivo:</Title>
              <Text>{instrucoes.motivo}</Text>
            </Line>
          ) : (
            <></>
          )}

          {instrucoes.acompanhado_por ? (
            <Line>
              <Title>Quem acompanhou:</Title>
              <Text>{instrucoes.acompanhado_por}</Text>
            </Line>
          ) : (
            <></>
          )}

          {instrucoes.procedimento ? (
            <Line>
              <Title>Procedimento:</Title>
              <Text>{instrucoes.procedimento}</Text>
            </Line>
          ) : (
            <></>
          )}

          {instrucoes.tempo_horas ? (
            <Line>
              <Title>Tempo necessário:</Title>
              <Text>{instrucoes.tempo_horas}</Text>
            </Line>
          ) : (
            <></>
          )}

          {instrucoes.quant_tecnicos ? (
            <Line>
              <Title>Quant. técnicos:</Title>
              <Text>{instrucoes.quant_tecnicos}</Text>
            </Line>
          ) : (
            <></>
          )}

          {instrucoes.materiais ? (
            <Line>
              <Title>Materiais necessários:</Title>
              <Text>{instrucoes.materiais}</Text>
            </Line>
          ) : (
            <></>
          )}

          {instrucoes.epi ? (
            <Line>
              <Title>EPIs necessários:</Title>
              <Text>{instrucoes.epi}</Text>
            </Line>
          ) : (
            <></>
          )}

          {instrucoes.ferramentas ? (
            <Line>
              <Title>Ferramentas necessárias:</Title>
              <Text>{instrucoes.ferramentas}</Text>
            </Line>
          ) : (
            <></>
          )}

          {instrucoes.agendado_com ? (
            <Line>
              <Title>Agendado com:</Title>
              <Text>{instrucoes.agendado_com}</Text>
            </Line>
          ) : (
            <></>
          )}

          {instrucoes.tecnico_recomendado ? (
            <Line>
              <Title>Técnico recomendado:</Title>
              <Text>{instrucoes.tecnico_recomendado}</Text>
            </Line>
          ) : (
            <></>
          )}

          <Line>
            <Text>
              {data_instrucoes} ({nome_func})
            </Text>
          </Line>
        </Col>
      </Container>
    </>
  );
}
