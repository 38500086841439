import React from 'react';
import styled from 'styled-components';
import { Upload, message } from 'antd';
import { urlAPI } from '../../services/urlAPI';
import { useGlobalAttributes } from '../../contexts/GlobalAttributes';

import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const UploadPhoto = ({ origem, id_table_origem }) => {
  const { setRefresh, refresh } = useGlobalAttributes();

  const props = {
    name: 'file',
    multiple: false,
    action: `${urlAPI}/foto/${origem}/${id_table_origem}`,
    showUploadList: {
      showPreviewIcon: true,
      showRemoveIcon: false,
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }

      if (status === 'done') {
        setRefresh(!refresh);
        message.success(`${info.file.name} anexado com sucesso.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} falhou ao ser anexado.`);
      }
    },
  };

  return (
    <Container>
      <Dragger {...props}>
        <div style={{ width: '100%' }}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Clique ou arraste as fotos aqui para anexar
          </p>
        </div>
      </Dragger>
    </Container>
  );
};

export default UploadPhoto;
