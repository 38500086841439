import React from 'react';
import Drawer from '../../components/Drawer';
import List from './List/List';
import ModalScreen from '../../components/ModalScreen';
import { useOSView } from '../../contexts/OSView';
import { Body as BodyConsultarOS } from '../ConsultarOs/Body';
import { Body as BodyGerirOS } from '../GerirOs/Body';

const Separar: React.FC = () => {
  const { screen, openModal, setOpenModal } = useOSView();

  return (
    <Drawer page="Separar Peças">
      <List />

      <ModalScreen open={openModal} setOpen={setOpenModal}>
        {screen === 'consultaros' && <BodyConsultarOS />}
        {screen === 'gerir' && <BodyGerirOS />}
      </ModalScreen>
    </Drawer>
  );
};

export default Separar;
