import api from '../services/api';
import { IExtraService } from '../types/ExtraService/IExtraService';

async function GetExtraServiceConcludeByOrigin(
  table_origin: string,
  id_table_origin: number | string,
): Promise<IExtraService[] | undefined> {
  if (!table_origin || !id_table_origin) return;

  try {
    const response = await api.get(
      `extraservice/conclude/byorigin?id_table_origin=${id_table_origin}&table_origin=${table_origin}`,
    );

    return response.data;
  } catch {
    return;
  }
}

export default GetExtraServiceConcludeByOrigin;
