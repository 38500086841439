/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ConvertDateTime from '../../../functions/ConvertDateTime';
import ConvertCurrency from '../../../functions/ConvertCurrency';
import { Button } from '@material-ui/core';
import { Resume } from './styles';
import { useOSView } from '../../../contexts/OSView';
import { IReqPeca } from '../../../types/ReqPeca/IReqPeca';

interface IProps {
  req_peca: IReqPeca;
}

const RowTable = (props: IProps) => {
  const [data_criacao, setDataCriacao] = useState('');
  const [data_aprovacao, setDataAprovacao] = useState('');
  const [data_faturamento, setDataFaturamento] = useState('');
  const [valor_total, setValor_total] = useState('');

  const { req_peca } = props;

  const { defineIdOs } = useOSView();

  useEffect(() => {
    convertDateCriacao(req_peca.created_at);

    convertDateAprovacao(req_peca.data_aprovacao);

    convertDateFaturamento(req_peca.data_faturamento);

    convertValorTotal(req_peca.valor_total);
  }, []);

  async function convertDateCriacao(created_at: string) {
    const data_convertida = await ConvertDateTime(created_at);

    setDataCriacao(data_convertida);
  }

  async function convertDateAprovacao(data_aprovacao: string) {
    const data_convertida = await ConvertDateTime(data_aprovacao);

    setDataAprovacao(data_convertida);
  }

  async function convertDateFaturamento(data_faturamento: string) {
    const data_convertida = await ConvertDateTime(data_faturamento);

    setDataFaturamento(data_convertida);
  }

  async function convertValorTotal(valor_total: string) {
    const valor_total_convertido = await ConvertCurrency(valor_total);

    setValor_total(valor_total_convertido);
  }

  return (
    <tr key={req_peca.id_req_peca}>
      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
        <Button onClick={() => defineIdOs(req_peca.id_os, 'gerir')}>
          {req_peca.id_os}
        </Button>
      </td>
      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
        {req_peca.edificio} <br />
        <Resume>{req_peca.nome_peca}</Resume>
      </td>
      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
        {data_criacao}
      </td>
      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
        {valor_total}
      </td>
      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
        {req_peca.status}
      </td>

      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
        {data_aprovacao}
      </td>
      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
        {data_faturamento}
      </td>
    </tr>
  );
};

export default RowTable;
