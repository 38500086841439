import React from 'react';
import logoImg from '../../../../assets/images/logo-elite.png';
import {
  Container,
  BoxBusiness,
  BoxBusinessLine,
  BoxLogo,
  BoxPhones,
  BoxAdress,
  BoxTitle,
  Box,
} from './styles';

export default function Head() {
  return (
    <Container>
      <BoxBusiness>
        <BoxBusinessLine>
          <BoxLogo>
            <img src={logoImg} alt="Logo" width="100" />
          </BoxLogo>
          <BoxPhones>
            Fone: (51) 2313.7071 - 99275.0670 <br />
            E-mail: elite@eliteelevadores.com.br <br />
            www.eliteelevadores.com.br
          </BoxPhones>
        </BoxBusinessLine>

        <BoxBusinessLine>
          <BoxAdress>
            Av. Berlim, 499 - Bairro São Geraldo - CEP 90240-581 <br />
            Porto Alegre - RS
          </BoxAdress>
        </BoxBusinessLine>
      </BoxBusiness>
      <BoxTitle>MANUTENÇÃO PREVENTIVA</BoxTitle>
      <Box />
    </Container>
  );
}
