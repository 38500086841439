import api from '../services/api';

async function GetCoordsByAdress(adress: string) {
  if (!adress) return;

  try {
    const response = await api.get(`get_coords_byadress?adress=${adress}`);

    const { lat, lng } = response.data;

    return { lat, lng };
  } catch (e) {
    return;
  }
}

export default GetCoordsByAdress;
