/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-case-declarations */
import React, { useState, useEffect } from 'react';
import ConvertDateTime from '../../../functions/ConvertDateTime';
import { useOSView } from '../../../contexts/OSView';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Button } from '@material-ui/core';
import { Tooltip } from 'antd';
import { AlertOutlined, CheckOutlined } from '@ant-design/icons';
import {
  parseISO,
  format,
  isValid,
  intervalToDuration,
  differenceInBusinessDays,
} from 'date-fns';
import ScheduleOs from '../../../components/ScheduleOs';
import { Tag, IconRed, IconOrange, IconYellow, IconGreen } from './styles';
import api from '../../../services/api';
import OSViewSlideOver from '../../../components/OSViewSlideOver';
import GetApelidoEquip from '../../../functions/GetApelidoEquip';

const RowTable = ({ os }) => {
  const [data_criacao, setDataCriacao] = useState('');
  const [status_exibir, setStatus_exibir] = useState('');
  const [qtdReq_peca, setQtdReq_peca] = useState(0);
  const [apelidoEquipamento, setApelidoEquipamento] = useState('');
  const [open, setOpen] = useState(false);
  const [isReview, setIsReview] = useState(false);

  const { defineIdOs } = useOSView();

  useEffect(() => {
    convertDate(os.created_at, os?.last_review);
    defineStatus(os);
    contReq_peca();

    getEquip();
  }, [os]);

  async function getEquip() {
    if (!os.id_equipamento) return;

    const apelido = await GetApelidoEquip(os.id_equipamento);

    setApelidoEquipamento(apelido);
  }

  async function contReq_peca() {
    try {
      const response = await api.get(
        `req_peca?lines_per_page=0&id_os=${os.id_os}`,
      );

      setQtdReq_peca(response.data.total);
    } catch (err) {
      return;
    }
  }

  function diffHours(date1, date2) {
    const diff = intervalToDuration({
      start: new Date(date1),
      end: new Date(date2),
    });

    return diff;
  }

  async function convertDate(created_at, last_review) {
    let data_convertida = '';

    data_convertida = await ConvertDateTime(created_at);

    setDataCriacao(data_convertida);

    if (last_review) {
      const diff = diffHours(created_at, last_review);

      if (diff.years > 0 || diff.months > 0 || diff.days > 0) setIsReview(true);
    }
  }

  async function defineStatus(os) {
    switch (os.status) {
      case 'aberto':
        if (os.id_atendimento_last) {
          setStatus_exibir('Retornar');
        } else {
          setStatus_exibir('Aberto');
        }

        break;

      case 'agendado':
        const data_agendamento_valid = isValid(new Date(os.data_agendamento));
        let agenda_data_br = '';

        if (data_agendamento_valid) {
          const agenda_data = parseISO(os.data_agendamento);
          agenda_data_br = format(agenda_data, "dd'/'MM'/'yy - HH:mm");
          //Alterar aqui quando tiver agendamento de data
        }

        if (os.nome_apelido_agendamento) {
          setStatus_exibir(
            'Agendado - ' + os.nome_apelido_agendamento + '  ' + agenda_data_br,
          );
        }

        break;

      case 'deslocamento':
        setStatus_exibir(os.nome_apelido_atendimento + ' Deslocando');
        break;

      case 'atendimento':
        setStatus_exibir(os.nome_apelido_atendimento + ' em Atendimento');

        break;
    }
  }

  return (
    <>
      <OSViewSlideOver
        id_os={os.id_os}
        open={open}
        setOpen={setOpen}
        action="consultar"
      />
      <TableRow key={os.id_os} style={{ height: 50 }}>
        <TableCell align="left">
          <Button onClick={() => setOpen(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
          </Button>
        </TableCell>
        <TableCell style={{ fontSize: 16, fontWeight: 600 }} align="left">
          <Button onClick={() => defineIdOs(os.id_os, 'consultar')}>
            {os.id_os}
          </Button>
        </TableCell>
        <TableCell
          style={{ fontSize: 12 }}
          align="left"
          component="th"
          scope="row"
        >
          <Tooltip title={os.edificio} placement="right">
            {os.apelido}
          </Tooltip>
        </TableCell>
        <TableCell style={{ fontSize: 12 }} align="left">
          {apelidoEquipamento}
        </TableCell>
        <TableCell style={{ fontSize: 11, color: '#757575' }} align="left">
          <Tooltip title={os.descricao} placement="right">
            {os.descricao.substring(25, 0)} ...
          </Tooltip>
        </TableCell>
        {isReview ? (
          <TableCell
            style={{ fontSize: 10, color: 'red', fontWeight: 'bold' }}
            align="left"
          >
            <Tooltip title="Revisada" placement="right">
              {data_criacao}
            </Tooltip>
          </TableCell>
        ) : (
          <TableCell style={{ fontSize: 10 }} align="left">
            {data_criacao}
          </TableCell>
        )}
        <TableCell
          style={{ fontSize: 12, textTransform: 'capitalize' }}
          align="left"
        >
          {status_exibir}
          {os.status === 'aberto' || os.status === 'agendado' ? (
            <ScheduleOs os={os} />
          ) : null}
        </TableCell>
        <TableCell style={{ fontSize: 12 }} align="center">
          {os.prioridade == 'urgente' ? (
            <Tooltip title="Urgente" placement="right">
              <IconRed />
            </Tooltip>
          ) : (
            <></>
          )}
          {os.prioridade == 'alta' ? (
            <Tooltip title="Prioridade Alta" placement="right">
              <IconOrange />
            </Tooltip>
          ) : (
            <></>
          )}
          {os.prioridade == 'media' ? (
            <Tooltip title="Prioridade Média" placement="right">
              <IconYellow />
            </Tooltip>
          ) : (
            <></>
          )}
          {os.prioridade == 'baixa' ? (
            <Tooltip title="Prioridade Baixa" placement="right">
              <IconGreen />
            </Tooltip>
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell style={{ fontSize: 12 }} align="left">
          {os.situacao == 'liberada' ? (
            <Tooltip title="Liberada para Execução" placement="right">
              <CheckOutlined style={{ fontSize: 22, color: '#64dd17' }} />
            </Tooltip>
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell style={{ fontSize: 12 }} align="left">
          {os.funcionamento_equipamento == 'inoperante' ? (
            <Tooltip title="Equipamento Inoperante" placement="right">
              <AlertOutlined style={{ fontSize: 22, color: '#d50000' }} />
            </Tooltip>
          ) : (
            <></>
          )}
          {os.funcionamento_equipamento == 'parcial' ? (
            <Tooltip title="Funcionamento Parcial" placement="right">
              <AlertOutlined style={{ fontSize: 22, color: '#ffa900' }} />
            </Tooltip>
          ) : (
            <></>
          )}
        </TableCell>
        <TableCell align="left">
          {qtdReq_peca ? (
            <Tooltip
              title={'Quantidade de peças: ' + qtdReq_peca}
              placement="right"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                />
              </svg>
            </Tooltip>
          ) : null}
        </TableCell>
      </TableRow>
    </>
  );
};

export default RowTable;
