import React from 'react';
import Drawer from '../../components/Drawer';
import List from './List/List';

const NovaOs: React.FC = () => {
  return (
    <Drawer page="Nova OS">
      <List />
    </Drawer>
  );
};

export default NovaOs;
