import React from 'react';
import {
  ContainerContent,
  Columm,
  BoxInfo,
  TitleInfo,
  DescriptionInfo,
} from './styles';

const ContentContato = ({ collaborator }) => {
  return (
    <>
      <ContainerContent>
        <Columm>
          <BoxInfo>
            <TitleInfo>Endereço atual</TitleInfo>
            <DescriptionInfo>
              {collaborator?.address?.logradouro}{' '}
              {collaborator?.address?.numero}{' '}
              {collaborator?.address?.complemento}
            </DescriptionInfo>
            <DescriptionInfo>{collaborator?.address?.bairro}</DescriptionInfo>
            <DescriptionInfo>
              {collaborator?.address?.cidade} - {collaborator?.address?.estado}
            </DescriptionInfo>
          </BoxInfo>
        </Columm>

        <Columm>
          <BoxInfo>
            <TitleInfo>WhatsApp</TitleInfo>
            <DescriptionInfo>{collaborator?.whatsapp}</DescriptionInfo>
          </BoxInfo>
        </Columm>

        <Columm>
          <BoxInfo>
            <TitleInfo>Telefone</TitleInfo>
            <DescriptionInfo>{collaborator?.telefone1}</DescriptionInfo>
            <DescriptionInfo>{collaborator?.telefone2}</DescriptionInfo>
            <DescriptionInfo>{collaborator?.telefone3}</DescriptionInfo>
          </BoxInfo>
        </Columm>
      </ContainerContent>

      <ContainerContent>
        <Columm>
          <BoxInfo>
            <TitleInfo>E-mail</TitleInfo>
            <DescriptionInfo>{collaborator?.email1}</DescriptionInfo>
            <DescriptionInfo>{collaborator?.email2}</DescriptionInfo>
            <DescriptionInfo>{collaborator?.email3}</DescriptionInfo>
          </BoxInfo>
        </Columm>
      </ContainerContent>
    </>
  );
};

export default ContentContato;
