import styled from 'styled-components';

export const LineOption = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextName = styled.div``;

export const TextDistance = styled.div`
  color: #757575;
  font-size: 12px;
`;
