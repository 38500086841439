import React, { useState } from 'react';
import Drawer from '../../components/Drawer';
import api from '../../services/api';
import { IOutfitter } from '../../types/Outfitter/IOutfitter';
import Body from './components/Body';
import List from './components/List';
import SlideOver from './components/SlideOver';

export default function Outfitters() {
  const [id_outfitter, setId_outfitter] = useState<number | null>(null);
  const [outfitters, setOutfitters] = useState<[IOutfitter] | []>([]);
  const [lines_per_page, setLines_per_page] = useState(999);
  const [open, setOpen] = useState(false);

  function newOutfitter() {
    setId_outfitter(null);

    setOpen(true);
  }

  async function getList(filter?: string) {
    if (!filter) filter = '';

    try {
      const response = await api.get(
        `outfitter?lines_per_page=${lines_per_page}${filter}`,
      );

      setOutfitters(response.data.data);
    } catch (error) {
      return;
    }
  }

  return (
    <Drawer page="Fornecedores">
      <div className="h-full flex bg-white">
        <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
          <div className="flex-1 relative z-0 flex overflow-hidden">
            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
              {id_outfitter ? (
                <Body id_outfitter={id_outfitter} getList={getList} />
              ) : null}
            </main>
            <List
              outfitters={outfitters}
              getList={getList}
              setId_outfitter={setId_outfitter}
              id_outfitter={id_outfitter}
              newOutfitter={newOutfitter}
            />
          </div>
        </div>
      </div>

      <SlideOver
        open={open}
        setOpen={setOpen}
        id_outfitter_edit={id_outfitter}
        setId_outfitter={setId_outfitter}
        getList={getList}
      />
    </Drawer>
  );
}
