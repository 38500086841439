import React, { useEffect, useState } from 'react';
import { Image } from 'antd';

import { Container, Box } from './styles';
import api from '../../services/api';

interface IProps {
  table_origin: string;
  id_table_origin: number;
}

export default function ImagesListPreview({
  table_origin,
  id_table_origin,
}: IProps) {
  const [listImages, setListImages] = useState([]);

  useEffect(() => {
    getImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table_origin, id_table_origin]);

  async function getImages() {
    try {
      const response = await api.get(
        `foto?origin=${table_origin}&id_table_origin=${id_table_origin}`,
      );
      setListImages(response.data.data);
    } catch (err) {
      return;
    }
  }

  return (
    <Container>
      {listImages.map(image => (
        <Box key={image}>
          <Image src={image.url} width="100%" height="100%" />
        </Box>
      ))}
    </Container>
  );
}
