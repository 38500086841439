/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import { useOSGerir } from '../../../../../contexts/OSGerir';
import { useOSView } from '../../../../../contexts/OSView';
import { useAuth } from '../../../../../contexts/Auth';
import api from '../../../../../services/api';
import {
  feedbackError,
  feedbackSuccess,
  feedbackWarning,
} from '../../../../../functions/MessageFeedback';
import SlideOverPart from './components/SlideOverPart';
import ListItems from './components/ListItems';

export default function ReqPecaList() {
  const [listReqPecas, setListReqPecas] = useState([]);
  const [valorTotal, setValorTotal] = useState('0');
  const [submeting, setSubmeting] = useState(false);
  const [openSlider, setOpenSlider] = useState(false);
  const [id_part_view, setId_part_view] = useState();

  const { getReqPecas, loading, id_os_view, save } = useOSGerir();
  const { setRefresh, refresh } = useOSView();
  const { user } = useAuth();

  useEffect(() => {
    getListReqPecas();
  }, [loading, id_os_view]);

  useEffect(() => {
    somaTotal();
  }, [listReqPecas]);

  async function getListReqPecas() {
    try {
      const response = await getReqPecas();

      setListReqPecas(response.data.data);
    } catch (err) {
      return;
    }
  }

  async function somaTotal() {
    let vtotal = '0';

    setValorTotal(vtotal);

    listReqPecas.map(function (req_peca) {
      //Se o valor da req estiver em branco, não entra na soma
      if (req_peca.valor_total) {
        var numero1 = parseFloat(vtotal);
        var numero2 = parseFloat(req_peca.valor_total);

        vtotal = (numero1 + numero2).toFixed(2);

        setValorTotal(vtotal);
      }
    });
  }

  async function handleSave() {
    if (listReqPecas.length === 0) return;

    setSubmeting(true);

    //Verifica se o primeiro item já está validado. Se não, valida todos. Edit: Agora é possível criar itens novos
    if (listReqPecas[0].aprovado)
      feedbackWarning('Cuidado! Esta OS já está Aprovada');

    await validaTodosItens(listReqPecas[0].id_os);

    listReqPecas.map(async function (req_peca) {
      const { id_os, quantidade, nome_peca, id_peca, valor_unit, valor_total } =
        req_peca;

      if (!nome_peca) feedbackError('Não foi possível validar todos os itens.');

      const newVersionReq = {
        id_os,
        quantidade,
        nome_peca,
        id_peca,
        valor_unit,
        valor_total,
      };

      try {
        await save(newVersionReq, req_peca.id_req_peca);
      } catch {
        feedbackError(
          'Erro ao salvar a requisição. Se o problema persistir, contate o suporte.',
        );

        return;
      }
    });

    setSubmeting(false);
  }

  async function validaTodosItens(id_os) {
    const req = {
      id_os,
      validado: true,
      type: 'comercial',
    };

    try {
      await api.put(`req_peca/${id_os}/changestatus`, req);

      setRefresh(!refresh);
      feedbackSuccess('OS validada com sucesso');
    } catch (err) {
      return;
    }
  }

  return (
    <Container>
      <SlideOverPart
        id_part={id_part_view}
        openSlider={openSlider}
        setOpenSlider={setOpenSlider}
        setId_part_view={setId_part_view}
      />

      <ListItems
        listReqPecas={listReqPecas}
        setListReqPecas={setListReqPecas}
        handleSave={handleSave}
        submeting={submeting}
        setShowSliderDetailsPart={setOpenSlider}
        setId_part_view={setId_part_view}
      />
    </Container>
  );
}
