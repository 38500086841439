import React from 'react';

import { Container } from './styles';
import ReqPecaList from './ReqPecaList';
import BoxSeparacaoReqPeca from './BoxSeparacaoReqPeca';
import StepsRelease from './StepsRelease';
import { useAuth } from '../../../../contexts/Auth';

const SectionReq: React.FC = () => {
  const { user } = useAuth();

  return (
    <Container>
      <StepsRelease />
      <ReqPecaList />
      {user.id_grupo_usuario == 58 || user.id_grupo_usuario <= 12 ? (
        <BoxSeparacaoReqPeca />
      ) : null}
    </Container>
  );
};

export default SectionReq;
