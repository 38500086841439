import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';
import RowList from './RowList';

interface IProps {
  month: number | string;
  year: number | string;
}

export default function List(props: IProps) {
  const [collaborators, setCollaborators] = useState([]);

  const { month, year } = props;

  useEffect(() => {
    getListCollaborators();
  }, []);

  async function getListCollaborators() {
    try {
      const response = await api.get(
        'collaborator/config?preference=show_to_sales',
      );

      setCollaborators(response.data);
    } catch (err) {
      return;
    }
  }

  return (
    <>
      <div>
        <div className="max-w-6xl px-2 sm:px-2 lg:px-4">
          <div className="flex flex-col mt-2">
            <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Colaborador
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Total Gerado
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Total Aprovado
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {collaborators?.map(collaborator => (
                    <RowList
                      id_funcionario={collaborator.id_collaborator}
                      month={month}
                      year={year}
                      key={collaborator.id_funcionario}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
