/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { PlusIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { feedbackError } from '../../../../functions/MessageFeedback';
import api from '../../../../services/api';
import Empty from './Empty';
import SlideOverVincUser from './SlideOverVincUser';
import { useClientRegister } from '../../../../contexts/ClientRegister';
import Card from './Card';
import { IPivotUserClient } from '../../../../types/Cliente/Users/IPivotUserClient';
import { IShowCliente } from '../../../../types/IShowCliente';
import SectionKeyUserVinculate from './SectionKeyUserVinculate';

export default function FormUsers() {
  const [client, setClient] = useState<IShowCliente | null>();
  const [users, setUsers] = useState<[IPivotUserClient] | []>([]);
  const [openSliderVincUser, setOpenSliderVincUser] = useState(false);

  const { id_cliente, loading } = useClientRegister();

  useEffect(() => {
    if (id_cliente) {
      getUsers();

      getClient();
    }
  }, [id_cliente, loading]);

  useEffect(() => {
    if (!openSliderVincUser) getUsers();
  }, [openSliderVincUser]);

  async function getClient() {
    try {
      const response = await api.get(`cliente/${id_cliente}`);

      setClient(response.data);
    } catch (err) {
      feedbackError('Não foi possível obter dados adicionais deste cliente');
    }
  }

  async function getUsers() {
    try {
      const response = await api.get(
        `client_api/pivotuserclient/client/${id_cliente}`,
      );

      const { data } = response;

      data.sort(function (a, b) {
        if (a.disabled < b.disabled) {
          return true;
        } else {
          return -1;
        }
      });

      setUsers(data);
    } catch (err) {
      feedbackError(
        'Erro ao carregar os usuários. Tente novamente mais tarde.',
      );
    }
  }

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-10">
      <SlideOverVincUser
        open={openSliderVincUser}
        setOpen={setOpenSliderVincUser}
        key_register_user={client?.key_register_user}
      />

      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Usuários de Cliente
            </h3>
            <p className="mt-1 text-sm text-gray-500"></p>
          </div>

          <SectionKeyUserVinculate
            key_register_user={client?.key_register_user}
            id_client={client?.id_cliente}
            getClient={getClient}
          />

          <div>
            {users.length > 0 ? (
              <>
                <div className="mb-2">
                  <button
                    type="button"
                    onClick={() => setOpenSliderVincUser(true)}
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <PlusIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Vincular usuário
                  </button>
                </div>
                <ul
                  role="list"
                  className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
                >
                  {users.map((user: IPivotUserClient) => (
                    <Card user={user} key={user.id_user} getUsers={getUsers} />
                  ))}
                </ul>
              </>
            ) : (
              <div className="text-center">
                <Empty />
                <div className="mt-6">
                  <button
                    type="button"
                    onClick={() => setOpenSliderVincUser(true)}
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <PlusIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Vincular usuário
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6"></div>
      </div>
    </div>
  );
}
