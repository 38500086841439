/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import RowTable from './RowTable';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';

import {
  List_container,
  BodyList,
  FormFilter,
  NavigationBottomBar,
  TopBar,
} from './styles';

const useStyles = makeStyles({
  table: {
    minWidth: 100,
    width: 1100,
    marginBottom: 1,
  },
  inputMed: {
    paddingLeft: 5,
    marginHorizontal: 5,
    height: 30,
    width: 100,
    color: '#232129',
    borderWidth: 1,
    borderColor: '#2c2c2c',
  },
  buttonSearch: {
    height: 25,
    marginLeft: 5,
    color: '#3B72FF',
    fontSize: 10,
    backgroundColor: '#2c2c2c',
  },
});

export default function List() {
  const history = useHistory();

  const [osAbertas, setOsAbertas] = useState([]);
  const [page, setpage] = useState(1);
  const [lines_per_page, setLines_per_page] = useState(20);
  const [qtdPages, setQtdPages] = useState();
  const [codSearch, setCodSearch] = useState();

  let urlFilter = `&lines_per_page=${lines_per_page}`;

  useEffect(() => {
    getList();
  }, [page]);

  function changePage(action) {
    if (action == 'avancar') {
      setpage(page + 1);
    }
    if (action == 'voltar' && page > 1) {
      setpage(page - 1);
    }
  }

  function getList() {
    api.get(`os_pendentes?page=${page}${urlFilter}`).then(response => {
      setOsAbertas(response.data.data);

      let numPages = response.data.total / lines_per_page;
      numPages = Math.ceil(numPages);
      setQtdPages(numPages);
    });
  }

  function getOsPerCod() {
    api.get(`os/${codSearch}`).then(response => {
      if (response.data) history.push('/consultaros');
    });
  }

  const classes = useStyles();

  return (
    <List_container>
      <TopBar>
        <Button
          variant="contained"
          style={{
            marginBottom: 10,
            backgroundColor: '#000',
            color: '#3B72FF',
          }}
          href="/novaos"
        >
          Criar Nova
        </Button>

        <FormFilter>
          <input
            className={classes.inputMed}
            autoComplete="none"
            type="number"
            placeholder="Buscar cód."
            value={codSearch}
            onChange={e => setCodSearch(e.target.value)}
            onClick={() => setpage(1)} //ao clicar no filtro, retorna para a pág 1 para reiniciar a exibição
          />

          <Button
            className={classes.buttonSearch}
            onClick={() => getOsPerCod()}
          >
            Buscar
          </Button>
        </FormFilter>
      </TopBar>

      <BodyList>
        <div style={{ width: 'auto', overflowX: 'auto' }}>
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="tablecontacts"
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 50 }} align="left">
                    Cód.
                  </TableCell>
                  <TableCell style={{ width: 300 }} align="left">
                    Edifício
                  </TableCell>
                  <TableCell style={{ width: 100 }} align="left">
                    Elevador
                  </TableCell>
                  <TableCell style={{ width: 200 }} align="left">
                    Criação
                  </TableCell>
                  <TableCell style={{ width: 50 }} align="left">
                    Prioridade
                  </TableCell>
                  <TableCell style={{ width: 200 }} align="left">
                    Status
                  </TableCell>
                  <TableCell style={{ width: 40 }} align="left"></TableCell>
                  <TableCell style={{ width: 40 }} align="left"></TableCell>
                  <TableCell style={{ width: 40 }} align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {osAbertas.map(os => (
                  <RowTable key={os.id_os} os={os} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <NavigationBottomBar>
            {page == 1 ? (
              <Button disabled variant="outlined" size="medium">
                Anterior
              </Button>
            ) : (
              <Button
                onClick={() => changePage('voltar')}
                variant="outlined"
                size="medium"
              >
                Anterior
              </Button>
            )}

            {qtdPages === page ? (
              <Button disabled variant="outlined" size="medium">
                Próximo
              </Button>
            ) : (
              <Button
                onClick={() => changePage('avancar')}
                variant="outlined"
                size="medium"
              >
                Próximo
              </Button>
            )}
          </NavigationBottomBar>
        </div>
      </BodyList>
    </List_container>
  );
}
