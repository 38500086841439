import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { LineOption, TextName, TextDistance } from './styles';

import MeasureDistance from '../../../../functions/MeasureDistance';
import api from '../../../../services/api';
import GenerateArrayIdsCollaboratorByPreference from '../../../../functions/GenerateArrayIdsCollaboratorByPreference';

export default function WindowSchedule(props) {
  const [listTecnicos, setListTecnicos] = useState([]);
  const [IdsTec, setIdsTec] = useState([]);

  const { os, func, setFunc } = props;

  useEffect(() => {
    getCollaboratorsToSchedule();

    getListTecnicos();
  }, []);

  async function getCollaboratorsToSchedule() {
    const list = await GenerateArrayIdsCollaboratorByPreference(
      'show_to_schedule_os',
    );

    setIdsTec(list);
  }

  async function getListTecnicos() {
    try {
      const response = await api.get(
        `collaborator/min?status=ativo&tecnico=true`,
      );

      setListTecnicos(response.data.data);
    } catch (err) {
      return;
    }
  }

  let { Option } = Select;

  const listaTec = listTecnicos?.map((tecnico, id_funcionario) => {
    let statusView = '-';

    // if (tecnico.status_availability === 'Disponivel') {
    //   const distance = MeasureDistance(
    //     os.geo_lat,
    //     os.geo_lon,
    //     tecnico.coords.lat,
    //     tecnico.coords.lng,
    //   );
    //   if (distance) statusView = '~ ' + distance.substring(3, 0) + 'km';
    // } else {
    //   statusView = 'Téc. Indisp.';
    // }

    if (IdsTec.includes(tecnico.id_funcionario)) {
      return (
        <Option
          value={tecnico.id_funcionario}
          key={tecnico.id_funcionario}
          nome={tecnico.nome_apelido}
        >
          <LineOption>
            <TextName>{tecnico.nome_apelido}</TextName>
            <TextDistance>{statusView}</TextDistance>
          </LineOption>
        </Option>
      );
    }
  });

  function onChange(value) {
    setFunc(value);
  }

  function onBlur() {
    //console.log('blur');
  }

  function onFocus() {
    //console.log('focus');
  }

  function onSearch(val) {
    //console.log('search:', val);
  }

  return (
    <Select
      showSearch
      style={{ width: 300 }}
      placeholder="Selecione o técnico"
      optionFilterProp="children"
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onSearch={onSearch}
      value={func}
      defaultValue={func}
      filterOption={(input, option) =>
        option.nome.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      <Option value="" nome="-">
        <LineOption>-</LineOption>
      </Option>
      {listaTec}
    </Select>
  );
}
