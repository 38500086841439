import styled from 'styled-components';

export const ContainerContent = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
`;

export const ContainerLineContent = styled.div`
  width: 100%;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
`;

export const Columm = styled.div`
  width: 226px;
`;

export const BoxInfo = styled.div`
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TitleInfo = styled.text`
  font-size: 14px;
  color: #aaa;
  margin-bottom: 5px;
`;

export const DescriptionInfo = styled.text`
  font-size: 14px;
  color: #666;
  min-height: 18px;
`;
