import React, { useEffect, useState } from 'react';
import GenerateArrayIdsCollaboratorByPreference from '../../../../functions/GenerateArrayIdsCollaboratorByPreference';
import RowList from './components/RowList';

export default function ServicesCurrentCollaborators() {
  const [idsCollaborators, setIdsCollaborators] = useState<number[] | []>([]);

  useEffect(() => {
    getIds();
  }, []);

  async function getIds() {
    try {
      const listIds = await GenerateArrayIdsCollaboratorByPreference(
        'show_to_availability',
      );

      setIdsCollaborators(listIds);
    } catch (error) {
      return;
    }
  }

  return (
    <section>
      <div className="rounded-lg bg-white overflow-hidden shadow">
        <div className="p-4">
          <h2
            className="text-base font-medium text-gray-900"
            id="recent-hires-title"
          >
            Agora
          </h2>
          <div className="flow-root mt-4">
            <ul role="list" className="-my-5 divide-y divide-gray-200">
              {idsCollaborators?.map((id_collaborator: number) => (
                <RowList
                  id_collaborator={id_collaborator}
                  key={id_collaborator}
                />
              ))}
            </ul>
          </div>
          <div className="mt-6">
            {/* <a
              href="#"
              className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Ver tudo
            </a> */}
          </div>
        </div>
      </div>
    </section>
  );
}
