import React, { useEffect, useState } from 'react';
import ChartSales from '../ChartSales';
import Cards from './Cards';
import List from './List';

export default function StaatsPartReq() {
  const [month, setMonth] = useState<number | string>();
  const [year, setYear] = useState<number | string>();

  useEffect(() => {
    getCurrentDate();
  }, []);

  function getCurrentDate() {
    const currentDate = new Date();

    setMonth(currentDate.getMonth() + 1);
    setYear(currentDate.getFullYear());
  }

  function changePeriod(date: string) {
    setMonth(date.slice(5, 8));
    setYear(date.slice(0, 4));
  }

  return (
    <>
      <div className="min-h-full justify-start">
        <div className="flex flex-col flex-1 justify-start">
          <main className="flex-1 pb-8 justify-start">
            <div className="mt-2 justify-start">
              <div className="max-w-6xl px-2 sm:px-2 lg:px-4 justify-start">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                  Vendas e Orçamentos
                </h2>
                <span>
                  Dados referentes ao mês
                  <input
                    className="ml-2 bg-opacity-0 bg-white"
                    name="period"
                    id="period"
                    type="month"
                    onChange={e => changePeriod(e.target.value)}
                  />
                </span>
                <Cards month={month} year={year} />
              </div>
              <List month={month} year={year} />
            </div>
          </main>
        </div>
      </div>

      <ChartSales />
    </>
  );
}
