import React from 'react';
import Drawer from '../../components/Drawer';

const Agenda: React.FC = () => {
  return (
    <Drawer page="Agenda">
      <iframe
        src="https://calendar.google.com/calendar/embed?height=900&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FSao_Paulo&amp;src=YWdlbmRhLmVsaXRlZWxldmFkb3Jlc0BnbWFpbC5jb20&amp;src=cmVsY3N1ZWNoZ2swNWY4NWpjcTFpNGo1b2NAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=MWg1dWo2ajUydjRtaGJpdmhndWQ0bW4zcGdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=YXM4bGhsMDRkZGNlMTV2c3JodTc3b2IxMzBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=bDhpcmQ4OWY1cWw3MzI5ZjViNzRoYnFhaGdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=a2Q1N2c5bjFvazNmZDdqcTcycmdydHBrMjBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=bzYyMTIzNmtrZ3BkNW9nYzJyMm5nM2U5Y2dAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=anB2azVqdDYwcGE5N2Y0MnZyZWYxbXQ2YmdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23A79B8E&amp;color=%23F4511E&amp;color=%23039BE5&amp;color=%23E4C441&amp;color=%23D50000&amp;color=%237CB342&amp;color=%239E69AF&amp;color=%238E24AA"
        width="100%"
        height="800"
        frameBorder="0"
        scrolling="no"
      />
    </Drawer>
  );
};

export default Agenda;
