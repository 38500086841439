import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Tooltip, Modal } from 'antd';
import {
  EditOutlined,
  RollbackOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Container, Line, Title, Description } from './styles';
import HeadOS from '../../../../components/HeadOS';
import ChangeStatusLiberacao from '../../../../components/ChangeStatusLiberacao';
import InstrucoesOs from '../../../../components/InstrucoesOs';
import LineLog from '../../../../components/Logs/LineLog';
import ImagesOSList from './ImagesOSList';
import api from '../../../../services/api';
import { useOSView } from '../../../../contexts/OSView';
import { useOSBuilder } from '../../../../contexts/OSBuilder';
import { useAuth } from '../../../../contexts/Auth';

import GetApelidoEquip from '../../../../functions/GetApelidoEquip';
import ConvertDateTime from '../../../../functions/ConvertDateTime';
import GetNameFunc from '../../../../functions/GetNameFunc';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../../functions/MessageFeedback';
import ListAttendances from '../../../../components/AttendancesOSList/ListAttendances';
import LineLastMp from '../../../../components/OS/LineLastMp';

function SectionOS() {
  const [apelidoEquip, setApelidoEquip] = useState('');
  const [dataCriacao, setDataCriacao] = useState('');
  const [nameFunc, setNameFunc] = useState('');
  const [nameFuncAgenda, setNameFuncAgenda] = useState('');
  const [logsAlterSituacao, setLogsAlterSituacao] = useState([]);
  const [isModalReabrirVisible, setIsModalReabrirVisible] = useState(false);
  const [
    isModalLogStatusLiberacaoVisible,
    setIsModalLogStatusLiberacaoVisible,
  ] = useState(false);

  const { id_cliente_view, os, loading } = useOSView();
  const { defineIdOs } = useOSBuilder();
  const { user } = useAuth();

  const history = useHistory();

  (async () => {
    const apelido = await GetApelidoEquip(os.id_equipamento);
    setApelidoEquip(apelido);

    convertData();
  })(loading);

  async function convertData() {
    const data_convertida = await ConvertDateTime(os.created_at);
    setDataCriacao(data_convertida);

    if (os.id_funcionario_gerou) {
      const { nome_apelido } = await GetNameFunc(os.id_funcionario_gerou);
      setNameFunc(nome_apelido);
    }

    if (os.funcionario_agendamento != 0) {
      const { nome_apelido: nome_agenda } = await GetNameFunc(
        os.funcionario_agendamento,
      );
      setNameFuncAgenda(nome_agenda);
    }
  }

  async function getLogsAlterSituacao() {
    try {
      const response = await api.get(
        `logs?table_origin=os&id_table_origin=${os.id_os}&type=alter_situacao_os`,
      );
      setLogsAlterSituacao(response.data);
    } catch (err) {
      return;
    }
  }

  const showModalReabrir = () => {
    setIsModalReabrirVisible(true);
  };

  const handleOkModalReabrir = async () => {
    setIsModalReabrirVisible(false);
    reabrirOS();
  };

  const handleCancelModalReabrir = () => {
    setIsModalReabrirVisible(false);
  };

  const showModalLog = () => {
    getLogsAlterSituacao();
    setIsModalLogStatusLiberacaoVisible(true);
  };

  const handleOkModalLog = () => {
    setIsModalLogStatusLiberacaoVisible(false);
  };

  const handleCancelModalLog = () => {
    setIsModalLogStatusLiberacaoVisible(false);
  };

  async function reabrirOS() {
    try {
      await api.put(`os/manager/${os.id_os}/reabrir`);
    } catch (err) {
      feedbackError('Ocorreu um erro');
    }

    window.location.reload();
  }

  const valueClipboard = `\nEdifício: ${os.apelido}\nEndereço: ${os.logradouro}, ${os.numero}\n${os.bairro} - ${os.cidade}\nDescrição: ${os.descricao}\nPré-observação: ${os.pre_observacao}\nElevador: ${apelidoEquip}\nPrioridade: ${os.prioridade}\nData de Criação: ${dataCriacao}`;

  return (
    <Container>
      <HeadOS id_cliente={id_cliente_view} />
      {os.chamado ? (
        <Line>
          <Title>Chamado - </Title>
          <Title>Solicitante:</Title>
          <Description>{os.solicitante}</Description>
        </Line>
      ) : (
        <></>
      )}

      <Line>
        <Title>ID: {os.id_os}</Title>
      </Line>

      <Line>
        <Title>Equipamento:</Title>
        <Description>{apelidoEquip}</Description>
      </Line>

      <LineLastMp id_equipamento={os.id_equipamento} />

      <Line>
        <Title>Descrição:</Title>
        <Description>{os.descricao}</Description>
      </Line>

      {os.pre_observacao ? (
        <Line>
          <Title>Pré-observação:</Title>
          <Description>{os.pre_observacao}</Description>
        </Line>
      ) : (
        <></>
      )}

      <Line>
        <Title>Criação:</Title>
        <Description>
          {dataCriacao} - {nameFunc}
        </Description>
      </Line>

      <Line>
        <Title>Prioridade:</Title>
        <Description>{os.prioridade}</Description>
      </Line>

      {os.status == 'agendado' ? (
        <Line>
          <Title>Agendamento:</Title>
          <Description>{nameFuncAgenda}</Description>
        </Line>
      ) : (
        <></>
      )}

      <Line>
        <Title>Status de Atendimento:</Title>
        <Description>
          {os.status === 'cancelado' ? (
            <span className="bg-red-600 text-white px-3 py-1 rounded">
              Cancelada
            </span>
          ) : (
            os.status
          )}
        </Description>
      </Line>
      {os.status !== 'cancelado' && (
        <Line>
          <Title>Status de Liberação:</Title>
          <Description>
            {os.situacao === 'pendencia'
              ? 'Bloqueada'
              : os.status === 'concluido'
              ? ''
              : 'Liberada'}
          </Description>
          {os.status !== 'concluido' &&
          (user.id_grupo_usuario === 58 ||
            user.id_grupo_usuario === 50 ||
            user.id_grupo_usuario === 10) ? (
            <ChangeStatusLiberacao />
          ) : null}

          <Tooltip title="Ver Logs de Liberação" placement="right">
            <Button
              type="secondary"
              style={{ marginLeft: 5 }}
              onClick={() => showModalLog()}
            >
              <FileTextOutlined style={{ fontSize: 16 }} />
            </Button>
          </Tooltip>
        </Line>
      )}
      {os.status !== 'cancelado' && (
        <Line>
          {user.id_grupo_usuario <= 10 ||
          user.id_grupo_usuario === 58 ||
          user.id_grupo_usuario === 50 ? (
            <Button
              type="primary"
              onClick={() =>
                defineIdOs(os.id_os, os.id_cliente, history, 'edit')
              }
            >
              <EditOutlined /> Editar
            </Button>
          ) : (
            <></>
          )}

          {user.id_grupo_usuario <= 10 ||
          user.id_grupo_usuario === 58 ||
          user.id_grupo_usuario === 50 ? (
            os.status === 'concluido' ? (
              <Button
                type="secondary"
                style={{ marginLeft: 10 }}
                onClick={() => showModalReabrir()}
              >
                <RollbackOutlined /> Reabrir OS
              </Button>
            ) : (
              false
            )
          ) : (
            <></>
          )}
        </Line>
      )}

      <Line>
        <CopyToClipboard
          text={os.chamado ? 'Chamado' + valueClipboard : 'OS' + valueClipboard}
          onCopy={() => feedbackSuccess('Copiado para área de transferência')}
        >
          <Button
            type="secondary"
            style={{ marginBottom: 5, paddingLeft: 5, paddingRight: 5 }}
          >
            Copiar
          </Button>
        </CopyToClipboard>
      </Line>

      <Modal
        title="Reabrir OS?"
        visible={isModalReabrirVisible}
        onOk={handleOkModalReabrir}
        onCancel={handleCancelModalReabrir}
      >
        <p>Você tem certeza que deseja reabrir esta OS?</p>
        <p>
          Este processo fará com que ela retorne para a lista de OS abertas.
        </p>
      </Modal>

      <Modal
        title="Logs de alterações de Status de Liberação"
        visible={isModalLogStatusLiberacaoVisible}
        onOk={handleOkModalLog}
        onCancel={handleCancelModalLog}
        width={1000}
      >
        {logsAlterSituacao.map((log, index) => (
          // eslint-disable-next-line react/jsx-key
          <LineLog log={log} />
        ))}
      </Modal>

      <ImagesOSList />

      <InstrucoesOs id_os={os.id_os} />

      <ListAttendances id_os={os.id_os} />
    </Container>
  );
}

export default SectionOS;
