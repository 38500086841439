import React from 'react';
import {
  ContainerContent,
  Columm,
  BoxInfo,
  TitleInfo,
  DescriptionInfo,
} from './styles';

const ContentPessoal = ({ collaborator }) => {
  return (
    <ContainerContent>
      <Columm>
        <BoxInfo>
          <TitleInfo>Local de nascimento</TitleInfo>
          <DescriptionInfo>{collaborator.local_nascimento}</DescriptionInfo>
        </BoxInfo>

        <BoxInfo>
          <TitleInfo>CNH</TitleInfo>
          <DescriptionInfo>{collaborator.cnh}</DescriptionInfo>
        </BoxInfo>
      </Columm>

      <Columm>
        <BoxInfo>
          <TitleInfo>Nacionalidade</TitleInfo>
          <DescriptionInfo>{collaborator.nacionalidade}</DescriptionInfo>
        </BoxInfo>

        <BoxInfo>
          <TitleInfo>Grau de instrução</TitleInfo>
          <DescriptionInfo>{collaborator.grau_instrucao}</DescriptionInfo>
        </BoxInfo>
      </Columm>

      <Columm>
        <BoxInfo>
          <TitleInfo>Cor</TitleInfo>
          <DescriptionInfo>{collaborator.cor}</DescriptionInfo>
        </BoxInfo>

        <BoxInfo>
          <TitleInfo>Estado civil</TitleInfo>
          <DescriptionInfo>{collaborator.estado_civil}</DescriptionInfo>
        </BoxInfo>
      </Columm>
    </ContainerContent>
  );
};

export default ContentPessoal;
