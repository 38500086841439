import React from 'react';
import {
  Container,
  Line,
  Box,
  BoxDate,
  BoxSign,
  BoxText,
  BoxRubrica,
  BoxMonthly,
  BoxHour,
  BoxTitle,
} from './styles';

export default function MonthlyControl() {
  const meses = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ];

  return (
    <Container>
      <Line>
        <BoxDate>
          <BoxTitle>Dia</BoxTitle>
        </BoxDate>
        <BoxMonthly>
          <BoxTitle>Mês</BoxTitle>
        </BoxMonthly>
        <BoxHour>
          <BoxTitle>Início</BoxTitle>
        </BoxHour>
        <BoxHour>
          <BoxTitle>Conclusão:</BoxTitle>
        </BoxHour>
        <BoxRubrica>
          <BoxTitle>Rubrica Téc:</BoxTitle>
        </BoxRubrica>
        <BoxSign>
          <BoxTitle>Assinatura do Responsável:</BoxTitle>
        </BoxSign>
      </Line>

      {meses.map(mes => (
        <Line key={mes}>
          <BoxDate>
            <BoxTitle />
          </BoxDate>
          <BoxMonthly>
            <BoxTitle>{mes}.</BoxTitle>
          </BoxMonthly>
          <BoxHour>
            <BoxTitle />
          </BoxHour>
          <BoxHour>
            <BoxTitle />
          </BoxHour>
          <BoxRubrica>
            <BoxTitle />
          </BoxRubrica>
          <BoxSign>
            <BoxTitle />
          </BoxSign>
        </Line>
      ))}

      <BoxTitle>Observações:</BoxTitle>
    </Container>
  );
}
