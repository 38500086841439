import React, { useState } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import Drawer from '../../components/Drawer';
import List from './components/List';
import Description from './components/Description';
import { UserAddIcon } from '@heroicons/react/outline';
import SlideOverUserClient from './components/SlideOverUserClient';

export default function UsersClientManager() {
  const [id_user_select, setId_user_select] = useState<string | null>(null);
  const [openSlider, setOpenSlider] = useState(false);
  const [refresh, setRefresh] = useState<number>(0);

  function editUser(id: string): void {
    setId_user_select(id);

    setOpenSlider(true);
  }

  function newUser(): void {
    setId_user_select(null);

    setOpenSlider(true);
  }

  return (
    <Drawer page="Usuários de Clientes">
      <SlideOverUserClient
        openSliderUserClient={openSlider}
        setOpenSliderUserClient={setOpenSlider}
        id={id_user_select}
        setId_user_select={setId_user_select}
        setRefresh={setRefresh}
        refresh={refresh}
      />

      <div className="flex-1 relative z-0 flex overflow-hidden">
        <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last bg-white">
          <nav
            className="flex items-start px-4 py-3 sm:px-6 lg:px-8 xl:hidden"
            aria-label="Breadcrumb"
          >
            <a
              href="#"
              className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900"
            >
              <ChevronLeftIcon
                className="-ml-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <span>Usuários</span>
            </a>
          </nav>

          <Description id_user_select={id_user_select} editUser={editUser} />
        </main>
        <aside className="hidden xl:order-first xl:flex xl:flex-col flex-shrink-0 h-screen w-96 border-2 border-gray-300 rounded bg-white">
          <div className="px-6 pt-6 pb-4 border-b-2">
            <button
              type="button"
              onClick={() => newUser()}
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <UserAddIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              Criar Novo
            </button>

            {/* <form className="mt-4 flex space-x-4" action="#">
              <div className="flex-1 min-w-0">
                <label htmlFor="search" className="sr-only">
                  Buscar
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="search"
                    name="search"
                    id="search"
                    className="focus:ring-pink-500 focus:border-pink-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Buscar"
                  />
                </div>
              </div>
              <button
                type="submit"
                className="inline-flex justify-center px-3.5 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
              >
                <FilterIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="sr-only">Buscar</span>
              </button>
            </form> */}
          </div>

          <List setId_user_select={setId_user_select} refresh={refresh} />
        </aside>
      </div>
    </Drawer>
  );
}
