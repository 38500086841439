import React from 'react';
import Drawer from '../../components/Drawer';
import { Body } from './Body';

export default function ConsultarOs() {
  return (
    <Drawer page="OS">
      <Body />
    </Drawer>
  );
}
