/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  CheckCircleOutlined,
  DollarOutlined,
  TagsOutlined,
  AreaChartOutlined,
  SyncOutlined,
  CheckOutlined,
} from '@ant-design/icons';

import { Container, HrInactive, HrActive, Box, LineBox } from './styles';
import api from '../../../../../services/api';
import { useOSView } from '../../../../../contexts/OSView';

export default function StepsRelease() {
  const [listReqPecas, setListReqPecas] = useState([]);
  const [validado, setValidado] = useState(false);
  const [aprovado, setAprovado] = useState(false);
  const [separado, setSeparado] = useState(false);
  const [concluido, setConcluido] = useState(false);
  const [faturado, setFaturado] = useState(false);

  const { id_os_view, refresh } = useOSView();

  useEffect(() => {
    getReqPecas();
  }, [id_os_view, refresh]);

  async function getReqPecas() {
    try {
      const response = await api.get(`req_peca?id_os=${id_os_view}`);
      setListReqPecas(response.data.data);

      if (response.data.data) setSteps(response.data.data);
    } catch (err) {
      return;
    }
  }

  function setSteps(list) {
    verifyValidacao(list[0].validado);
    verifyAprovacao(list[0].aprovado);
    verifySeparacao(list);
    verifyConcluida(list[0].status);
    verifyFaturamento(list[0].faturado);
  }

  function verifyValidacao(validado) {
    if (validado) {
      setValidado(true);
    } else {
      setValidado(false);
    }
  }

  function verifyAprovacao(aprovado) {
    if (aprovado) {
      setAprovado(true);
    } else {
      setAprovado(false);
    }
  }

  async function verifySeparacao(list) {
    let itensTotal = list.length;

    let numItensSeparados = 0;

    await list.map(function (req_peca) {
      if (req_peca.separado) numItensSeparados++;
    });

    if (numItensSeparados === itensTotal) {
      setSeparado(true);
    } else {
      setSeparado(false);
    }
  }

  function verifyConcluida(status) {
    if (status === 'concluido') {
      setConcluido(true);
    } else {
      setConcluido(false);
    }
  }

  function verifyFaturamento(faturado) {
    if (faturado) {
      setFaturado(true);
    } else {
      setFaturado(false);
    }
  }

  return (
    <>
      {listReqPecas.length > 0 ? (
        <Container>
          <Box>
            <LineBox>
              {validado ? (
                <CheckCircleOutlined
                  style={{ fontSize: 30, color: '#6D9773', marginLeft: 10 }}
                />
              ) : null}

              {!validado ? (
                <SyncOutlined
                  style={{ fontSize: 16, color: '#6D9773', marginLeft: 10 }}
                />
              ) : null}
            </LineBox>
            <LineBox>
              {validado ? (
                <text style={{ color: '#6D9773' }}>Validado</text>
              ) : null}

              {!validado ? (
                <text style={{ color: '#DADADA' }}>Validado</text>
              ) : null}
            </LineBox>
          </Box>
          {validado ? <HrActive /> : <HrInactive />}
          <Box>
            <LineBox>
              {aprovado ? (
                <DollarOutlined
                  style={{ fontSize: 30, color: '#6D9773', marginLeft: 10 }}
                />
              ) : null}

              {!aprovado ? (
                <DollarOutlined
                  style={{ fontSize: 30, color: '#DADADA', marginLeft: 10 }}
                />
              ) : null}

              {!aprovado && validado ? (
                <SyncOutlined
                  style={{ fontSize: 16, color: '#6D9773', marginLeft: 10 }}
                />
              ) : null}
            </LineBox>
            <LineBox>
              {aprovado ? (
                <text style={{ color: '#6D9773' }}>Aprovado</text>
              ) : null}

              {!aprovado ? (
                <text style={{ color: '#DADADA' }}>Aprovado</text>
              ) : null}
            </LineBox>
          </Box>
          {aprovado ? <HrActive /> : <HrInactive />}
          <Box>
            <LineBox>
              {separado ? (
                <TagsOutlined
                  style={{ fontSize: 30, color: '#6D9773', marginLeft: 10 }}
                />
              ) : null}

              {!separado ? (
                <TagsOutlined
                  style={{ fontSize: 30, color: '#DADADA', marginLeft: 10 }}
                />
              ) : null}

              {!separado && aprovado && validado ? (
                <SyncOutlined
                  style={{ fontSize: 16, color: '#6D9773', marginLeft: 10 }}
                />
              ) : null}
            </LineBox>
            <LineBox>
              {separado ? (
                <text style={{ color: '#6D9773' }}>Separado</text>
              ) : null}

              {!separado ? (
                <text style={{ color: '#DADADA' }}>Separado</text>
              ) : null}
            </LineBox>
          </Box>
          {separado ? <HrActive /> : <HrInactive />}
          <Box>
            <LineBox>
              {concluido ? (
                <CheckOutlined
                  style={{ fontSize: 30, color: '#6D9773', marginLeft: 10 }}
                />
              ) : null}

              {!concluido ? (
                <CheckOutlined
                  style={{ fontSize: 30, color: '#DADADA', marginLeft: 10 }}
                />
              ) : null}

              {!concluido && separado && aprovado && validado ? (
                <SyncOutlined
                  style={{ fontSize: 16, color: '#6D9773', marginLeft: 10 }}
                />
              ) : null}
            </LineBox>
            <LineBox>
              {concluido ? (
                <text style={{ color: '#6D9773' }}>Concluído</text>
              ) : null}

              {!concluido ? (
                <text style={{ color: '#DADADA' }}>Concluído</text>
              ) : null}
            </LineBox>
          </Box>
          {concluido ? <HrActive /> : <HrInactive />}
          <Box>
            <LineBox>
              {faturado ? (
                <AreaChartOutlined
                  style={{ fontSize: 30, color: '#6D9773', marginLeft: 10 }}
                />
              ) : null}

              {!faturado ? (
                <AreaChartOutlined
                  style={{ fontSize: 30, color: '#DADADA', marginLeft: 10 }}
                />
              ) : null}

              {!faturado && concluido && separado && aprovado && validado ? (
                <SyncOutlined
                  style={{ fontSize: 16, color: '#6D9773', marginLeft: 10 }}
                />
              ) : null}
            </LineBox>
            <LineBox>
              {faturado ? (
                <text style={{ color: '#6D9773' }}>Faturado</text>
              ) : null}

              {!faturado ? (
                <text style={{ color: '#DADADA' }}>Faturado</text>
              ) : null}
            </LineBox>
          </Box>
        </Container>
      ) : null}
    </>
  );
}
