import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
`;

export const List_container = styled.div`
  flex-direction: row;
  background-color: red;
`;

export const BodyList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
`;

export const FormFilter = styled.div`
  font-size: 12px;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
`;

export const WindowsViewContainer = styled.div`
  min-width: 400px;
  background-color: #fff;
  border: 1px solid #9e9e9e;
`;

export const BoxViewContainer = styled.div``;
