import React from 'react';

interface IProps {
  type_report_email: string[];
  setType_report_email(any): void;
  type_report_whatsapp: string[];
  setType_report_whatsapp(any): void;
}

export default function NotificationsSection(props: IProps) {
  const {
    type_report_email,
    setType_report_email,
    type_report_whatsapp,
    setType_report_whatsapp,
  } = props;

  function handleChange(report: string, type: string): void {
    switch (report) {
      case 'type_report_email':
        if (type_report_email?.includes(type)) {
          const list = type_report_email.filter(item => item !== type);
          setType_report_email(list);
        } else {
          setType_report_email([...type_report_email, type]);
        }

        break;

      case 'type_report_whatsapp':
        if (type_report_whatsapp?.includes(type)) {
          const list = type_report_whatsapp.filter(item => item !== type);
          setType_report_whatsapp(list);
        } else {
          setType_report_whatsapp([...type_report_whatsapp, type]);
        }

        break;
    }
  }

  return (
    <div className="pt-8">
      <div>
        <h3 className="text-lg leading-5 font-medium text-gray-900">
          Notificações
        </h3>
        <p className="text-xs text-gray-500">
          Selecione os tipos de relatórios que este contato deve receber e por
          qual meio.
        </p>
      </div>
      <div className="mt-6">
        <fieldset className="mt-4">
          <legend className="text-sm font-medium text-gray-900">
            E-mail (principal)
          </legend>
          <div className="mt-1 space-y-1">
            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="resume_pos_preventive"
                  name="resume_pos_preventive"
                  type="checkbox"
                  checked={
                    type_report_email?.includes('resume_pos_preventive')
                      ? true
                      : false
                  }
                  onChange={() =>
                    handleChange('type_report_email', 'resume_pos_preventive')
                  }
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="resume_pos_preventive"
                  className="font-medium text-gray-700"
                >
                  Relatório após manutenção preventiva
                </label>
                <p className="text-gray-400 text-xs">
                  Receberá um relatório após a manutenção preventiva.
                </p>
              </div>
            </div>
            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="proposal_partreq"
                  name="proposal_partreq"
                  type="checkbox"
                  checked={
                    type_report_email?.includes('proposal_partreq')
                      ? true
                      : false
                  }
                  onChange={() =>
                    handleChange('type_report_email', 'proposal_partreq')
                  }
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="proposal_partreq"
                  className="font-medium text-gray-700"
                >
                  Orçamento de requisição de peças{' '}
                  <span className="inline-flex items-center px-2 py-0.25 rounded text-xs font-medium bg-red-100 text-red-900">
                    Cuidado
                  </span>
                </label>
                <p className="text-gray-400 text-xs">
                  O contato poderá receber orçamentos de requisições e
                  aprová-los.
                </p>
              </div>
            </div>
            {/* <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="resume_pos_attendance_os"
                  name="resume_pos_attendance_os"
                  type="checkbox"
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="resume_pos_attendance_os"
                  className="font-medium text-gray-700"
                >
                  Relatório após atendimento de OS
                </label>
                <p className="text-gray-400 text-xs">
                  Receberá um relatório após
                  <b> cada atendimento</b> de OS (exceto chamados).
                </p>
              </div>
            </div>

            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="resume_open_called"
                  name="resume_open_called"
                  type="checkbox"
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="resume_open_called"
                  className="font-medium text-gray-700"
                >
                  Alerta de abertura de chamado
                </label>
                <p className="text-gray-400 text-xs">
                  Receberá sempre que for aberto um chamado.
                </p>
              </div>
            </div>
            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="resume_pos_attendance_called"
                  name="resume_pos_attendance_called"
                  type="checkbox"
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="resume_pos_attendance_called"
                  className="font-medium text-gray-700"
                >
                  Relatório após atendimento de chamado
                </label>
                <p className="text-gray-400 text-xs">
                  Receberá um relatório após
                  <b> cada atendimento</b> de chamado.
                </p>
              </div>
            </div>
            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="resume_schedule_preventive"
                  name="resume_schedule_preventive"
                  type="checkbox"
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="resume_schedule_preventive"
                  className="font-medium text-gray-700"
                >
                  Alerta de agendamento de preventiva
                </label>
                <p className="text-gray-400 text-xs">
                  Receberá um alerta com a data e horário programado para MP.
                </p>
              </div>
            </div> */}
          </div>
        </fieldset>
      </div>
      <div className="mt-3">
        <fieldset className="mt-4">
          <legend className="text-sm font-medium text-gray-900">
            WhatsApp
          </legend>
          <div className="mt-1 space-y-1">
            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="resume_pos_preventive_wpp"
                  name="resume_pos_preventive_wpp"
                  type="checkbox"
                  checked={
                    type_report_whatsapp?.includes('resume_pos_preventive')
                      ? true
                      : false
                  }
                  onChange={() =>
                    handleChange(
                      'type_report_whatsapp',
                      'resume_pos_preventive',
                    )
                  }
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="resume_pos_preventive_wpp"
                  className="font-medium text-gray-700"
                >
                  Relatório após manutenção preventiva
                </label>
                <p className="text-gray-400 text-xs">
                  Receberá um relatório após a manutenção preventiva.
                </p>
              </div>
            </div>
            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="proposal_partreq_wpp"
                  name="proposal_partreq_wpp"
                  type="checkbox"
                  checked={
                    type_report_whatsapp?.includes('proposal_partreq')
                      ? true
                      : false
                  }
                  onChange={() =>
                    handleChange('type_report_whatsapp', 'proposal_partreq')
                  }
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="proposal_partreq_wpp"
                  className="font-medium text-gray-700"
                >
                  Orçamento de requisição de peças{' '}
                  <span className="inline-flex items-center px-2 py-0.25 rounded text-xs font-medium bg-red-100 text-red-900">
                    Cuidado
                  </span>
                </label>
                <p className="text-gray-400 text-xs">
                  O contato poderá receber orçamentos de requisições e
                  aprová-los.
                </p>
              </div>
            </div>
            {/* <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="resume_pos_attendance_os_wpp"
                  name="resume_pos_attendance_os_wpp"
                  type="checkbox"
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="resume_pos_attendance_os_wpp"
                  className="font-medium text-gray-700"
                >
                  Relatório após atendimento de OS
                </label>
                <p className="text-gray-400 text-xs">
                  Receberá um relatório após
                  <b> cada atendimento</b> de OS (exceto chamados).
                </p>
              </div>
            </div>

            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="resume_open_called_wpp"
                  name="resume_open_called_wpp"
                  type="checkbox"
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="resume_open_called_wpp"
                  className="font-medium text-gray-700"
                >
                  Alerta de abertura de chamado
                </label>
                <p className="text-gray-400 text-xs">
                  Receberá sempre que for aberto um chamado.
                </p>
              </div>
            </div>
            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="resume_pos_attendance_called_wpp"
                  name="resume_pos_attendance_called_wpp"
                  type="checkbox"
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="resume_pos_attendance_called_wpp"
                  className="font-medium text-gray-700"
                >
                  Relatório após atendimento de chamado
                </label>
                <p className="text-gray-400 text-xs">
                  Receberá um relatório após
                  <b> cada atendimento</b> de chamado.
                </p>
              </div>
            </div>
            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="resume_schedule_preventive_wpp"
                  name="resume_schedule_preventive_wpp"
                  type="checkbox"
                  className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="resume_schedule_preventive_wpp"
                  className="font-medium text-gray-700"
                >
                  Alerta de agendamento de preventiva
                </label>
                <p className="text-gray-400 text-xs">
                  Receberá um alerta com a data e horário programado para MP.
                </p>
              </div>
            </div> */}
          </div>
        </fieldset>
      </div>
    </div>
  );
}
