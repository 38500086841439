import React, { useEffect } from 'react';
import { List, Spin } from 'antd';
import { Container, TextFeedback, LoadingComponent } from './styles';

import { useNotifications } from '../../contexts/Notifications';

import Notify from './components/Notify';

export default function NotificationsWindow(props) {
  const { getNotifications, data, loading } = useNotifications();

  useEffect(() => {
    getNotifications();
  }, []);

  const antIcon = <LoadingComponent spin />;

  return (
    <Container>
      {data.length === 0 && !loading ? (
        <TextFeedback>Não há Notificações</TextFeedback>
      ) : (
        <></>
      )}

      {loading ? (
        <Spin indicator={antIcon} />
      ) : (
        <List
          inverted
          initialNumToRender={100}
          dataSource={data}
          renderItem={item => <Notify notify={item} />}
        />
      )}
    </Container>
  );
}
