import styled from 'styled-components';

export const FormFilterTop = styled.div`
  font-size: 12px;
  padding: 6px;
  border-radius: 4px;
  background-color: #f5f5f5;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
`;

export const Resume = styled.div`
  font-size: 11px;
  color: #757575;
`;
