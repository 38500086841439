import React from 'react';
import Drawer from '../../components/Drawer';
import List from './List/List';

const Preventivas: React.FC = () => {
  return (
    <Drawer page="Preventivas Realizadas">
      <List />
    </Drawer>
  );
};

export default Preventivas;
