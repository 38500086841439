import React from 'react';
import { Link } from 'react-router-dom';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const RowTable = ({ part, openPart }) => {
  return (
    <TableRow key={part.id_part} style={{ height: 40 }}>
      <TableCell
        style={{ fontSize: 16, fontWeight: 600 }}
        align="left"
        onClick={() => openPart(part.id_part)}
      >
        {part.id_part}
      </TableCell>
      <TableCell
        style={{ fontSize: 12 }}
        align="left"
        component="th"
        scope="row"
        onClick={() => openPart(part.id_part)}
      >
        {part.description}
      </TableCell>
      <TableCell style={{ fontSize: 12 }} align="left">
        {part.price ? 'R$ ' + part.cost_value : null}
      </TableCell>
      <TableCell style={{ fontSize: 12 }} align="left">
        {part.current_quantity}
      </TableCell>
      <TableCell style={{ fontSize: 12 }} align="left">
        {part.price ? 'R$ ' + part.price : null}
      </TableCell>
      <TableCell style={{ fontSize: 12 }} align="left"></TableCell>
    </TableRow>
  );
};

export default RowTable;
