/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useState, useContext, useEffect } from 'react';
import api from '../services/api';
import socket from '../services/socket';

import { useAuth } from './Auth';

const NotificationsContext = createContext<any>({});

export const NotificationsProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [viewNotifyWindow, setViewNotifyWindow] = useState(false);
  const [numNotif, setNumNotif] = useState(0);
  const [data, setData] = useState([]);

  const { user } = useAuth();

  useEffect(() => {
    if (viewNotifyWindow) {
      socket.on(`list_notify`, async notify => {
        await getNotifications();
      });
    } else {
      socket.off(`list_notify`);
    }
  }, [viewNotifyWindow]);

  async function countNotifications() {
    try {
      const response = await api.get(
        `notification/countNotView?id_user=${user.id_usuario}&user_group=${user.id_grupo_usuario}`,
      );
      setNumNotif(response.data);
    } catch (err) {
      setNumNotif(0);
    }
  }

  async function getNotifications() {
    try {
      const response = await api.get(
        `notification?id_user=${user.id_usuario}&user_group=${user.id_grupo_usuario}`,
      );
      setData(response.data);
      setNumNotif(0);
      setLoading(false);
    } catch (err) {
      return;
    }
  }

  return (
    <NotificationsContext.Provider
      value={{
        setLoading,
        loading,
        setViewNotifyWindow,
        viewNotifyWindow,
        numNotif,
        countNotifications,
        getNotifications,
        data,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export function useNotifications() {
  const context = useContext(NotificationsContext);
  return context;
}
