import api from '../../../../../../services/api';

async function GetListRecentCallers(id_cliente: number) {
  if (!id_cliente) return;

  try {
    const response = await api.get(
      `os/called/recent_callers_name?id_cliente=${id_cliente}`,
    );

    return response.data;
  } catch (e) {
    return;
  }
}

export default GetListRecentCallers;
