import React from 'react';
import NotificationsPreferences from './components/NotificationsPreferences';
import ConfigsPreferences from './components/ConfigsPreferences';

interface IProps {
  id_funcionario?: number;
  setId_funcionario?(id_funcionario: number): void;
}

export default function FormPreferences({ id_funcionario }: IProps) {
  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <ConfigsPreferences id_funcionario={id_funcionario} />

      <NotificationsPreferences id_funcionario={id_funcionario} />
    </div>
  );
}
