import React from 'react';
import Drawer from '../../components/Drawer';
import List from './List/List';

const PontoEletronico: React.FC = () => {
  return (
    <Drawer page="Ponto eletrônico">
      <List />
    </Drawer>
  );
};

export default PontoEletronico;
