import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import InputMask from 'react-input-mask';
import api from '../../../../services/api';
import { useClientRegister } from '../../../../contexts/ClientRegister';
import { IContact } from '../../../../types/Contact/IContact';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../../functions/MessageFeedback';
import { IPivotContactCliente } from '../../../../types/Contact/IPivotContactCliente';
import { Popconfirm } from 'antd';
import NotificationsSection from './NotificationsSection';
interface IProps {
  id_contact?: number;
  setId_contact_view: unknown;
}

export default function SlideOverContact(props: IProps) {
  const [contact, setContact] = useState<IContact>();
  const [cargo, setCargo] = useState('sindico');
  const [pivotContact, setPivotContact] = useState<IPivotContactCliente>();
  const [type_report_email, setType_report_email] = useState<string[]>([]);
  const [type_report_whatsapp, setType_report_whatsapp] = useState<string[]>(
    [],
  );

  const { id_contact } = props;

  const { openSliderContact, setOpenSliderContact, id_cliente, setLoading } =
    useClientRegister();

  useEffect(() => {
    if (id_contact) {
      getContact();

      getPivotContact();
    } else {
      setContact(null);
      setCargo(null);

      setPivotContact(null);
      setType_report_email([]);
      setType_report_whatsapp([]);
    }
  }, [id_contact]);

  async function getContact() {
    try {
      const response = await api.get(`contact/${id_contact}`);

      setContact(response.data);
    } catch (err) {
      feedbackError('Erro ao carregar o contato. Tente novamente mais tarde');
    }
  }

  async function getPivotContact() {
    try {
      const response = await api.get(
        `contactCliente/${id_contact}/${id_cliente}`,
      );

      const { data } = response;

      if (data?.type_report_email) setType_report_email(data.type_report_email);
      if (data?.type_report_whatsapp)
        setType_report_whatsapp(data.type_report_whatsapp);

      setPivotContact(data);

      setCargo(data.cargo);
    } catch {
      feedbackError('Erro ao carregar alguns dados do contato');
    }
  }

  async function handleChange(event) {
    setContact({
      ...contact,
      [event.target.name]: event.target.value,
    });
  }

  async function handleSubmit() {
    if (!contact.nome || !cargo) {
      feedbackError('Campos nome e cargo são obrigatórios');
      return;
    }

    let { cpf, whatsapp, telefone1, telefone2, telefone3 } = contact;

    cpf = cpf?.replace(/[^0-9]/g, '');
    telefone1 = telefone1?.replace(/[^0-9]/g, '');
    telefone2 = telefone2?.replace(/[^0-9]/g, '');
    telefone3 = telefone3?.replace(/[^0-9]/g, '');
    whatsapp = whatsapp?.replace(/[^0-9]/g, '');

    const body = {
      nome: contact.nome,
      apelido: contact.apelido,
      cpf,
      telefone1,
      telefone2,
      telefone3,
      whatsapp,
      email1: contact.email1,
      email2: contact.email2,
      data_nascimento: contact.data_nascimento,
      observacao: contact.observacao,
    };

    setLoading(true);

    if (id_contact) {
      try {
        await api.put(`contact/${id_contact}`, body);

        const body_pivot = {
          id_contact: pivotContact.id_contact,
          id_cliente: pivotContact.id_cliente,
          cargo,
          type_report_email,
          type_report_whatsapp,
          status: 'enabled',
        };

        if (pivotContact) await api.put(`contactCliente`, body_pivot);

        feedbackSuccess('Salvo com sucesso');

        setLoading(false);
      } catch (err) {
        feedbackError('Alguns dados não puderam ser salvos');
      }
    } else {
      try {
        const id_created = await api.post(`contact`, body);

        const body_pivot = {
          id_cliente,
          id_contact: id_created.data[0],
          cargo,
          status: 'enabled',
        };

        await api.post(`contactCliente`, body_pivot);

        feedbackSuccess('Salvo com sucesso');

        setLoading(false);
      } catch (err) {
        feedbackError('Não foi possível criar');
      }
    }
    setLoading(false);

    setOpenSliderContact(false);
  }

  async function disablePivot() {
    setLoading(true);

    try {
      await api.put(
        `contactCliente/disable/${pivotContact?.id_contact}/${pivotContact?.id_cliente}`,
      );
    } catch (err) {
      feedbackError('Não foi possível desativar este contato');
    }

    setLoading(false);
  }

  const onChangeCargo = e => {
    setCargo(e.target.value);
  };

  return (
    <Transition.Root show={openSliderContact} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={setOpenSliderContact}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-md">
                <span className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div className="h-0 flex-1 overflow-y-auto">
                    <div className="bg-blue-700 py-2 px-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">
                          {!id_contact ? 'Novo Contato' : 'Editar Contato'}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-blue-700 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpenSliderContact(false)}
                          >
                            <span className="sr-only">Fechar painel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-blue-300">
                          Dados de contato
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                      <div className="divide-y divide-gray-200 px-4 sm:px-6">
                        <div className="space-y-6 pt-6 pb-5">
                          <div className="flex grid-cols-2 gap-4">
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Nome completo
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="nome"
                                  id="nome"
                                  value={contact?.nome}
                                  defaultValue={contact?.nome}
                                  onChange={e => handleChange(e)}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Apelido
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="apelido"
                                  id="apelido"
                                  value={contact?.apelido}
                                  defaultValue={contact?.apelido}
                                  onChange={e => handleChange(e)}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex grid-cols-2 gap-4">
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                CPF
                              </label>
                              <div className="mt-1">
                                <InputMask
                                  type="text"
                                  mask="999.999.999-99"
                                  name="cpf"
                                  id="cpf"
                                  value={contact?.cpf}
                                  defaultValue={contact?.cpf}
                                  onChange={e => handleChange(e)}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            {/* <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Data de nascimento
                              </label>
                              <div className="mt-1">
                                <input
                                  name="data_nascimento"
                                  id="data_nascimento"
                                  value={contact?.data_nascimento}
                                  defaultValue={dataNascimentoStr}
                                  type="date"
                                  onChange={e => handleChange(e)}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div> */}
                          </div>

                          <div className="flex grid-cols-2 gap-4">
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                WhatsApp
                              </label>
                              <div className="mt-1">
                                <InputMask
                                  type="text"
                                  mask="(99) 99999-9999"
                                  name="whatsapp"
                                  id="whatsapp"
                                  value={contact?.whatsapp}
                                  defaultValue={contact?.whatsapp}
                                  onChange={e => handleChange(e)}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Telefone
                              </label>
                              <div className="mt-1">
                                <InputMask
                                  type="text"
                                  mask="(99) 99999-9999"
                                  name="telefone1"
                                  id="telefone1"
                                  value={contact?.telefone1}
                                  defaultValue={contact?.telefone1}
                                  onChange={e => handleChange(e)}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex grid-cols-2 gap-4">
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Telefone (alternativo)
                              </label>
                              <div className="mt-1">
                                <InputMask
                                  type="text"
                                  mask="(99) 99999-9999"
                                  name="telefone2"
                                  id="telefone2"
                                  value={contact?.telefone2}
                                  defaultValue={contact?.telefone2}
                                  onChange={e => handleChange(e)}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Telefone (alternativo)
                              </label>
                              <div className="mt-1">
                                <InputMask
                                  type="text"
                                  mask="(99) 99999-9999"
                                  name="telefone3"
                                  id="telefone3"
                                  value={contact?.telefone3}
                                  defaultValue={contact?.telefone3}
                                  onChange={e => handleChange(e)}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex grid-cols-2 gap-4">
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                E-mail
                              </label>
                              <div className="mt-1">
                                <input
                                  type="email"
                                  name="email1"
                                  id="email1"
                                  value={contact?.email1}
                                  defaultValue={contact?.email1}
                                  onChange={e => handleChange(e)}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                E-mail (alternativo)
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="email2"
                                  id="email2"
                                  value={contact?.email2}
                                  defaultValue={contact?.email2}
                                  onChange={e => handleChange(e)}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="observacao"
                              className="block text-sm font-medium text-gray-900"
                            >
                              Observação
                            </label>
                            <div className="mt-1">
                              <textarea
                                id="observacao"
                                name="observacao"
                                rows={2}
                                value={contact?.observacao}
                                defaultValue={contact?.observacao}
                                onChange={e => handleChange(e)}
                                className="block w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                              />
                            </div>
                          </div>
                          <div>
                            <label className="text-base font-medium text-gray-900">
                              Cargo
                            </label>
                            <p className="text-sm leading-5 text-gray-500">
                              Que função esta pessoa exerce neste condomínio?
                            </p>
                            <div className="flex grid-cols-2 gap-4">
                              <div className="mt-1 w-2/3">
                                <select
                                  id="cargo"
                                  name="cargo"
                                  style={{ backgroundColor: '#fff' }}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  value={cargo}
                                  onChange={e => onChangeCargo(e)}
                                >
                                  <option value="">Selecione</option>
                                  <option value="sindico">
                                    Síndico / Proprietário
                                  </option>
                                  <option value="subsindico">
                                    Sub-síndico
                                  </option>
                                  <option value="conselheiro">
                                    Conselheiro
                                  </option>
                                  <option value="zelador">Zelador</option>
                                  <option value="servicosgerais">
                                    Serviços gerais
                                  </option>
                                  <option value="morador">Morador</option>
                                  <option value="outro">Outro</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <NotificationsSection
                          type_report_email={type_report_email}
                          setType_report_email={setType_report_email}
                          type_report_whatsapp={type_report_whatsapp}
                          setType_report_whatsapp={setType_report_whatsapp}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-shrink-0 justify-between px-4 py-4">
                    {pivotContact?.status === 'enabled' ? (
                      <Popconfirm
                        title="Você tem certeza que deseja desativar este contato para este cliente?"
                        onConfirm={disablePivot}
                        okText="Sim"
                        cancelText="Não"
                      >
                        <a>
                          <div className="flex items-center">Desativar</div>
                        </a>
                      </Popconfirm>
                    ) : (
                      'Não vinculado'
                    )}
                    <div>
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        onClick={() => setOpenSliderContact(false)}
                      >
                        Cancelar
                      </button>
                      <button
                        onClick={() => handleSubmit()}
                        className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </span>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
