import { intervalToDuration } from 'date-fns';
import api from '../services/api';
import { ICollaboratorMin } from '../types/Collaborator/ICollaboratorMin';

async function GetNameFunc(id: number): Promise<ICollaboratorMin | undefined> {
  if (!id) return;

  const storagedCollaborator = localStorage.getItem(
    `@elevsis:collaborator/min/${id}`,
  );

  if (storagedCollaborator) {
    const collaborator = await JSON.parse(storagedCollaborator);

    const oldCache = intervalToDuration({
      start: new Date(collaborator.oldCache),
      end: new Date(),
    });

    if (oldCache.hours < 1) {
      return collaborator;
    } else {
      const collaborator = await getApi(id);

      return collaborator;
    }
  } else {
    const collaborator = await getApi(id);

    return collaborator;
  }

  async function getApi(id: number) {
    try {
      const response = await api.get(`collaborator/min/${id}`);

      const { data } = response;

      response.data.oldCache = new Date();

      localStorage.setItem(
        `@elevsis:collaborator/min/${id}`,
        JSON.stringify(response.data),
      );

      return data;
    } catch (error) {
      return;
    }
  }
}

export default GetNameFunc;
