import React from 'react';
import { Container, Line, Title, Description } from './styles';

function Alter_req_peca({ log }) {
  return (
    <>
      <Container>
        {log?.old.codigo == log?.body.codigo ? null : (
          <Line>
            <Title>Código.:</Title>
            <Description>
              Foi alterado de: <b>{log?.old.codigo}</b> para{' '}
              <b>{log?.body.codigo}</b>
            </Description>
          </Line>
        )}

        {log?.old.quantidade == log?.body.quantidade ? null : (
          <Line>
            <Title>Quantidade:</Title>
            <Description>
              Foi alterado de: <b>{log?.old.quantidade}</b> para{' '}
              <b>{log?.body.quantidade}</b>
            </Description>
          </Line>
        )}

        {log?.old.nome_peca == log?.body.nome_peca ? null : (
          <Line>
            <Title>Nome da Peça:</Title>
            <Description>
              Foi alterado de: <b>{log?.old.nome_peca}</b> para{' '}
              <b>{log?.body.nome_peca}</b>
            </Description>
          </Line>
        )}

        {log?.old.valor_unit == log?.body.valor_unit ? null : (
          <Line>
            <Title>Valor Unit.:</Title>
            <Description>
              Foi alterado de: <b>R$ {log?.old.valor_unit}</b> para{' '}
              <b>R$ {log?.body.valor_unit}</b>
            </Description>
          </Line>
        )}

        {log?.old.valor_total == log?.body.valor_total ? null : (
          <Line>
            <Title>Valor Total do Item:</Title>
            <Description>
              Foi alterado de: <b>R$ {log?.old.valor_total}</b> para{' '}
              <b>R$ {log?.body.valor_total}</b>
            </Description>
          </Line>
        )}

        <Line />

        <Line>
          <Title>Descrição:</Title>
          <Description>{log.description}</Description>
        </Line>

        <Line>
          <Description>Item original: {log.body.nome_peca}</Description>
        </Line>
      </Container>
    </>
  );
}

export default Alter_req_peca;
