import React from 'react';

const tabs = [{ name: 'Geral', href: '#', current: true }];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function TabMenu() {
  return (
    <div>
      <div>
        <div className="max-w-5xl pt-4 sm:pt-1 2xl:pt-5 bg-gray-100 border-b border-blue-200 mx-auto px-4 sm:px-6 lg:px-8">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map(tab => (
              <a
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current
                    ? 'border-blue-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
