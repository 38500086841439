/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import RowTable from './RowTable';
import api from '../../../../services/api';
import { IOsReport } from '../../@types/IOsReport';
import { feedbackError } from '../../../../functions/MessageFeedback';
import InputSelectDate from './InputSelectDate';

export default function ChamadosReport() {
  const [list, setList] = useState<IOsReport[] | []>([]);
  const [groupBy, setGroupBy] = useState('id_cliente');
  const [filter_created_date_start, setFilter_created_date_start] =
    useState('');
  const [filter_created_date_end, setFilter_created_date_end] = useState('');

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    getList();
  }, [groupBy, filter_created_date_start, filter_created_date_end]);

  async function getList() {
    if (!filter_created_date_start || !filter_created_date_end) return;

    try {
      const response = await api.get(
        `os/report?chamado=true&filter_created_date_start=${filter_created_date_start}&filter_created_date_end=${filter_created_date_end}`,
      );

      const { data } = response.data;

      //Caso necessite ordenar
      // data.sort((a, b) => {
      //   if (a.id_cliente < b.id_cliente) {
      //     return -1;
      //   }
      //   if (a.id_cliente > b.id_cliente) {
      //     return 1;
      //   }
      //   return 0;
      // });

      setList(data);
    } catch (error) {
      feedbackError('Não foi possível carregar a lista');
    }
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900"></h1>
          <p className="mt-4 text-sm text-gray-700 inline-flex">
            <InputSelectDate
              setFilter_created_date_start={setFilter_created_date_start}
              setFilter_created_date_end={setFilter_created_date_end}
            />
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
      </div>
      <div className="mt-4 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Cód.
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Edificío
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Elevador
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Relato
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Parecer
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Criação
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {list.map((os: IOsReport) => (
                    <RowTable key={os.id_os} os={os} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
