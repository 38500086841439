/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { feedbackError } from '../../../../functions/MessageFeedback';
import api from '../../../../services/api';
import TransformToCodes from '../../functions/TransformToCodes';
import { CheckIcon, TrashIcon, XCircleIcon } from '@heroicons/react/solid';
import { IOutfitter } from '../../../../types/Outfitter/IOutfitter';
import GetNameFuncByUser from '../../../../functions/GetNameFuncByUser';
import convertDate from '../../../../functions/ConvertDate';
import convertDateTime from '../../../../functions/ConvertDateTime';
import ListItemsShow from '../ListItemsShow';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import { Tooltip } from 'antd';
import { useAuth } from '../../../../contexts/Auth';

interface IProps {
  open: boolean;
  setOpen(open: boolean): void;
  codesPurchase?: string;
  getList(): void;
}

export default function SlideOverShow(props: IProps) {
  const [loading, setLoading] = useState(false);
  const [outfitter, setOutfitter] = useState<IOutfitter | null>();
  const [listItems, setListItems] = useState<any>([]);
  const [approved, setApproved] = useState<boolean>();
  const [approvedBy, setApprovedBy] = useState('');
  const [approvedDate, setApprovedDate] = useState('');
  const [sellerName, setSellerName] = useState('');
  const [purchaseBy, setPurchaseBy] = useState('');
  const [purchaseDate, setPurchaseDate] = useState('');
  const [formPayment, setFormPayment] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [shippingFare, setShippingFare] = useState('');

  const { open, setOpen, codesPurchase, getList } = props;

  const { user } = useAuth();

  useEffect(() => {
    if (!open) {
      setOutfitter(null);
    } else {
      getPurchaseOrder();
    }
  }, [open]);

  async function getPurchaseOrder() {
    if (!codesPurchase) return;

    const { sequencialCode, yearCode } = await TransformToCodes(codesPurchase);

    try {
      const response = await api.get(
        `purchase_order/bycode?sequencialCode=${sequencialCode}&yearCode=${yearCode}`,
      );

      const { data } = response;

      setApproved(data.approved);
      setSellerName(data.sellerName);
      setShippingFare(data.shippingFare);
      setFormPayment(data.formPayment);

      if (data?.approvedBy) {
        const { nome_apelido } = await GetNameFuncByUser(data.approvedBy);

        setApprovedBy(nome_apelido);
      }

      if (data?.approvedDate) {
        const approvedDate = await convertDateTime(data.approvedDate);

        setApprovedDate(approvedDate);
      }

      const { nome_apelido } = await GetNameFuncByUser(data.purchaseBy);

      setPurchaseBy(nome_apelido);

      const purchaseDate = await convertDate(data.purchaseDate);

      setPurchaseDate(purchaseDate);

      const deliveryDate = await convertDate(data.deliveryDate);

      setDeliveryDate(deliveryDate);

      if (data?.id_outfitter) getOutfitter(data.id_outfitter);

      if (data?.items) {
        setListItems(data.items);
      } else {
        setListItems([]);
      }
    } catch (error) {
      feedbackError('Erro ao carregar os dados');
      setOpen(false);
    }
  }

  async function getOutfitter(id_outfitter: number) {
    try {
      const response = await api.get(`outfitter/${id_outfitter}`);

      setOutfitter(response.data);
    } catch (err) {
      return;
    }
  }

  async function approveOrder(approved: boolean) {
    const { sequencialCode, yearCode } = await TransformToCodes(codesPurchase);

    const data = { approved };

    try {
      await api.put(
        `purchase_order/approve/${sequencialCode}/${yearCode}`,
        data,
      );

      getList();

      getPurchaseOrder();
    } catch (err) {
      return;
    }
  }

  async function removeOrder() {
    const { sequencialCode, yearCode } = await TransformToCodes(codesPurchase);

    if (approved) {
      feedbackError('Ordem aprovada não pode ser deletada.');
    } else {
      try {
        await api.delete(`purchase_order/delete/${sequencialCode}/${yearCode}`);

        getList();

        setOpen(false);
      } catch (err) {
        return;
      }
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div className="pointer-events-auto w-screen max-w-3xl">
                  <div className="h-screen flex-1 overflow-y-auto">
                    <div className="flex flex-row pt-3 px-4 sm:px-6 justify-between">
                      <div className="">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Pedido: {codesPurchase}
                        </h3>
                        <p className="mt-1 max-w-2xl text-xs text-gray-500">
                          {approved ? (
                            <>
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-lg text-xs font-medium bg-green-100 text-green-800">
                                Aprovado
                              </span>
                              <span className="text-xs ml-2">
                                Por {approvedBy}, em {approvedDate}
                              </span>

                              {user?.id_grupo_usuario <= 12 ? (
                                <Tooltip title="Desaprovar">
                                  <ConfirmDialog
                                    onConfirm={() => approveOrder(false)}
                                    title="Você confirma a desaprovação?"
                                  >
                                    <button
                                      type="button"
                                      className="inline-flex ml-3 items-center justify-center rounded-md border bg-orange-700 px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-700 focus:ring-offset-2 sm:w-auto"
                                    >
                                      <XCircleIcon
                                        className="h-4 w-4 mr-1 text-white"
                                        aria-hidden="true"
                                      />
                                      Desaprovar
                                    </button>
                                  </ConfirmDialog>
                                </Tooltip>
                              ) : null}
                            </>
                          ) : (
                            <>
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-lg text-xs font-medium bg-red-100 text-red-800">
                                Não Aprovado
                              </span>
                              {user?.id_grupo_usuario <= 12 ? (
                                <Tooltip title="Aprovar">
                                  <ConfirmDialog
                                    onConfirm={() => approveOrder(true)}
                                    title="Você confirma a aprovação?"
                                  >
                                    <button
                                      type="button"
                                      className="inline-flex ml-3 items-center justify-center rounded-md border bg-green-400 px-2 py-1 text-sm font-medium text-black shadow-sm hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-green-200 focus:ring-offset-2 sm:w-auto"
                                    >
                                      <CheckIcon
                                        className="h-4 w-4 mr-1 text-black"
                                        aria-hidden="true"
                                      />
                                      Aprovar
                                    </button>
                                  </ConfirmDialog>
                                </Tooltip>
                              ) : null}
                            </>
                          )}
                        </p>
                      </div>
                      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        {/* <button
                          type="button"
                          className="inline-flex items-center justify-center rounded-md border-2 border-blue-700 bg-white px-2 py-2 text-sm font-medium text-black shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 sm:w-auto"
                        >
                          <PrinterIcon
                            className="h-6 w-6 text-blue-700"
                            aria-hidden="true"
                          />
                        </button> */}

                        {user?.id_grupo_usuario <= 12 ? (
                          <Tooltip title="Deletar">
                            <ConfirmDialog
                              onConfirm={() => removeOrder()}
                              title="Você tem certeza que deseja remover esta ordem de compra?"
                            >
                              <button
                                type="button"
                                className="inline-flex items-center justify-center rounded-md bg-white px-2 py-2 text-sm font-medium text-black shadow-sm hover:bg-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 sm:w-auto"
                              >
                                <TrashIcon
                                  className="h-6 w-6 text-red-700"
                                  aria-hidden="true"
                                />
                              </button>
                            </ConfirmDialog>
                          </Tooltip>
                        ) : null}
                      </div>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-4 sm:px-6">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                        {outfitter?.id_outfitter ? (
                          <div className="sm:col-span-2">
                            <dt className="text-sm font-medium text-gray-500">
                              Fornecedor
                            </dt>
                            <dd className="mt-1 text-xs text-gray-900">
                              {outfitter?.nome_apelido}
                            </dd>
                            <dd className="mt-0 text-xs text-gray-900">
                              {outfitter?.logradouro}
                              {outfitter?.numero
                                ? `, ${outfitter.numero}`
                                : null}
                              {outfitter?.bairro
                                ? ` ${outfitter.bairro}`
                                : null}
                              {outfitter?.cidade
                                ? ` - ${outfitter.cidade}`
                                : null}
                              {outfitter?.estado
                                ? ` - ${outfitter.estado}`
                                : null}
                            </dd>
                            <dd className="mt-0 text-xs text-gray-900">
                              {outfitter?.fone_contato}
                              {outfitter?.email_contato
                                ? ` - ${outfitter.email_contato}`
                                : null}
                            </dd>
                          </div>
                        ) : null}
                        {sellerName ? (
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Vendedor
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {sellerName}
                            </dd>
                          </div>
                        ) : null}

                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Comprador
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {purchaseBy}
                          </dd>
                        </div>
                        {deliveryDate ? (
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Prazo de entrega
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {deliveryDate}
                            </dd>
                          </div>
                        ) : null}
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Data da compra
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {purchaseDate}
                          </dd>
                        </div>
                        {shippingFare ? (
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Frete
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {shippingFare}
                            </dd>
                          </div>
                        ) : null}
                        {formPayment ? (
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Forma de pagamento
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {formPayment}
                            </dd>
                          </div>
                        ) : null}
                      </dl>
                      <ListItemsShow listItems={listItems} />
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
