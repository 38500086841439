import React from 'react';

interface IProps {
  page: number;
  qtdPages: number;
  totalRegisters: number;
  changePage(action: string): void;
}

export default function NavBarList(props: IProps) {
  const { page, qtdPages, totalRegisters, changePage } = props;

  return (
    <nav
      className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-2"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Exibindo pág.{' '}
          <span className="font-medium">
            {page} / {qtdPages}.{' '}
          </span>
          <span className="font-medium">
            Total de registros encontrados: {totalRegisters}
          </span>
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        {page === 1 ? (
          <a className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 opacity-20">
            Anterior
          </a>
        ) : (
          <a
            onClick={() => changePage('voltar')}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Anterior
          </a>
        )}

        {qtdPages === page ? (
          <a className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 opacity-20">
            Próximo
          </a>
        ) : (
          <a
            onClick={() => changePage('avancar')}
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Próximo
          </a>
        )}
      </div>
    </nav>
  );
}
