/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Modal } from 'antd';
import { XIcon } from '@heroicons/react/solid';

interface IProps {
  open: boolean;
  setOpen(open: boolean): void;
  title?: string;
  children: any;
}

export default function ModalScreen({
  children,
  open,
  setOpen,
  title,
}: IProps) {
  return (
    <Modal
      title={title}
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      cancelText="Voltar"
      destroyOnClose={true}
      footer={null}
      width="90%"
      zIndex={10}
      closeIcon={
        <button
          type="button"
          className="inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          <XIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      }
    >
      {children}
    </Modal>
  );
}
