/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import api from '../../../../services/api';
import RowTable from './RowTable';
import { IPurchaseOrder } from '../../../../types/PurchaseOrder/IPurchaseOrder';
import { Input } from 'antd';
import ShowByCode from '../../functions/ShowByCode';
import { feedbackError } from '../../../../functions/MessageFeedback';
import SlideOver from '../SlideOver';
import TransformToCode from '../../functions/TransformToCode';
import { PencilIcon } from '@heroicons/react/solid';
import SlideOverShow from '../SlideOverShow';
import { useAuth } from '../../../../contexts/Auth';

const { Search } = Input;

export default function List() {
  const [codesPurchase, setCodesPurchase] = useState<string | null>();
  const [open, setOpen] = useState(false);
  const [openShow, setOpenShow] = useState(false);
  const [purchases, setPurchases] = useState([]);
  const [approvedStatus, setApprovedStatus] = useState('false');

  const { user } = useAuth();

  let urlFilter = ``;

  useEffect(() => {
    if (approvedStatus == 'true') urlFilter = `&approved=true`;
    if (approvedStatus == 'false') urlFilter = `&approved=false`;
    if (approvedStatus == '') urlFilter = ``;

    getList();
  }, [approvedStatus]);

  useEffect(() => {
    if (!openShow) setCodesPurchase(null);
  }, [openShow]);

  async function newPurchaseOrder() {
    try {
      const response = await api.post(`purchase_order/reserve`);

      const { sequencialCode, yearCode } = response.data;

      if (sequencialCode && yearCode) {
        const code = await TransformToCode(sequencialCode, yearCode);

        setCodesPurchase(code);

        setOpen(true);
      } else {
        setCodesPurchase(null);

        setOpen(false);

        return;
      }
    } catch (err) {
      feedbackError('Não foi possível');
    }
  }

  async function editPurchaseOrder(sequencialCode: number, yearCode: number) {
    const code = await TransformToCode(sequencialCode, yearCode);

    const purchase = await ShowByCode(code);

    if (purchase && purchase.purchaseBy === user.id_usuario) {
      setCodesPurchase(code);

      setOpen(true);
    } else {
      feedbackError('Você não pode editar esta ordem de compra');
    }
  }

  async function getList() {
    try {
      const response = await api.get(
        `purchase_order?status=enabled${urlFilter}`,
      );

      setPurchases(response.data);
    } catch (error) {
      setTimeout(() => {
        getList();
      }, 5000);

      feedbackError(
        'Não foi possível carregar a lista. Tentando novamente em alguns segundos...',
      );
    }
  }

  async function viewPurchaseOrder(code: string) {
    const purchase = await ShowByCode(code);

    if (purchase) {
      setCodesPurchase(code);

      setOpenShow(true);
    } else {
      feedbackError('Não encontrada');
    }
  }

  return (
    <div className="px-2 sm:px-4 lg:px-4">
      <div className="sm:flex sm:items-center">
        <div className="pt-4 flex flex-row sm:mt-0 sm:ml-0 sm:flex-none">
          <Search
            placeholder="Código"
            autoComplete="none"
            min="1"
            onSearch={viewPurchaseOrder}
            style={{ width: 150 }}
          />
        </div>
        <div className="pt-4 flex flex-row sm:mt-0 sm:ml-0 sm:flex-none">
          <select
            id="approvedStatus"
            name="approvedStatus"
            className="mt-0 block w-full bg-white ml-2 pl-3 pr-10 py-2 text-base border border-gray-200 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            defaultValue="false"
            onChange={e => setApprovedStatus(e.target.value)}
          >
            <option value="">Todas</option>
            <option value="true">Aprovadas</option>
            <option value="false">Não Aprovadas</option>
          </select>
        </div>
        <div className="pt-4 flex flex-row sm:mt-0 sm:ml-0 sm:flex-none">
          <button
            type="button"
            onClick={() => newPurchaseOrder()}
            className="inline-flex items-center justify-center ml-4 rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Novo Pedido
          </button>
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Código
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Fornecedor
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Solicitante
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                    ></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {purchases.map((purchase: IPurchaseOrder, idx: number) => (
                    <RowTable key={idx} purchase={purchase} getList={getList}>
                      {!purchase.approved ? (
                        <button
                          type="button"
                          onClick={() =>
                            editPurchaseOrder(
                              purchase.sequencialCode,
                              purchase.yearCode,
                            )
                          }
                          className="inline-flex items-center mr-2 bg-white border border-transparent shadow-sm text-sm leading-4 rounded-md text-black focus:outline-none "
                        >
                          <PencilIcon
                            className="ml-0 mr-0 h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                      ) : null}
                    </RowTable>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <SlideOver
        open={open}
        setOpen={setOpen}
        codesPurchase={codesPurchase}
        setCodesPurchase={setCodesPurchase}
        getList={getList}
      />

      <SlideOverShow
        open={openShow}
        setOpen={setOpenShow}
        codesPurchase={codesPurchase}
        getList={getList}
      />
    </div>
  );
}
