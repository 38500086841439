import styled from 'styled-components';

export const Time = styled.div`
  align-self: flex-end;
  font-size: 10px;
  color: #333;
`;

export const Remetente = styled.div`
  align-self: flex-end;
  font-size: 10px;
  margin-left: 5px;
  color: #333;
`;

export const Mensagem = styled.div`
  font-size: 13px;
  color: #333;
`;

export const Item = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  flex: 1px;
  background-color: #ccc;
  border-radius: 12px;
  padding: 3px;
`;

export const Box_date = styled.div`
  display: flex;
  flex-direction: row;
  margin: 6px;
`;

export const Balloon = styled.div`
  max-width: 300px;
  padding: 5px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 5px;
`;
