import React, { useEffect, useState } from 'react';
import { Time, Title, Description, Item, Box_date, BoxNotify } from './styles';
import convertDateTime from '../../../functions/ConvertDateTime';
import { useOSView } from '../../../contexts/OSView';
import { useNotifications } from '../../../contexts/Notifications';

export default function Notify(props) {
  const [data_br, setData_br] = useState('');

  const { notify } = props;

  const { setViewNotifyWindow } = useNotifications();

  const { defineIdOs } = useOSView();

  useEffect(() => {
    converterDados(notify);
  }, [props]);

  async function converterDados(notify) {
    const data_convertida = await convertDateTime(notify.datetime);
    setData_br(data_convertida);
  }

  function renderDate(date) {
    return (
      <Box_date>
        <Time>{date}</Time>
      </Box_date>
    );
  }

  function navigation() {
    defineIdOs(notify.id_table_origin, 'consultar');

    setViewNotifyWindow(false);
  }

  return (
    <Item onClick={() => navigation()}>
      <BoxNotify>
        <Title>{notify.title}</Title>
        <Description>{notify.description}</Description>
      </BoxNotify>
      {renderDate(data_br)}
    </Item>
  );
}
