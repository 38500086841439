import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

export const Container = styled.div`
  background-color: #fff;
  flex: 0.5;
  padding: 0px;
  border: 2px solid #001029;
  border-radius: 3px;
  height: 600px;
  min-width: 300px;
  width: 320px;
  overflow-y: scroll;
  position: absolute;
  z-index: 9999;
`;

export const TextFeedback = styled.div`
  padding: 10px;
  font-size: 14px;
`;

export const LoadingComponent = styled(LoadingOutlined)`
  margin-left: 140px;
  font-size: 24px;
`;
