import React from 'react';

import { Container } from '../styles';
import SectionOS from './SectionOS';

const Body: React.FC = () => {
  return <SectionOS />;
};

export default Body;
