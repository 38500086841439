import React from 'react';
import { IClienteMin } from '../../../../types/Cliente/IClienteMin';
import { IEquipamento } from '../../../../types/Equipamento/IEquipamento';
import {
  Container,
  LineEdificio,
  LineAdress,
  LineSup,
  LineInf,
  Box,
  BoxLast,
  BoxTitle,
  BoxText,
  ClienteText,
} from './styles';

interface IProps {
  equipamento?: IEquipamento;
  cliente?: IClienteMin;
  ano_exercicio?: number;
}

export default function Identity(props: IProps) {
  const { equipamento, cliente, ano_exercicio } = props;

  const year = new Date().getFullYear();

  return (
    <Container>
      <LineEdificio>
        Nome do Edifício:
        <ClienteText>
          {cliente?.edificio}
          {cliente?.apelido ? ` (${cliente?.apelido})` : null}
        </ClienteText>
      </LineEdificio>
      <LineAdress>
        Endereço:
        <ClienteText>
          {cliente?.logradouro && cliente?.numero
            ? `${cliente?.logradouro}, ${cliente?.numero} - ${cliente?.bairro}
          - ${cliente?.cidade} - ${cliente?.estado}`
            : null}
        </ClienteText>
      </LineAdress>
      <LineSup>
        <Box style={{ flex: 2 }}>
          <BoxTitle>Apelido Equip:</BoxTitle>
          <BoxText>{equipamento?.apelido}</BoxText>
        </Box>
        <Box>
          <BoxTitle>Fabricante:</BoxTitle>
          <BoxText>{equipamento?.fabricante}</BoxText>
        </Box>
        <Box>
          <BoxTitle>Modelo:</BoxTitle>
          <BoxText>{equipamento?.modelo}</BoxText>
        </Box>
        <Box>
          <BoxTitle>Comando:</BoxTitle>
          <BoxText>{equipamento?.comando}</BoxText>
        </Box>
        <BoxLast>
          <BoxTitle>Tipo de porta:</BoxTitle>
          <BoxText>{equipamento?.tipoporta}</BoxText>
        </BoxLast>
      </LineSup>
      <LineInf>
        <Box style={{ paddingRight: 1 }}>
          <BoxTitle>Obra n°:</BoxTitle>
          <BoxText>{equipamento?.obra}</BoxText>
        </Box>
        <Box>
          <BoxTitle>Capac.:</BoxTitle>
          <BoxText>{equipamento?.capacidade}</BoxText>
        </Box>
        <Box>
          <BoxTitle>Veloc.:</BoxTitle>
          <BoxText>{equipamento?.velocidade}</BoxText>
        </Box>
        <Box>
          <BoxTitle>Máq.:</BoxTitle>
          <BoxText>{equipamento?.maquina}</BoxText>
        </Box>
        <Box>
          <BoxTitle>Paradas:</BoxTitle>
          <BoxText>{equipamento?.paradas}</BoxText>
        </Box>
        <BoxLast>
          <BoxTitle>Ano exercício:</BoxTitle>
          <BoxText>{ano_exercicio || year}</BoxText>
        </BoxLast>
      </LineInf>
    </Container>
  );
}
