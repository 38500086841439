/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import api from '../../../services/api';
import SpinnerLoad from '../../../components/SpinnerLoad';
import ButtonDefault from '../../../components/ButtonDefault';
import { feedbackError } from '../../../functions/MessageFeedback';
import Row from './Row';
import { Select, Button } from 'antd';
import { RightOutlined, UserAddOutlined } from '@ant-design/icons';
import {
  List_container,
  BodyList,
  FormFilter,
  TitleBody,
  DescriptionBody,
  TopBar,
  CardList,
} from './styles';

const { Option } = Select;

export default function List(props) {
  const [collaborators, setCollaborators] = useState([]);
  const [numTotal, setNumTotal] = useState();
  const [status, setStatus] = useState('ativo');
  const [loading, setLoading] = useState(true);

  const { setId_funcionario } = props;

  useEffect(() => {
    getList();
  }, [status]);

  async function getList() {
    try {
      const response = await api.get(
        `collaborator/min?lines_per_page=9999&status=${status}`,
      );

      setCollaborators(response.data.data);
      setNumTotal(response.data.total);
    } catch (err) {
      feedbackError('Erro ao buscar a lista');
    }
    setLoading(false);
  }

  async function handleChange(value) {
    setStatus(value);
  }

  return (
    <List_container>
      <TopBar>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingRight: 10,
          }}
        >
          <TitleBody></TitleBody>

          <Link
            to={{
              pathname: '/collaboratorregister',
              state: { id_funcionario: null, mode_collaborator: 'new' },
            }}
          >
            <ButtonDefault>
              <UserAddOutlined /> Novo
            </ButtonDefault>
          </Link>
        </div>
        <DescriptionBody>Exibindo {numTotal} colaboradores</DescriptionBody>
        <FormFilter>
          <Autocomplete
            options={collaborators}
            getOptionLabel={collaborator => collaborator.nome}
            style={{ width: 230, height: 50, marginRight: 10, padding: 0 }}
            onChange={(event, collaboratorSelected) => {
              setId_funcionario(collaboratorSelected?.id_funcionario);
            }}
            renderInput={params => (
              <TextField {...params} label="Buscar" variant="outlined" />
            )}
          />

          <Select
            defaultValue={status}
            style={{ width: 110, height: 35 }}
            onChange={handleChange}
          >
            <Option value="ativo">Ativos</Option>
            <Option value="inativo">Inativos</Option>
            <Option value="%">Todos</Option>
          </Select>
        </FormFilter>
      </TopBar>

      {loading ? (
        <SpinnerLoad />
      ) : (
        <BodyList>
          {collaborators.map(collaborator => (
            <CardList key={collaborator.id_funcionario}>
              <Row collaborator={collaborator}>
                <Button
                  onClick={() => setId_funcionario(collaborator.id_funcionario)}
                  icon={
                    <RightOutlined
                      style={{ fontSize: '24px', color: '#ccc' }}
                    />
                  }
                  size="large"
                  style={{ right: 0, border: 0 }}
                />
              </Row>
            </CardList>
          ))}
        </BodyList>
      )}
    </List_container>
  );
}
