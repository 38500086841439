import api from '../services/api';

async function GenerateArrayIdsCollaboratorByPreference(type: string) {
  try {
    const response = await api.get(`collaborator/config?preference=${type}`);

    const { data } = response;

    const list = [];

    data?.map((item, idx) => {
      list.push(item.id_collaborator);
    });

    return list;
  } catch (error) {
    return;
  }
}

export default GenerateArrayIdsCollaboratorByPreference;
