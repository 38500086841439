import React, { useEffect, useState } from 'react';

import api from '../../../../services/api';
import { Container } from './styles';
import { useOSBuilder } from '../../../../contexts/OSBuilder';
import HeadOS from '../../../../components/HeadOS';
import Form from './FormOS';

const SectionOS: React.FC = () => {
  const { id_cliente } = useOSBuilder();

  //Removido em 23/03/21
  //defineId_os_edit(null);

  return (
    <Container>
      <HeadOS id_cliente={id_cliente} />
      <Form />
    </Container>
  );
};

export default SectionOS;
