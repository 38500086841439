/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input';
import ConfirmDialog from '../../../../../../../components/ConfirmDialog';
import { IPart } from '../../../../../../../types/Part/IPart';
import { IReqPeca } from '../../../../../../../types/ReqPeca/IReqPeca';
import ImagesReqList from '../../../ImagesReqList';
import GetPart from '../../../../../../../functions/GetPart';

interface IProps {
  req_peca: IReqPeca;
  pos: number;
  listReqPecas: any;
  setListReqPecas(IReqPeca): void;
  setOpenSliderNewEdit(open: boolean): void;
  changePart(index: number, part: IPart | null): void;
  removePart(index: number): void;
  showSliderDetailsPart(show: boolean, id_part: number): unknown;
}

export default function RowList(props: IProps) {
  const [showValorTotal, setShowValorTotal] = useState('');

  const {
    req_peca,
    pos,
    listReqPecas,
    setListReqPecas,
    setOpenSliderNewEdit,
    changePart,
    removePart,
    showSliderDetailsPart,
  } = props;

  useEffect(() => {
    const valor_total = parseFloat(req_peca.valor_total);
    setShowValorTotal(valor_total.toFixed(2));
  }, [req_peca, listReqPecas]);

  useEffect(() => {
    if (!req_peca?.validado) getPart();
  }, []);

  async function getPart() {
    if (!req_peca.id_peca || req_peca.validado) return;

    const data = await GetPart(req_peca.id_peca);

    if (data?.price) changed_valor_unit(pos, data.price);
  }

  function changed_quantidade(index: number, e) {
    const newArr = [...listReqPecas];
    newArr[index].quantidade = e;

    const total_item =
      listReqPecas[index].valor_unit * listReqPecas[index].quantidade;

    newArr[index].valor_total = total_item;

    setListReqPecas(newArr);
  }

  function changed_nome_peca(index: number, e) {
    const newArr = [...listReqPecas];
    newArr[index].nome_peca = e;

    setListReqPecas(newArr);
  }

  function changed_id_peca(index, e) {
    const newArr = [...listReqPecas];
    newArr[index].id_peca = e;

    setListReqPecas(newArr);
  }

  function changed_valor_unit(index: number, e) {
    const newArr = [...listReqPecas];
    newArr[index].valor_unit = e;

    const total_item =
      listReqPecas[index].valor_unit * listReqPecas[index].quantidade;

    newArr[index].valor_total = total_item;

    setListReqPecas(newArr);
  }

  return (
    <>
      <tr key={req_peca.id_req_peca} className="border-b border-gray-200">
        <td className="hidden py-3 pr-1 text-left text-sm text-gray-500 sm:table-cell">
          {req_peca.status !== 'cancelado' && (
            <Tooltip title="Remover item">
              <ConfirmDialog
                onConfirm={() => removePart(pos)}
                title="Tem certeza que deseja remover este item? Caso confirme, será deletado imediatamente e não será possível recuperá-lo!"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="red"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </ConfirmDialog>
            </Tooltip>
          )}
        </td>
        <td className="hidden py-3 w-20 px-2 text-left text-sm text-gray-500 sm:table-cell">
          <input
            type="number"
            min={0}
            name="quantidade"
            id="quantidade"
            value={req_peca?.quantidade}
            defaultValue={req_peca?.quantidade}
            onChange={e => changed_quantidade(pos, e.target.value)}
            style={{ width: 60 }}
            className="h-8 px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </td>
        <td className="py-3 w-96 flex flex-row items-center pl-2 pr-2 text-sm sm:pl-2 md:pl-0">
          <input
            type="text"
            name="nome_peca"
            id="nome_peca"
            value={req_peca?.nome_peca}
            defaultValue={req_peca?.nome_peca}
            onChange={e => changed_nome_peca(pos, e.target.value)}
            style={{ width: 300 }}
            className="h-8 px-1 mr-2 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />

          <Tooltip title="Selecionar peça do estoque">
            <a onClick={() => changePart(pos, null)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </a>
          </Tooltip>

          {req_peca?.id_peca ? (
            <Tooltip title="Ver detalhes desta peça">
              <a onClick={() => showSliderDetailsPart(true, req_peca?.id_peca)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 ml-3"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="green"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                  />
                </svg>
              </a>
            </Tooltip>
          ) : null}
        </td>
        <td className="hidden py-3 w-30 px-2 text-left text-sm text-gray-500 sm:table-cell">
          <input
            type="text"
            name="id_peca"
            id="id_peca"
            value={req_peca?.id_peca}
            defaultValue={req_peca?.id_peca}
            onChange={e => changed_id_peca(pos, e.target.value)}
            style={{ width: 100 }}
            className="h-8 px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </td>
        <td className="hidden py-3 w-20 px-2 text-left text-xs text-gray-500 sm:table-cell">
          <CurrencyInput
            value={req_peca?.valor_unit}
            multiline
            precision="2"
            decimalSeparator="."
            thousandSeparator=""
            onChangeEvent={e => changed_valor_unit(pos, e.target.value)}
            style={{ width: 100 }}
            className="h-8 px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </td>
        <td className="py-3 pl-3 pr-2 text-right text-sm text-gray-500 sm:pr-2 md:pr-0">
          {showValorTotal}
        </td>
      </tr>

      <tr className="border-b border-gray-300">
        <td className="py-3 pl-3 pr-2 text-right text-sm text-gray-500 sm:pr-2 md:pr-0" />
        <td className="py-3 pl-3 pr-2 text-right text-sm text-gray-500 sm:pr-2 md:pr-0" />
        <td className="hidden py-0 pr-1 text-left text-sm text-gray-500 sm:table-cell">
          <ImagesReqList id_req_peca={req_peca.id_req_peca} />
        </td>
      </tr>
    </>
  );
}
