import styled from 'styled-components';

export const Container = styled.div`
  width: 95%;
  margin: 0 auto;
  margin-top: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  border: 1px solid #f0f0f0;
  align-items: center;
  align-content: center;
`;

export const Box = styled.div`
  min-width: 65px;
  width: 80px;
  padding: 2px;
`;

export const LineBox = styled.div`
  width: 80px;
  margin-bottom: 8px;
`;

export const HrInactive = styled.div`
  width: 50px;
  color: #dadada;
  background-color: #dadada;
  height: 1px;
  width: 10%;
`;

export const HrActive = styled.div`
  width: 50px;
  color: #0d7d65;
  background-color: #0d7d65;
  height: 1px;
  width: 10%;
`;
