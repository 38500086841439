/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import api from '../../services/api';
import { feedbackError } from '../../functions/MessageFeedback';
import { IPreventiva } from '../../types/Preventiva/IPreventiva';
import { IAssignClient } from '../../types/Preventiva/IAssignClient';
import Drawer from '../../components/Drawer';
import convertDateTime from '../../functions/ConvertDateTime';
import GetApelidoEquip from '../../functions/GetApelidoEquip';
import GetNameFunc from '../../functions/GetNameFunc';
import { IClienteMin } from '../../types/Cliente/IClienteMin';

interface IState {
  state: {
    id_preventiva: number;
    mode: string;
  };
}

export default function Preventiva() {
  const [preventiva, setPreventiva] = useState<IPreventiva>();
  const [clientAssign, setClientAssign] = useState<IAssignClient | null>();
  const [cliente, setCliente] = useState<IClienteMin>();
  const [start, setStart] = useState('');
  const [finish, setFinish] = useState('');
  const [apelido_equipamento, setApelido_equipamento] = useState('');
  const [tecnico_responsavel, setTecnico_responsavel] = useState('');

  const location: IState = useLocation();

  const { id_preventiva, mode } = location.state;

  useEffect(() => {
    getPreventiva();
    getClientAssign();
  }, []);

  async function getPreventiva() {
    try {
      const response = await api.get(`preventiva/${id_preventiva}`);

      setPreventiva(response.data);

      getCliente(response.data.id_cliente);

      convertData(response.data);
    } catch (err) {
      feedbackError(
        'Erro ao carregar a preventiva. Tente novamente. Caso o erro persista, contate o suporte.',
      );
    }
  }

  async function getClientAssign() {
    try {
      const response = await api.get(`preventiva/${id_preventiva}/assign`);

      setClientAssign(response.data);
    } catch (err) {
      return;
    }
  }

  async function getCliente(id_cliente: number) {
    try {
      const response = await api.get(`cliente/min/${id_cliente}`);

      setCliente(response.data);
    } catch (err) {
      return;
    }
  }

  async function convertData(preventiva: IPreventiva): Promise<void> {
    const start = await convertDateTime(preventiva.start);
    setStart(start);

    const finish = await convertDateTime(preventiva.finish);
    setFinish(finish);

    const apelido_equip = await GetApelidoEquip(preventiva.id_equipamento);
    setApelido_equipamento(apelido_equip);

    const { nome_apelido } = await GetNameFunc(preventiva.id_funcionario);
    setTecnico_responsavel(nome_apelido);
  }

  return (
    <Drawer page="Manutenção preventiva">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {cliente?.edificio}
          </h3>
          <p className="mt-0 max-w-2xl text-sm text-gray-500">
            {cliente?.logradouro}, {cliente?.numero} {cliente?.bairro} -{' '}
            {cliente?.cidade} - {cliente?.estado}
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Status</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {preventiva?.status === 'concluida' ? (
                  <span className="inline-flex rounded bg-green-100 px-2 capitalize text-xs font-semibold leading-5 text-green-800">
                    {preventiva?.status}
                  </span>
                ) : (
                  <span className="inline-flex rounded bg-yellow-100 px-2 capitalize text-xs font-semibold leading-5 text-yellow-800">
                    {preventiva?.status}
                  </span>
                )}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Elevador</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {apelido_equipamento}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Início</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {start}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Término</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {finish}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Técnico responsável
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {tecnico_responsavel}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Cliente responsável
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {preventiva?.nome_cliente_responsavel}
              </dd>
            </div>
            {clientAssign?.assign_cliente ? (
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Assinatura do cliente responsável
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <img
                    src={`data:image/png;base64,${clientAssign.assign_cliente}`}
                  />
                </dd>
              </div>
            ) : null}
          </dl>
        </div>
      </div>
    </Drawer>
  );
}
