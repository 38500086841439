import React from 'react';

interface IProps {
  nameSearch: string;
  setNameSearch(nameSearch: string): unknown | void;
}

export default function InputSearchName(props: IProps) {
  const { nameSearch, setNameSearch } = props;

  return (
    <div className="mt-0 mr-2 border border-gray-300 focus-within:border-blue-600 focus-within:rounded-lg">
      <input
        type="text"
        name={nameSearch}
        id={nameSearch}
        className="block w-full h-full px-2 py-2 rounded-lg border-0 border-b border-transparent bg-gray-50 focus:border-blue-600 focus:ring-0 sm:text-sm"
        placeholder="Pesquisar..."
        onChange={e => setNameSearch(e.target.value)}
      />
    </div>
  );
}
