//Tipos de log que possibilitam ver mais informações (tela lateral)
const typesViewMore = [
  'alter_status_funcionamento',
  'alter_os',
  'alter_req_peca',
  'scheduling_os',
  'alter_board_os',
];

export default typesViewMore;
