/* eslint-disable @typescript-eslint/no-explicit-any */

import { PlusIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { feedbackError } from '../../../../functions/MessageFeedback';
import api from '../../../../services/api';
import Empty from './Empty';
import SlideOverContact from './SlideOverContact';
import { useClientRegister } from '../../../../contexts/ClientRegister';
import { IContact } from '../../../../types/Contact/IContact';
import Card from './Card';
import { IPivotContactCliente } from '../../../../types/Contact/IPivotContactCliente';

export default function FormContatos() {
  const [contacts, setContacts] = useState<[IContact] | []>([]);
  const [id_contact, setId_contact] = useState<number>();

  const { id_cliente, loading, setOpenSliderContact } = useClientRegister();

  useEffect(() => {
    if (id_cliente) getContacts();
  }, [id_cliente, loading]);

  async function getContacts() {
    try {
      const response = await api.get(
        `contactCliente/bycliente?id_cliente=${id_cliente}`,
      );

      const { data } = response;

      data.sort(function (a, b) {
        if (a.status < b.status) {
          return true;
        } else {
          return -1;
        }
      });

      setContacts(data);
    } catch (err) {
      feedbackError(
        'Erro ao carregar os contatos. Tente novamente mais tarde.',
      );
    }
  }

  async function openNewContact() {
    setOpenSliderContact(true);

    setId_contact(null);
  }

  async function selectContact(id_contact: number) {
    setId_contact(id_contact);

    setOpenSliderContact(true);
  }

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-10">
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Contatos
            </h3>
            <p className="mt-1 text-sm text-gray-500"></p>
          </div>

          <div>
            {contacts.length > 0 ? (
              <>
                <ul
                  role="list"
                  className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
                >
                  {contacts.map((contact: IContact) => (
                    <Card
                      contact={contact}
                      selectContact={selectContact}
                      key={contact.id_contact}
                    />
                  ))}
                </ul>
                <div className="mt-2">
                  <button
                    type="button"
                    onClick={() => openNewContact()}
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <PlusIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Novo Contato
                  </button>
                </div>
              </>
            ) : (
              <div className="text-center">
                <Empty />
                <div className="mt-6">
                  <button
                    type="button"
                    onClick={() => openNewContact()}
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <PlusIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Novo Contato
                  </button>
                </div>
              </div>
            )}
          </div>
          <SlideOverContact
            id_contact={id_contact}
            setId_contact_view={setId_contact}
          />
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6"></div>
      </div>
    </div>
  );
}
