import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Drawer from '../../components/Drawer';
import Sidebar from './components/Sidebar';

import { Container } from './styles';

interface ILocation {
  state?: {
    id_funcionario?: number;
  };
}

export default function CollaboratorRegister() {
  const [id_funcionario, setId_funcionario] = useState<number | null>(null);

  const location: ILocation = useLocation();

  useEffect(() => {
    if (location.state?.id_funcionario) {
      setId_funcionario(location.state?.id_funcionario);
    } else {
      setId_funcionario(null);
    }
  }, [location]);

  const propId = {
    id_funcionario,
    setId_funcionario,
  };

  return (
    <Drawer page="Registro de Colaborador">
      <Container>
        <Sidebar {...propId} />
      </Container>
    </Drawer>
  );
}
