/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ConvertDateTime from '../../../functions/ConvertDateTime';
import { useOSView } from '../../../contexts/OSView';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Button } from '@material-ui/core';
import { Tooltip } from 'antd';
import { FileTextOutlined, PhoneOutlined } from '@ant-design/icons';
import api from '../../../services/api';
import OSViewSlideOver from '../../../components/OSViewSlideOver';

const RowTable = ({ os }) => {
  const [data_criacao, setDataCriacao] = useState('');
  const [lastAttendance, setLastAttendance] = useState();
  const [qtdReq_peca, setQtdReq_peca] = useState(0);
  const [open, setOpen] = useState(false);

  const { defineIdOs } = useOSView();

  useEffect(() => {
    getlastAttendance();
    convertDate(os.created_at);
    contReq_peca();
  }, []);

  async function contReq_peca() {
    try {
      const response = await api.get(
        `req_peca?lines_per_page=0&id_os=${os.id_os}`,
      );

      setQtdReq_peca(response.data.total);
    } catch (err) {
      return;
    }
  }

  async function getlastAttendance() {
    try {
      const response = await api.get(`atendimento/${os.id_atendimento_last}`);

      setLastAttendance(response.data);
    } catch (err) {
      return;
    }
  }

  async function convertDate(created_at) {
    const data_convertida = await ConvertDateTime(created_at);
    setDataCriacao(data_convertida);
  }

  return (
    <>
      {console.log(os)}
      <OSViewSlideOver
        id_os={os.id_os}
        open={open}
        setOpen={setOpen}
        action="consultar"
      />
      <TableRow key={os.id_os} style={{ height: 50 }}>
        <TableCell align="left">
          <Button onClick={() => setOpen(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
          </Button>
        </TableCell>

        <TableCell style={{ fontSize: 16, fontWeight: 600 }} align="left">
          <Button onClick={() => defineIdOs(os.id_os, 'consultar')}>
            {os.id_os}
          </Button>
        </TableCell>
        <TableCell
          style={{ fontSize: 12 }}
          align="left"
          component="th"
          scope="row"
        >
          {os.apelido}
        </TableCell>
        <TableCell style={{ fontSize: 12 }} align="left">
          {os.apelido_equipamento}
        </TableCell>
        <TableCell style={{ fontSize: 11, color: '#757575' }} align="left">
          <Tooltip
            title={
              'Descrição: ' +
              os.descricao +
              ' | Conclusão: ' +
              lastAttendance?.parecer
            }
            placement="right"
          >
            {os.descricao.substring(40, 0)} ...
          </Tooltip>
        </TableCell>
        <TableCell style={{ fontSize: 12 }} align="left">
          {data_criacao}
        </TableCell>
        <TableCell style={{ fontSize: 12 }} align="left">
          {os.nome_apelido}
        </TableCell>
        <TableCell
          style={{ fontSize: 12, textTransform: 'capitalize' }}
          align="left"
        >
          {os.status}
        </TableCell>
        <TableCell style={{ fontSize: 12 }} align="left">
          {os.chamado ? (
            <Tooltip title="Chamado" placement="right">
              <PhoneOutlined style={{ color: '#d50000', fontSize: 22 }} />
            </Tooltip>
          ) : (
            <Tooltip title="OS" placement="right">
              <FileTextOutlined style={{ color: '#757575', fontSize: 22 }} />
            </Tooltip>
          )}
        </TableCell>
        <TableCell align="left">
          {qtdReq_peca ? (
            <Tooltip
              title={'Quantidade de peças: ' + qtdReq_peca}
              placement="right"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                />
              </svg>
            </Tooltip>
          ) : null}
        </TableCell>
      </TableRow>
    </>
  );
};

export default RowTable;
