import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1120px;
  height: 100vh;
  margin: 0 auto; /* div no centro da página */
  display: flex;
  align-items: center; /* Conteúdo alinhado verticalmente */
  justify-content: center; /* Conteúdo alinhado horizontalemnte */
`;

export const Title = styled.h1`
  color: '#010729';
  margin-bottom: 16px;
  font-size: 20px;
`;

export const Content = styled.div`
  background-color: #eee;
  border-radius: 8px;
  padding-right: 30px;
  padding-left: 30px;
  display: flex;
  align-items: center; /* Conteúdo alinhado verticalmente */
  justify-content: center; /* Conteúdo alinhado horizontalemnte */

  form {
    margin: 0px 0;
    text-align: center;
  }
`;
