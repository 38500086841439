import React, { useState } from 'react';
import Drawer from '../../components/Drawer';
import List from './List';
import Body from './Body';

import { Container } from './styles';

export default function Collaborators() {
  const [id_funcionario, setId_funcionario] = useState(10);

  const propId = {
    id_funcionario,
    setId_funcionario,
  };

  return (
    <Drawer page="Colaboradores">
      <Container>
        <List {...propId} />
        <Body {...propId} />
      </Container>
    </Drawer>
  );
}
