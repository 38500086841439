import styled from 'styled-components';

export const Item = styled.div`
  margin-bottom: 0px;
  margin-left: 1px;
  margin-right: 1px;
  flex: 1px;
  background-color: #fff;
  padding: 3px;
  border-bottom: 1px solid #aaa;
  cursor: pointer;
`;

export const BoxNotify = styled.div`
  max-width: 300px;
  padding: 3px;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #333;
`;

export const Description = styled.div`
  font-size: 12px;
  color: #555;
`;

export const Box_date = styled.div`
  display: flex;
  flex-direction: row;
  margin: 6px;
`;

export const Time = styled.div`
  align-self: flex-end;
  font-size: 9px;
  color: #555;
`;
