import React from 'react';
import Drawer from '../../components/Drawer';
import ListLogs from './components/ListLogs';

const Logs: React.FC = () => {
  return (
    <Drawer page="Logs do Sistema">
      <ListLogs />
    </Drawer>
  );
};

export default Logs;
