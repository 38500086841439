import React from 'react';
import { EditOutlined } from '@material-ui/icons';
import { Avatar, Upload } from 'antd';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../../../functions/MessageFeedback';
import { urlAPI } from '../../../../../services/urlAPI';
import { BoxInfo, Columm, ContainerContent } from '../../styles';

const { Dragger } = Upload;

interface IProps {
  id_funcionario: number;
  foto?: string;
  setCollaborator(collaborator: unknown): void;
}

export default function AvatarArea(props: IProps) {
  const { id_funcionario, setCollaborator, foto } = props;

  const propsUp = {
    name: 'avatar',
    multiple: false,
    action: `${urlAPI}/collaborator/avatar/${id_funcionario}`,
    showUploadList: {
      showPreviewIcon: false,
      showRemoveIcon: false,
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        setCollaborator(prevState => {
          return { ...prevState, foto: info.file.response.foto };
        });
      }

      if (status === 'done') {
        feedbackSuccess(`${info.file.name} anexado com sucesso.`);
      } else if (status === 'error') {
        feedbackError(`${info.file.name} falhou ao ser anexado.`);
      }
    },
  };

  return (
    <div className="shadow sm:rounded-md sm:overflow-hidden">
      <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
        <div>
          <h3 className="text-lg leading-4 font-medium text-gray-900">
            Foto de perfil
          </h3>
        </div>

        <ContainerContent>
          <Columm>
            <BoxInfo style={{ flexDirection: 'row' }}>
              <Avatar size={80} src={foto} />
              <Dragger
                {...propsUp}
                style={{
                  width: 50,
                  height: 30,
                  border: 0,
                  backgroundColor: '#fff',
                }}
              >
                <EditOutlined style={{ fontSize: 20 }} />
              </Dragger>
            </BoxInfo>
          </Columm>
        </ContainerContent>
      </div>
    </div>
  );
}
