import { feedbackSuccess } from '../../../../../../functions/MessageFeedback';
import api from '../../../../../../services/api';
import { IPreferenceNotification } from '../../../../../../types/Notification/IPreferenceNotification';

async function HandleSubmit(
  preferences: IPreferenceNotification,
): Promise<void | Error> {
  try {
    await api.post(`notification/preference`, preferences);

    feedbackSuccess('Salvo com sucesso');

    return;
  } catch (err) {
    return err;
  }
}

export default HandleSubmit;
