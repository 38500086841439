import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 110px;
  border-bottom: 1px solid #000;
`;

export const BoxBusiness = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid #000;
`;

export const BoxBusinessLine = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
`;

export const BoxLogo = styled.div`
  width: 110px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const BoxPhones = styled.div`
  display: flex;
  width: 200px;
  height: 70px;
  margin: 3px;
  font-size: 12px;
  line-height: 20px;
`;

export const BoxAdress = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-left: 6px;
  font-size: 12px;
  line-height: 18px;
`;

export const BoxTitle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100%;
  font-size: 18px;
  font-weight: 600;
  padding-left: 20px;
`;

export const Box = styled.div`
  flex: 1;
  height: 100%;
  flex-direction: column;
`;
