/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import InputMask from 'react-input-mask';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../functions/MessageFeedback';
import { IUserList } from '../../../types/Cliente/Users/IUserList';
import api from '../../../services/api';
import { Select, Switch } from 'antd';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const { Option } = Select;

interface IProps {
  openSliderUserClient: boolean;
  setOpenSliderUserClient(open: boolean): void;
  id?: string;
  setId_user_select(id: string): void;
  setRefresh(num: unknown): void;
  refresh: number;
}

export default function SlideOverUserClient(props: IProps) {
  const [user, setUser] = useState<IUserList | null>();
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('cpf');

  const {
    openSliderUserClient,
    setOpenSliderUserClient,
    id,
    setRefresh,
    refresh,
    setId_user_select,
  } = props;

  useEffect(() => {
    if (id) {
      getUser();
    } else {
      setUser(null);
    }
  }, [id]);

  async function getUser() {
    try {
      const response = await api.get(`client_api/user/${id}`);

      setUser(response.data);
    } catch (err) {
      feedbackError('Erro ao carregar o usuário. Tente novamente mais tarde');
    }
  }

  async function handleChange(event) {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  }

  async function handleSubmit() {
    if (!user.first_name || !user.last_name || !user.email) {
      feedbackError('Campos nome e e-mail são obrigatórios');
      return;
    }

    if ((!password1 || password1 !== password2) && !id) {
      feedbackError('As senhas digitadas não coincidem');
      return;
    }

    setLoading(true);

    let { cpf, cnpj } = user;

    cpf = cpf?.replace(/[^0-9]/g, '');
    cnpj = cnpj?.replace(/[^0-9]/g, '');

    const body = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      username: user.email,
      password: password1,
      cpf: user.cpf,
      cnpj: user.cnpj,
      blocked: user.blocked,
      disabled: user.disabled,
    };

    setLoading(true);

    if (id) {
      try {
        const response = await api.put(`client_api/user/${id}`, body);

        if (response?.data?.id === id) {
          feedbackSuccess('Salvo com sucesso');
        } else {
          feedbackError(
            'Erro ao salvar! Verifique os dados informados e se já não existe um usuário com este e-mail.',
          );
        }

        setId_user_select(null);

        setLoading(false);
      } catch (err) {
        feedbackError('Erro ao editar o usuário.');
      }
    } else {
      try {
        await api.post(`client_api/user`, body);

        feedbackSuccess('Salvo com sucesso');

        setLoading(false);
      } catch (err) {
        feedbackError('Não foi possível criar');
      }
    }
    setLoading(false);

    setRefresh(refresh + 1);

    setOpenSliderUserClient(false);
  }

  return (
    <Transition.Root show={openSliderUserClient} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={setOpenSliderUserClient}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-md">
                <span className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div className="h-0 flex-1 overflow-y-auto">
                    <div className="bg-blue-700 py-2 px-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">
                          {!id ? 'Novo Usuário' : 'Editar Usuário'}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-blue-700 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpenSliderUserClient(false)}
                          >
                            <span className="sr-only">Fechar painel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="mt-1">
                        <p className="text-sm text-blue-300">
                          Cadastro de usuário de cliente
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                      <div className="divide-y divide-gray-200 px-4 sm:px-6">
                        <div className="space-y-6 pt-6 pb-5">
                          <div className="flex grid-cols-2 gap-4">
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Primeiro nome
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="first_name"
                                  id="first_name"
                                  value={user?.first_name}
                                  defaultValue={user?.first_name}
                                  onChange={e => handleChange(e)}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Sobrenome
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="last_name"
                                  id="last_name"
                                  value={user?.last_name}
                                  defaultValue={user?.last_name}
                                  onChange={e => handleChange(e)}
                                  className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex grid-cols-2 gap-4">
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Tipo
                              </label>
                              <Select
                                className="block h-8 w-full mt-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                id="type"
                                value={type}
                                onChange={e => setType(e)}
                              >
                                <Option value="cpf">Pessoa Física</Option>
                                <Option value="cnpj">Pessoa Jurídica</Option>
                              </Select>
                            </div>
                            {type === 'cpf' ? (
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  CPF
                                </label>
                                <div className="mt-1">
                                  <InputMask
                                    type="text"
                                    mask="999.999.999-99"
                                    name="cpf"
                                    id="cpf"
                                    value={user?.cpf}
                                    defaultValue={user?.cpf}
                                    onChange={e => handleChange(e)}
                                    className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                            ) : (
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  CNPJ
                                </label>
                                <div className="mt-1">
                                  <InputMask
                                    type="text"
                                    mask="99.999.999/9999-99"
                                    name="cnpj"
                                    id="cnpj"
                                    value={user?.cnpj}
                                    defaultValue={user?.cnpj}
                                    onChange={e => handleChange(e)}
                                    className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                            )}
                          </div>

                          <div>
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium text-gray-900"
                            >
                              E-mail / Login
                            </label>
                            <div className="mt-1">
                              <input
                                type="email"
                                name="email"
                                id="email"
                                value={user?.email}
                                defaultValue={user?.email}
                                onChange={e => handleChange(e)}
                                className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                              />
                            </div>
                          </div>

                          {id ? null : (
                            <div className="flex grid-cols-2 gap-4">
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Senha
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="password"
                                    name="password1"
                                    id="password1"
                                    value={password1}
                                    defaultValue={password1}
                                    onChange={e => setPassword1(e.target.value)}
                                    className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Repita a senha
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="password"
                                    name="password2"
                                    id="password2"
                                    value={password2}
                                    defaultValue={password2}
                                    onChange={e => setPassword2(e.target.value)}
                                    className="block h-8 w-full px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="flex grid-cols-1 gap-2">
                            <div className="flex flex-row">
                              <label
                                htmlFor="blocked"
                                className="pb-1 mr-2 block text-sm font-medium text-gray-700"
                              >
                                Bloqueado:
                              </label>

                              <Switch
                                defaultChecked={user?.blocked}
                                onChange={e =>
                                  setUser(prevState => {
                                    return { ...prevState, blocked: e };
                                  })
                                }
                                className={classNames(
                                  user?.blocked ? 'bg-blue-600' : 'bg-gray-200',
                                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500',
                                )}
                              />
                            </div>
                          </div>

                          <div className="flex grid-cols-1 gap-2">
                            <div className="flex flex-row">
                              <label
                                htmlFor="disabled"
                                className="pb-1 mr-2 block text-sm font-medium text-gray-700"
                              >
                                Desativado:
                              </label>

                              <Switch
                                defaultChecked={user?.disabled}
                                onChange={e =>
                                  setUser(prevState => {
                                    return { ...prevState, disabled: e };
                                  })
                                }
                                className={classNames(
                                  user?.disabled
                                    ? 'bg-blue-600'
                                    : 'bg-gray-200',
                                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500',
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-between px-4 py-4">
                      <div>
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                          onClick={() => setOpenSliderUserClient(false)}
                        >
                          Cancelar
                        </button>
                        <button
                          disabled={loading}
                          onClick={() => handleSubmit()}
                          className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                          Salvar
                        </button>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
