/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import api from '../../services/api';
import { IClienteMin } from '../../types/Cliente/IClienteMin';
import { IEquipamento } from '../../types/Equipamento/IEquipamento';
import Head from './components/Head';
import Identity from './components/Identity';
import MonthlyControl from './components/MonthlyControl';

import { Container, List, Ficha } from './styles';

interface ILocation {
  equip1: number;
}

const PreventivesCardsPrint: React.FC = () => {
  const [cliente, setCliente] = useState<IClienteMin>();
  const [equip1, setEquip1] = useState<IEquipamento>();

  const location = useLocation<ILocation>();

  useEffect(() => {
    if (location.state?.equip1) {
      getEquip1(location.state?.equip1);
    }
  }, [location]);

  async function getEquip1(id: number) {
    try {
      const response = await api.get(`equipamento/${id}`);

      setEquip1(response.data);

      getCliente(response.data.id_cliente);
    } catch (error) {
      return;
    }
  }

  async function getCliente(id_cliente: number) {
    try {
      const response = await api.get(`cliente/min/${id_cliente}`);

      setCliente(response.data);
    } catch (error) {
      return;
    }
  }

  return (
    <Container>
      <Ficha>
        <Head />
        <Identity
          equipamento={equip1}
          cliente={cliente}
          ano_exercicio={new Date().getFullYear()}
        />
        <MonthlyControl />
      </Ficha>
    </Container>
  );
};

export default PreventivesCardsPrint;
