import React, { useEffect } from 'react';
import { Fragment, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { PlusIcon, XIcon } from '@heroicons/react/outline';
import { IUserList } from '../../../../types/Cliente/Users/IUserList';
import api from '../../../../services/api';
import { feedbackError } from '../../../../functions/MessageFeedback';
import ConfirmDialog from '../../../../components/ConfirmDialog';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

interface IProps {
  open: boolean;
  setOpen(open: boolean): void;
  key_register_user?: string;
}

export default function SlideOverVincUser(props: IProps) {
  const [users, setUsers] = useState<IUserList[] | []>([]);

  const { open, setOpen, key_register_user } = props;

  useEffect(() => {
    getUsers();
  }, []);

  async function getUsers() {
    try {
      const response = await api.get(`client_api/user`);

      setUsers(response.data);
    } catch (err) {
      return;
    }
  }

  async function vinculateUser(id_user: string, type: string) {
    if (!key_register_user) {
      feedbackError(
        'Não foi possível buscar todas as informações necessárias para vincular este usuário. Tente novamente mais tarde.',
      );

      return;
    }

    const data = {
      id_user,
      type,
      key: key_register_user,
    };

    try {
      await api.post(`client_api/pivotuserclient`, data);
    } catch (err) {
      feedbackError('Não foi possível vincular este usuário');
    }

    setOpen(false);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-md">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="px-6 pt-4 pb-2">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        Usuários
                      </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-blue-500"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Fechar painel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="border-b border-gray-200">
                    {/* Barra de filtros */}
                  </div>
                  <ul
                    role="list"
                    className="flex-1 divide-y divide-gray-200 overflow-y-auto"
                  >
                    {users.map((user: IUserList, idx: number) => (
                      <li key={user.id}>
                        <div className="group relative flex items-center py-2 px-4">
                          <a href="#" className="-m-1 block flex-1 p-1">
                            <div
                              className="absolute inset-0 group-hover:bg-gray-50"
                              aria-hidden="true"
                            />
                            <div className="relative flex min-w-0 flex-1 items-center">
                              <span className="relative inline-block flex-shrink-0">
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={
                                    user?.avatar_url
                                      ? user.avatar_url
                                      : 'https://storage-app-do-elevador.s3.sa-east-1.amazonaws.com/default-avatar.svg'
                                  }
                                  alt=""
                                />
                              </span>
                              <div className="ml-4 truncate">
                                <p className="truncate text-sm font-medium text-gray-900">
                                  {user.first_name} {user.last_name}
                                </p>
                                <p className="truncate text-xs text-gray-400">
                                  {user.username}
                                </p>
                              </div>
                            </div>
                          </a>
                          <Menu
                            as="div"
                            className="relative ml-2 inline-block flex-shrink-0 text-left"
                          >
                            <Menu.Button className="group relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                              <span className="sr-only">Abrir opções</span>
                              <span className="flex h-full w-full items-center justify-center rounded-full">
                                <PlusIcon
                                  className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                              </span>
                            </Menu.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute top-0 right-9 z-10 w-60 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  <Menu.Item>
                                    {({ active }) => (
                                      <ConfirmDialog
                                        title="Você tem certeza?"
                                        onConfirm={() =>
                                          vinculateUser(user.id, 'default')
                                        }
                                      >
                                        <a
                                          href=""
                                          className={classNames(
                                            active
                                              ? 'bg-gray-100 text-gray-900'
                                              : 'text-gray-700',
                                            'block px-4 py-2 text-sm',
                                          )}
                                        >
                                          Vincular como padrão
                                        </a>
                                      </ConfirmDialog>
                                    )}
                                  </Menu.Item>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <ConfirmDialog
                                        title="Cuidado: Você tem certeza que deseja dar permissão total para este usuário neste cliente?"
                                        onConfirm={() =>
                                          vinculateUser(user.id, 'admin')
                                        }
                                      >
                                        <a
                                          href=""
                                          className={classNames(
                                            active
                                              ? 'bg-gray-100 text-gray-900'
                                              : 'text-gray-700',
                                            'block px-4 py-2 text-sm',
                                          )}
                                        >
                                          Vincular como administrador
                                        </a>
                                      </ConfirmDialog>
                                    )}
                                  </Menu.Item>
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
