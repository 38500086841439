import React, { useState, useEffect } from 'react';
import DurationTimeCalculate from '../../../../../functions/DurationTimeCalculate';
import GetExtraServiceCurrent from '../../../../../functions/GetExtraServiceCurrent';
import GetLastExtraService from '../../../../../functions/GetLastExtraService';
import GetNameFunc from '../../../../../functions/GetNameFunc';
import { FaCheck, FaRoute, FaTools } from 'react-icons/fa';
import { ICollaboratorMin } from '../../../../../types/Collaborator/ICollaboratorMin';
import { IExtraService } from '../../../../../types/ExtraService/IExtraService';
import GenerateDetailsToShow from '../functions/GenerateDetailsToShow';

interface IProps {
  id_collaborator: number;
}

export default function RowList({ id_collaborator }: IProps) {
  const [extraService, setExtraService] = useState<IExtraService | null>(null);
  const [textTitle, setTextTitle] = useState('');
  const [textDetails, setTextDetails] = useState('');
  const [collaborator, setCollaborator] = useState<ICollaboratorMin | null>(
    null,
  );

  useEffect(() => {
    getCollaborator();
  }, [id_collaborator]);

  async function getCollaborator() {
    if (!id_collaborator) return;

    const collaborator = await GetNameFunc(id_collaborator);

    setCollaborator(collaborator);

    if (collaborator?.status === 'ativo') {
      await getServiceCurrent();

      setInterval(getServiceCurrent, 10000);
    }
  }

  async function getServiceCurrent() {
    if (!id_collaborator) return;

    const serviceCurrent = await GetExtraServiceCurrent(id_collaborator);

    setExtraService(serviceCurrent);

    if (serviceCurrent) {
      const { textTitle, textDetails } = await GenerateDetailsToShow(
        serviceCurrent,
      );

      setTextTitle(textTitle);

      if (textDetails) setTextDetails(textDetails);
    } else {
      setTextTitle('está livre');

      const serviceLast = await GetLastExtraService(id_collaborator);

      if (serviceLast?.finish) {
        const duration = DurationTimeCalculate(serviceLast.finish);

        setTextDetails(duration);
      }
    }
  }

  return (
    <li className="py-3">
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          <img
            className="h-8 w-8 rounded-full"
            src={collaborator?.foto}
            alt=""
          />
        </div>

        <div className="flex-shrink-0">
          {!extraService ? <FaCheck size={20} color="#00cf00" /> : null}

          {extraService?.displacement_start &&
          !extraService?.displacement_finish &&
          extraService?.status === 'doing' ? (
            <FaRoute size={20} />
          ) : null}

          {extraService?.attendance_start &&
          !extraService?.attendance_finish &&
          extraService?.status === 'doing' ? (
            <FaTools size={20} />
          ) : null}
        </div>
        <div className="flex flex-1 min-w-0 flex-col">
          <span className="text-sm font-medium text-gray-900 truncate">
            {collaborator?.nome_apelido} {textTitle}
          </span>
          <span className="text-xs text-gray-500 truncate">{textDetails}</span>
        </div>
        <div>
          {/* <a className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50">
            Ver
          </a> */}
        </div>
      </div>
    </li>
  );
}
