/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid';

interface IProps {
  password: string;
}

export default function ValidateForcePassword({ password }: IProps) {
  const [uppercheck, setUppercheck] = useState(false);
  const [lowercheck, setLowercheck] = useState(false);
  const [countcheck, setCountcheck] = useState(false);
  const [numbercheck, setNumbercheck] = useState(false);
  const [symbolcheck, setSymbolcheck] = useState(false);

  const upperCase = /[A-Z]/;
  const lowerCase = /[a-z]/;
  const numbers = /[0-9]/;
  const symbols = /[!|@|#|$|%|^|&|*|(|)|-|_]/;

  useEffect(() => {
    if (password.length >= 8 && password.length <= 16) {
      setCountcheck(true);
    } else {
      setCountcheck(false);
    }

    if (upperCase.test(password)) {
      setUppercheck(true);
    } else {
      setUppercheck(false);
    }

    if (lowerCase.test(password)) {
      setLowercheck(true);
    } else {
      setLowercheck(false);
    }
    if (numbers.test(password)) {
      setNumbercheck(true);
    } else {
      setNumbercheck(false);
    }
    if (symbols.test(password)) {
      setSymbolcheck(true);
    } else {
      setSymbolcheck(false);
    }
  }, [password]);

  return (
    <div className="flex items-start justify-start flex-col">
      {countcheck ? (
        <div className="text-xs text-green-500 flex flex-row">
          <CheckCircleIcon className="text-green-500 w-4 mr-1" />A senha precisa
          ter entre 8 e 16 dígitos
        </div>
      ) : (
        <div className="text-xs text-red-500 flex flex-row">
          <ExclamationCircleIcon className="text-red-500 w-4 mr-1" />A senha
          precisa ter entre 8 e 16 dígitos
        </div>
      )}

      {uppercheck ? (
        <div className="text-xs text-green-500 flex flex-row">
          <CheckCircleIcon className="text-green-500 w-4 mr-1" />A senha precisa
          conter letra maiúscula
        </div>
      ) : (
        <div className="text-xs text-red-500 flex flex-row">
          <ExclamationCircleIcon className="text-red-500 w-4 mr-1" />A senha
          precisa conter letra maiúscula
        </div>
      )}

      {lowercheck ? (
        <div className="text-xs text-green-500 flex flex-row">
          <CheckCircleIcon className="text-green-500 w-4 mr-1" />A senha precisa
          conter letra minúscula
        </div>
      ) : (
        <div className="text-xs text-red-500 flex flex-row">
          <ExclamationCircleIcon className="text-red-500 w-4 mr-1" />A senha
          precisa conter letra minúscula
        </div>
      )}

      {numbercheck ? (
        <div className="text-xs text-green-500 flex flex-row">
          <CheckCircleIcon className="text-green-500 w-4 mr-1" />A senha precisa
          conter número
        </div>
      ) : (
        <div className="text-xs text-red-500 flex flex-row">
          <ExclamationCircleIcon className="text-red-500 w-4 mr-1" />A senha
          precisa conter número
        </div>
      )}

      {symbolcheck ? (
        <div className="text-xs text-green-500 flex flex-row">
          <CheckCircleIcon className="text-green-500 w-4 mr-1" />A senha precisa
          conter caractere especial
        </div>
      ) : (
        <div className="text-xs text-red-500 flex flex-row">
          <ExclamationCircleIcon className="text-red-500 w-4 mr-1" />A senha
          precisa conter caractere especial
        </div>
      )}
    </div>
  );
}
