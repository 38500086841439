import DurationTimeCalculate from '../../../../../functions/DurationTimeCalculate';
import GetNameEdificio from '../../../../../functions/GetNameEdificio';
import { IExtraService } from '../../../../../types/ExtraService/IExtraService';

interface IResult {
  textTitle: string;
  textDetails?: string;
}

async function GenerateDetailsToShow(service: IExtraService): Promise<IResult> {
  if (!service) return;

  const result = {
    textTitle: '',
    textDetails: '',
  };

  const customer = await GetNameEdificio(service.id_cliente);

  switch (service.type) {
    case 'os':
      if (service.table_origin === 'atendimento') {
        if (service.displacement_start && !service.displacement_finish) {
          result.textTitle = `está deslocando até o Edif. ${customer?.apelido} para ${service?.motive}`;

          result.textDetails = DurationTimeCalculate(
            service.displacement_start,
          );
        }

        if (service.attendance_start && !service.attendance_finish) {
          result.textTitle = `está em Edif. ${customer?.apelido} para ${service?.motive}`;

          result.textDetails = DurationTimeCalculate(service.attendance_start);
        }
      }

      break;

    case 'preventive':
      if (service.displacement_start && !service.displacement_finish) {
        result.textTitle = `está deslocando até o Edif. ${customer?.apelido} para ${service?.motive}`;

        result.textDetails = DurationTimeCalculate(service.displacement_start);
      }

      if (service.attendance_start && !service.attendance_finish) {
        result.textTitle = `está em Edif. ${customer?.apelido} para ${service?.motive}`;

        result.textDetails = DurationTimeCalculate(service.attendance_start);
      }

      break;
  }

  return result;
}

export default GenerateDetailsToShow;
