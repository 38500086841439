import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  height: 200px;
  display: flex;
  flex-direction: column;
`;

export const Line = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 16px;
  border-bottom: 1px solid #000;
`;

export const BoxDate = styled.div`
  flex: 1;
  height: 30px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #000;
  align-items: center;
  justify-content: center;
`;

export const BoxMonthly = styled.div`
  flex: 1;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #000;
`;

export const BoxHour = styled.div`
  flex: 2;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #000;
`;

export const BoxRubrica = styled.div`
  flex: 4;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #000;
`;

export const Box = styled.div`
  flex: 2;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #000;
  align-items: center;
  justify-content: center;
`;

export const BoxSign = styled.div`
  flex: 8;
  height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 2px;
`;

export const BoxTitle = styled.text`
  font-size: 11px;
  padding-left: 2px;
  padding-top: 2px;
`;

export const BoxText = styled.text`
  font-size: 11px;
  padding-left: 2px;
  padding-top: 2px;
`;
