import styled from 'styled-components';

export const Line = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 0px;
`;

export const DescriptionComplement = styled.div`
  font-size: 12px;
  color: #555;
  ::first-letter {
    text-transform: uppercase;
  }
`;
