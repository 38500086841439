/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import SpinnerLoad from '../../../../../../components/SpinnerLoad';
import GetUserByFunc from '../../../../../../functions/GetUserByFunc';
import GetPreferencesNotifications from './GetPreferencesNotifications';
import HandleSubmit from './HandleSubmit';

interface IProps {
  id_funcionario: number;
}

export default function NotificationsPreferences({ id_funcionario }: IProps) {
  const [id_user, setId_user] = useState<number | null>(null);
  const [appTypes, setAppTypes] = useState<any>([]);
  const [appWhen, setAppWhen] = useState<string | null>(null);
  const [pushTypes, setPushTypes] = useState<any>([]);
  const [pushWhen, setPushWhen] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPreferences();
  }, []);

  async function getPreferences() {
    if (!id_funcionario) return;

    const { id_usuario } = await GetUserByFunc(id_funcionario);

    setId_user(id_usuario);

    setLoading(true);

    try {
      const data = await GetPreferencesNotifications(id_usuario);

      if (data) {
        setAppTypes(data?.notification_app?.type);

        setAppWhen(data?.notification_app?.when);

        setPushTypes(data?.push_notification?.type);

        setPushWhen(data?.push_notification?.when);
      }
    } catch (error) {
      setLoading(false);
    }

    setLoading(false);
  }

  async function saveData() {
    setLoading(true);

    const data = {
      id_user,
      enabled: true,
      notification_app: {
        type: appTypes,
        when: appWhen,
      },
      push_notification: {
        type: pushTypes,
        when: pushWhen,
      },
    };

    try {
      await HandleSubmit(data);
    } catch (error) {
      setLoading(false);

      return;
    }

    setLoading(false);
  }

  async function changeAppList(type: string, action: 'add' | 'remove') {
    if (action === 'add') setAppTypes([...appTypes, type]);

    if (action === 'remove')
      setAppTypes(appTypes.filter((item: string) => item !== type));
  }

  async function changePushList(type: string, action: 'add' | 'remove') {
    if (action === 'add') setPushTypes([...pushTypes, type]);

    if (action === 'remove')
      setPushTypes(pushTypes.filter((item: string) => item !== type));
  }

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-4 sm:p-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Notificações
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Escolha quando este usuário receberá notificações
            </p>
          </div>

          {loading ? (
            <SpinnerLoad />
          ) : (
            <div className="mt-6 pl-2">
              <fieldset>
                <legend className="text-base font-medium pt-2 text-gray-900">
                  Notificações internas, no App
                </legend>

                <div className="mb-6">
                  <select
                    id="appWhen"
                    name="appWhen"
                    className="mt-1 block flex-1 pl-2 pr-2 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                    defaultValue={appWhen}
                    value={appWhen}
                    onChange={e => setAppWhen(e.target.value)}
                  >
                    {/* <option value="available">Quando Disponível</option> */}
                    <option value="ever">Sempre</option>
                    <option value="never">Nunca</option>
                  </select>
                </div>

                <div className="mt-2 space-y-1">
                  <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="app_new_called"
                        name="new_called"
                        type="checkbox"
                        checked={
                          appTypes?.includes('new_called') ? true : false
                        }
                        onChange={
                          appTypes?.includes('new_called')
                            ? () => changeAppList('new_called', 'remove')
                            : () => changeAppList('new_called', 'add')
                        }
                        className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="new_called"
                        className="font-medium text-gray-700"
                      >
                        Novos chamados
                      </label>
                      <p className="text-gray-500"></p>
                    </div>
                  </div>
                </div>
                <div className="mt-2 space-y-1">
                  <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="app_new_indicated_os"
                        name="new_indicated_os"
                        type="checkbox"
                        checked={
                          appTypes?.includes('new_indicated_os') ? true : false
                        }
                        onChange={
                          appTypes?.includes('new_indicated_os')
                            ? () => changeAppList('new_indicated_os', 'remove')
                            : () => changeAppList('new_indicated_os', 'add')
                        }
                        className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="new_indicated_os"
                        className="font-medium text-gray-700"
                      >
                        Indicação de OS
                      </label>
                      <p className="text-gray-500">
                        Notificar quando esta pessoa for indicada para
                        atendimento de OS
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-2 space-y-1">
                  <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="app_new_message_you_os"
                        name="new_message_you_os"
                        type="checkbox"
                        checked={
                          appTypes?.includes('new_message_you_os')
                            ? true
                            : false
                        }
                        onChange={
                          appTypes?.includes('new_message_you_os')
                            ? () =>
                                changeAppList('new_message_you_os', 'remove')
                            : () => changeAppList('new_message_you_os', 'add')
                        }
                        className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="new_message_you_os"
                        className="font-medium text-gray-700"
                      >
                        Nova mensagem em OS.
                      </label>
                      <p className="text-gray-500">
                        Notificar esta pessoa ao ser mencionada em uma mensagem
                      </p>
                    </div>
                  </div>
                </div>
              </fieldset>

              <fieldset>
                <legend className="text-base font-medium pt-8 text-gray-900">
                  Notificações Push
                </legend>

                <div className="mb-6">
                  <select
                    id="pushWhen"
                    name="pushWhen"
                    className="mt-1 block flex-1 pl-2 pr-2 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                    defaultValue={pushWhen}
                    value={pushWhen}
                    onChange={e => setPushWhen(e.target.value)}
                  >
                    {/* <option value="available">Quando Disponível</option> */}
                    <option value="ever">Sempre</option>
                    <option value="never">Nunca</option>
                  </select>
                </div>

                <div className="mt-2 space-y-1">
                  <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="push_new_called"
                        name="new_called"
                        type="checkbox"
                        checked={
                          pushTypes?.includes('new_called') ? true : false
                        }
                        onChange={
                          pushTypes?.includes('new_called')
                            ? () => changePushList('new_called', 'remove')
                            : () => changePushList('new_called', 'add')
                        }
                        className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="new_called"
                        className="font-medium text-gray-700"
                      >
                        Novos chamados
                      </label>
                      <p className="text-gray-500"></p>
                    </div>
                  </div>
                </div>
                <div className="mt-2 space-y-1">
                  <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="push_new_indicated_os"
                        name="new_indicated_os"
                        type="checkbox"
                        checked={
                          pushTypes?.includes('new_indicated_os') ? true : false
                        }
                        onChange={
                          pushTypes?.includes('new_indicated_os')
                            ? () => changePushList('new_indicated_os', 'remove')
                            : () => changePushList('new_indicated_os', 'add')
                        }
                        className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="new_indicated_os"
                        className="font-medium text-gray-700"
                      >
                        Indicação de OS
                      </label>
                      <p className="text-gray-500">
                        Notificar quando esta pessoa for indicada para
                        atendimento de OS
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-2 space-y-1">
                  <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="push_new_message_you_os"
                        name="new_message_you_os"
                        type="checkbox"
                        checked={
                          pushTypes?.includes('new_message_you_os')
                            ? true
                            : false
                        }
                        onChange={
                          pushTypes?.includes('new_message_you_os')
                            ? () =>
                                changePushList('new_message_you_os', 'remove')
                            : () => changePushList('new_message_you_os', 'add')
                        }
                        className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="new_message_you_os"
                        className="font-medium text-gray-700"
                      >
                        Nova mensagem em OS.
                      </label>
                      <p className="text-gray-500">
                        Notificar esta pessoa ao ser mencionada em uma mensagem
                      </p>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          )}
        </div>
        <div className="px-4 py-3 bg-white text-right sm:px-6">
          <button
            type="submit"
            disabled={loading}
            onClick={() => saveData()}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  );
}
