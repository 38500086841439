import React, { useEffect, useState } from 'react';
import api from '../../../services/api';
import { IAtendimento } from '../../../types/Atendimento/IAtendimento';
import Atendimento from '../Row';

interface IProps {
  id_os: number;
}

export default function ListAttendances({ id_os }: IProps) {
  const [list, setList] = useState<IAtendimento[] | []>([]);

  useEffect(() => {
    listAttendances();
  }, []);

  async function listAttendances() {
    if (!id_os) return;

    try {
      const response = await api.get(`atendimento?id_os=${id_os}`);

      setList(response.data.data);
    } catch (err) {
      return;
    }
  }

  return (
    <div className="bg-white ml-1 shadow overflow-hidden sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {list?.map((item: IAtendimento) => (
          <Atendimento atendimento={item} key={item.id_atendimento} />
        ))}
      </ul>
    </div>
  );
}
