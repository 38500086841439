import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import ConvertDateTime from '../../../../functions/ConvertDateTime';
import GetUserPerCode from '../../../../functions/GetUserPerCode';
import TypesViewMore from '../TypesViewMore';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const RowTable = ({ log, setLogView }) => {
  const [createDate, setCreateDate] = useState('');
  const [nameAuthor, setNameAuthor] = useState('');

  useEffect(() => {
    convertDate(log.dateTime);
    convertUser(log.id_user_author);
  }, [log]);

  async function convertDate(dateTime) {
    const data_convertida = await ConvertDateTime(dateTime);
    setCreateDate(data_convertida);
  }

  async function convertUser(id_user) {
    const { nome_apelido } = await GetUserPerCode(id_user);
    setNameAuthor(nome_apelido);
  }

  return (
    <TableRow key={log.id}>
      <TableCell
        align="left"
        style={{ fontSize: 12 }}
        component="th"
        scope="row"
      >
        {nameAuthor}
      </TableCell>
      <TableCell align="left" style={{ fontSize: 12 }}>
        {log.description}
      </TableCell>
      <TableCell align="left" style={{ fontSize: 10, color: '#000' }}>
        {createDate}
      </TableCell>
      <TableCell align="right">
        <Button
          style={{ border: 0, backgroundColor: '#F5F5F5' }}
          onClick={() => setLogView(log)}
        >
          {TypesViewMore.includes(log.type) ? (
            <RightOutlined style={{ fontSize: 14, color: '#000' }} />
          ) : null}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default RowTable;
