/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { CashIcon } from '@heroicons/react/solid';
import api from '../../../../services/api';
import { feedbackError } from '../../../../functions/MessageFeedback';

interface IProps {
  id_funcionario: number;
  month: number | string;
  year: number | string;
}

export default function RowList(props: IProps) {
  const [totalGenerated, setTotalGenerated] = useState('');
  const [totalApproved, setTotalApproved] = useState('');
  const [nome_apelido, setNome_apelido] = useState('');

  const { id_funcionario, month, year } = props;

  useEffect(() => {
    getTotalGenerated();

    getTotalApproved();

    getFunc();
  }, [props]);

  async function getFunc() {
    try {
      const response = await api.get(`collaborator/min/${id_funcionario}`);

      const { nome_apelido } = response.data;

      setNome_apelido(nome_apelido);
    } catch (error) {
      return;
    }
  }

  async function getTotalGenerated() {
    try {
      const response = await api.get(
        `stats/total_generate?modo_edicao=false&month=${month}&year=${year}&id_funcionario=${id_funcionario}`,
      );

      const { data } = response;

      setTotalGenerated(
        data.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      );
    } catch (error) {
      feedbackError('Ocorreu um erro ao carregar os dados');
    }
  }

  async function getTotalApproved() {
    try {
      const response = await api.get(
        `stats/total_approved?modo_edicao=false&month=${month}&year=${year}&id_funcionario=${id_funcionario}`,
      );

      const { data } = response;

      setTotalApproved(
        data.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }),
      );
    } catch (error) {
      feedbackError('Ocorreu um erro ao carregar os dados');
    }
  }

  return (
    <tr className="bg-white">
      <td className="max-w-0 w-full px-6 pt-2 whitespace-nowrap text-sm text-gray-900">
        <div className="flex">
          <a href="" className="group inline-flex space-x-2 truncate text-sm">
            <CashIcon
              className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />
            <p className="text-gray-500 truncate group-hover:text-gray-900">
              {nome_apelido}
            </p>
          </a>
        </div>
      </td>
      <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
        <span className="text-gray-900 font-medium">{totalGenerated}</span>
      </td>
      <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
        <span className="text-gray-900 font-medium">{totalApproved}</span>
      </td>
    </tr>
  );
}
