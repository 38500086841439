import React, { useEffect, useState } from 'react';
import { PlusIcon } from '@heroicons/react/outline';
import { IItem } from '../../../../types/PurchaseOrder/IItem';
import ItemsRow from '../ItemsRow';

interface IProps {
  listItems: IItem[] | [];
  setListItems(any): void;
}

export default function ItemsTable(props: IProps) {
  const [countChanges, setCountChanges] = useState<number>(0);
  const [showValorTotal, setShowValorTotal] = useState('');

  const { listItems, setListItems } = props;

  useEffect(() => {
    let valorTotal = 0;

    listItems?.map((item: IItem, idx: number) => {
      const total_item = item.unitaryValue * item.quantity;

      valorTotal = valorTotal + total_item;
    });

    if (valorTotal) setShowValorTotal(valorTotal.toFixed(2));
  }, [listItems]);

  async function addItem() {
    const list: IItem[] = listItems;

    const defaultData = {
      quantity: null,
      unityValue: '0.00',
      code: '',
      description: '',
    };

    list.push(defaultData);

    setListItems(list);

    setCountChanges(countChanges + 1);
  }

  return (
    <div className="flex flex-row w-full">
      <div className="mx-4 mt-2 w-full flex flex-col sm:mx-6 md:mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          {listItems.length > 0 ? (
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 justify-start text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                ></th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 justify-start text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                  Quant.
                </th>
                <th
                  scope="col"
                  className="hidden py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Descrição
                </th>
                <th
                  scope="col"
                  className="hidden py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  OS.
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-3 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                >
                  Valor Unitário
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-3 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                >
                  R$ Total
                </th>
              </tr>
            </thead>
          ) : null}
          <tbody>
            {listItems && listItems.length >= 1
              ? listItems.map((item, index) => (
                  <ItemsRow
                    item={item}
                    pos={index}
                    listItems={listItems}
                    setListItems={setListItems}
                    key={index}
                  />
                ))
              : null}
          </tbody>
          {listItems.length > 0 ? (
            <tfoot>
              <tr>
                <th
                  scope="row"
                  colSpan={4}
                  className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
                >
                  Total
                </th>
                <th
                  scope="row"
                  className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
                >
                  Total
                </th>
                <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                  R$ {showValorTotal}
                </td>
              </tr>
            </tfoot>
          ) : null}
        </table>
        <button
          type="button"
          onClick={() => addItem()}
          className="inline-flex items-center w-1/4 px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
          Incluir novo Item
        </button>
      </div>
    </div>
  );
}
