/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../../functions/MessageFeedback';
import api from '../../../../services/api';
import TransformToCodes from '../../functions/TransformToCodes';
import { IPurchaseOrder } from '../../../../types/PurchaseOrder/IPurchaseOrder';
import SelectOutfitter from '../SelectOutfitter';
import SelectAuthor from '../SelectAuthor';
import { format } from 'date-fns';
import ItemsTable from '../ItemsTable';

interface IProps {
  open: boolean;
  setOpen(open: boolean): void;
  codesPurchase?: string;
  setCodesPurchase?(codesPurchase: string | null): void;
  getList(): void;
}

export default function SlideOver(props: IProps) {
  const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrder>();
  const [loading, setLoading] = useState(false);
  const [idOutfitter, setIdOutfitter] = useState<number | null>();
  const [purchaseBy, setPurchaseBy] = useState<number | null>();
  const [listItems, setListItems] = useState<any>([]);

  const { open, setOpen, codesPurchase, setCodesPurchase, getList } = props;

  useEffect(() => {
    if (!open) {
      setCodesPurchase(null);
      setIdOutfitter(null);
    }

    if (open && !codesPurchase) {
      setCodesPurchase(null);
    }

    if (open && codesPurchase) getPurchaseOrder();
  }, [open]);

  async function getPurchaseOrder() {
    if (!codesPurchase) return;

    const { sequencialCode, yearCode } = await TransformToCodes(codesPurchase);

    try {
      const response = await api.get(
        `purchase_order/bycode?sequencialCode=${sequencialCode}&yearCode=${yearCode}`,
      );

      const { data } = response;

      setPurchaseOrder(data);

      if (data?.id_outfitter) setIdOutfitter(data.id_outfitter);

      if (data?.purchaseDate) {
        setPurchaseOrder({
          ...purchaseOrder,
          purchaseDate: data.purchaseDate,
        });
      } else {
        setPurchaseOrder({
          ...purchaseOrder,
          purchaseDate: format(new Date(), 'yyyy-MM-dd'),
        });
      }

      if (data?.items) {
        setListItems(data.items);
      } else {
        setListItems([]);
      }
    } catch (error) {
      feedbackError('Erro ao carregar os dados');
      setOpen(false);
    }
  }

  function handleChange(event) {
    setPurchaseOrder({
      ...purchaseOrder,
      [event.target.name]: event.target.value,
    });
  }

  async function handleSubmit() {
    setLoading(true);

    const data = purchaseOrder;

    data.status = 'enabled';
    data.id_outfitter = idOutfitter;
    data.items = listItems;

    try {
      if (codesPurchase) {
        const { sequencialCode, yearCode } = await TransformToCodes(
          codesPurchase,
        );

        if (purchaseOrder.approved === true) {
          feedbackError('Ordem aprovada não pode ser editada');
        } else {
          await api.put(`purchase_order/${sequencialCode}/${yearCode}`, data);

          feedbackSuccess('Dados atualizados');
        }
      } else {
        await api.post(`purchase_order`, data);

        feedbackSuccess('Criado com sucesso');
      }
    } catch (error) {
      feedbackError('Erro ao salvar. Caso o erro persista, contate o suporte.');
    }

    setLoading(false);

    if (getList) getList();

    setPurchaseOrder(null);

    setOpen(false);

    if (setCodesPurchase) setCodesPurchase(null);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-3xl">
                <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                  <div className="h-0 flex-1 overflow-y-auto">
                    <div className="bg-blue-700 py-4 px-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">
                          {codesPurchase
                            ? `Pedido: ${codesPurchase} `
                            : 'Novo Pedido'}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-blue-700 text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Fechar</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                      <div className="divide-y divide-gray-200 px-4 sm:px-6">
                        <div className="space-y-6 pt-6 pb-5">
                          <div>
                            <SelectOutfitter
                              idOutfitter={idOutfitter}
                              setIdOutfitter={setIdOutfitter}
                            />
                          </div>

                          <div className="flex flex-row w-full">
                            <div className="flex w-1/3 flex-col mr-2">
                              <label
                                htmlFor="sellerName"
                                className="block text-xs font-medium text-gray-900"
                              >
                                Vendedor
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="sellerName"
                                  id="sellerName"
                                  value={purchaseOrder?.sellerName}
                                  defaultValue={purchaseOrder?.sellerName}
                                  onChange={e => handleChange(e)}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-xs"
                                />
                              </div>
                            </div>

                            <div className="flex w-1/3 flex-col mr-2">
                              <label
                                htmlFor="purchaseBy"
                                className="block text-xs font-medium text-gray-900"
                              >
                                Comprador
                              </label>
                              <SelectAuthor
                                idUser={purchaseBy}
                                setIdUser={setPurchaseBy}
                              />
                            </div>

                            <div className="flex w-1/3 flex-col">
                              <label
                                htmlFor="purchaseDate"
                                className="block text-xs font-medium text-gray-900"
                              >
                                Data da compra
                              </label>
                              <div className="mt-1">
                                <input
                                  type="date"
                                  name="purchaseDate"
                                  id="purchaseDate"
                                  value={purchaseOrder?.purchaseDate}
                                  defaultValue={purchaseOrder?.purchaseDate}
                                  autoComplete="purchaseDate"
                                  onChange={e => handleChange(e)}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-row w-full">
                            <div className="flex w-1/3 flex-col mr-2">
                              <label
                                htmlFor="deliveryDate"
                                className="block text-xs font-medium text-gray-900"
                              >
                                Prazo de entrega
                              </label>
                              <div className="mt-1">
                                <input
                                  type="date"
                                  name="deliveryDate"
                                  id="deliveryDate"
                                  value={purchaseOrder?.deliveryDate}
                                  defaultValue={purchaseOrder?.deliveryDate}
                                  autoComplete="deliveryDate"
                                  onChange={e => handleChange(e)}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                />
                              </div>
                            </div>

                            <div className="flex w-1/3 flex-col mr-2">
                              <label
                                htmlFor="shippingFare"
                                className="block text-xs font-medium text-gray-900"
                              >
                                Frete
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="shippingFare"
                                  id="shippingFare"
                                  value={purchaseOrder?.shippingFare}
                                  defaultValue={purchaseOrder?.shippingFare}
                                  onChange={e => handleChange(e)}
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-xs"
                                />
                              </div>
                            </div>

                            <div className="flex w-1/3 flex-col">
                              <label
                                htmlFor="formPayment"
                                className="block text-xs font-medium text-gray-900"
                              >
                                Forma pagamento
                              </label>
                              <div className="mt-1">
                                <textarea
                                  id="formPayment"
                                  name="formPayment"
                                  rows={2}
                                  value={purchaseOrder?.formPayment}
                                  defaultValue={purchaseOrder?.formPayment}
                                  onChange={e => handleChange(e)}
                                  className="block w-full p-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-xs"
                                />
                              </div>
                            </div>
                          </div>
                          <ItemsTable
                            listItems={listItems}
                            setListItems={setListItems}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-shrink-0 justify-end px-4 py-4">
                    <button
                      type="button"
                      className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      onClick={() => setOpen(false)}
                    >
                      Cancelar
                    </button>

                    <button
                      disabled={loading}
                      onClick={() => handleSubmit()}
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      Salvar
                    </button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
