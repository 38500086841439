/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IOutfitter } from '../../../../types/Outfitter/IOutfitter';
import { feedbackError } from '../../../../functions/MessageFeedback';
import api from '../../../../services/api';
import { PencilAltIcon } from '@heroicons/react/solid';
import SlideOver from '../SlideOver';
import Card from './Card';
interface IProps {
  id_outfitter: number;
  getList(filter?: string | null): void;
}

export default function Body(props: IProps) {
  const [outfitter, setOutfitter] = useState<IOutfitter>();
  const [open, setOpen] = useState(false);

  const { id_outfitter, getList } = props;

  useEffect(() => {
    getOutfitter();
  }, [props]);

  async function getOutfitter() {
    if (!id_outfitter) return;

    try {
      const response = await api.get(`outfitter/${id_outfitter}`);

      setOutfitter(response.data);
    } catch (error) {
      feedbackError('Erro ao carregar os dados do fornecedor');
    }
  }

  function editOutfitter() {
    setOpen(true);
  }

  return (
    <article>
      <div className="bg-white">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="mt-0 sm:mt-0 sm:flex sm:items-end sm:space-x-5">
            <div className="mt-2 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="sm:hidden 2xl:block min-w-0 mb-2 flex-1">
                <h1 className="text-xl font-bold text-gray-900 truncate">
                  {outfitter?.nome_apelido}
                </h1>
              </div>
              <div className="mt-3 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                <button
                  onClick={() => editOutfitter()}
                  type="button"
                  className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <PencilAltIcon
                    className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>Editar</span>
                </button>
              </div>
            </div>
          </div>
          <div className="hidden sm:block 2xl:hidden mt-2 mb-2 min-w-0 flex-1">
            <h1 className="text-xl font-bold text-gray-900 truncate">
              {outfitter?.nome_apelido}
            </h1>
          </div>
        </div>
      </div>

      <div className="mt-4 bg-white sm:mt-1 2xl:mt-3">
        <div className="border-b border-gray-200">
          <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              <a
                className="border-blue-500 text-gray-900 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                aria-current="page"
              >
                Geral
              </a>
            </nav>
          </div>
        </div>
      </div>

      <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <Card
            title={outfitter?.cnpj ? 'CNPJ' : 'CPF'}
            valueLine1={outfitter?.cnpj ? outfitter?.cnpj : outfitter?.cpf}
          />

          <Card
            title={outfitter?.cnpj ? 'Razão Social' : 'Nome Completo'}
            valueLine1={
              outfitter?.cnpj
                ? outfitter?.razao_social
                : outfitter?.nome_completo
            }
          />

          <Card
            title="Endereço"
            valueLine1={outfitter?.logradouro + ', ' + outfitter?.numero}
            valueLine2={outfitter?.complemento}
            valueLine3={
              outfitter?.bairro +
              ' - ' +
              outfitter?.cidade +
              ' - ' +
              outfitter?.estado
            }
            valueLine4={outfitter?.cep}
          />

          <Card
            title="Contato"
            valueLine1={outfitter?.nome_contato}
            valueLine2={'Fone: ' + outfitter?.fone_contato}
            valueLine3={
              outfitter?.complemento_contato
                ? `Ramal ${outfitter?.complemento_contato}`
                : null
            }
            valueLine4={'E-mail: ' + outfitter?.email_contato}
          />

          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">
              {outfitter?.observacao ? 'Observação' : null}
            </dt>
            <dd className="mt-1 max-w-prose text-sm text-gray-900 space-y-5">
              {outfitter?.observacao}
            </dd>
          </div>
        </dl>
      </div>
      <SlideOver
        open={open}
        setOpen={setOpen}
        id_outfitter_edit={id_outfitter}
        getList={getList}
      />
    </article>
  );
}
