import React, { useState, useEffect } from 'react';

import { BoxViewContainer } from '../../styles';
import ConvertDateTime from '../../../../functions/ConvertDateTime';
import GetUserPerCode from '../../../../functions/GetUserPerCode';
import Alter_status_funcionamento from './Alter_status_funcionamento';
import Scheduling_os from './Scheduling_os';
import Alter_board_os from './Alter_board_os';
import Alter_req_peca from './Alter_req_peca';
import Alter_os from './Alter_os';

const BoxView = ({ log }) => {
  const [createDate, setCreateDate] = useState('');
  const [nameAuthor, setNameAuthor] = useState('');

  useEffect(() => {
    convertDate(log.dateTime);
    convertUser(log.id_user_author);
  }, [log]);

  async function convertDate(dateTime) {
    if (!dateTime) return;

    const data_convertida = await ConvertDateTime(dateTime);
    setCreateDate(data_convertida);
  }

  async function convertUser(id_user) {
    if (!id_user) return;

    const { nome_apelido } = await GetUserPerCode(id_user);
    setNameAuthor(nome_apelido);
  }

  return (
    <BoxViewContainer>
      {log.type === 'alter_status_funcionamento' ? (
        <Alter_status_funcionamento log={log} />
      ) : null}
      {log.type === 'scheduling_os' ? <Scheduling_os log={log} /> : null}
      {log.type === 'alter_board_os' ? <Alter_board_os log={log} /> : null}
      {log.type === 'alter_req_peca' ? <Alter_req_peca log={log} /> : null}
      {log.type === 'alter_os' ? <Alter_os log={log} /> : null}
    </BoxViewContainer>
  );
};

export default BoxView;
