import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 5px;
`;

export const Title = styled.div`
  font-weight: bold;
  align-items: flex-start;
  align-content: flex-start;
  margin-right: 5px;
`;

export const Description = styled.div`
  font-size: 12px;
`;
