import { feedbackError } from '../../../../../functions/MessageFeedback';
import api from '../../../../../services/api';
import { IGrupoUsuario } from '../../../../../types/User/IGrupoUsuario';

async function GetPermissionsGroups(): Promise<IGrupoUsuario[] | void> {
  try {
    const response = await api.get(`/user/group`);

    const { data } = response.data;

    return data;
  } catch (err) {
    feedbackError('Erro ao buscar os grupos de usuário');
  }

  return;
}

export default GetPermissionsGroups;
