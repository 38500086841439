/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { TextField, Button, Select, MenuItem } from '@material-ui/core';
import { Form } from '@unform/web';
import { feedbackSuccess } from '../../../../../functions/MessageFeedback';

import { Container, Box } from './styles';
import api from '../../../../../services/api';
import { useOSGerir } from '../../../../../contexts/OSGerir';
import { useOSView } from '../../../../../contexts/OSView';
import { useAuth } from '../../../../../contexts/Auth';
import SwitchInputDefault from '../../../../../components/SwitchInputDefault';

export default function BoxAprovacaoReqPeca() {
  const [aprovado, setAprovado] = useState(false);
  const [legenda_comercial, setLegenda_comercial] = useState('');
  const [message, setMessage] = useState('');
  const [messageSeparacao, setMessageSeparacao] = useState('');
  const [statusOS, setStatusOS] = useState();

  const { loading, setloading, id_os_view, getReqPecas } = useOSGerir();
  const { setRefresh, refresh } = useOSView();

  const { user } = useAuth();

  //Atualiza quando tem alteração
  useEffect(() => {
    getReq();
  }, [loading]);

  async function getReq() {
    try {
      const response = await getReqPecas();
      const { aprovado, legenda_comercial, status } = response.data.data[0];

      if (aprovado) setAprovado(true);

      setLegenda_comercial(legenda_comercial);

      setStatusOS(status);

      getQuadro();

      getQuadroSeparacao();
    } catch (err) {
      return;
    }
  }

  async function getQuadro() {
    if (!id_os_view) return;

    try {
      const response = await api.get(`os/board/${id_os_view}?type=comercial`);
      const { message } = response.data;

      setMessage(message);
    } catch (err) {
      return;
    }
  }

  async function getQuadroSeparacao() {
    if (!id_os_view) return;

    try {
      const response = await api.get(`os/board/${id_os_view}?type=separacao`);
      const { message } = response.data;

      setMessageSeparacao(message);
    } catch (err) {
      return;
    }
  }

  async function handleSave() {
    const id_os = parseInt(id_os_view);

    const req = {
      id_os: id_os_view,
      type: 'comercial',
      legenda_comercial,
      aprovado,
    };

    const board = {
      type: 'comercial',
      message,
      id_os,
    };

    try {
      await api.put(`req_peca/${id_os_view}/changestatus`, req);

      feedbackSuccess('Informações salvas com sucesso');

      setRefresh(!refresh);
      setloading(false);

      await api.post(`os/board`, board);

      feedbackSuccess('Quadro salvo com sucesso');
    } catch {
      setRefresh(!refresh);
      setloading(false);
    }
  }

  return (
    <>
      <Form onSubmit={handleSave}>
        {messageSeparacao ? (
          <Container>
            <Box>
              <TextField
                disabled
                style={{
                  minWidth: 100,
                  width: 550,
                  fontSize: 5,
                }}
                variant="outlined"
                multiline
                rows={3}
                value={messageSeparacao}
              />
            </Box>
          </Container>
        ) : null}

        <Container>
          <Box>
            Aprovado
            <SwitchInputDefault enabled={aprovado} setEnabled={setAprovado} />
          </Box>

          <Box>
            <TextField
              style={{
                minWidth: 100,
                width: 300,
                fontSize: 5,
                backgroundColor: '#fff',
              }}
              label="Quadro (Somente para uso da Elite)"
              variant="outlined"
              multiline
              rows={4}
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
          </Box>

          <Box>
            <Select
              style={{ width: 150, height: 40 }}
              labelId="Legenda"
              id="legenda"
              variant="outlined"
              value={legenda_comercial}
              onChange={e => setLegenda_comercial(e.target.value)}
            >
              <MenuItem value="">-</MenuItem>
              <MenuItem value="r">R - Reenviar</MenuItem>
              <MenuItem value="e">E - Enviado</MenuItem>
              <MenuItem value="ne">NE - Não Enviado</MenuItem>
              <MenuItem value="o">O - Orçamento</MenuItem>
              <MenuItem value="mi">MI - Manutenção Integral</MenuItem>
              <MenuItem value="c">C - Cliente</MenuItem>
              <MenuItem value="a">A - Aplicado</MenuItem>
              <MenuItem value="g">G - Garantia</MenuItem>
            </Select>

            {user.id_grupo_usuario == 50 || user.id_grupo_usuario == 10
              ? statusOS !== 'cancelado' && (
                  <Button
                    variant="contained"
                    type="submit"
                    style={{ marginTop: 10, marginLeft: 6, padding: 2 }}
                  >
                    Salvar
                  </Button>
                )
              : null}
          </Box>
        </Container>
      </Form>
    </>
  );
}
