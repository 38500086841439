import React, { useState, useEffect } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { message } from 'antd';

import api from '../services/api';
import socket from '../services/socket';

import { useOSBuilder } from '../contexts/OSBuilder';

export default function InputStatusEquipamento(props) {
  const [funcionamentoAtual, setFuncionamentoAtual] = useState('');

  let { id_equipamento_edit } = useOSBuilder();

  useEffect(() => {
    getEquip(id_equipamento_edit);
    getSocket(id_equipamento_edit);
  }, [id_equipamento_edit]);

  const feedback = funcionamento => {
    message.info(`Funcionamento do equipamento definido como ${funcionamento}`);
  };

  async function handleFuncionamento(funcionamento) {
    try {
      const response = await api.put(
        `equipamento/changefuncionamento/${id_equipamento_edit}`,
        { funcionamento },
      );
      setFuncionamentoAtual(funcionamento);

      feedback(funcionamento);

      getEquip(id_equipamento_edit);
    } catch (err) {
      console.log(err);
    }
  }

  async function getEquip(id_equipamento) {
    try {
      const response = await api.get(`equipamento/${id_equipamento}`);
      const { data } = response;

      setFuncionamentoAtual(data.funcionamento);
    } catch (err) {
      console.log(err);
    }
  }

  function getSocket(id_equipamento) {
    socket.on(
      'list_funcionamento_equipamentos',
      list_funcionamento_equipamentos => {
        getEquip(id_equipamento);
      },
    );
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
      }}
    >
      <Select
        style={{ width: '60%', height: 40 }}
        required
        labelId="Funcionamento"
        id="funcionamento"
        variant="outlined"
        value={funcionamentoAtual}
        onChange={e => handleFuncionamento(e.target.value)}
      >
        <MenuItem value="normal">Normal</MenuItem>
        <MenuItem value="parcial">Parcial</MenuItem>
        <MenuItem value="inoperante">Inoperante</MenuItem>
      </Select>

      <text style={{ fontSize: 16, marginLeft: 10 }}>Funcionamento</text>
    </div>
  );
}
