import React, { useState } from 'react';
import NavigationTopBar from '../NavigationTopBar';
import { Container } from './styles';

export default function Body() {
  const [section, setSection] = useState('parts');

  return (
    <Container>
      <NavigationTopBar
        section={section}
        setSection={setSection}
      ></NavigationTopBar>
    </Container>
  );
}
