import React, { useState, useEffect } from 'react';
import { RedoOutlined } from '@ant-design/icons';
import { Button, Tooltip, Modal } from 'antd';

import { useAuth } from '../../contexts/Auth';
import { useGlobalAttributes } from '../../contexts/GlobalAttributes';
import api from '../../services/api';
import WindowSchedule from './components/WindowSchedule';
import {
  feedbackError,
  feedbackSuccess,
} from '../../functions/MessageFeedback';

export default function ScheduleOs(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [funcionario_agendamento, setFuncionario_agendamento] = useState();
  const [id_os, setId_os] = useState();

  const { user } = useAuth();
  const { setRefresh, refresh } = useGlobalAttributes();

  const { os } = props;

  useEffect(() => {
    setId_os(os.id_os);

    if (os.funcionario_agendamento === 0) {
      setFuncionario_agendamento(null);
    } else {
      setFuncionario_agendamento(os.funcionario_agendamento);
    }
  }, []);

  async function handleSave() {
    const os = {
      funcionario_agendamento: funcionario_agendamento
        ? funcionario_agendamento
        : null,
    };

    try {
      await api.put(`os/manager/${id_os}/agendar`, os);
      feedbackSuccess('Indicação realizada com sucesso');
      setRefresh(!refresh);
    } catch (err) {
      feedbackError('Erro ao indicar');
    }
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    handleSave();

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {user.id_grupo_usuario <= 58 ? (
        <Tooltip
          placement="right"
          title={'Indicar técnico para a OS ' + os.id_os}
        >
          <Button type="text" onClick={showModal}>
            <RedoOutlined style={{ fontSize: 12 }} />
          </Button>
        </Tooltip>
      ) : null}

      <Modal
        title={'Indicar técnico para a OS ' + os.id_os}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <WindowSchedule
          os={os}
          func={funcionario_agendamento}
          setFunc={setFuncionario_agendamento}
        />
      </Modal>
    </>
  );
}
