import React, { useEffect, useState } from 'react';
import ConvertDateTime from '../../../functions/ConvertDateTime';
import GetExtraServiceConcludeByOrigin from '../../../functions/GetExtraServiceConcludeByOrigin';
import { IAtendimento } from '../../../types/Atendimento/IAtendimento';
import { IExtraService } from '../../../types/ExtraService/IExtraService';
import AvatarCollaborator from '../../../components/AvatarCollaborator';
import ImagesAttendanceList from '../ImagesAttendanceList';

interface IProps {
  atendimento: IAtendimento;
}
export default function Row(props: IProps) {
  const [startAttendance, setStartAttendance] = useState('');
  const [finishAttendance, setFinishAttendance] = useState('');
  const [extraServices, setExtraServices] = useState<IExtraService[] | []>([]);

  const { atendimento } = props;

  useEffect(() => {
    getListAux();

    convertData();
  }, [props]);

  async function getListAux() {
    const list = await GetExtraServiceConcludeByOrigin(
      'atendimento',
      atendimento.id_atendimento,
    );

    if (list.length > 1) setExtraServices(list);
  }

  async function convertData() {
    const data_chegada = await ConvertDateTime(atendimento.data_chegada);

    setStartAttendance(data_chegada);

    const data_saida = await ConvertDateTime(atendimento?.data_saida);

    setFinishAttendance(data_saida);
  }

  return (
    <li key={atendimento.id_atendimento}>
      <div
        className={
          atendimento.status === 'review'
            ? 'border-2 border-orange-600'
            : 'border'
        }
      >
        <div className="block hover:bg-gray-50 mb-2">
          <div className="px-4 py-4 sm:px-6">
            <div className="flex items-center justify-between">
              {atendimento.status === 'pendente' && (
                <p className="text-sm font-semibold text-blue-600 truncate">
                  Em Andamento
                </p>
              )}
              {atendimento.status === 'concluido' && (
                <p className="text-sm font-semibold text-green-600 truncate">
                  Atendimento Finalizado
                </p>
              )}
              {atendimento.status === 'cancelado' && (
                <p className="text-sm font-semibold text-red-600 truncate">
                  Cancelado
                </p>
              )}
              {atendimento.status === 'review' && (
                <p className="text-sm font-semibold text-gray-600 truncate">
                  Revisão
                </p>
              )}

              <div className="ml-2 flex-shrink-0 flex items-baseline text-sm text-gray-500">
                <span className="mr-1 font-semibold">Téc. Resp:</span>
                <AvatarCollaborator
                  id_collaborator={atendimento.id_funcionario_resp}
                />
              </div>
            </div>

            <div className="mt-0 sm:flex sm:justify-between">
              <div className="sm:flex w-2/3">
                <p className="flex items-center text-sm text-gray-500">
                  <div className="flex flex-col">
                    <span>{atendimento.parecer}</span>
                    <span>{atendimento.observacao}</span>

                    <div className="flex flex-row items-baseline">
                      <span className="mt-2 mr-1 font-semibold">Início: </span>
                      <span>{startAttendance}</span>
                    </div>
                    <div className="flex flex-row items-baseline">
                      <span className="mr-1 font-semibold">Fim: </span>
                      <span>{finishAttendance}</span>
                    </div>

                    {atendimento.status !== 'review' && (
                      <>
                        <div className="flex flex-row items-baseline">
                          <span className="mt-2 mr-1 font-semibold">
                            Cliente:{' '}
                          </span>
                          <span>{atendimento.cliente_resp}</span>
                        </div>
                      </>
                    )}

                    <ImagesAttendanceList
                      id_atendimento={atendimento.id_atendimento}
                    />
                  </div>
                </p>
              </div>
              <div className="mt-2 flex items-baseline text-sm text-gray-500 sm:mt-0">
                <span className="mr-2"></span>
                <div className="flex flex-col w-1/3">
                  {extraServices.map(
                    (item: IExtraService) =>
                      item.id_collaborator !==
                        atendimento.id_funcionario_resp && (
                        <div
                          key={item.id}
                          className="flex flex-row items-baseline text-sm text-gray-500"
                        >
                          <AvatarCollaborator
                            id_collaborator={item.id_collaborator}
                          />
                        </div>
                      ),
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
