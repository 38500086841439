import React, { useEffect, useState } from 'react';
import { ExclamationIcon } from '@heroicons/react/solid';

import api from '../../services/api';
import {
  Container,
  Head,
  Edificio,
  ApelidoEdificio,
  LineName,
  BoxAdress,
  BoxAdressLeft,
  BoxAdressRight,
  BoxObs,
  TextAdress,
  TextObs,
} from './styles';

export default function HeadOS(props) {
  const [cliente, setCliente] = useState({});

  useEffect(() => {
    const { id_cliente } = props;

    getCliente(id_cliente);
  }, []);

  function getCliente(id_cliente) {
    api.get(`cliente/min/${id_cliente}`).then(response => {
      setCliente(response.data);
    });
  }

  return (
    <Container>
      {cliente.status === 'inativo' ? (
        <div className="rounded bg-yellow-300 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon
                className="h-6 w-6 text-black-800"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-base text-black-800">
                Atenção! Cliente Inativo.
              </h3>
              <div className="mt-2 text-sm">
                <p>Este cliente que não possui contrato ativo com a empresa.</p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <Head>
        <LineName>
          <Edificio>{cliente.edificio}</Edificio>
          <ApelidoEdificio>{cliente.apelido}</ApelidoEdificio>
        </LineName>
        <BoxAdress>
          <BoxAdressLeft>
            <TextAdress>
              {cliente.logradouro}, {cliente.numero} - Bairro {cliente.bairro} -{' '}
              {cliente.cidade} - {cliente.estado}
            </TextAdress>
          </BoxAdressLeft>
          <BoxAdressRight />
        </BoxAdress>
      </Head>
      <BoxObs>
        {cliente.obs_tecnica ? (
          <div>
            <TextObs>Obs. Téc.: {cliente.obs_tecnica}</TextObs>
          </div>
        ) : (
          <></>
        )}
      </BoxObs>
    </Container>
  );
}
