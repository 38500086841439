/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Popconfirm } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

interface IProps {
  title: string;
  children: any;
  onConfirm(arg0: any): void;
}

export default function ConfirmDialog(props: IProps) {
  const { title, children, onConfirm } = props;

  return (
    <Popconfirm
      title={title}
      onConfirm={onConfirm}
      icon={<QuestionCircleOutlined style={{ color: '#FFBA00' }} />}
    >
      <a href="#">{children}</a>
    </Popconfirm>
  );
}
