import styled from 'styled-components';

export const List_container = styled.div`
  width: 400px;
  background-color: #fff;
  border-right: 1px solid #e8e8e8;
  border-bottom: 2px solid #e8e8e8;
  padding-top: 10px;
`;

export const TopBar = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  border-bottom: 1px solid #e8e8e8;
`;

export const TitleBody = styled.text`
  font-size: 16px;
  margin-bottom: 6px;
`;

export const DescriptionBody = styled.text`
  font-size: 12px;
  font-weight: 300;
  color: #777;
  margin-bottom: 15px;
`;

export const FormFilter = styled.div`
  font-size: 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const BodyList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  height: 70vh;
  width: auto;
  background-color: #fff;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 3px;
    background: #fff;
  }
  ::-webkit-scrollbar-thumb {
    width: 3px;
    background: #ccc;
  }
`;

export const CardList = styled.div`
  width: 100%;
  min-height: 60px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
`;

export const ContentCard = styled.div`
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const NameCard = styled.text`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
`;

export const DescriptionCard = styled.text`
  font-size: 12px;
  color: #777;
  text-transform: capitalize;
`;
