import { PlusIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react';
import { feedbackError } from '../../../../functions/MessageFeedback';
import api from '../../../../services/api';
import { IShowEquipamento } from '../../../../types/IShowEquipamento';
import Card from './components/Card';
import Empty from './components/Empty';
import SlideOverEquipamento from './components/SlideOverEquipamento';
import { useClientRegister } from '../../../../contexts/ClientRegister';

export default function FormEquipamentos() {
  const [equipamentos, setEquipamentos] = useState<[IShowEquipamento] | []>([]);
  const [id_equipamento, setId_equipamento] = useState<number>();

  const { id_cliente, loading, setOpenSliderEquip } = useClientRegister();

  useEffect(() => {
    if (id_cliente) getEquipamentos();
  }, [id_cliente, loading]);

  async function getEquipamentos() {
    try {
      const response = await api.get(
        `equipamento?lines_per_page=9999&id_cliente=${id_cliente}`,
      );

      setEquipamentos(response.data.data);
    } catch (err) {
      feedbackError(
        'Erro ao carregar os equipamentos. Tente novamente mais tarde.',
      );
    }
  }

  async function openNewEquipamento() {
    setOpenSliderEquip(true);

    setId_equipamento(null);
  }

  async function selectEquip(id_equipamento: number) {
    setId_equipamento(id_equipamento);

    setOpenSliderEquip(true);
  }

  return (
    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-10">
      <form action="#" method="POST">
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Equipamentos deste Cliente
              </h3>
              <p className="mt-1 text-sm text-gray-500"></p>
            </div>

            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 sm:col-span-2">
                {equipamentos.length > 0 ? (
                  <>
                    <Card
                      equipamentos={equipamentos}
                      selectEquip={selectEquip}
                    />
                    <div className="mt-2">
                      <button
                        type="button"
                        onClick={() => openNewEquipamento()}
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        <PlusIcon
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        Novo Equipamento
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="text-center">
                    <Empty />
                    <div className="mt-6">
                      <button
                        type="button"
                        onClick={() => openNewEquipamento()}
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        <PlusIcon
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        Novo Equipamento
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <SlideOverEquipamento
                id_equipamento={id_equipamento}
                setId_equipamento_view={setId_equipamento}
              />
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6"></div>
        </div>
      </form>
    </div>
  );
}
