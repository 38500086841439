import styled from 'styled-components';

export const List_container = styled.div`
  flex-direction: row;
`;

export const BodyList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: center;
`;

export const FormFilter = styled.div`
  font-size: 12px;
  padding: 6px;
  border-radius: 4px;
  background-color: #f5f5f5;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
`;

export const NavigationBottomBar = styled.div`
  margin-top: 5px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  width: auto;
`;

export const TopBar = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Tag = styled.div`
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 3px;
  margin-bottom: 1px;
  border-radius: 2px;
  border: 1px solid #c1c1c1;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
`;
