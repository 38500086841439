import api from '../services/api';
import { intervalToDuration } from 'date-fns';

async function GetApelidoEquip(id: number) {
  const storagedEquip = localStorage.getItem(`@elevsis-biz:equipamento/${id}`);

  if (storagedEquip) {
    const equipamento = await JSON.parse(storagedEquip);

    const oldCache = intervalToDuration({
      start: new Date(equipamento.oldCache),
      end: new Date(),
    });

    if (oldCache.hours < 24) {
      const { apelido } = equipamento;

      return apelido;
    } else {
      const apelido = await getApi(id);

      return apelido;
    }
  } else {
    const apelido = await getApi(id);

    return apelido;
  }

  async function getApi(id: number) {
    try {
      const response = await api.get(`equipamento/min/${id}`);

      const { apelido } = response.data;

      response.data.oldCache = new Date();

      localStorage.setItem(
        `@elevsis-biz:equipamento/${id}`,
        JSON.stringify(response.data),
      );

      return apelido;
    } catch (error) {
      return;
    }
  }
}

export default GetApelidoEquip;
