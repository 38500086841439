import api from '../services/api';
import { intervalToDuration } from 'date-fns';

async function GetUserPerCode(id: number) {
  const storagedUser = localStorage.getItem(`@elevsis-biz:user/moreFunc/${id}`);

  if (storagedUser) {
    const user = await JSON.parse(storagedUser);

    const oldCache = intervalToDuration({
      start: new Date(user.oldCache),
      end: new Date(),
    });

    if (oldCache.minutes < 1) {
      const {
        id_funcionario,
        usuario,
        id_grupo_usuario,
        bloqueado,
        desativado,
        nome,
        nome_apelido,
      } = user;

      return {
        id_funcionario,
        usuario,
        id_grupo_usuario,
        bloqueado,
        desativado,
        nome,
        nome_apelido,
      };
    } else {
      const response = await api.get(`user/moreFunc/${id}`);

      response.data.oldCache = new Date();

      localStorage.setItem(
        `@elevsis-biz:user/moreFunc/${id}`,
        JSON.stringify(response.data),
      );

      const {
        id_funcionario,
        usuario,
        id_grupo_usuario,
        bloqueado,
        desativado,
        nome,
        nome_apelido,
      } = response.data;

      return {
        id_funcionario,
        usuario,
        id_grupo_usuario,
        bloqueado,
        desativado,
        nome,
        nome_apelido,
      };
    }
  } else {
    const response = await api.get(`user/moreFunc/${id}`);

    response.data.oldCache = new Date();

    localStorage.setItem(
      `@elevsis-biz:user/moreFunc/${id}`,
      JSON.stringify(response.data),
    );

    const {
      id_funcionario,
      usuario,
      id_grupo_usuario,
      bloqueado,
      desativado,
      nome,
      nome_apelido,
    } = response.data;

    return {
      id_funcionario,
      usuario,
      id_grupo_usuario,
      bloqueado,
      desativado,
      nome,
      nome_apelido,
    };
  }
}

export default GetUserPerCode;
