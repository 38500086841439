import React, { createContext, useState, useEffect, useContext } from 'react';
import { message } from 'antd';

import api from '../services/api';
import { IAuthContextData } from '../types/Context/IAuthContextData';
import { IUserSession } from '../types/User/IUserSession';

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<IUserSession | null>(null);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    (function loadStorageData() {
      const storagedUser = localStorage.getItem('@elevsis-biz:user');

      if (storagedUser) {
        setUser(JSON.parse(storagedUser));
      }
      setloading(false);
    })();
  }, []);

  const errorLogin = () => {
    message.error('Erro ao fazer login');
  };

  async function sign(username: string, password: string) {
    const body = {
      username,
      password,
    };

    const deviceid = {
      headers: {
        deviceid: 'react',
      },
    };

    try {
      const response = await api.post('/user/session', body, deviceid);

      const user_api = response.data;

      localStorage.setItem('@elevsis-biz:user', JSON.stringify(user_api.user));
      localStorage.setItem('@elevsis-biz:token', user_api.token);
      localStorage.setItem('@elevsis-biz:refreshToken', user_api.refresh_token);

      setUser(user_api.user);
    } catch (err) {
      errorLogin();
    }
  }

  async function signOut() {
    try {
      await api.post(`user/session/logout`);
    } catch (err) {
      return;
    }

    localStorage.clear();

    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{ signed: !!user, user, loading, sign, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
