import styled from 'styled-components';

export const BoxChart = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  padding: 8px;
  margin-left: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
`;

export const HeadBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const ControlsBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 400px;
  padding-right: 10px;
`;

export const TitleBox = styled.text`
  display: flex;
  align-items: flex-start;
  align-content: center;
  margin-bottom: 10px;
  padding-left: 5px;
  font-weight: bold;
`;
