import React from 'react';

interface IProps {
  setSearchPart(searchPart: string): unknown;
}

export default function InputSearchName(props: IProps) {
  const { setSearchPart } = props;

  return (
    <div className="relative border border-gray-300 rounded-md px-3 py-2 mb-1 shadow-sm focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
      <input
        type="text"
        name="name"
        id="name"
        autoComplete="none"
        className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
        placeholder="Pesquisar nome da peça..."
        onChange={e => setSearchPart(e.target.value)}
      />
    </div>
  );
}
