import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-left: 5px;
  padding-right: 5px;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
`;

export const Title = styled.div`
  color: #000;
  font-weight: bold;
  margin-right: 5px;
`;

export const Text = styled.div`
  color: #2c2c2c;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  width: 80%;
`;
