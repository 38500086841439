import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  min-height: 600px;
  position: relative;
`;
