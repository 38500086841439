import React from 'react';
import Map from './components/Map';
import StatusFuncionarios from './components/StatusFuncionarios';

export default function MapaAoVivo() {
  return (
    <div className="w-full max-w-6xl h-screen bg-gray-light-100 flex">
      <Map />

      <div className="w-40 h-96 p-5 text-sm text-gray-light-100 float-right relative">
        <StatusFuncionarios />
      </div>
    </div>
  );
}
