import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../../services/api';
import RowTable from './RowTable';
import { useOSView } from '../../../contexts/OSView';
import SpinnerLoad from '../../../components/SpinnerLoad';
import { Input, Space, Select } from 'antd';
import { AudioOutlined } from '@ant-design/icons';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';

import {
  List_container,
  BodyList,
  FormFilter,
  NavigationBottomBar,
  TopBar,
} from './styles';
import NavBarList from '../../../components/NavBarList';
import ModalScreen from '../../../components/ModalScreen';
import Body from '../../RegisterParts/Body';

const useStyles = makeStyles({
  table: {
    minWidth: 670,
    width: 770,
    marginBottom: 1,
  },
});

const { Option } = Select;
const { Search } = Input;

export default function List() {
  const [parts, setParts] = useState([]);
  const [searchDescription, setSearchDescription] = useState('');
  const [page, setpage] = useState<number>(1);
  const [lines_per_page, setLines_per_page] = useState<number>(15);
  const [qtdPages, setQtdPages] = useState<number>();
  const [totalRegisters, setTotalRegisters] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);
  const [searchType, setSearchType] = useState<string>('description');
  const [openModal, setOpenModal] = useState(false);
  const [id_part_view, setId_part_view] = useState<number | null>(null);

  const urlFilter = `&lines_per_page=${lines_per_page}&description=${searchDescription}`;

  const history = useHistory();

  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchDescription]);

  useEffect(() => {
    setpage(1);
  }, [searchDescription]);

  function changePage(action: string) {
    if (action == 'avancar') {
      setpage(page + 1);
    }
    if (action == 'voltar' && page > 1) {
      setpage(page - 1);
    }
  }

  async function getList() {
    const response = await api.get(`parts?page=${page}${urlFilter}`);

    const data = response.data.data;

    setParts(data);

    let numPages = response.data.total / lines_per_page;
    numPages = Math.ceil(numPages);
    setQtdPages(numPages);
    setTotalRegisters(response.data.total);

    // let total = 0;

    // data.map(item => {
    //   if (item.cost_value && item.current_quantity > 0) {
    //     let custo = parseFloat(item.cost_value);

    //     custo = custo * item.current_quantity;

    //     total = custo + total;

    //     setCusto_total(total);
    //   }

    //   return item;
    // });

    setLoading(false);
  }

  function definePart(id_part: number) {
    if (!id_part) return;

    setId_part_view(id_part);

    setOpenModal(true);
  }

  function searchCode(value: string | number) {
    if (!value) return;

    let code = value;

    if (typeof code === 'string') code = parseInt(code);

    definePart(code);
  }

  function searchName(value: string) {
    if (value.length < 3 && value.length > 0) return;

    setSearchDescription(value);
  }

  const classes = useStyles();

  return (
    <List_container>
      <TopBar>
        <FormFilter>
          <Search
            placeholder="Buscar cód."
            type="number"
            min={1}
            onSearch={searchCode}
            style={{ width: 150 }}
          />

          <text style={{ marginLeft: 20 }}>Pesquisar Por:</text>

          <Select
            defaultValue="description"
            style={{ width: 120, fontSize: 12, marginLeft: 5 }}
            onChange={e => setSearchType(e)}
          >
            <Option value="description">Descrição</Option>
            {/* <Option value="stockadress">Local Estoque</Option>
            <Option value="compatibility">Marca</Option>
            <Option value="category">Categoria</Option> */}
          </Select>

          {searchType === 'description' ? (
            <Input
              placeholder="Nome da peça"
              onChange={e => searchName(e.target.value)}
              style={{ width: 200 }}
            />
          ) : null}
        </FormFilter>
      </TopBar>

      {loading ? (
        <SpinnerLoad />
      ) : (
        <BodyList>
          <div style={{ width: 'auto', overflowX: 'auto' }}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="tableparts"
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: 80 }} align="left">
                      Cód.
                    </TableCell>
                    <TableCell style={{ width: 450 }} align="left">
                      Descrição
                    </TableCell>
                    <TableCell style={{ width: 120 }} align="left">
                      Custo unit.
                    </TableCell>
                    <TableCell style={{ width: 80 }} align="left">
                      Quant.
                    </TableCell>
                    <TableCell style={{ width: 130 }} align="left">
                      Valor unit.
                    </TableCell>
                    <TableCell style={{ width: 30 }} align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parts.map(part => (
                    <RowTable
                      key={part.id_part}
                      part={part}
                      openPart={definePart}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <NavBarList
              page={page}
              qtdPages={qtdPages}
              totalRegisters={totalRegisters}
              changePage={changePage}
            />
          </div>
        </BodyList>
      )}

      <ModalScreen open={openModal} setOpen={setOpenModal}>
        <Body id_part_edit={id_part_view} part_mode="edit" />
      </ModalScreen>
    </List_container>
  );
}
