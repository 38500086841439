import React, { useState } from 'react';
import {
  CogIcon,
  ViewGridIcon,
  UserCircleIcon,
  UserGroupIcon,
  OfficeBuildingIcon,
} from '@heroicons/react/outline';
import FormGeral from './FormGeral';
import FormEquipamentos from './FormEquipamentos';
import { useClientRegister } from '../../../contexts/ClientRegister';
import FormContatos from './FormContatos';
import FormUsers from './FormUsers';

const navigation = [
  { name: 'Geral', icon: OfficeBuildingIcon, current: true },
  { name: 'Equipamentos', icon: ViewGridIcon, current: false },
  { name: 'Contatos', icon: UserGroupIcon, current: false },
  { name: 'Usuários', icon: UserCircleIcon, current: false },
  //{ name: 'Preferências', icon: CogIcon, current: false },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function MenuForm() {
  const [screenView, setScreenView] = useState('Geral');

  const { id_cliente } = useClientRegister();

  return (
    <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <aside className="py-6 px-2 sm:px-4 lg:py-0 lg:px-0 lg:col-span-2">
        <nav className="space-y-1">
          {navigation.map(item => (
            <a
              key={item.name}
              onClick={() => setScreenView(item.name)}
              className={classNames(
                item.name === screenView
                  ? 'bg-gray-50 text-blue-700 hover:text-blue-700 hover:bg-white'
                  : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                'group rounded-md px-3 py-2 flex items-center text-sm font-medium',
              )}
              aria-current={item.name === screenView ? 'page' : undefined}
            >
              <item.icon
                className={classNames(
                  item.name === screenView
                    ? 'text-blue-500 group-hover:text-blue-500'
                    : 'text-gray-400 group-hover:text-gray-500',
                  'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
                )}
                aria-hidden="true"
              />
              <span className={id_cliente ? 'truncate' : 'truncate opacity-20'}>
                {item.name}
              </span>
            </a>
          ))}
        </nav>
      </aside>

      {screenView === 'Geral' ? <FormGeral /> : null}

      {screenView === 'Equipamentos' && id_cliente ? (
        <FormEquipamentos />
      ) : null}

      {screenView === 'Contatos' && id_cliente ? <FormContatos /> : null}

      {screenView === 'Usuários' && id_cliente ? <FormUsers /> : null}
    </div>
  );
}
