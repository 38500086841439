import React, { useState, useEffect } from 'react';
import { Container, Line, Title, Description } from './styles';
import GetNameFunc from '../../../../functions/GetNameFunc';

function Scheduling_os({ log }) {
  const [nameSchedule, setNameSchedule] = useState('');

  useEffect(() => {
    const { body } = log;
    if (body) convertUser(body.funcionario_agendamento);
  }, [log]);

  async function convertUser(id_funcionario) {
    if (!id_funcionario) {
      setNameSchedule('-');
      return;
    }

    const { nome_apelido } = await GetNameFunc(id_funcionario);
    setNameSchedule(nome_apelido);
  }

  return (
    <Container>
      <Line>
        <Title>Agendado para:</Title>
        <Description>{nameSchedule}</Description>
      </Line>

      <Line>
        <Title>Descrição:</Title>
        <Description>{log.description}</Description>
      </Line>
    </Container>
  );
}

export default Scheduling_os;
