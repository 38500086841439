import React from 'react';
import Drawer from '../../components/Drawer';
import { useAuth } from '../../contexts/Auth';
import ServicesCurrentCollaborators from './components/ServicesCurrentCollaborators';
import StaatsPartReq from './components/StaatsPartReq';

const Dashboard: React.FC = () => {
  const { user } = useAuth();

  return (
    <Drawer page="Dashboard">
      <div className="flex flex-col">
        <div className="w-full p-5 float-right relative">
          <ServicesCurrentCollaborators />
        </div>

        <div className="flex justify-start flex-col content-start">
          {user.id_grupo_usuario <= 58 ? <StaatsPartReq /> : null}
        </div>
      </div>
    </Drawer>
  );
};

export default Dashboard;
