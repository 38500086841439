/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useState, useContext } from 'react';

const ClientRegisterContext = createContext<any>({});

export const ClientRegisterProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [id_cliente, setId_cliente] = useState<number>();
  const [modeEdit, setModeEdit] = useState(false);
  const [openSliderEquip, setOpenSliderEquip] = useState(false);
  const [openSliderContact, setOpenSliderContact] = useState(false);

  return (
    <ClientRegisterContext.Provider
      value={{
        loading,
        setLoading,
        modeEdit,
        setModeEdit,
        id_cliente,
        setId_cliente,
        openSliderEquip,
        setOpenSliderEquip,
        openSliderContact,
        setOpenSliderContact,
      }}
    >
      {children}
    </ClientRegisterContext.Provider>
  );
};

export function useClientRegister() {
  const context = useContext(ClientRegisterContext);
  return context;
}
