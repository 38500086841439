import styled from 'styled-components';
import { Layout } from 'antd';
const { Header, Content, Footer } = Layout;

export const LayoutContainer = styled(Layout)`
  min-height: 100vh;
`;

export const HeaderMenu = styled(Header)`
  padding-left: 3px;
  padding-right: 3px;
  color: #fff;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const PageName = styled.span`
  padding: 30px;
  font-size: 16px;
`;

export const PageContent = styled(Content)`
  margin: 0px 16px;
`;

export const PageFooter = styled(Footer)`
  text-align: center;
`;

export const BoxAvatar = styled.div`
  width: 60;
  border-radius: 20px;
  color: #2c2c2c;
  margin-top: 10px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

export const ImgAvatar = styled.img`
  border-radius: 20px;
  margin: center;
  border: 0px solid #ccc;
  width: 40px;
  height: 40px;
`;
