import React, { useState } from 'react';
import {
  ClipboardCopyIcon,
  DuplicateIcon,
  LinkIcon,
  RefreshIcon,
} from '@heroicons/react/solid';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from 'antd';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../../functions/MessageFeedback';
import api from '../../../../services/api';
import ConfirmDialog from '../../../../components/ConfirmDialog';

interface IProps {
  key_register_user: string;
  id_client: number;
  getClient(): void;
}

export default function SectionKeyUserVinculate(props: IProps) {
  const [loading, setLoading] = useState(false);

  const { getClient, id_client, key_register_user } = props;

  async function changeKey() {
    if (!id_client || loading) return;

    setLoading(true);

    try {
      await api.put(`/cliente/keyregisteruser/${id_client}`);

      feedbackSuccess('Código alterado com sucesso.');

      getClient();
    } catch (error) {
      feedbackError('Erro ao gerar novo código.');
    }

    setLoading(false);
  }

  return (
    <div className="rounded-md bg-blue-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <LinkIcon className="h-5 w-5 text-black" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-black">
            <span className="">Código de vinculação:</span>{' '}
            <a>
              <CopyToClipboard
                text={key_register_user}
                onCopy={() =>
                  feedbackSuccess('Copiado para área de transferência')
                }
              >
                <span className="font-semibold">
                  {key_register_user
                    ? key_register_user
                    : 'Nenhuma chave existente'}
                </span>
              </CopyToClipboard>
            </a>
          </p>
          <p className="mt-3 text-sm md:mt-0 md:ml-6">
            <ConfirmDialog
              title="CUIDADO! Ao gerar um novo código de vinculação, o anterior será invalidado e, quem o possuir, não poderá mais utilizá-lo. Tem certeza que deseja invalidar o atual e gerar um novo código?"
              onConfirm={() => changeKey()}
            >
              <a className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-900 flex flex-row items-center">
                <RefreshIcon
                  className="h-4 w-4 text-blue-600"
                  aria-hidden="true"
                />
                <span className="ml-1 text-blue-600">Substituir chave</span>
              </a>
            </ConfirmDialog>
          </p>
        </div>
      </div>
    </div>
  );
}
