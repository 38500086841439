import React, { useState, useEffect } from 'react';
import { Select, Input } from 'antd';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import api from '../../../../services/api';

const { Search } = Input;
const { Option } = Select;

const BoxFilter = props => {
  let { setType, setTableOrigin, setId_table_origin } = props;

  const [fieldType, setFieldType] = useState('');
  const [clientes, setClientes] = useState([]);

  function handleChangeId(id) {
    if (id > 0 || id == '') {
      setId_table_origin(id);
    } else {
      alert('Código inválido');
    }
  }

  function getClientes() {
    api.get(`cliente/min?lines_per_page=99999999`).then(response => {
      setClientes(response.data.data);
    });
  }

  function handleChangeType(type) {
    setType(type);

    //Limpa os demais filtros
    setId_table_origin();
    setTableOrigin('');

    managerType(type);
  }

  async function managerType(type) {
    switch (type) {
      case 'alter_status_os':
        setFieldType('os');
        setTableOrigin('os');
        break;

      case 'alter_os':
        setFieldType('os');
        setTableOrigin('os');
        break;

      case 'alter_situacao_os':
        setFieldType('os');
        setTableOrigin('os');
        break;

      case 'scheduling_os':
        setFieldType('os');
        setTableOrigin('os');
        break;

      case 'alter_board_os':
        setFieldType('os');
        setTableOrigin('os');
        break;

      case 'alter_status_req_peca':
        setFieldType('os');
        setTableOrigin('os');
        break;

      case 'alter_req_peca':
        setFieldType('os');
        setTableOrigin('os');
        break;

      case 'alter_status_funcionamento':
        setFieldType('cliente');
        setTableOrigin('cliente');
        break;

      default:
        setFieldType('');
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Select
        placeholder="Tipo"
        style={{ width: 300 }}
        onChange={handleChangeType}
      >
        <Option value="alter_status_os">
          Status de OS (Valid. / Aprov. / Faturam.)
        </Option>
        <Option value="alter_status_req_peca">
          Separação de material de OS
        </Option>
        <Option value="alter_status_funcionamento">
          Alteração de funcionamento de equip.
        </Option>
        <Option value="alter_os">Alteração de OS</Option>
        <Option value="alter_situacao_os">Situação de liberação de OS</Option>
        <Option value="alter_req_peca">Alteração de requisição de peça</Option>
        <Option value="scheduling_os">Agendamento de OS</Option>
        <Option value="alter_board_os">Alteração de quadro de OS</Option>
      </Select>

      {fieldType === 'os' ? (
        <Search
          type="number"
          placeholder="Cód. OS"
          onSearch={handleChangeId}
          style={{ width: 150, marginLeft: 10 }}
        />
      ) : null}

      {fieldType === 'cliente' ? (
        <>
          {getClientes()}
          <Autocomplete
            options={clientes}
            getOptionLabel={clientes => clientes.edificio}
            style={{ width: 350, marginLeft: 10 }}
            onChange={(event, clienteselected) => {
              handleChangeId(clienteselected?.id_cliente);
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Pesquisar Cliente"
                variant="outlined"
              />
            )}
          />
        </>
      ) : null}
    </div>
  );
};

export default BoxFilter;
