import api from '../../../../../services/api';
import { IUser } from '../../../../../types/Collaborator/IUser';

async function GetUser(id_funcionario: number): Promise<IUser | void> {
  try {
    const response = await api.get(`user/byFunc/${id_funcionario}`);

    const { data } = response;

    data.tecnico = !!data.tecnico;

    return data;
  } catch (err) {
    return;
  }
}

export default GetUser;
