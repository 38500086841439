import React, { useEffect, useState } from 'react';
import AutocompleteInput from '../../../../../../../components/AutocompleteInput';
import GetListRecentCallers from '../../functions/GetListRecentCallers';

interface IProps {
  id_cliente: number;
  solicitante: string;
  setSolicitante(solicitante: string): void;
}

interface IOption {
  value: string;
}

export default function SolicitanteInput({
  solicitante,
  setSolicitante,
  id_cliente,
}: IProps) {
  const [options, setOptions] = useState<IOption[] | []>([]);

  useEffect(() => {
    getList();
  }, []);

  async function getList() {
    if (!id_cliente) return;

    try {
      const array = await GetListRecentCallers(id_cliente);

      if (!array || array.lenght === 0) return;

      await array.map((item: string) => {
        const register = { value: item };

        setOptions(prevlist => [...prevlist, register]);
      });
    } catch (err) {
      return;
    }
  }

  return (
    <AutocompleteInput
      setValue={setSolicitante}
      value={solicitante}
      options={options}
      style={{ width: 150, marginLeft: 10 }}
      placeholder="Solicitante"
    />
  );
}
