import React, { useEffect } from 'react';

import Drawer from '../../components/Drawer';
import MenuForm from './components/MenuForm';
import { useClientRegister } from '../../contexts/ClientRegister';
import { useLocation } from 'react-router-dom';

interface IState {
  state: {
    id_cliente: number | undefined;
    mode: string;
  };
}

export default function ClienteRegister() {
  const location: IState = useLocation();

  const { id_cliente, setId_cliente, setModeEdit } = useClientRegister();

  useEffect(() => {
    if (location.state?.mode === 'edit') {
      setId_cliente(location.state?.id_cliente);
      setModeEdit(true);
    }

    if (location.state?.mode === 'new') {
      setId_cliente();
      setModeEdit(false);
    }
  }, [location]);

  return (
    <Drawer page="Cadastro de Cliente">
      <MenuForm />
    </Drawer>
  );
}
