import React from 'react';
import { IOutfitter } from '../../../../types/Outfitter/IOutfitter';

interface IProps {
  outfitter: IOutfitter;
  setId_outfitter(id_outfitter: number): void;
  id_outfitter: number | null;
}

export default function Row(props: IProps) {
  const { outfitter, setId_outfitter, id_outfitter } = props;

  return (
    <li style={{ listStyleType: 'none' }}>
      <div
        onClick={() => setId_outfitter(outfitter.id_outfitter)}
        className="relative px-6 py-2 flex items-center space-x-3 bg-gray-100 border hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500"
        style={
          id_outfitter === outfitter.id_outfitter
            ? { borderColor: '#7fa0ff', borderWidth: 2 }
            : null
        }
      >
        <div className="flex-1 min-w-0">
          <a href="#" className="focus:outline-none">
            <span className="absolute inset-0" aria-hidden="true" />
            <p className="text-sm font-medium text-gray-900">
              {outfitter?.nome_apelido}
            </p>
            <p className="text-xs text-gray-400 truncate">
              {outfitter?.logradouro}, {outfitter?.numero} - {outfitter?.cidade}{' '}
              - {outfitter?.estado}
            </p>
          </a>
        </div>
      </div>
    </li>
  );
}
