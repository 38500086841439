/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useState, useContext } from 'react';
import api from '../services/api';
import { feedbackError } from '../functions/MessageFeedback';

const OSViewContext = createContext<any>({});

export const OSViewProvider: React.FC = ({ children }) => {
  const [os, setOs] = useState();
  const [id_os_view, setId_os_view] = useState(null);
  const [id_req_view, setId_req_view] = useState(null);
  const [id_cliente_view, setId_cliente_view] = useState();
  const [loading, setloading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [screen, setScreen] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  function defineLoading(state: any) {
    setloading(state);
  }

  async function defineIdOs(id_os: any, destino: any) {
    setId_os_view(id_os);

    try {
      await getOs(id_os);

      if (destino === 'gerir') {
        setScreen('gerir');
      } else {
        setScreen('consultaros');
      }

      setOpenModal(true);
    } catch (err) {
      setId_os_view(null);

      return;
    }
  }

  async function getOs(id_os: number) {
    try {
      const response = await api.get('os/' + id_os);

      setOs(response.data);

      defineIdCliente(response.data.id_cliente);
    } catch (err) {
      feedbackError('Falha ao carregar a OS. Tente novamente');
    }
  }

  function defineIdReq(id_req: any) {
    setId_req_view(id_req);
  }

  function defineIdCliente(id_cliente: any) {
    setId_cliente_view(id_cliente);
  }

  return (
    <OSViewContext.Provider
      value={{
        os,
        id_os_view,
        id_req_view,
        id_cliente_view,
        loading,
        refresh,
        setRefresh,
        defineLoading,
        defineIdOs,
        defineIdReq,
        defineIdCliente,
        getOs,
        screen,
        openModal,
        setOpenModal,
      }}
    >
      {children}
    </OSViewContext.Provider>
  );
};

export function useOSView() {
  const context = useContext(OSViewContext);
  return context;
}
