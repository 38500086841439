import axios, { AxiosError } from 'axios';
import { feedbackError } from '../functions/MessageFeedback';
import { urlAPI } from './urlAPI';

// Variavel para informar se está acontecendo uma requisição de refresh token
let isRefreshing = false;
// Variavel para armazenar a fila de requisições que falharam por token expirado
let failedRequestQueue = [];

// Tipagem dos dados de response da api de autenticação
type AuthApiResponse = {
  token: string;
  refresh_token: string;
};

interface IConfig {
  headers: {
    authorization: string;
    deviceid: string;
  };
}

const api = axios.create({
  baseURL: urlAPI,
});

api.interceptors.request.use(async (config: IConfig) => {
  try {
    const token = localStorage.getItem('@elevsis-biz:token');
    if (token) {
      config.headers.authorization = token;
      config.headers.deviceid = 'react';
    }
    return config;
  } catch (err) {
    return;
  }
});

// Cria um interceptor para interceptar todas as requisições que forem feitas
api.interceptors.response.use(
  response => {
    // Se a requisição der sucesso, retorna a resposta
    return response;
  },
  async (error: AxiosError) => {
    // Se a requisição der erro, verifica se o erro é de autenticação
    if (error.response.status === 401) {
      // Se o erro for de autenticação, verifica se o erro foi de token expirado
      if (error?.response?.data === 'token expired') {
        // Recupera o refresh token do localStorage
        const refresh_token = localStorage.getItem('@elevsis-biz:refreshToken');
        // Recupera toda a requisição que estava sendo feita e deu erro para ser refeita após o refresh token
        const originalConfig = error.config;

        // Verifica se já existe uma request de refreshToken acontecendo
        if (!isRefreshing) {
          // Se não existir, inicia a requisição de refreshToken
          isRefreshing = true;

          // Faz uma requisição de refreshToken
          api
            .post('/user/refreshToken/renew', {
              refresh_token,
            })
            .then(response => {
              // Recupera os dados do response e cria o newRefreshToken por que já está sendo utilizado a variável refreshToken
              const { token, refresh_token: newRefreshToken } =
                response.data as AuthApiResponse;

              // Salva o token no localStorage
              localStorage.setItem('@elevsis-biz:token', token);
              // Salva o refreshToken no localStorage
              localStorage.setItem(
                '@elevsis-biz:refreshToken',
                newRefreshToken,
              );

              // Define novamente o header de autorização nas requisições
              // api.defaults.headers['Authorization'] = `Bearer ${token}`;******************
              originalConfig.headers['authorization'] = token;

              // Faz todas as requisições que estavam na fila e falharam
              failedRequestQueue.forEach(request => request.onSuccess(token));
              // Limpa a fila de requisições que falharam
              failedRequestQueue = [];
            })
            .catch(async err => {
              // Retorna os erros que estão salvos na fila de requisições que falharam
              failedRequestQueue.forEach(request => request.onFailure(err));
              // Limpa a fila de requisições que falharam
              failedRequestQueue = [];

              feedbackError(
                'Erro na solicitação. Caso o erro persista, contate o suporte',
              );
            })
            .finally(() => {
              // Indica que a requisição de refreshToken acabou
              isRefreshing = false;
            });
        }

        // Usando a Promise no lugar do async await, para que a requisição seja feita após o refresh token
        return new Promise((resolve, reject) => {
          // Adiciona a requisição na fila de requisições que falharam com as informações necessárias para refazer a requisição novamente
          failedRequestQueue.push({
            // Se a requisição der sucesso, chama o onSuccess
            onSuccess: (token: string) => {
              // Adiciona o novo token gerado no refresh token no header de autorização
              originalConfig.headers['authorization'] = token;

              // Faz a requisição novamente passando as informações originais da requisição que falhou
              resolve(api(originalConfig));
            },
            // Se a requisição der erro, chama o onFailure
            onFailure: (err: AxiosError) => {
              // Se não for possivel refazer a requisição, retorna o erro
              reject(err);
            },
          });
        });
      } else {
        feedbackError(
          'Erro na solicitação. Caso o erro persista, contate o suporte',
        );
      }
    }

    // Se não cair em nenhum if retorna um error padrão
    return Promise.reject(error);
  },
);

export default api;
