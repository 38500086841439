import React, { useEffect, useState } from 'react';
import { Switch } from 'antd';
import { LineAjuste, TitleAjuste, ContainerLineContent } from '../../../styles';
import HandleSubmit from './HandleSubmit';
import { ICollaboratorConfig } from '../../../../../../types/Collaborator/ICollaboratorConfig';
import GetConfigs from './GetConfigs';
import SpinnerLoad from '../../../../../../components/SpinnerLoad';

interface IProps {
  id_funcionario?: number;
}

export default function ConfigsPreferences(props: IProps) {
  const [configs, setConfigs] = useState<ICollaboratorConfig | null>(null);
  const [loading, setLoading] = useState(false);

  const { id_funcionario } = props;

  useEffect(() => {
    if (id_funcionario) {
      getConfigs();
    } else {
      cleanStates();
    }
  }, [id_funcionario]);

  async function getConfigs() {
    if (!id_funcionario) return;

    setLoading(true);

    try {
      const data = await GetConfigs(id_funcionario);

      if (data) setConfigs(data);
    } catch (error) {
      setLoading(false);
    }

    setLoading(false);
  }

  async function cleanStates() {
    setConfigs(null);
  }

  async function saveData() {
    setLoading(true);

    configs.id_collaborator = id_funcionario;

    try {
      await HandleSubmit(configs);
    } catch (error) {
      setLoading(false);

      return;
    }

    setLoading(false);
  }

  return (
    <div className="shadow sm:rounded-md sm:overflow-hidden">
      <div className="bg-white py-6 px-4 space-y-4 sm:p-6">
        <div>
          <h3 className="text-lg leading-4 font-medium text-gray-900">
            Preferências
          </h3>
        </div>

        {loading ? (
          <SpinnerLoad />
        ) : (
          <ContainerLineContent>
            <LineAjuste>
              <TitleAjuste>Exibir no gráfico de vendas</TitleAjuste>
              <Switch
                id="show_to_sales"
                defaultChecked={configs?.show_to_sales}
                checked={configs?.show_to_sales}
                onChange={e =>
                  setConfigs(prevState => {
                    return { ...prevState, show_to_sales: e };
                  })
                }
              />
            </LineAjuste>

            <LineAjuste>
              <TitleAjuste>Rastrear a localização constantemente</TitleAjuste>
              <Switch
                id="update_position"
                defaultChecked={configs?.update_position}
                checked={configs?.update_position}
                onChange={e =>
                  setConfigs(prevState => {
                    return { ...prevState, update_position: e };
                  })
                }
              />
            </LineAjuste>

            <LineAjuste>
              <TitleAjuste>Exibir localização no mapa</TitleAjuste>
              <Switch
                id="show_to_map"
                defaultChecked={configs?.show_to_map}
                checked={configs?.show_to_map}
                onChange={e =>
                  setConfigs(prevState => {
                    return { ...prevState, show_to_map: e };
                  })
                }
              />
            </LineAjuste>
            <LineAjuste>
              <TitleAjuste>Exibir na área de disponibilidade</TitleAjuste>
              <Switch
                id="show_to_availability"
                defaultChecked={configs?.show_to_availability}
                checked={configs?.show_to_availability}
                onChange={e =>
                  setConfigs(prevState => {
                    return { ...prevState, show_to_availability: e };
                  })
                }
              />
            </LineAjuste>

            <LineAjuste>
              <TitleAjuste>
                Permitir indicá-lo para atendimento técnico
              </TitleAjuste>
              <Switch
                id="show_to_schedule_os"
                defaultChecked={configs?.show_to_schedule_os}
                checked={configs?.show_to_schedule_os}
                onChange={e =>
                  setConfigs(prevState => {
                    return { ...prevState, show_to_schedule_os: e };
                  })
                }
              />
            </LineAjuste>
          </ContainerLineContent>
        )}
      </div>
      <div className="px-4 py-3 bg-white text-right sm:px-6">
        <button
          disabled={loading}
          onClick={() => saveData()}
          type="submit"
          className="bg-blue-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Salvar
        </button>
      </div>
    </div>
  );
}
