import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const ContainerBody = styled.div`
  width: 1100px;
  padding-top: 0px;
`;

export const InputPhone = styled(InputMask)`
  border: 1px solid #ddd;
  border-radius: 2px;
  height: 32px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const ContainerContent = styled.div`
  width: 100%;
  margin-top: 5px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  background-color: #fff;
`;

export const ContainerLineContent = styled.div`
  width: 100%;
  margin-top: 5px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
`;

export const TitleBody = styled.text`
  font-size: 16px;
  margin-bottom: 6px;
`;

export const Columm = styled.div`
  width: 300px;
`;

export const BoxInfo = styled.div`
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 40px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
`;

export const TitleField = styled.text`
  font-size: 12px;
  color: #333;
  margin-bottom: 5px;
`;

export const DescriptionInfo = styled.text`
  font-size: 12px;
  color: #aaa;
  min-height: 18px;
`;

export const LineAjuste = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebecee;
  justify-content: space-between;
`;

export const TitleAjuste = styled.text`
  color: #333;
  font-size: 14px;
`;
