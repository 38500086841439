/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import { IItem } from '../../../../types/PurchaseOrder/IItem';

interface IProps {
  item: IItem;
  listItems: IItem[];
  setListItems(any): void;
  pos: number;
}

export default function ItemsRow(props: IProps) {
  const [showValorTotal, setShowValorTotal] = useState('');

  const { pos, item, listItems, setListItems } = props;

  useEffect(() => {
    sumTotal();
  }, [props]);

  function sumTotal() {
    const total_item = listItems[pos].unitaryValue * listItems[pos].quantity;

    setShowValorTotal(total_item.toFixed(2));
  }

  function changed_quantidade(index: number, e) {
    const newArr = [...listItems];
    newArr[index].quantity = e;

    setListItems(newArr);

    sumTotal();
  }

  function changed_description(index: number, e) {
    const newArr = [...listItems];
    newArr[index].description = e;

    setListItems(newArr);
  }

  function changed_code(index: number, e) {
    const newArr = [...listItems];
    newArr[index].code = e;

    setListItems(newArr);
  }

  function changed_valor_unit(index: number, e) {
    const newArr = [...listItems];
    newArr[index].unitaryValue = e;

    setListItems(newArr);

    sumTotal();
  }

  async function removeItem(index: number) {
    const newArr = [...listItems];

    newArr.splice(index, 1);

    setListItems(newArr);

    sumTotal();
  }

  return (
    <>
      <tr className="border-b border-gray-200">
        <td className="hidden py-3 pr-1 text-left text-sm text-gray-500 sm:table-cell">
          <Tooltip title="Remover item">
            <ConfirmDialog
              onConfirm={() => removeItem(pos)}
              title="Tem certeza que deseja remover este item?"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="red"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
            </ConfirmDialog>
          </Tooltip>
        </td>
        <td className="py-4 pl-3 pr-2 w-2/12 text-left text-sm text-gray-500 sm:pr-6 md:pr-0">
          <input
            type="number"
            min={1}
            name="quantity"
            id="quantity"
            value={item?.quantity}
            defaultValue={item?.quantity}
            onChange={e => changed_quantidade(pos, e.target.value)}
            className="h-8 px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </td>
        <td className="hidden py-4 px-2 w-5/12 text-left text-sm text-gray-500 sm:table-cell">
          <input
            type="text"
            name="description"
            id="description"
            value={item?.description}
            defaultValue={item?.description}
            onChange={e => changed_description(pos, e.target.value)}
            className="h-8 px-1 mr-2 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </td>
        <td className="py-4 pl-1 pr-2 w-2/12 text-sm sm:pl-6 md:pl-0">
          <input
            type="text"
            name="code"
            id="code"
            value={item?.code}
            defaultValue={item?.code}
            onChange={e => changed_code(pos, e.target.value)}
            className="h-8 px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </td>
        <td className="hidden py-4 px-2 w-2/12 text-left text-sm text-gray-500 sm:table-cell">
          <CurrencyInput
            value={item?.unitaryValue}
            multiline
            precision="2"
            decimalSeparator="."
            thousandSeparator=""
            onChangeEvent={e => changed_valor_unit(pos, e.target.value)}
            className="h-8 px-1 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </td>
        <td className="py-3 pl-3 pr-2 text-left text-sm text-gray-500 sm:pr-2 md:pr-0">
          {showValorTotal}
        </td>
      </tr>
    </>
  );
}
