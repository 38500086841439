import styled from 'styled-components';

export const List_container = styled.div`
  flex-direction: row;
`;

export const BodyList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: center;
`;

export const FormFilterTop = styled.div`
  font-size: 12px;
  padding: 6px;
  margin-left: 20px;
  border-radius: 4px;
  background-color: #f5f5f5;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
`;

export const TopBar = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Tag = styled.div`
  padding-left: 5px;
  padding-right: 5px;
  margin-right: 3px;
  margin-bottom: 1px;
  border-radius: 2px;
  border: 1px solid #c1c1c1;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
`;

export const FormFilterRight = styled.div`
  width: 200px;
  font-size: 12px;
  padding: 6px;
  margin-left: 4px;
  border-radius: 4px;
  border: 1px solid #9e9e9e;
  background-color: #fff;
`;

export const LineFilter = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const IconRed = styled.div`
  background-color: #d50000;
  width: 20px;
  height: 20px;
  border-radius: 15px;
`;

export const IconOrange = styled.div`
  background-color: #f57f17;
  width: 20px;
  height: 20px;
  border-radius: 15px;
`;

export const IconYellow = styled.div`
  background-color: #ffd600;
  width: 20px;
  height: 20px;
  border-radius: 15px;
`;

export const IconGreen = styled.div`
  background-color: #aeea00;
  width: 20px;
  height: 20px;
  border-radius: 15px;
`;
