/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { Form } from '@unform/web';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../../../functions/MessageFeedback';

import { Container, Box } from './styles';
import api from '../../../../../services/api';
import { useOSGerir } from '../../../../../contexts/OSGerir';
import { useOSView } from '../../../../../contexts/OSView';
import SwitchInputDefault from '../../../../../components/SwitchInputDefault';

export default function BoxFaturamentoReqPeca() {
  const [faturado, setFaturado] = useState();
  const [statusOS, setStatusOS] = useState();
  const { loading, id_os_view, getReqPecas } = useOSGerir();
  const { setRefresh, refresh } = useOSView();

  //Atualiza quando tem alteração
  useEffect(() => {
    getReq();
  }, [loading]);

  async function getReq() {
    try {
      const response = await getReqPecas();
      const { faturado, status } = response.data.data[0];

      //converte para bool
      if (faturado) {
        setFaturado(true);
      } else {
        setFaturado(false);
      }

      setStatusOS(status);
    } catch (err) {
      return;
    }
  }

  async function handleSave() {
    const req = {
      id_os: id_os_view,
      faturado,
      type: 'faturamento',
    };

    try {
      await api.put(`req_peca/${id_os_view}/changestatus`, req);

      feedbackSuccess('Informações salvas com sucesso');
      setRefresh(!refresh);
    } catch {
      feedbackError('Erro ao definir o status de faturamento');
      return;
    }
  }

  return (
    <>
      <Form onSubmit={handleSave}>
        <Container>
          <Box>
            Faturado
            <SwitchInputDefault enabled={faturado} setEnabled={setFaturado} />
          </Box>
          {statusOS !== 'cancelado' && (
            <Box>
              <Button
                variant="contained"
                type="submit"
                style={{ marginTop: 10, marginLeft: 6, padding: 2 }}
              >
                Salvar
              </Button>
            </Box>
          )}
        </Container>
      </Form>
    </>
  );
}
