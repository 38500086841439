import React, { useEffect, useState } from 'react';
import { MailIcon } from '@heroicons/react/solid';
import { WhatsApp } from '@material-ui/icons';
import api from '../../../../services/api';
import { useClientRegister } from '../../../../contexts/ClientRegister';
import { IPivotContactCliente } from '../../../../types/Contact/IPivotContactCliente';
import { IContact } from '../../../../types/Contact/IContact';

export default function Card(props) {
  const [pivotContact, setPivotContact] = useState<IPivotContactCliente>();
  const [contactFull, setContactFull] = useState<IContact>();

  const { contact, selectContact } = props;

  const { id_cliente } = useClientRegister();

  useEffect(() => {
    getPivotContact();

    getContact();
  }, [props]);

  async function getPivotContact() {
    try {
      const response = await api.get(
        `contactCliente/${contact.id_contact}/${id_cliente}`,
      );

      setPivotContact(response.data);
    } catch (err) {
      return;
    }
  }

  async function getContact() {
    try {
      const response = await api.get(`contact/${contact.id_contact}`);

      setContactFull(response.data);
    } catch (err) {
      return;
    }
  }

  return (
    <li className="col-span-1 bg-white rounded-lg border-2 shadow divide-y divide-gray-200">
      <a onClick={() => selectContact(contact.id_contact)}>
        <div className="w-full flex items-center justify-between p-6 space-x-6">
          <div className="flex-1 truncate">
            <div className="flex items-start space-x-3">
              <h3 className="text-gray-900 text-sm font-medium truncate">
                {contactFull?.nome}{' '}
                {contactFull?.apelido ? `(${contactFull?.apelido})` : null}
              </h3>
              {pivotContact?.status === 'enabled' ? (
                <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                  Ativo
                </span>
              ) : (
                <span className="flex-shrink-0 inline-block px-2 py-0.5 text-red-800 text-xs font-medium bg-red-100 rounded-full">
                  Inativo
                </span>
              )}
            </div>
            <p className="mt-0 text-gray-500 text-sm truncate capitalize">
              Cargo: {pivotContact?.cargo}
            </p>
            <p className="mt-0 text-gray-500 text-sm truncate">
              Fone: {contactFull?.telefone1} <br />
              E-mail: {contactFull?.email1}
            </p>
          </div>
          <img
            className="w-10 h-10 bg-white rounded-full flex-shrink-0"
            src="https://www.seekpng.com/png/full/514-5147412_default-avatar-icon.png"
            alt=""
          />
        </div>
      </a>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="-ml-px w-0 flex-1 flex">
            <a
              href={`https://wa.me/55${contactFull?.whatsapp}`}
              target="_blank"
              title="Abrir WhatsApp"
              className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
              rel="noreferrer"
            >
              <WhatsApp className="w-5 h-5 text-gray-400" aria-hidden="true" />
              <span className="ml-3">WhatsApp</span>
            </a>
          </div>
          <div className="w-0 flex-1 flex">
            <a
              href={`mailto:${contactFull?.email1}`}
              title="Abrir E-mail"
              className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
            >
              <MailIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              <span className="ml-3">E-mail</span>
            </a>
          </div>
        </div>
      </div>
    </li>
  );
}
