import React, { useState } from 'react';
import Drawer from '../../components/Drawer';
import ChamadosReport from './components/ChamadosReport';
import InputSelectTypeReport from './components/ChamadosReport/InputSelectTypeReport';

const Relatorios: React.FC = () => {
  const [reportType, setReportType] = useState('');

  return (
    <Drawer page="Relatórios">
      {reportType === 'chamados' ? (
        <ChamadosReport />
      ) : (
        <InputSelectTypeReport setReportType={setReportType} />
      )}
    </Drawer>
  );
};

export default Relatorios;
