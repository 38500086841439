/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IItem } from '../../../../types/PurchaseOrder/IItem';

interface IProps {
  item: IItem;
}

export default function Row(props: IProps) {
  const [showValorTotal, setShowValorTotal] = useState('');

  const { item } = props;

  useEffect(() => {
    sumTotal();
  }, [props]);

  function sumTotal() {
    const total_item = item.unitaryValue * item.quantity;

    setShowValorTotal(total_item.toFixed(2));
  }

  return (
    <>
      <tr className="border-b border-gray-200">
        <td className="hidden py-3 pr-1 text-left text-sm text-gray-500 sm:table-cell"></td>
        <td className="py-4 pl-3 pr-2 w-2/12 text-left text-sm text-gray-500 sm:pr-6 md:pr-0">
          {item.quantity}
        </td>
        <td className="hidden py-4 px-2 w-5/12 text-left text-sm text-gray-500 sm:table-cell">
          {item.description}
        </td>
        <td className="py-4 pl-1 pr-2 w-2/12 text-sm sm:pl-6 md:pl-0">
          {item.code}
        </td>
        <td className="hidden py-4 px-2 w-2/12 text-left text-sm text-gray-500 sm:table-cell">
          {item.unitaryValue}
        </td>
        <td className="py-3 pl-3 pr-2 text-left text-sm text-gray-500 sm:pr-2 md:pr-0">
          {showValorTotal}
        </td>
      </tr>
    </>
  );
}
