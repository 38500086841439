/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../../contexts/Auth';

import RowTable from '../RowTable';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import {
  Container,
  FormFilter,
  List_container,
  BodyList,
  WindowsViewContainer,
} from '../../styles';
import BoxView from '../BoxView';
import BoxFilter from '../BoxFilter';
import api from '../../../../services/api';

const useStyles = makeStyles({
  table: {
    minWidth: 450,
    width: 700,
    backgroundColor: '#f5f5f5',
  },
});

export default function List() {
  const [logs, setLogs] = useState([]);
  const [logView, setLogView] = useState({});

  const [type, setType] = useState('');
  const [table_origin, setTableOrigin] = useState('');
  const [id_table_origin, setId_table_origin] = useState();

  const { user } = useAuth();

  useEffect(() => {
    getLogs();
  }, [type, table_origin, id_table_origin]);

  async function getLogs() {
    let strFilter = `logs?type=${type}`;

    if (table_origin && id_table_origin && type) {
      //Se todos os campos preenchidos
      strFilter = `logs?type=${type}&table_origin=${table_origin}&id_table_origin=${id_table_origin}`;
    } else {
      if (table_origin && id_table_origin) {
        //Se não informado tipo
        strFilter = `logs?table_origin=${table_origin}&id_table_origin=${id_table_origin}`;
      }
    }

    try {
      const response = await api.get(strFilter);
      setLogs(response.data);
    } catch (err) {
      console.log(err);
    }
  }

  const classes = useStyles();

  return (
    <>
      <FormFilter>
        <BoxFilter
          setType={setType}
          setTableOrigin={setTableOrigin}
          setId_table_origin={setId_table_origin}
        />
      </FormFilter>

      <Container>
        <List_container>
          <BodyList>
            <TableContainer
              component={Paper}
              style={{ width: 700, minWidth: 450 }}
            >
              <Table
                className={classes.table}
                size="small"
                aria-label="tablelogs"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left" style={{ width: 90 }}>
                      Autor:
                    </TableCell>
                    <TableCell align="left" style={{ width: 280 }}>
                      Descrição do evento:
                    </TableCell>
                    <TableCell align="left" style={{ width: 60 }}>
                      Em:
                    </TableCell>
                    <TableCell align="left" style={{ width: 20 }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logs.map(log => (
                    <RowTable log={log} setLogView={setLogView} key={log} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </BodyList>
        </List_container>

        <WindowsViewContainer>
          <BoxView log={logView} />
        </WindowsViewContainer>
      </Container>
    </>
  );
}
