const OptionsStep1 = [
  { label: 'S1 - Almox', value: 'S1' },
  { label: 'S2 - Depósito', value: 'S2' },
];

const OptionsStep2 = [
  { label: 'F1 - Fileira 1', value: 'F1' },
  { label: 'F2 - Fileira 2', value: 'F2' },
  { label: 'F3 - Fileira 3', value: 'F3' },
  { label: 'F4 - Fileira 4', value: 'F4' },
  { label: 'F5 - Fileira 5', value: 'F5' },
  { label: 'F6 - Fileira 6', value: 'F6' },
];

const OptionsStep3 = [
  { label: 'L1 - Linha 1', value: 'L1' },
  { label: 'L2 - Linha 2', value: 'L2' },
  { label: 'L3 - Linha 3', value: 'L3' },
  { label: 'L4 - Linha 4', value: 'L4' },
  { label: 'L5 - Linha 5', value: 'L5' },
  { label: 'L6 - Linha 6', value: 'L6' },
  { label: 'L7 - Linha 7', value: 'L7' },
  { label: 'L8 - Linha 8', value: 'L8' },
  { label: 'L9 - Linha 9', value: 'L9' },
  { label: 'L10 - Linha 10', value: 'L10' },
  { label: 'L11 - Linha 11', value: 'L11' },
  { label: 'L12 - Linha 12', value: 'L12' },
  { label: 'L13 - Linha 13', value: 'L13' },
  { label: 'L14 - Linha 14', value: 'L14' },
  { label: 'L15 - Linha 15', value: 'L15' },
  { label: 'L16 - Linha 16', value: 'L16' },
  { label: 'L17 - Linha 17', value: 'L17' },
  { label: 'L18 - Linha 18', value: 'L18' },
  { label: 'L19 - Linha 19', value: 'L19' },
  { label: 'L20 - Linha 20', value: 'L20' },
];

const OptionsStep4 = [
  { label: 'C1 - Coluna 1', value: 'C1' },
  { label: 'C2 - Coluna 2', value: 'C2' },
  { label: 'C3 - Coluna 3', value: 'C3' },
  { label: 'C4 - Coluna 4', value: 'C4' },
  { label: 'C5 - Coluna 5', value: 'C5' },
  { label: 'C6 - Coluna 6', value: 'C6' },
  { label: 'C7 - Coluna 7', value: 'C7' },
  { label: 'C8 - Coluna 8', value: 'C8' },
  { label: 'C9 - Coluna 9', value: 'C9' },
  { label: 'C10 - Coluna 10', value: 'C10' },
  { label: 'C11 - Coluna 11', value: 'C11' },
  { label: 'C12 - Coluna 12', value: 'C12' },
  { label: 'C13 - Coluna 13', value: 'C13' },
  { label: 'C14 - Coluna 14', value: 'C14' },
  { label: 'C15 - Coluna 15', value: 'C15' },
  { label: 'C16 - Coluna 16', value: 'C16' },
  { label: 'C17 - Coluna 17', value: 'C17' },
  { label: 'C18 - Coluna 18', value: 'C18' },
  { label: 'C19 - Coluna 19', value: 'C19' },
  { label: 'C20 - Coluna 20', value: 'C20' },
  { label: 'C21 - Coluna 21', value: 'C21' },
  { label: 'C22 - Coluna 22', value: 'C22' },
  { label: 'C23 - Coluna 23', value: 'C23' },
  { label: 'C24 - Coluna 24', value: 'C24' },
  { label: 'C25 - Coluna 25', value: 'C25' },
  { label: 'C26 - Coluna 26', value: 'C26' },
  { label: 'C27 - Coluna 27', value: 'C27' },
  { label: 'C28 - Coluna 28', value: 'C28' },
  { label: 'C29 - Coluna 29', value: 'C29' },
  { label: 'C30 - Coluna 30', value: 'C30' },
];

export { OptionsStep1, OptionsStep2, OptionsStep3, OptionsStep4 };
