/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useState, useContext } from 'react';

import api from '../services/api';
import { useOSView } from './OSView';
import { feedbackSuccess, feedbackError } from '../functions/MessageFeedback';

const OSGerirContext = createContext<any>({});

export const OSGerirProvider: React.FC = ({ children }) => {
  const [loading, setloading] = useState(true);

  const { id_os_view, id_cliente_view, os } = useOSView();

  async function save(req_peca: any, id_req_peca: number) {
    setloading(true);

    try {
      if (id_req_peca) {
        const response = await api.put(`req_peca/${id_req_peca}`, req_peca);

        feedbackSuccess('Item salvo');
        setloading(false);

        return response;
      } else {
        const response = await api.post(`req_peca`, req_peca);

        feedbackSuccess('Item salvo');
        setloading(false);

        return response;
      }
    } catch (err) {
      feedbackError('Erro ao salvar');

      setloading(false);
    }
  }

  async function getReqPecas() {
    try {
      const response = await api.get(`req_peca?id_os=${id_os_view}`);
      return response;
    } catch (err) {
      alert('Falha ao carregar a requisição. Tente novamente');
    }
  }

  return (
    <OSGerirContext.Provider
      value={{
        save,
        getReqPecas,
        loading,
        setloading,
        id_os_view,
        id_cliente_view,
        os,
      }}
    >
      {children}
    </OSGerirContext.Provider>
  );
};

export function useOSGerir() {
  const context = useContext(OSGerirContext);
  return context;
}
