import { parseISO, format, isValid } from 'date-fns';
import ptLocale from 'date-fns/locale/pt';

async function convertDate(dateTime: string) {
  const valid = isValid(new Date(dateTime));

  if (valid) {
    const data_criacao = await parseISO(dateTime);
    const data_br = await format(data_criacao, "dd'/'MM'/'yy", {
      locale: ptLocale,
    });

    return data_br;
  } else {
    return;
  }
}

export default convertDate;
