import styled from 'styled-components';

export const container = styled.div`
  flex: 1;
`;

export const List_container = styled.div`
  flex-direction: row;
`;

export const BodyList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: center;
`;

export const FormFilter = styled.div`
  width: 200px;
  font-size: 12px;
  padding: 6px;
  margin-left: 4px;
  border-radius: 4px;
  border: 1px solid #9e9e9e;
  background-color: #fff;
`;
