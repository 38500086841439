/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Image, Tooltip } from 'antd';

import { Container, Box } from './styles';
import api from '../../../../services/api';
import { useGlobalAttributes } from '../../../../contexts/GlobalAttributes';
import { feedbackError } from '../../../../functions/MessageFeedback';
import { DeleteOutlined, StarOutlined, StarFilled } from '@ant-design/icons';

interface IProps {
  origem: string;
  id_table_origem: number;
}

const ImagesPartList = (props: IProps) => {
  const [listImages, setListImages] = useState<any[]>([]);

  const { origem, id_table_origem } = props;

  const { refresh } = useGlobalAttributes();

  useEffect(() => {
    getImages();
  }, [origem, id_table_origem, refresh]);

  async function getImages() {
    try {
      const response = await api.get(
        `foto?origin=${origem}&id_table_origin=${id_table_origem}`,
      );
      setListImages(response.data.data);
    } catch (err) {
      return;
    }
  }

  async function removeImage(id_foto: number) {
    try {
      await api.delete(`foto/${id_foto}}`);
      getImages();
    } catch (err) {
      feedbackError('Erro ao excluir a foto');
    }
  }

  async function definePriority(id_foto: number, action: string) {
    if (action === 'add') {
      const lowPriority = { priority: 2 };
      const highPriority = { priority: 1 };

      await Promise.all(
        listImages.map(async (item): Promise<void> => {
          if (item.priority === 1) await updateFoto(item.id_foto, lowPriority);
        }),
      );

      await updateFoto(id_foto, highPriority);

      await getImages();
    }
  }

  async function updateFoto(id_foto: number, data: any) {
    await api.put(`foto/${id_foto}}`, data);
  }

  return (
    <Container>
      {listImages.map(image => (
        <Box key={image}>
          <Image src={image.url} height="80px" />
          <Tooltip placement="topRight" title="Apagar">
            <DeleteOutlined
              style={{ fontSize: '16px', color: 'red' }}
              onClick={() => {
                if (
                  window.confirm(
                    'Você tem certeza que deseja remover essa foto?',
                  )
                )
                  removeImage(image.id_foto);
              }}
            />
          </Tooltip>
          {image.priority === 1 ? (
            <Tooltip placement="topRight" title="Principal">
              <StarFilled
                style={{ fontSize: '16px', color: '#ffd10f', paddingLeft: 6 }}
              />
            </Tooltip>
          ) : (
            <Tooltip placement="topRight" title="Tornar principal">
              <StarOutlined
                onClick={() => definePriority(image.id_foto, 'add')}
                style={{ fontSize: '16px', color: '#aaa', paddingLeft: 6 }}
              />
            </Tooltip>
          )}
        </Box>
      ))}
    </Container>
  );
};

export default ImagesPartList;
