/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import convertDateTime from '../../../../functions/ConvertDateTime';
import GetApelidoEquip from '../../../../functions/GetApelidoEquip';
import GetNameEdificio from '../../../../functions/GetNameEdificio';
import { IOsReport } from '../../@types/IOsReport';

interface IProps {
  os: IOsReport;
}

const RowTable = ({ os }: IProps) => {
  const [dataCriacao, setDataCriacao] = useState('');
  const [edificio, setEdificio] = useState('');
  const [apelidoEquip, setApelidoEquip] = useState('');

  useEffect(() => {
    convertData();
  }, [os]);

  async function convertData() {
    const data_convertida = await convertDateTime(os.created_at);
    setDataCriacao(data_convertida);

    const { edificio, apelido } = await GetNameEdificio(os.id_cliente);
    setEdificio(`${edificio} (${apelido})`);

    const apelido_equip = await GetApelidoEquip(os.id_equipamento);
    setApelidoEquip(apelido_equip);
  }

  return (
    <tr key={os.id_os}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {os?.id_os}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500">{edificio}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {apelidoEquip}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500">
        <Tooltip title={os?.descricao} placement="right">
          {os?.descricao.substring(35, 0)} ...
        </Tooltip>
      </td>
      <td className="px-3 py-4 text-sm text-gray-500">
        <Tooltip title={os?.parecer} placement="right">
          {os?.parecer.substring(35, 0)} ...
        </Tooltip>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {dataCriacao}
      </td>
    </tr>
  );
};

export default RowTable;
