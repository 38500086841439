import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  cliente: {
    id_cliente: number;
    id_setor: number;
    edificio: string;
    apelido: string;
    status: string;
  };
}

const RowTable = ({ cliente }: IProps) => {
  return (
    <tr key={cliente.id_cliente}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {cliente.edificio}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {cliente.apelido}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">
        {cliente.status === 'ativo' ? (
          <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
            {cliente.status}
          </span>
        ) : (
          <span className="inline-flex rounded-full bg-red-200 px-2 text-xs font-semibold leading-5 text-red-800">
            {cliente.status}
          </span>
        )}
      </td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <Link
          to={{
            pathname: '/clienteregister',
            state: { id_cliente: cliente.id_cliente, mode: 'edit' },
          }}
        >
          <a href="#" className="text-indigo-600 hover:text-indigo-900">
            Editar
            <span className="sr-only"></span>
          </a>
        </Link>
      </td>
    </tr>
  );
};

export default RowTable;
